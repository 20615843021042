import { defineMessages } from 'react-intl';

export default defineMessages({
    HomeDetails: {
        id: 'policy.common.component.policy-dwelling-info-ho6.Home Details',
        defaultMessage: 'Home Details'
    },
    locationAddress: {
        id: 'policy.common.component.policy-dwelling-info-ho6.Location Address',
        defaultMessage: 'Location Address'
    },
    yearBuilt: {
        id: 'policy.common.component.policy-dwelling-info-ho6.Year Built',
        defaultMessage: 'Year Built'
    }
});
