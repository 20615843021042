import { defineMessages } from 'react-intl';

export default defineMessages({
    viewInvoices: {
        id: 'billing.billing-summary.View Invoices',
        defaultMessage: 'View Invoices'
    },
    viewDocuments: {
        id: 'billing.billing-summary.View Documents',
        defaultMessage: 'View Documents'
    },
    makeAPayment: {
        id: 'billing.billing-summary.Make a Payment',
        defaultMessage: 'Make a Payment'
    },
    oneTimePayment: {
        id: 'billing.billing-summary.One-Time Payment',
        defaultMessage: 'Make a One-Time Payment'
    },
    viewPolicies: {
        id: 'billing.billing-summary.View Policies',
        defaultMessage: 'View Policies'
    },
    viewBillingDocuments: {
        id: 'billing.billing-summary.Billing Documents',
        defaultMessage: 'Billing Documents'
    },
    changePaymentMethod: {
        id: 'billing.billing-summary.Change Payment Method',
        defaultMessage: 'Change Payment Method'
    },
    setupAutomaticPayments: {
        id: 'billing.billing-summary.Setup Automatic Payments',
        defaultMessage: 'Setup Automatic Payments'
    },
    selectInvoicesToPay: {
        id: 'billing.billing-summary.Select Invoices to Pay',
        defaultMessage: 'Select Invoices to Pay'
    },
    policies: {
        id: 'billing.billing-summary.Policies',
        defaultMessage: 'Policies'
    },
    payNow: {
        id: 'billing.billing-summary.Pay Now',
        defaultMessage: 'Pay Now'
    },
    personalAutoPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Personal Auto Policy (Automatic Payments Enabled)',
        defaultMessage: 'Personal Auto Policy (Automatic Payments Enabled)'
    },
    personalAutoPolicy: {
        id: 'billing.billing-summary.Personal Auto Policy',
        defaultMessage: 'Personal Auto Policy'
    },
    homeownersPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Homeowners Policy (Automatic Payments Enabled)',
        defaultMessage: 'Homeowners Policy (Automatic Payments Enabled)'
    },
    homeownersPolicy: {
        id: 'billing.billing-summary.Homeowners Policy',
        defaultMessage: 'Homeowners Policy'
    },
    dwellingFire: {
        id: 'billing.billing-summary.DwellingFire Policy',
        defaultMessage: 'Dwelling Fire Policy'
    },
    dwellingFirePolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.DwellingFire Policy (Automatic Payments Enabled)',
        defaultMessage: 'Dwelling Fire Policy (Automatic Payments Enabled)'
    },
    personalExcess: {
        id: 'billing.billing-summary.Personal Excess Policy',
        defaultMessage: 'Personal Umbrella Policy'
    },
    personalExcessPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.PersonalExcess Policy (Automatic Payments Enabled)',
        defaultMessage: 'Personal Umbrella Policy (Automatic Payments Enabled)'
    },
    businessOwnersPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Business Owners Policy (Automatic Payments Enabled)',
        defaultMessage: 'Business Owners Policy (Automatic Payments Enabled)'
    },
    businessOwnersPolicy: {
        id: 'billing.billing-summary.Business Owners Policy',
        defaultMessage: 'Business Owners Policy'
    },
    commercialAutoPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Commercial Auto Policy (Automatic Payments Enabled)',
        defaultMessage: 'Commercial Auto Policy (Automatic Payments Enabled)'
    },
    commercialAutoPolicy: {
        id: 'billing.billing-summary.Commercial Auto Policy',
        defaultMessage: 'Commercial Auto Policy'
    },
    commercialPropertyPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Commercial Property Policy (Automatic Payments Enabled)',
        defaultMessage: 'Commercial Property Policy (Automatic Payments Enabled)'
    },
    commercialPropertyPolicy: {
        id: 'billing.billing-summary.Commercial Property Policy',
        defaultMessage: 'Commercial Property Policy'
    },
    commercialPackagePolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Commercial Package Policy (Automatic Payments Enabled)',
        defaultMessage: 'Commercial Package Policy (Automatic Payments Enabled)'
    },
    commercialPackagePolicy: {
        id: 'billing.billing-summary.Commercial Package Policy',
        defaultMessage: 'Commercial Package Policy'
    },
    generalLiabilityPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.General Liability Policy (Automatic Payments Enabled)',
        defaultMessage: 'General Liability Policy (Automatic Payments Enabled)'
    },
    generalLiabilityPolicy: {
        id: 'billing.billing-summary.General Liability Policy',
        defaultMessage: 'General Liability Policy'
    },
    inlandMarinePolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Inland Marine Policy (Automatic Payments Enabled)',
        defaultMessage: 'Inland Marine Policy (Automatic Payments Enabled)'
    },
    inlandMarinePolicy: {
        id: 'billing.billing-summary.Inland Marine Policy',
        defaultMessage: 'Inland Marine Policy'
    },
    workersCompPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Workers Compensation Policy (Automatic Payments Enabled)',
        defaultMessage: 'Workers Compensation Policy (Automatic Payments Enabled)'
    },
    workersCompPolicy: {
        id: 'billing.billing-summary.Workers Compensation Policy',
        defaultMessage: 'Workers Compensation Policy'
    },
    workersComp7PolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Workers Compensation (v7) Policy (Automatic Payments Enabled)',
        defaultMessage: 'Workers Compensation (v7) Policy (Automatic Payments Enabled)'
    },
    workersComp7Policy: {
        id: 'billing.billing-summary.Workers Compensation (v7) Policy',
        defaultMessage: 'Workers Compensation (v7) Policy'
    },
    policyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Policy (Automatic Payments Enabled)',
        defaultMessage: 'Policy (Automatic Payments Enabled)'
    },
    policy: {
        id: 'billing.billing-summary.Policy',
        defaultMessage: 'Policy'
    },
    ok: {
        id: 'billing.billing-summary.OK',
        defaultMessage: 'OK'
    },
    invoicesAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Invoices (Automatic Payments Enabled)',
        defaultMessage: 'Invoices (Automatic Payments Enabled)'
    },
    paymentSchedule: {
        id: 'billing.billing-summary.Payment Schedule',
        defaultMessage: 'Payment Schedule'
    },
    paymentInformation: {
        id: 'billing.billing-summary.Payment Information',
        defaultMessage: 'Payment Information'
    },
    startingDate: {
        id: 'billing.billing-summary.Starting Date',
        defaultMessage: 'Starting Date'
    },
    paymentMethodHasBeenChanged: {
        id: 'billing.billing-summary.Payment method has been changed.',
        defaultMessage: 'Payment method has been changed.'
    },
    automaticPaymentOfHasBeenAppliedToYourAccount: {
        id: 'billing.billing-summary.Your payment method has been successfully updated to automatic renewal.',
        defaultMessage: 'Your payment method has been successfully updated to automatic renewal.'
    },
    yourAutomaticPaymentsWillBeginOn: {
        id: 'billing.billing-summary.Your automatic payments will begin on x.',
        defaultMessage: 'Your automatic payments will begin on {startingDate}.'
    },
    paymentOfHasBeenAppliedToYourAccount: {
        id: 'billing.billing-summary.Payment of x has been applied to your account.',
        defaultMessage: 'Payment of {paidAmount} has been applied to your account.'
    },
    amountToPay: {
        id: 'billing.billing-summary.Amount to Pay',
        defaultMessage: 'Amount to Pay'
    },
    paymentRequestFailed: {
        id: 'billing.billing-summary.Payment request failed',
        defaultMessage: 'Payment request failed'
    },
    sorryYourPaymentCouldNotBeProcessedAtThisTime: {
        id: 'billing.billing-summary.Sorry your payment could not be processed at this time',
        defaultMessage: 'Sorry your payment could not be processed at this time'
    },
    accountUpdateFailed: {
        id: 'billing.billing-summary.Account update failed',
        defaultMessage: 'Account update failed'
    },
    sorryWeWereNotAbleToSetupAutomaticPaymentsOnYourAccount: {
        id: 'billing.billing-summary.Sorry, we were not able to setup automatic payments on your account',
        defaultMessage: 'Sorry, we were not able to setup automatic payments on your account'
    },
    changingPaymentMethod: {
        id: 'billing.billing-summary.Changing payment method',
        defaultMessage: 'Changing payment method'
    },
    thereWasAProblemChangingThePaymentMethod: {
        id: 'billing.billing-summary.There was a problem changing the payment method',
        defaultMessage: 'There was a problem changing the payment method'
    },
    invoicesToPay: {
        id: 'billing.billing-summary.Invoices to Pay',
        defaultMessage: 'Invoices to Pay'
    },
    paidInvoices: {
        id: 'billing.billing-summary.Paid Invoices',
        defaultMessage: 'Paid Invoices'
    },
    Disbursment: {
        id: 'billing.billing-summary.Disbursment',
        defaultMessage: 'Disbursements'
    },
    
    Date: {
        id: 'billing.billing-summary.Disbursment.Date Issued',
        defaultMessage: 'Date Issued'
    },
    Amount: {
        id: 'billing.billing-summary.Amount',
        defaultMessage: 'Amount'
    },
    Reason: {
        id: 'billing.billing-summary.Reason',
        defaultMessage: 'Reason'
    },

    Disbursment: {
        id: 'billing.billing-summary.Payment',
        defaultMessage: 'Payments'
    },
    Disbursment: {
        id: 'billing.billing-summary.Invoice Schedule',
        defaultMessage: 'Invoice Schedule'
    },


});
