import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitle: {
        id: 'policy.view.policy-details.Policy Details',
        defaultMessage: 'Policy Details: {policyNumber}'
    },
    currentTab: {
        id: 'policy.view.policy-details.tab.Current',
        defaultMessage: 'Current'
    },
    changePolicy: {
        id: 'policy.view.policy-details.Change Policy',
        defaultMessage: 'Change Policy'
    },
    renewalTab: {
        id: 'policy.view.policy-details.tab.Renewal',
        defaultMessage: 'Renewal'
    },
    DownloadIDCard: {
        id: 'policy.view.policy-details.Download ID Card',
        defaultMessage: 'Download ID Card'
    }
});
