import React, {
    useState, useContext, useEffect, useCallback
} from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { WizardPage, WizardPageTemplate, wizardProps } from 'gw-portals-wizard-react';
import { TranslatorContext } from '@jutro/locale';
import { useDependencies } from 'gw-portals-dependency-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import {
    CheckboxField, IconButton, Chevron, ModalNextProvider
} from '@jutro/components';
import { useValidation } from 'gw-portals-validation-react';
import { messages as commonMessages } from 'gw-platform-translations';
import metadata from './BuildingPage.metadata.json5';
import './BuildingPage.scss';
import messages from '../../FNOLCP.messages';

function FNOLCPBuildingPage(props) {
    const { wizardData: claimVM, updateWizardData, authHeader } = props;
    const translator = useContext(TranslatorContext);
    const { FNOLService } = useDependencies('FNOLService');
    const [totalBuildings, updateTotalBuildings] = useState([]);
    const [policyRiskUnits, setPolicyRiskUnits] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        registerComponentValidation
    } = useValidation('BuildingPage');

    const getResponseData = useCallback((responseData, oldClaimVM) => {
        const addressData = responseData.lobs.commercialProperty;
        const contactsInfo = addressData.propertyRiskUnits.map((contact) => {
            const selected = oldClaimVM.policy.lobs
                .commercialProperty.propertyRiskUnits.value.findIndex(
                    (unit) => unit.policySystemId === contact.policySystemId
                ) > -1;
            const addressline2 = contact.addressline2 ? `, ${contact.addressline2}` : '';
            const buildingAddress = `${contact.addressLine1} ${addressline2}, ${contact.city}`;
            return {
                documentCheckbox: contact,
                documentBuilding: contact.buildingNumber,
                documentLocation: buildingAddress,
                documentLocationNo: contact.locationNumber,
                documentLocationId: contact.policySystemId,
                documentisCheck: selected
            };
        });
        return contactsInfo;
    }, []);

    const getPolicySummaryRiskUnitsFromService = useCallback(() => {
        if (!claimVM.policy.lobs.commercialProperty) {
            claimVM.policy.lobs.value = { commercialProperty: { propertyRiskUnits: [] } };
        }
        const oldClaimVM = _.cloneDeep(claimVM);
        const policyNumber = _.get(claimVM.value, 'policy.policyNumber');
        const lossDate = _.get(claimVM.value, 'lossDate');
        setIsLoading(true);
        return FNOLService.getPolicySummaryRiskUnits(policyNumber, lossDate, authHeader).then(
            (response) => {
                setIsLoading(false);
                setPolicyRiskUnits(response);
                const contactdatatable = getResponseData(response, oldClaimVM);
                updateTotalBuildings([...contactdatatable]);
            }
        );
    }, [FNOLService, authHeader, claimVM, getResponseData]);

    useEffect(
        () => {
            getPolicySummaryRiskUnitsFromService();
        },
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleClose = useCallback(
        (contact) => {
            const index = totalBuildings.indexOf(contact);
            totalBuildings[index].documentisCheck = false;
            updateTotalBuildings([...totalBuildings]);
        },
        [totalBuildings]
    );

    const getCloseButton = useCallback(
        (contact) => {
            return (
                <IconButton
                    id="closeButtonIcon"
                    onClick={() => handleClose(contact)}
                    icon="mi-close"
                />
            );
        },
        [handleClose]
    );

    const handleClick = useCallback(
        (value, contact) => {
            const selectedIndex = totalBuildings.indexOf(contact);
            totalBuildings[selectedIndex].documentisCheck = value;
            updateTotalBuildings([...totalBuildings]);
            const locationList = _.get(
                claimVM,
                'policy.lobs.commercialProperty.propertyRiskUnits.value'
            );
            const riskUnitLocationList = _.get(
                policyRiskUnits,
                'lobs.commercialProperty.propertyRiskUnits'
            );
            const index = riskUnitLocationList.findIndex((unit) => {
                return unit.policySystemId === contact.documentLocationId;
            });
            if (value) {
                locationList.push(riskUnitLocationList[index]);
                _.set(
                    claimVM,
                    'policy.lobs.commercialProperty.propertyRiskUnits.value',
                    locationList
                );
            } else {
                const updatedList = locationList.splice(index, 0);
                _.set(
                    claimVM,
                    'policy.lobs.commercialProperty.propertyRiskUnits.value',
                    updatedList
                );
            }
            updateWizardData(claimVM);
        },
        [claimVM, totalBuildings, policyRiskUnits, updateWizardData]
    );

    const getSelectedValue = useCallback((item, index, { path: property }) => {
        return item[property];
    }, []);

    const getDataCell = useCallback((item, index, { path: property }) => {
        return item[property];
    }, []);

    const getCheckboxDataCell = useCallback(
        (item, index) => {
            return (
                <CheckboxField
                    name={item.documentCheckbox.policySystemId}
                    id={item.documentCheckbox.policySystemId}
                    value={item.documentisCheck}
                    onValueChange={(e) => handleClick(e, item, index)}
                />
            );
        },
        [handleClick]
    );

    const onNext = useCallback(() => {
        const modifiedBuildingsArray = _.filter(totalBuildings, (item) => item.documentisCheck);
        if (modifiedBuildingsArray.length === 0) {
            return false;
        }
        const buildings = _.get(claimVM.value, 'policy.lobs.commercialProperty.propertyRiskUnits');
        const policyNumber = _.get(claimVM.value, 'policy.policyNumber');
        const selectedBuildings = buildings.filter((building) => {
            return modifiedBuildingsArray.some((modifiedBuilding) => {
                return building.policySystemId === modifiedBuilding.documentCheckbox.policySystemId;
            });
        });
        _.set(claimVM.value, 'policy.lobs.commercialProperty.propertyRiskUnits', selectedBuildings);
        setIsLoading(true);
        setLoadingMessage(translator(messages.cpCreateDraftClaim));
        return FNOLService.getFNOLDetails(policyNumber, claimVM.value, authHeader)
            .then((response) => {
                claimVM.value = response;
                return claimVM;
            })
            .catch(() => {
                ModalNextProvider.showAlert({
                    title: messages.cpCreateDraftErrorTitle,
                    message: messages.cpCreateDraftErrorMessage,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [totalBuildings, claimVM, translator, FNOLService, authHeader]);

    const isSelectedBuildings = useCallback(() => {
        if (totalBuildings.length === 0) {
            return null;
        }
        const selectedBuildings = _.filter(totalBuildings, (item) => item.documentisCheck);
        if (selectedBuildings.length > 0) {
            return true;
        }
        return false;
    }, [totalBuildings]);

    const renderAccordionHeader = (isOpen, totalBuildingDetails) => {
        const buildingHeader = `${
            _.filter(totalBuildingDetails, (item) => item.documentisCheck).length
        }
            ${translator(messages.cpSelectedBuildings)}`;
        return (
            <React.Fragment>
                <Chevron isOpen={isOpen} align="right" />
                <h4>{buildingHeader}</h4>
            </React.Fragment>
        );
    };

    const overrideProps = {
        cpBuildingDataTable: {
            data: totalBuildings
        },
        selectedGrid: {
            data: _.filter(totalBuildings, (item) => item.documentisCheck)
        },
        buildingSearchAccordion: {
            visible: _.filter(totalBuildings, (item) => item.documentisCheck).length !== 0
        },
        additionalAccordionDetails: {
            renderHeader: (isOpen) => {
                return renderAccordionHeader(isOpen, totalBuildings);
            }
        },
        buildingPageLoader: {
            loaded: !isLoading,
            loadingMessage: translator(loadingMessage)
        },
        cpBuildingDetails: {
            visible: !isLoading
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            getSelectedValue: getSelectedValue,
            getCheckboxDataCell: getCheckboxDataCell,
            getCloseButton: getCloseButton,
            getDataCell: getDataCell
        }
    };

    useEffect(() => {
        registerComponentValidation(isSelectedBuildings);
    }, [isSelectedBuildings, registerComponentValidation]);

    return (
        <WizardPage
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            template={WizardPageTemplate}
            onNext={onNext}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

FNOLCPBuildingPage.propTypes = wizardProps;
export default withRouter(withAuthenticationContext(FNOLCPBuildingPage));
