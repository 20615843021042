import { defineMessages } from 'react-intl';

export default defineMessages({
    dueDate: {
        id: 'billing.invoices-table.Due Date',
        defaultMessage: 'Due Date'
    },
    billStatus: {
        id: 'billing.invoices-table.Bill Status',
        defaultMessage: 'Bill Status'
    },
    lastPaymentDate: {
        id: 'billing.invoices-table.Last Payment Date',
        defaultMessage: 'Payment Date'
    },
    invoiceTotal: {
        id: 'billing.invoices-table.Invoice Total',
        defaultMessage: 'Invoice Total'
    },
    paid: {
        id: 'billing.invoices-table.Paid',
        defaultMessage: 'Paid'
    },
    outstanding: {
        id: 'billing.invoices-table.Outstanding',
        defaultMessage: 'Outstanding'
    },
    invoice: {
        id: 'billing.invoices-table.Invoice Number',
        defaultMessage: 'Invoice Number'
    },
    pay: {
        id: 'billing.invoices-table.Pay',
        defaultMessage: 'Pay'
    },
    noMatchingPayments: {
        id: 'billing.invoices-table.No matching payments.',
        defaultMessage: 'No matching payments.'
    },
    policyNumberWithTerm: {
        id: 'billing.invoices-table.Policy Number.',
        defaultMessage: 'Policy Number.'
    }
});
