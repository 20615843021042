import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ImageComponent from '../ImageComponent/ImageComponent';
import styles from './EntryPageTemplateWithOutLogo.module.scss';

function EntryPageTemplateWithOutLogo(props) {
    const { contentToRender } = props;

    return (
        <Fragment>
            <div id="entryPageTemplate" className={styles.entryPageTemplate}>
                <div id="entryPageTemplateContent" className={styles.entryPageTemplateContent}>
                    <div
                        id="entryPageContentContainer"
                        className={styles.entryPageContentContainer}
                    >
                        {contentToRender}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

EntryPageTemplateWithOutLogo.propTypes = {
    contentToRender: PropTypes.shape({}).isRequired
};

export default EntryPageTemplateWithOutLogo;
