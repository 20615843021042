import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { useAuthentication, AUTH_ERRORS } from 'gw-digital-auth-react';
import AuthMessageComponent from '../AuthMessageComponent/AuthMessageComponent';
import { isEmpty } from '../LoginUtil';

import messages from './ResetPasswordComponent.messages';
import metadata from './ResetPasswordComponent.metadata.json5';
import styles from '../CommonLogin.module.scss';
import { Loader } from '@jutro/components';
import { OtpService } from 'nlc-capability-otp';

function ResetPasswordComponent(props) {
    const PWD_REGEX = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{12,}$');
    const translator = useContext(TranslatorContext);
    const [formData, updateFormData] = useState('');
    const [resetCode, setResetCode] = useState();
    const [userName, setUserName] = useState();
    const [pageError, updatePageError] = useState();
    const [isLoading, setLoading] = useState(false);
    const [pageView, setPageView] = useState();
    const [moveToSignIn, setMoveToSignIn] = useState();
    
    const {
        verifyResetCode, changePassword, authHeader, login, getPasswordToken_Ext
    } = useAuthentication();
    const { location, history } = props;

    useEffect(() => {
        setLoading(true);
        const parsedParams = queryString.parse(location.search);
        setLoading(true);
        verifyCode(parsedParams.token);        
    }, []);

    const handleNewPassword = useCallback(
        (evt) => {
            if (evt) {
                evt.preventDefault();
            }

            if (isEmpty(formData.newPassword) || isEmpty(formData.confirmPassword)) {
                updatePageError(AUTH_ERRORS.fieldEmpty);
                return Promise.resolve();
            }
            if(!PWD_REGEX.test(formData.newPassword)){
                updatePageError('invalidPassword');
                return Promise.resolve();
            }
            if (!_.isEqual(formData.newPassword, formData.confirmPassword)) {
                updatePageError(AUTH_ERRORS.fieldIncorrect);
                return Promise.resolve();
            }

            const newPasswordObject = {
                new_password: formData.newPassword,
                code: resetCode
            };

            return changePassword(newPasswordObject, authHeader)
                .then((data) => {                    
                    const inputData = new FormData();
                    inputData.append('userName',  userName);
                    const mfaData = {
                        method: 'POST',
                        body: inputData,
                        redirect: 'follow',
            
                    }                    
                    OtpService.updatePasswordReset(mfaData).then((res)=>{
                        if(res.status === 200) {
                            setPageView(AUTH_ERRORS.autoLoginError);
                        }
                    });                                    
                })
                .catch((err) => {                   
                    switch (err.error) {
                        case AUTH_ERRORS.invalidTokenOrEmail:
                            setPageView(AUTH_ERRORS.invalidTokenOrEmail);
                            break;
                        default: 
                            getCode();
                            //setPageView(AUTH_ERRORS.invalidTokenOrEmail);                         
                            updatePageError('invalidPassword');
                            break;
                    }
                });
        },
        [authHeader, changePassword, formData, history, login, resetCode]
    );

    const continueToSignIn = useCallback(
        (evt) => {
            if (evt) {
                evt.preventDefault();
            }
            setMoveToSignIn(true);
        },
        []
    );

    const getCode = useCallback(() => {
        setLoading(true);
        getPasswordToken_Ext(userName).then(
            (data) => {
                verifyCode(data.res.code);
            },
            () => {
                setPageView(AUTH_ERRORS.invalidToken);
                setLoading(false);
            }
        );
           
    },[getPasswordToken_Ext, userName]);

    const verifyCode = useCallback((code) => {
        verifyResetCode(code).then(
            (data) => {
                setResetCode(data.res.newCode);
                setUserName(data.res.userName);
                setLoading(false);
            },
            () => {
                setPageView(AUTH_ERRORS.invalidToken);
                setLoading(false);
            }
        );
           
    },[verifyResetCode]);

    const readValue = useCallback((id, path) => _.get(formData, path), [formData]);
    const writeValue = useCallback(
        (value, path) => updateFormData({
            ...formData,
            [path]: value
        }),
        [formData]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveValue: readValue,
        resolveComponentMap: {
            authmessagecomponent: AuthMessageComponent
        }
    };

    const overrides = {
        '@field': {
            onValueChange: writeValue
        },
        pwdMessage:{
            message: translator(messages.passwordMessage),
            visible: pageView === undefined
        },
        errorMsg: {
            visible: !_.isUndefined(pageError),
            message: translator(messages[pageError])
        },
        newPasswordFieldsContainer: {
            visible: !pageView
        },
        passwordForm: {
            onSubmit: handleNewPassword
        },
        actionsWrapperSingleButton: {
            className: classNames(styles.actionsWrapperSingleButton, styles.coupleButtonStyle)
        },
        changePassword: {
            trigger: handleNewPassword
        },
        continueToSignIn: {
            trigger: continueToSignIn
        },
        successfulContainer: {
            visible: pageView === AUTH_ERRORS.autoLoginError,           
        },       
        invalidTokenError: {
            visible: pageView === AUTH_ERRORS.invalidToken
        },
        invalidTokenOrPasswordError: {
            visible: pageView === AUTH_ERRORS.invalidTokenOrEmail
        },
        invalidAttemptError: {
            visible: pageView === AUTH_ERRORS.invalidAttempt
        }
    };

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (moveToSignIn) {
        const nextState = { pathname: '/login-page'};
        return <Redirect to={nextState} />;
    }

    return renderContentFromMetadata(metadata.componentContent, overrides, resolvers);
}

ResetPasswordComponent.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(ResetPasswordComponent);
