import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import CoverableIterableComponent from './CoverableIterableComponent/CoverableIterableComponent';
import metadata from './PolicyCommonCoverables.metadata.json5';
import './PolicyCommonCoverables.messages';

function PolicyCommonCoverables(props) {
    const { value: coverables } = props;
    const policyCoverables = useMemo(() => {
        return _.filter(coverables, (coverable) => {
            return coverable.coverages.length > 0;
        });
    }, [coverables]);

    const overrides = {
        commonCoverablesIterable: {
            data: policyCoverables
        }
    };

    const resolvers = {
        resolveComponentMap: {
            coverableiterablecomponent: CoverableIterableComponent
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.componentContent,
                policyCoverables,
                id,
                path,
                overrides
            );
        },
        [policyCoverables, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyCoverables}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={readValue}
        />
    );
}

PolicyCommonCoverables.propTypes = {
    value: PropTypes.arrayOf(PropTypes.shape({}))
};

PolicyCommonCoverables.defaultProps = {
    value: []
};

export default PolicyCommonCoverables;
