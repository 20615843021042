import React, { useCallback, useContext,useState,useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './DFSectionIICoverages.metadata.json5';
import styles from './DFSectionIICoverages.module.scss';
import './DFSectionIICoverages';
//import { get } from 'core-js/core/dict';



function DFSectionIICoverages(props) {
    const { data : sectionIILiabilityCoverages_Ext } = props;

    // const getDataTableCell = useCallback((item, index, { path: property }) => {
    //     return item[property];
    // });

     const getLimit= useCallback((sectionIILiabilityCoverages_Ext) =>{
        var i 
        for( let p in sectionIILiabilityCoverages_Ext){
           
            if(sectionIILiabilityCoverages_Ext[p].personalLiability == 'L – Personal Liability (Each Occurrence)'){
                i = 'L – Personal Liability (Each Occurrence)'
               
            }
              
        }
        return ` ${i} ` 
     });

     const getMedicalPayments = useCallback((sectionIILiabilityCoverages_Ext) =>{
        var i 
        for( let p in sectionIILiabilityCoverages_Ext){
           
            if(sectionIILiabilityCoverages_Ext[p].medicalPaymentsToOthers == 'M – Medical Payments To Others (Each Person)'){
                i = 'M – Medical Payments To Others (Each Person)'
                
            }
              
        }
        return ` ${i} ` 

    });

    const getCovLLimit = useCallback((sectionIILiabilityCoverages_Ext) =>{
        var i 
        for( let p in sectionIILiabilityCoverages_Ext){
            if(sectionIILiabilityCoverages_Ext[p].personalLiability == 'L – Personal Liability (Each Occurrence)'){
                i = sectionIILiabilityCoverages_Ext[p].terms[0].amount
                return `Cov L Limit \u00A0\u00A0\u00A0 $${i} `
            }
        }
        return `Cov L Limit \u00A0\u00A0\u00A0 - ` 
       
        
        

    });

    const getCovMLimit = useCallback((sectionIILiabilityCoverages_Ext) =>{
        var i 
        for( let p in sectionIILiabilityCoverages_Ext){
            if(sectionIILiabilityCoverages_Ext[p].medicalPaymentsToOthers == 'M – Medical Payments To Others (Each Person)'){
                i = sectionIILiabilityCoverages_Ext[p].terms[0].amount
                return `Cov M Limit \u00A0\u00A0\u00A0 $${i} `
            }
        }
        return `Cov M Limit \u00A0\u00A0\u00A0 - `
       

    });

    

   
    const overrides = {
        '@element': {
            textAlign: 'left'
        },

        infoTable:{
            data: _.get(sectionIILiabilityCoverages_Ext)
        },

        covLLimit:{
            value: getCovLLimit(sectionIILiabilityCoverages_Ext)
        },
        covMLimit:{
            value: getCovMLimit(sectionIILiabilityCoverages_Ext)
        },
        
       
    
        // limit:{
        //     value: getLimit(policyCoverages_Ext)
        //    },

        // rentation:{
        //     value: getRentation(policyCoverages_Ext)  
        //    }



    };

    const resolvers = {
        resolveCallbackMap:{
          //  getDataTableCell :  getDataTableCell 
            // liabilityLimit:getLimit,
            // rentation:getRentation

        },
        resolveClassNameMap: styles,

    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.componentContent,
                sectionIILiabilityCoverages_Ext,
                id,
                path,
                overrides
            );
        },
        [sectionIILiabilityCoverages_Ext, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={sectionIILiabilityCoverages_Ext}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

DFSectionIICoverages.propTypes = {
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
};

DFSectionIICoverages.defaultProps = {
    value: []
};

export default DFSectionIICoverages;
