import { defineMessages } from 'react-intl';

export default defineMessages({
    HomeDetails: {
        id: 'policy.common.component.policy-dwelling-info-ho4.Home Details',
        defaultMessage: 'Home Details'
    },
    locationAddress: {
        id: 'policy.common.component.policy-dwelling-info-ho4.Location Address',
        defaultMessage: 'Location Address'
    },
    yearBuilt: {
        id: 'policy.common.component.policy-dwelling-info-ho4.Year Built',
        defaultMessage: 'Year Built'
    }
});
