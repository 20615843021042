import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { WizardPage, wizardProps, WizardPageTemplate } from 'gw-portals-wizard-react';
import { ModalNextProvider } from '@jutro/components';

import { useValidation } from 'gw-portals-validation-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { Contact, Claim } from 'gw-capability-fnol-common-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { messages as commonMessages } from 'gw-platform-translations';
import metadata from './WhatPage.metadata.json5';
import messages from '../../FNOLWC.messages';

function FNOLWCWhatPage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const {
        wizardData: claimVM,
        updateWizardData,
        authHeader,
        history: {
            location: { state }
        }
    } = props;
    const translator = useContext(TranslatorContext);
    const { FNOLService } = useDependencies('FNOLService');
    const [isLoading, setIsLoading] = useState(false);
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('FNOLWCWhatPage');

    useEffect(
        () => {
            const lobExtension = claimVM.lobs.workersComp;
            const { injuryIncident } = lobExtension.value;

            if (!injuryIncident.injured) {
                injuryIncident.injured = new Contact({
                    displayName: 'New person',
                    gender: null,
                    dateOfBirth: null
                });
            }

            if (!injuryIncident.injured.primaryAddress) {
                injuryIncident.injured.initializePrimaryAddress();
            }
            updateWizardData(claimVM);
        },
        // Call once when page is mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const onNext = useCallback(() => {
        const policyNumber = _.get(claimVM.value, 'policy.policyNumber');
        const isClaimNumberAvailable = _.get(claimVM.value, 'claimNumber');
        if (!isClaimNumberAvailable) {
            setIsLoading(true);
            return FNOLService.getFNOLDetails(policyNumber, claimVM.value, authHeader)
                .then((response) => {
                    claimVM.value = new Claim(response);
                    return claimVM;
                })
                .catch(() => {
                    ModalNextProvider.showAlert({
                        title: messages.wcCreateDraftErrorTitle,
                        message: messages.wcCreateDraftErrorMessage,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                    return false;
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        return claimVM;
    }, [FNOLService, authHeader, claimVM]);

    const overrides = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'tablet' ? 'top' : 'left'
        },
        whatPageLoader: {
            loaded: !isLoading,
            loadingMessage: translator(messages.wcCreateDraftClaim)
        },
        whatPageContainer: {
            visible: !isLoading
        }
    };

    const { claimStatus } = state || {};

    const validateClaimStatus = useCallback(() => {
        return (
            !_.isNil(claimStatus)
            && claimStatus === translator({ id: 'typekey.ClaimState.draft', defaultMessage: 'Draft' })
        );
    }, [claimStatus, translator]);

    useEffect(() => {
        registerInitialComponentValidation(validateClaimStatus);
    }, [registerInitialComponentValidation, validateClaimStatus]);

    return (
        <WizardPage
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            onNext={onNext}
            template={WizardPageTemplate}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrides}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

FNOLWCWhatPage.propTypes = wizardProps;
export default withRouter(withAuthenticationContext(FNOLWCWhatPage));
