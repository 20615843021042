import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import appConfig from 'app-config';
import { useAuthentication } from 'gw-digital-auth-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { Loader } from '@jutro/components';
import { OtpService } from 'nlc-capability-otp';

function NlcEnrollmentComponent(props) {   
    const enrollmentType = _.get(appConfig, 'self-enrollment.type', '').toUpperCase();
    const isMFAEnabled = _.get(appConfig, 'env.MFA_Enabled_Ext', 'false');
    const { history } = props;
    const {userName, email, phoneNumber, policyNumber }= _.get(history, 'location.state');
    const { authHeader, refreshToken} = useAuthentication();
    const { EnrollmentService } = useDependencies('EnrollmentService');
    const [isEnrollmentDone, setEnrollmentDone] =  useState(false);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true); 
        const data = {
            details: {
                policyNumber: policyNumber,
                email:email,
                mobileNumber:phoneNumber
            },
            type: enrollmentType
        } 
        setEnrollmentDone((oldValue)=>{
            if(!oldValue){
                EnrollmentService.addEnrollmentRecord_Ext(data, authHeader).then((resp) => {
                    if(resp){
                        setEnrollmentDone((oldValue)=>oldValue = true);
                        refreshToken().then(() => {   
                            setLoading(false); 
                            oldValue = true;                        
                            if(isMFAEnabled && isMFAEnabled === 'true'){
                                const nextState = { pathname: '/nlc-signup-otp-verification', state: { userName, email, phoneNumber }};
                                history.push(nextState);
                            }else{
                                history.push('/home'); 
                            }   
                        });                                                                                 
                    }
                });
            }            
        });
        
        /* if(!isEnrollmentDone) {            
            EnrollmentService.addEnrollmentRecord_Ext(data, authHeader).then((resp) => {
                if(resp){
                    setEnrollmentDone((oldValue)=>oldValue = true);
                    refreshToken().then(() => {   
                        setLoading(false);                         
                        if(isMFAEnabled && isMFAEnabled === 'true'){
                            const nextState = { pathname: '/nlc-signup-otp-verification', state: { userName, email, phoneNumber }};
                            history.push(nextState);
                        }else{
                            history.push('/home'); 
                        }   
                    });                                                                                 
                }
            });
        }  */ 
    }, [authHeader, refreshToken]);
    
    const overrideProps = {
       
    };

    return (<Loader loaded={!isLoading} />);
}

NlcEnrollmentComponent.propTypes = {    
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
};

export default NlcEnrollmentComponent;
