import { defineMessages } from 'react-intl';

export default defineMessages({
    account: {
        id: 'header.links.Account',
        defaultMessage: 'My Details'
    },
    faq: {
        id: 'header.links.Customer',
        defaultMessage: 'Customer Support'
    },
    logout: {
        id: 'header.links.Logout',
        defaultMessage: 'Logout'
    }
});
