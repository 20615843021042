import { useCallback, useContext, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { useAuthentication, AUTH_ERRORS } from 'gw-digital-auth-react';
import AuthMessageComponent from '../AuthMessageComponent/AuthMessageComponent';
import { isEmpty, checkEmailValidity } from '../LoginUtil';
import { OtpService } from 'nlc-capability-otp';

import messages from './ForgotPasswordComponent.messages';
import metadata from './ForgotPasswordComponent.metadata.json5';
import styles from '../CommonLogin.module.scss';

function ForgotPasswordComponent(props) {
    const translator = useContext(TranslatorContext);
    const [email, updateEmail] = useState('');
    const [userName, updateUserName] = useState();
    const {forgotType} = props;
    const [pageError, updatePageError] = useState();
    const [pageView, setPageView] = useState();
    const { forgotPassword_Ext, forgotUserName_Ext, authHeader } = useAuthentication();
    const { onBackToSignIn } = props;
    
    const handleForgot = useCallback(
        async (evt) => {
            if (evt) {
                evt.preventDefault();
            }           
            if(forgotType === 'password'){           
                if (isEmpty(userName)) {
                    updatePageError('userNameValidation');
                    return Promise.resolve();
                }
                return forgotPassword_Ext(userName, authHeader)
                    .then(() => {
                        setPageView('success');
                    })
                    .catch((err) => {
                        if (err && err.baseError && err.baseError.includes('627')) {
                            updatePageError('whiteListEmailValidation');
                            return Promise.resolve();
                        }
                        if (err && err.baseError && err.baseError.includes('628')) {
                            updatePageError('invalidAttempt');
                            return Promise.resolve();
                        }
                    });
            }

            if(forgotType === 'userName'){

                if (isEmpty(email) || !checkEmailValidity(email)) {
                    updatePageError('emailValidation');
                    return Promise.resolve();
                }

                const inputData = new FormData();
                inputData.append('email',  email);
                const data = {
                    method: 'POST',
                    body: inputData,
                    redirect: 'follow'
                }
                try{
                    const otp = await OtpService.verifyWhiteListEmailData(data).then((resp) => resp.json())
                    .then((resp) => {
                        if(resp.response === true && resp.statusCode === 400){
                            updatePageError('whiteListEmailValidation');
                        }else{
                            return forgotUserName_Ext(email, authHeader)
                                .then(() => {
                                    setPageView('success');
                                })
                                .catch((err) => {
                                    updatePageError('unableToProcessRequest');
                            });
                        }
                    });          
                }catch(err){
                    updatePageError('unableToProcessRequest');
                }
            }
        },
        [authHeader, email, userName,  forgotType, forgotUserName_Ext, forgotPassword_Ext]
    );

    const readValue = useCallback((id,path) => {
        if(path === 'username'){
           return userName;           
        }
        if(path === 'email'){
           return email;
        }
    }, [email, userName]);
    const writeValue = useCallback((value, path) =>{ 
        if(path == 'username'){
            updateUserName(value);            
        }
        if(path === 'email'){
            updateEmail(value)
        }  
        updatePageError('');      
    }, []);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveValue: readValue,
        resolveCallbackMap: {
            handleBackToSignIn: onBackToSignIn           
        },
        resolveComponentMap: {
            authmessagecomponent: AuthMessageComponent
        }
    };

    const overrides = {
        '@field': {
            onValueChange: writeValue
        },
        heading:{
            content: forgotType === 'password' ? translator(messages.forgotYourPassword): translator(messages.forgotYourUserName)
        },
        description: {
            content: forgotType === 'password' ? translator(messages.forgotYourPasswordDescription): translator(messages.forgotYourUserNameDescription)
        }, 
        errorMsg: {
            visible: !_.isUndefined(pageError),
            content: translator(messages[pageError])
        },       
        emailFieldContainer: {
            visible: !pageView
        },
        email: {
            visible: forgotType === 'userName'
        },
        userName: {
            visible: forgotType === 'password'
        },
        emailForm: {
            onSubmit: handleForgot
        },        
        sendButton: {
            trigger: handleForgot
        },
        continueToSignIn: {
            trigger: onBackToSignIn
        },
        actionsWrapperSingleButton: {
            className: classNames(styles.actionsWrapperSingleButton, {
                [styles.coupleButtonStyle]: !_.isUndefined(onBackToSignIn),
                [styles.singleButtonStyle]: _.isUndefined(onBackToSignIn)
            })
        },
        linkToSignIn: {
            visible: !_.isUndefined(onBackToSignIn)
        },
        successfulContainer: {
            visible: pageView === 'success',            
        },
        successDescription: {
            className:styles.successDescriptionStyle,
            content: forgotType === 'password' ? translator(messages.forgotPasswordSuccess): translator(messages.forgotUsernameSuccess)
        },
        successValid: {
            visible: false, //forgotType === 'password',
            className:styles.successDescriptionStyle,
            content: translator(messages.forgotSuccessValid)
        },
        successInfo: {
            className:styles.successInfoStyle,
            content: translator(messages.forgotSuccessInfo)
        },
        successCheck: {
            className:styles.successCheckStyle,
            content: translator(messages.forgotSuccessCheck)
        }

    };

    return renderContentFromMetadata(metadata.componentContent, overrides, resolvers);
}

ForgotPasswordComponent.propTypes = {
    onBackToSignIn: PropTypes.func
};

ForgotPasswordComponent.defaultProps = {
    onBackToSignIn: undefined
};

export default ForgotPasswordComponent;
