import React, {
    useCallback, useContext, useMemo, useEffect, useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import appConfig from 'app-config';
import { withRouter, Redirect } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import { renderContentFromMetadata, validateContentFromMetadata } from '@jutro/uiconfig';
import { JsonRPCService } from 'gw-portals-transport-js';
import { getProxiedUrl } from 'gw-portals-url-js';
import { useAuthentication, AUTH_ERRORS } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { Loader } from '@jutro/components';
import AuthMessageComponent from '../AuthMessageComponent/AuthMessageComponent';
import metadata from './SignUpComponent.metadata.json5';
import messages from './SignUpComponent.messages';
import styles from './SignUpComponent.module.scss';
import { useDependencies } from 'gw-portals-dependency-react';
import { OtpService } from 'nlc-capability-otp';

function SignUpComponent(props) {
    const PWD_REGEX = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{12,}$');
    const EMAIL_REGEX = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/;    
    const enrollmentType = _.get(appConfig, 'self-enrollment.type', '');
    const isMFAEnabled = _.get(appConfig, 'env.MFA_Enabled_Ext', 'false');
    const translator = useContext(TranslatorContext);
    const [pageView, setPageView] = useState();
    const [pageError, updatePageError] = useState();
    const [isLoading, setLoading] = useState(false);
    const [formData, updateFormData] = useState({});
    const [policyDetailsValidated, setPolicyDetailsValidated] = useState(false); 
    const { signUp, login } = useAuthentication();
    const { isComponentValid, registerComponentValidation } = useValidation('SignUpComponent');
    const { history, successPath, onBackToSignIn } = props;
    const [verifiedPolicyDetails, setVerifiedPolicyDetails] = useState({});
    const { EnrollmentService } = useDependencies('EnrollmentService');
    const { authHeader } = useAuthentication();

    const showFieldInfo = (event) => {
        document.getElementById(`${event.target.id}Txt`).innerHTML = event.target.getAttribute('alt');
    }

    const hideFieldInfo = (event) => {
        document.getElementById(`${event.target.id}Txt`).innerHTML = '';
    }

    const handleAction = useCallback((evt) => {
        if (evt) {
            evt.preventDefault();
        }
        updatePageError(undefined);
        if(!policyDetailsValidated){
            validatePolicyDetails();
        }else {
            validateUserDetailsAndSingup();
        }
    }, [formData, policyDetailsValidated, validatePolicyDetails, validateUserDetailsAndSingup]);

    const validateUserDetailsAndSingup = useCallback(() => { 
        setLoading(true);         
        const firstName = _.get(formData, 'firstName'); 
        const lastName = _.get(formData, 'lastName');         
        const userName = _.get(formData, 'userName');  
        const email = _.get(formData, 'email');           
        const password = _.get(formData, 'password');
        const confirmPassword = _.get(formData, 'confirmPassword');
        const phoneNumber = _.get(formData, 'mobileNumber');
        if(phoneNumber){
            phoneNumber.replaceAll('-', '');
        }
        const policyNumber = _.get(formData, 'policyNumber');

        if (!firstName || !lastName || !userName || !phoneNumber || !email || !password  || !confirmPassword ) {
            updatePageError(AUTH_ERRORS.fieldEmpty);
            setLoading(false);
            return Promise.resolve();
        }

        if(!EMAIL_REGEX.test(email)){
            updatePageError('invalidEmail');
            setLoading(false);
            return Promise.resolve();
        }

        
        if(!PWD_REGEX.test(password)){
            updatePageError('invalidPassword');
            setLoading(false);
            return Promise.resolve();
        }
        if (password !== confirmPassword) {
            updatePageError('passwordsNotMatching');
            setLoading(false);
            return Promise.resolve();
        }
        const whiteListData = new FormData();
        whiteListData.append('mobileNumber', phoneNumber);
        whiteListData.append('email', email);
        const data = {
            method: 'POST',
            body: whiteListData
        }
        //console.log('whiteListData: ', data);
        OtpService.verifyWhitelistDevicesData(data).then((resp)=>resp.json())
        .then((resp)=> {
            if(resp.response === true && resp.statusCode === 400){
                updatePageError('whiteListedTxtMsg');
                setLoading(false);
                return Promise.resolve();
            }else{
                return signUp({
                    givenName: firstName,
                    familyName: lastName,
                    userName: userName,
                    email: email,
                    password: password,
                    phoneNumber: phoneNumber
                }).then(() => {
                    login({
                        username: userName,
                        password: password
                    }).then(() => {
                        /*if(isMFAEnabled && isMFAEnabled === 'true'){ 
                            const inputData = new FormData();
                            const data = {
                                method: 'POST',
                                body: inputData,
                                redirect: 'follow',
                            }  
                            inputData.append('userName', userName);   
                            inputData.append('policyNumber', policyNumber); 
                            inputData.append('email', email);
                            inputData.append('phoneNumber', phoneNumber);                      
                            try{                    
                                OtpService.createSignUpRecord(data).then((res)=>{
                                    if(res.status === 200) { 
                                        setLoading(false);
                                        const nextState = { pathname: '/nlc-signup-enrollment', state: { userName, email, phoneNumber, policyNumber} };
                                        history.push(nextState); 
                                    }
                                });            
                            }catch(err){
                                console.log(err);
                            }            
                        } else{ */
                            const nextState = { pathname: '/nlc-signup-enrollment', state: { userName, email, phoneNumber, policyNumber} };
                            history.push(nextState);  
                        //}                                
                    }).catch((err) => {
                        console.log(err);
                        setLoading(false);
                    });
                }).catch((error) => {
                    setLoading(false);
                    if (error && error.baseError && error.baseError.includes('621')) {
                        updatePageError('userAlreadyExists');
                    }else if (error && error.baseError && error.baseError.includes('620')) {
                        updatePageError('emailAlreadyExists');
                    }else{
                        setPageView(AUTH_ERRORS.invalidAttempt); 
                    }
                });
            }
        })
        .catch((error)=> console.log('Error Response: ', error));
       
    },[formData, isMFAEnabled, history]);

    const validatePolicyDetails = useCallback(() => {           
        const policyNumber = _.get(formData, 'policyNumber');
        const phoneNumber = _.get(formData, 'phoneNumber');
        //const zipCode = _.get(formData, 'zipCode');

        if (!policyNumber || !phoneNumber) {
            updatePageError(AUTH_ERRORS.fieldEmpty);
            return Promise.resolve();
        }
        const data= {
            details: {
                policyNumber: policyNumber,
                phoneNumber: phoneNumber
                //zipCode:zipCode
            },
            type: 'verification'
        }

        JsonRPCService.send(getProxiedUrl('validatePolicyDetails'), 'verifyPolicyDetails_Ext', [data])
        .then((polDetails)=>{
            //setVerifiedPolicyDetails(polDetails);
            setPolicyDetailsValidated(true);
            // if(polDetails.email){
            //     _.set(formData,'email',polDetails.email);                
            // }  
            if(polDetails.phoneNumber){
                _.set(formData,'mobileNumber',polDetails.phoneNumber);
            }             
        }).catch((error) => {
            console.log(error);
            updatePageError('invalidPolicyDetails');
        });           
    },[formData]);

    const readValue = useCallback((id, path) => _.get(formData, path), [formData]);

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = _.cloneDeep(formData);
            _.set(nextFormData, path, value);
            updateFormData(nextFormData);
            updatePageError(undefined);                        
        },
        [formData]
    );

    const overrideProps = useMemo(
        () => ({
            '@field': {
                onValueChange: writeValue
            },
            signUpContainer: {
                visible: _.isUndefined(pageView)
            },
            signUpHeading:{
                content: !policyDetailsValidated ? translator(messages.enterYourDetails) : translator(messages.signUp),
                className: styles.heading
            }, 
            // pwdMessage:{
            //     message: translator(messages.passwordMessage),
            //     visible: policyDetailsValidated,
            //     className: styles.passwordinfo
            // },
            // informationSection:{
            //     visible: !policyDetailsValidated
            // },
            verificationInformationText: {
                content: !policyDetailsValidated ? translator(messages.verificationInformationTextMsg) : translator(messages.completeYourPortalAccountMsg),
                className: styles.subheading
            },
            // verificationInformationAdditionalText: {
            //     content: translator(messages.verificationInformationAdditionalTextMsg)
            // },
            contactYourAgentText: {
                content: !policyDetailsValidated ? translator(messages.contactYourAgentTextMsg) : translator(messages.contactYourAgentPhoneTextMsg),
                className: styles.subsubheading
            },
            policyNumberArea: {
                className: styles.policyNumberArea
            },
            policyNumberLink: {
                onMouseOver: showFieldInfo,
                onMouseOut: hideFieldInfo
            },
            mobileNumberArea: {
                className: styles.mobileNumberArea
            },
            mobileNumberLink: {
                onMouseOver: showFieldInfo,
                onMouseOut: hideFieldInfo
            },
            // zipCodeArea: {
            //     className: styles.zipCodeArea
            // },
            // zipCodeLink: {
            //     onMouseOver: showFieldInfo,
            //     onMouseOut: hideFieldInfo  
            // },
            passwordArea: {
                className: styles.passwordArea
            },
            passwordLink: {
                onMouseOver: showFieldInfo,
                onMouseOut: hideFieldInfo  
            },
            errorMsg: {
                visible: !_.isUndefined(pageError),
                message: translator(messages[pageError])
            },
            emailAlreadyExistsError: {
                visible: pageView === AUTH_ERRORS.userAlreadyExists
            },
            invalidAttemptError: {
                visible: pageView === AUTH_ERRORS.invalidAttempt
            },
            unableToLoginAutomaticallyError: {
                visible: pageView === AUTH_ERRORS.autoLoginError
            },
            signUpButton: {
                trigger: handleAction,
                content: messages.next
            },
            policyDetailsContainer: {
                visible: !policyDetailsValidated,
                className: styles.policyDetailsContainerArea
            },
            userDetailsContainer: {
                visible: policyDetailsValidated
            },          
            mobileNumber:{
                disabled: policyDetailsValidated,
            }
        }),
        [validatePolicyDetails, pageError, pageView, translator, writeValue, policyDetailsValidated]
    );

    const resolvers = useMemo(
        () => ({
            resolveValue: readValue,
            resolveCallbackMap: {
                handleBackToSignIn: onBackToSignIn
            },
            resolveComponentMap: {
                authmessagecomponent: AuthMessageComponent
            },
            resolveClassNameMap: styles
        }),
        [onBackToSignIn, readValue]
    );

    const validateForm = useCallback(() => {
        return validateContentFromMetadata(metadata.componentContent, overrideProps, resolvers);
    }, [overrideProps, resolvers]);

    useEffect(() => {
        registerComponentValidation(validateForm);
    }, [registerComponentValidation, validateForm]);

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    return renderContentFromMetadata(metadata.componentContent, overrideProps, resolvers);
}

SignUpComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    successPath: PropTypes.string.isRequired,
    onBackToSignIn: PropTypes.func
};

SignUpComponent.defaultProps = {
    onBackToSignIn: undefined
};

export default withRouter(SignUpComponent);
