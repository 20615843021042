import { getProxiedServiceUrl, getProxiedUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class OtpService {
    
    static createSignUpRecord(data) {
        return fetch(getProxiedUrl('/createSignUpRecord'), data);
    }
    static updateSignUpVerificationStatus(data) {
        return fetch(getProxiedUrl('/updateSignUpVerificationStatus'), data);
    }
    static getSignUpVerificationStatus(data) {
        return fetch(getProxiedUrl('/getSignUpVerificationStatus'), data);
    }
    static sendEmailOtp(data) {
        return fetch(getProxiedUrl('/sendEmailOtp'), data);
    }

    static verifyEmailOtp(data) {
        return fetch(getProxiedUrl('/verifyEmailOtp'), data);
    }

    static sendSms(data) {
        return fetch(getProxiedUrl('/sendSms'), data);
    }

    static verifySmsOtp(data) {
        return fetch(getProxiedUrl('/verifySmsOtp'), data);
    }

    static saveTncRecordOfUser(data) {
        return fetch(getProxiedUrl('/saveTncRecordOfUser'), data);
    }

    static getTncStatusOfUser(data) {
        return fetch(getProxiedUrl('/getTncStatusOfUser'), data);
    }
    static updatePasswordReset(data) {
        return fetch(getProxiedUrl('/updatePasswordReset'), data);
    }
    static getPasswordResetStatus(data) {
        return fetch(getProxiedUrl('/getPasswordResetStatus'), data);
    }
    static lockUser(data) {
        return fetch(getProxiedUrl('/lockUser'), data);
    }
    static verifyUserLockStatus(data) {
        return fetch(getProxiedUrl('/verifyUserLockStatus'), data);
    }
    
    static deviceData(data) {
        return fetch(getProxiedUrl('/dontAskAgain'), data);
    }

    static browserData(data) {
        return fetch(getProxiedUrl('/verifyBrowserId'), data);
    }

    static verifyMFAData(data) {
        return fetch(getProxiedUrl('/verifyMFA'), data);
    }

    static verifyWhitelistDevicesData(data) {
        return fetch(getProxiedUrl('/verifyWhitelistDevices'), data);
    }

    static verifyWhiteListEmailData(data) {
        return fetch(getProxiedUrl('/verifyWhiteListEmail'), data);
    }

    static verifyWhiteListMobileData(data) {
        return fetch(getProxiedUrl('/verifyWhiteListMobile'), data);
    }

    
}
