import React, {
    useContext, useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { TranslatorContext } from '@jutro/locale';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './ConfirmationPage.metadata.json5';
import messages from '../../FNOL.messages';
import styles from './ConfirmationPage.module.scss';
import { Image } from '@jutro/components';

function ConfirmationPage(props) {
    const {
        history: { location: { state } },
        history,
        match: {
            params: { claimNumber }
        }
    } = props;
    const translator = useContext(TranslatorContext);

    const handlePrint = useCallback((e) => {
        e.preventDefault();
        window.print();
    }, []);

    const handleBackToHome = useCallback(() => {
        if (appConfig.persona === 'policyholder') {
            return history.push('/home');
        }
        const redirectPath = _.get(state, 'redirectPath');
        if (redirectPath) {
            return history.push({
                pathname: redirectPath,
                state: 'claims'
            });
        }
        return false;
    }, [history, state]);

    const getImage = () => {
       return(
        <div className={styles.imageStyle}>
        <Image
            alt=""
            figcaption=""
            id="Image"
            src="/branding/NLC_color.svg"
            width="200"
        />
        </div>
        );
    }

    const overrideProps = {
        claimReferenceNumber: {
            visible: !!claimNumber,
            content: translator(messages.referenceNumber, { num: claimNumber })
        },
        callButtonID: {
            content:
                appConfig.persona === 'policyholder'
                    ? messages.fnolBackToHome
                    : messages.fnolBackToClaims
        },
        foundationInsurance: {
            content: getImage()

        }

    };

    const resolvers = {
        resolveCallbackMap: {
            handleBackToHome: handleBackToHome,
            handlePrint: handlePrint
        },
        resolveClassNameMap: styles
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                id,
                path,
                overrideProps
            );
        },
        [overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

ConfirmationPage.propTypes = {
    history: PropTypes.shape({
        location: PropTypes.shape({
            state: PropTypes.shape({
                redirectPath: PropTypes.string.isRequired
            }).isRequired
        }).isRequired,
        push: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            claimNumber: PropTypes.string.isRequired
        })
    }).isRequired
};

export default withRouter(ConfirmationPage);
