import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ModalNextProvider } from '@jutro/components';
import { ImageComponent } from 'gw-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import _ from 'lodash';
import { messages as commonMessages } from 'gw-platform-translations';
import DamageList from './DamageList';
import styles from './VehicleDamagePicker.module.scss';
import carParts from './VehicleParts.json';
import messages from './VehicleDamagePicker.messages';

class VehicleDamagePicker extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        collisionPoints: PropTypes.shape({
            getCode: PropTypes.func
        }).isRequired,
        onValueChange: PropTypes.func.isRequired,
        path: PropTypes.string.isRequired,
        value: PropTypes.shape({
            code: PropTypes.string
        }).isRequired
    };

    state = {
        damagedParts: [],
        hovered: false,
        hoveredPart: null,
        selectedPart: null,
        damagedGroup: null
    };

    selectPart = (groupId, partID) => {
        const { collisionPoints } = this.props;
        if (collisionPoints) {
            switch (partID) {
                // case 'windshield':
                // case 'rear_windshield':
                //     ModalNextProvider.showAlert({
                //         title: messages.canNotSelectWindscreen,
                //         message: messages.canNotSelectWindscreenMessage,
                //         status: 'error',
                //         icon: 'mi-error-outline',
                //         confirmButtonText: commonMessages.ok
                //     }).catch(_.noop);
                //     return;
                // case 'roof':
                //     ModalNextProvider.showAlert({
                //         title: messages.canNotSelectRoof,
                //         message: messages.canNotSelectRoofMessage,
                //         status: 'error',
                //         icon: 'mi-error-outline',
                //         confirmButtonText: commonMessages.ok
                //     }).catch(_.noop);
                //     return;
                default: {
                    const partCode = collisionPoints.getCode(groupId);
                    this.setState(
                        {
                            selectedPart: partID,
                            damagedParts: [partCode],
                            damagedGroup: groupId
                        },
                        () => {
                            this.onCallBack();
                        }
                    );
                }
            }
        }
    };

    getClassName = (className) => {
        return cx(styles.gw_car_part, styles[className]);
    };

    isSelected = (group) => {
        const { damagedGroup } = this.state;
        if (damagedGroup !== group) {
            return false;
        }
        if (damagedGroup && damagedGroup === group) {
            return true;
        }
        return false;
    };

    getIcon = (part, group) => {
        const { hovered, hoveredPart, selectedPart } = this.state;
        const { value } = this.props;
        if (selectedPart && this.isSelected(group)) {
            return `/newclaimvehicle/onhover/damage_${part}_on.gif`;
        }
        if (hovered && hoveredPart === part) {
            return `/newclaimvehicle/onhover/damage_${part}_hover.gif`;
        }
        if (value && value.code === group) {
            return `/newclaimvehicle/onhover/damage_${part}_on.gif`;
        }
        return `/newclaimvehicle/damage_${part}_off.gif`;
    };

    onHover = (hovered, part) => {
        this.setState({ hovered, hoveredPart: part });
    };

    handleKeyDown = (event, group, part) => {
        if (event.keyCode === 'Enter') {
            this.selectPart(group, part);
        }
    };

    offDamage = (typeCode, selectedDamagedPart) => {
        const removeIdx = _.findIndex(selectedDamagedPart, { code: typeCode.code });
        if (removeIdx !== -1) {
            selectedDamagedPart.splice(removeIdx, 1);
        }
        this.setState({ damagedParts: selectedDamagedPart, selectedPart: null }, () => {
            this.onCallBack();
        });
    };

    onCallBack = () => {
        const { damagedParts } = this.state;
        const { onValueChange, path } = this.props;
        const value = !_.isEmpty(damagedParts) ? damagedParts[0].code : null;
        if (onValueChange) {
            onValueChange(value, path);
        }
    };

    render() {
        const translator = this.context;
        const { damagedParts } = this.state;
        const { value } = this.props;
        const availableDamageParts = !_.isNil(value) ? [value] : [];
        const listOfDamages = !_.isEmpty(damagedParts) ? damagedParts : availableDamageParts;
        const vehicleImageClass = cx(styles.gw_car, styles.gw_damage_picker);
        return (
            <div className={styles.gwCarContainer}>
                <div className={vehicleImageClass}>
                    <ImageComponent
                        id="vehicleDamageDiagram"
                        src="/newclaimvehicle/damage_diagram.gif"
                        alt="damageVehiclePart"
                    />
                    {carParts.parts.map((carpart, index) => (
                        <span
                            id={`carParts${index}`}
                            key={carpart.part}
                            className={this.getClassName(carpart.class)}
                            tabIndex={index}
                            group={carpart.group}
                            part={carpart.part}
                            onMouseEnter={() => this.onHover(true, carpart.part)}
                            onMouseOut={() => this.onHover(false)}
                            onBlur={() => this.onHover(false)}
                            onClick={() => this.selectPart(carpart.group, carpart.part)}
                            onKeyDown={(e) => this.handleKeyDown(e, carpart.group, carpart.part)}
                            role="button"
                        >
                            <ImageComponent
                                src={this.getIcon(carpart.part, carpart.group)}
                                tabIndex={index}
                                id={carpart.part}
                                alt="damageParts"
                            />
                        </span>
                    ))}
                </div>
                <div className={styles.gw_damage_info}>
                    <div className={styles.gw_damge_info_padded}>
                        {!_.isEmpty(listOfDamages) ? (
                            <DamageList damagedParts={listOfDamages} offDamage={this.offDamage} />
                        ) : (
                            <div className={styles.gw_damge_info_padded}>
                                {translator(messages.partOfTheCarDamaged)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default VehicleDamagePicker;
