import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Link, ModalNextProvider, Loader
} from '@jutro/components';
import { withIntl } from '@jutro/locale';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import formatDocumentTableData from '../../util/DocumentUtil';
import metadata from './DocumentsComponent.metadata.json5';
import messages from './DocumentsComponent.messages';
import styles from './DocumentsComponent.module.scss';

const getDateModified = (items, index, { path: property }) => {
    return <span>{items[property]}</span>;
};

const DocumentsComponent = (props) => {
    const {
        authHeader,
        initialDocumentsData,
        showDocumentUpload,
        uploadDocument,
        deleteDocument,
        downloadDocument,
        noDataMessage,
        showPagination,
        showHeader,
        showSearch,
        isLoading,
        value,
        intl
    } = props;
    const { UserService } = useDependencies('UserService');
    const [showDocumentUploadButton, updateShowDocumentUploadButton] = useState(false);
    const [searchKeyword, setKeyword] = useState('');
    useEffect(() => {
        if (showHeader) {
            const permissionDTO = {
                permission: 'doccreate'
            };
            UserService.hasUserSystemPermission(permissionDTO, authHeader)
                .then((isUserPermission) => {
                    updateShowDocumentUploadButton(isUserPermission);
                });
        }
        // Disabling to prevent continues re-rendering
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDownloadLink = useCallback(
        (item) => {
            const { publicID, sessionID } = item;
            return downloadDocument(publicID, sessionID);
        },
        [downloadDocument]
    );

    const onDeleteDocumentIcon = useCallback(
        (e, item) => {
            e.preventDefault();
            const { publicID } = item;
            ModalNextProvider.showConfirm({
                title: messages.removeDocument,
                message: messages.confirmRemoveDocument,
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: messages.documentRemoveConfirmYes,
                cancelButtonText: messages.documentRemoveConfirmNo
            }).then(async (results) => {
                if (results === 'cancel') {
                    return _.noop();
                }
                deleteDocument(publicID);
                return true;
            }, _.noop);
        },
        [deleteDocument]
    );

    const getRemoveIcon = useCallback(
        (item, index, property) => {
            return (
                <Link
                    href={item[property.id]}
                    onClick={(e) => onDeleteDocumentIcon(e, item)}
                    className={styles.trashLink}
                    icon="mi-delete"
                />
            );
        },
        [onDeleteDocumentIcon]
    );

    const getNameLink = useCallback(
        (item, index, { path: property }) => {
            const downloadLink = getDownloadLink(item);
            return (
                <Link
                    icon="mi-insert_drive_file"
                    href={downloadLink}
                    className={styles.documentName}
                    target="_blank"
                >
                    {item[property]}
                </Link>
            );
        },
        [getDownloadLink]
    );

    const handleSearchValueChange = useCallback((keyword) => {
        setKeyword(keyword);
    }, []);

    const overrideProps = {
        documentTableGrid: {
            data: formatDocumentTableData(intl, initialDocumentsData || value, searchKeyword),
            visible: !_.isEmpty(initialDocumentsData || value) && !isLoading,
            showPagination,
            showSearch
        },
        tilesPageHeaderSpan: {
            visible: (showDocumentUploadButton || showDocumentUpload)
        },
        noDocumentsDetails: {
            visible: !!noDataMessage && _.isEmpty(initialDocumentsData || value) && !isLoading
        },
        noDocumentsText: {
            message: noDataMessage
        },
        documentsTitleId: {
            visible: showHeader
        },
        uploadDocuments: {
            visible: !showHeader && showDocumentUpload
        },
        searchFilter: {
            visible: !_.isEmpty(initialDocumentsData || value),
            value: searchKeyword
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getNameLink: getNameLink,
            getDateModified: getDateModified,
            getRemoveIcon: getRemoveIcon,
            onUploadDocument: uploadDocument,
            handleSearchValueChange: handleSearchValueChange
        }
    };

    return (
        <Loader loaded={!isLoading}>
            <ViewModelForm
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </Loader>
    );
};

DocumentsComponent.propTypes = {
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,
    initialDocumentsData: PropTypes.shape({}),
    value: PropTypes.shape({}),
    uploadDocument: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    downloadDocument: PropTypes.func.isRequired,
    noDataMessage: PropTypes.string,
    showPagination: PropTypes.bool,
    showHeader: PropTypes.bool,
    showSearch: PropTypes.bool,
    showDocumentUpload: PropTypes.bool,
    isLoading: PropTypes.bool,
    intl: PropTypes.func.isRequired
};

DocumentsComponent.defaultProps = {
    showPagination: true,
    noDataMessage: '',
    showHeader: true,
    showSearch: false,
    showDocumentUpload: false,
    isLoading: false,
    value: undefined,
    initialDocumentsData: undefined
};

export default withIntl(withAuthenticationContext(DocumentsComponent));
