import React, { useContext, useMemo } from 'react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import messages from './ContactAgentPage.messages';
import metadata from './ContactAgentPage.metadata.json5';
import styles from './ContactAgentPage.module.scss';

function ContactAgentPage(props) {
    const {
        history: {
            location: { state }
        },
        match: {
            params: { claimNumber }
        }
    } = props;
    const translator = useContext(TranslatorContext);
    const { phoneNumber, assistNumber } = appConfig;

    const phoneNumberLink = useMemo(() => {
        const phoneLink = `tel:${phoneNumber}`;
        return (
            <a href={phoneLink} className={styles.buttonLinkStyleRemove}>
                {phoneNumber}
            </a>
        );
    }, [phoneNumber]);

    const overrides = {
        callButtonID: {
            content: phoneNumberLink
        },
        callForAssistance: {
            content: translator(messages.callForAssistance, { assistNumber })
        },
        claimReferenceNumber: {
            visible: !!claimNumber,
            content: translator(messages.claimReferenceNumber, { num: claimNumber })
        },
        link: {
            to: {
                pathname: _.get(state, 'redirectPath'),
            }
        }
    };
    const resolvers = {
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(metadata.pageContent, overrides, resolvers);
}
ContactAgentPage.propTypes = {
    history: PropTypes.shape({
        location: PropTypes.shape({
            state: PropTypes.shape({
                redirectPath: PropTypes.string.isRequired
            }).isRequired
        }).isRequired,
        push: PropTypes.func
    }).isRequired
};

export default withRouter(ContactAgentPage);
