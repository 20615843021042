import _ from 'lodash';

function Vehicle(data) {
    if (data) {
        _.extend(this, data);
    }
}

Vehicle.prototype.getDisplayName = function (defaultName, TranslateService) {
    let name = this.year || '';
    if (this.make) {
        if (name) {
            name += ' ';
        }
        name += this.make;
    }
    //let name = this.make || '';
    if (this.model) {
        if (name) {
            name += ' ';
        }
        name += this.model;
    }
    if (!name) {
        name = defaultName || TranslateService.instant('fnol.common.model.Unnamed');
    }
    return name;
};

export default Vehicle;
