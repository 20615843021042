import React, { useCallback, useContext,useState,useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PolicyCoverage.metadata.json5';
import styles from './PolicyCoverage.module.scss';
import './PolicyCoverage.messages';



function PolicyCoveragePE(props) {
    const { data: policyCoverages_Ext} = props;

    const getLimit= useCallback((policyCoverages_Ext) =>{
        const limit = policyCoverages_Ext[0].limit
        return `$ ${limit} (Each Accident)`

    });

    const getRentation= useCallback((policyCoverages_Ext) =>{
        const rentation = policyCoverages_Ext[0].rentation
        return `$ ${rentation} (Each Accident)`

    });

   
    const overrides = {
        '@element': {
            textAlign: 'left'
        },

        infoTable:{
            data: _.get(policyCoverages_Ext)
        },
    
        limit:{
            value: getLimit(policyCoverages_Ext)
           },

        rentation:{
            value: getRentation(policyCoverages_Ext)  
           }



    };

    const resolvers = {
        resolveCallbackMap:{
            liabilityLimit:getLimit,
            rentation:getRentation

        },
        resolveClassNameMap: styles,

    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.componentContent,
                policyCoverages_Ext,
                id,
                path,
                overrides
            );
        },
        [policyCoverages_Ext, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyCoverages_Ext}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

PolicyCoveragePE.propTypes = {
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
};

PolicyCoveragePE.defaultProps = {
    value: []
};

export default PolicyCoveragePE;
