import React, {
    Fragment, useCallback, useState, useContext, useRef
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import appConfig from 'app-config';
import { TranslatorContext } from '@jutro/locale';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { useAuthentication, RedirectAfterLogin, AUTH_ERRORS } from 'gw-digital-auth-react';
import { Loader } from '@jutro/components';
import { isEmpty } from '../LoginUtil';
import messages from './LoginComponent.messages';
import metadata from './LoginComponent.metadata.json5';
import styles from '../CommonLogin.module.scss';
import { OtpService } from 'nlc-capability-otp';

const maxNumberOfInvalidAttempts = _.get(appConfig, 'mfa.login.maxNumberOfInvalidLoginAttempts', undefined);

function LoginComponent(props) {
    const {
        onForgotPassword, onForgotUserName, successPath, loginPath, pathAfterSignIn, history
    } = props;
    
    const translator = useContext(TranslatorContext);
    const [formData, updateFormData] = useState({});
    const [loginError, updateLoginError] = useState('');
    const [invalidAttemptCount, setInvalidAtemptCount] = useState(0); 
    const [isSignInButtonClicked, setSignInButtonClicked] = useState(false);
    const { login: authLogin, isAuthInProgress } = useAuthentication();
    const signInRef = useRef({});
    const redirectionPath = 'nlc-login';
    const isMFAEnabled = _.get(appConfig, 'env.MFA_Enabled_Ext', 'false');

    const login = useCallback(async() => {
        const username = _.get(formData, 'username');
        const password = _.get(formData, 'password');       
      

        if (isEmpty(username) || isEmpty(password)) {
            updateLoginError('fieldEmpty');
            return Promise.resolve();
        }
        if(isMFAEnabled && isMFAEnabled === 'true'){ 
            const inputData1 = new FormData();
            inputData1.append('userName', username);  
            const data1 = {
                method: 'POST',
                body: inputData1,
                redirect: 'follow',
            } 
            const lockStatus = await  OtpService.verifyUserLockStatus(data1);
            if(lockStatus.status === 200) {
                const st = await lockStatus.json();
                if(st){
                    updateLoginError('accountLocked');
                    return Promise.resolve();
                }           
            }
        }

        updateLoginError(''); // reset errors
        setSignInButtonClicked(true);

        return authLogin({
            username: username,
            password: password
        }).then(() => { 
            // reset errors
            updateLoginError('');                              
        })
        .catch((err) => {
            switch (_.get(err, 'error')) {
                case AUTH_ERRORS.login_failure: // credentials are wrong
                    setInvalidAtemptCount((oldValue)=>{                        
                         if(oldValue === maxNumberOfInvalidAttempts){
                            lockTheUser(username);
                        }else{
                            updateLoginError('fieldsIncorrect'); 
                        }
                        return oldValue+1;
                    });
                   
                    break;
                case AUTH_ERRORS.notLoggedIn: // tokens check problem
                    updateLoginError('fieldsIncorrect');
                    break;
                case AUTH_ERRORS.account_locked: {
                    // account is locked
                    updateLoginError('accountLocked');
                    break;
                }
                default:                    
                    updateLoginError('invalidAttempt');
                    break;
            }
        });
        
    }, [invalidAttemptCount, authLogin, formData, maxNumberOfInvalidAttempts]);

    const lockTheUser = useCallback((userName) => {
        const inputData1 = new FormData();
        inputData1.append('userName', userName);  
        const data1 = {
            method: 'POST',
            body: inputData1,
            redirect: 'follow',
        }  
    
        try{                    
            OtpService.lockUser(data1).then((status)=>{
                if(status.status === 200) { 
                    updateLoginError('accountLocked');
                }
            });            
        }catch(err){
            console.log(err);
        }            
    },[]);

    const readValue = useCallback(
        (id, path) => {
            return _.get(formData, path);
        },
        [formData]
    );

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = _.cloneDeep(formData);
            _.set(nextFormData, path, value);
            updateFormData(nextFormData);
            updateLoginError('');
        },
        [formData]
    );

    const handleEnterKey = () => {
        signInRef.current.click();
    };
    if (isAuthInProgress) {
        return <Loader loaded={!isAuthInProgress} />;
    }

    const overrideProps = {
        '@field': {
            onValueChange: writeValue,
            onEnter: handleEnterKey
        },
        signIn: {
            trigger: login,
            innerRef: signInRef
        },
        linkToForgotPassword: {
            visible: !_.isUndefined(onForgotPassword)
        },
        linkToForgotUserName: {
            visible: !_.isUndefined(onForgotUserName)
        },
        actionsWrapperSingleButton: {
            className: classNames(styles.actionsWrapperSingleButton, {
                [styles.coupleButtonStyle]: !_.isUndefined(onForgotPassword),
                [styles.singleButtonStyle]: _.isUndefined(onForgotPassword)
            })
        },
        errorMsg: {
            content: translator(messages[loginError]),
            visible: !!loginError
        }
    };

    const resolvers = {
        resolveValue: readValue,
        resolveCallbackMap: {
            login: login,
            forgotPassword: onForgotPassword || _.noop,
            forgotUserName: onForgotUserName || _.noop
        },
        resolveClassNameMap: styles
    };

    const successRedirectPath = redirectionPath

    return (
        <Fragment>
            <RedirectAfterLogin successPath={successRedirectPath} loginPath={loginPath} />
            {renderContentFromMetadata(metadata.componentContent, overrideProps, resolvers)}
        </Fragment>
    );
}

LoginComponent.propTypes = {
    onForgotPassword: PropTypes.func,
    successPath: PropTypes.string,
    loginPath: PropTypes.string,
    pathAfterSignIn: PropTypes.string
};

LoginComponent.defaultProps = {
    onForgotPassword: undefined,
    successPath: undefined,
    loginPath: undefined,
    pathAfterSignIn: undefined
};

export default LoginComponent;
