import React, {
    useContext,
    useState,
    useEffect,
    useCallback
} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import _ from 'lodash';
// Imported First to allow Jutro styles to be overridden
import './App.scss';
import { setComponentMapOverrides } from '@jutro/uiconfig';
import {
    Main,
    ModalNextProvider
} from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { NavRoutes } from '@jutro/router';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { DynamicRoute, GrantedAuthorities, withAuthContextProvider } from 'gw-digital-auth-react';
import vmTranslator, { messages as commonMessages } from 'gw-platform-translations';
import { AccurateBreakpointPropagation } from 'gw-jutro-adapters-react';
import { EnrollmentService } from 'gw-capability-policyenrollment';
import { UserService } from 'gw-capability-user';
import { ViewModelServiceFactory } from 'gw-portals-viewmodel-js';
import { PolicyDetails } from 'gw-capability-policy-react';
import { AvailabilityService } from 'gw-capability-policycommon';
import { EndorsementService } from 'gw-capability-policychange';
import { PAPolicyChangeWizard } from 'gw-capability-policychange-pa-react';
import { HOPolicyChangeWizard } from 'gw-capability-policychange-ho-react';
import { LoginCapabilityComponentMap, LoginCapabilityComponents, NlcLoginComponent } from 'gw-portals-login-react';
import { personalizedOfferComponentMap, personalizedOfferComponents } from 'gw-capability-personalizedoffers-react';
import { VehicleInfoLookupComponentMap, VehicleInfoLookupComponents } from 'gw-capability-vehicleinfo-react';
import { policyJobComponentMap, policyJobComponents } from 'gw-capability-policyjob-react';
import {
    BillingSummaryPage,
    MakePaymentPage,
    billingComponentMap,
    billingAdditionalComponents
} from 'gw-capability-billing-react';
import { AdditionalEnrollment, SignUpEnrollment, NlcEnrollmentComponent, SignUpOtpComponent, LoginOtpComponent } from 'gw-capability-enrollment-react';
import { UsersProfile } from 'gw-capability-profileinfo-react';
import  mfaOtpVerifyComponent  from '../customer/nlc/common/capabilities-react/nlc-capabilities-accountDetails-react/mfaOtpVerify/mfaOtpVerifyComponent';
import { WizardPageTemplateWithTitle } from 'gw-portals-wizard-react';
import { ProducerInfoService } from 'gw-capability-profileinfo';

import { DependencyProvider } from 'gw-portals-dependency-react';
import { AccountBillingDetailsService, BillingService } from 'gw-capability-billing';
import { PolicyService } from 'gw-capability-policy';
import { ClaimDocumentService } from 'gw-capability-claimdocument';

import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import {
    platformComponentMap,
    platformComponents,
    ImageComponent,
} from 'gw-components-platform-react';
import { ErrorBoundary } from 'gw-portals-error-react';
import BaseFNOLWizard, { ClaimsConfirmationPage } from 'gw-capability-fnol-common-react';
import { ContactAgentPage } from 'gw-pages-platform-react';
import FNOLPAWizard from 'gw-capability-fnol-pa-react';
import FNOLWCWizard from 'gw-capability-fnol-wc-react';
import FNOLCAWizard from 'gw-capability-fnol-ca-react';
import FNOLHOWizard from 'gw-capability-fnol-ho-react';
import FNOLCPWizard from 'gw-capability-fnol-cp-react';
import FNOLBOPWizard from 'gw-capability-fnol-bop-react';
import FNOLGeneralWizard from 'gw-capability-fnol-gl-react';

import {
    ClaimList, ClaimDetails, CreateNote, PolicyLevelCoverages
} from 'gw-capability-claim-react';
import { ClaimService } from 'gw-capability-claim';
import { FNOLService } from 'gw-capability-fnol';
import FaqPage from 'gw-capability-faq-react';
import { PolicyDocumentError } from 'gw-capability-document-react';
import { filterCapabilityRoutes } from 'gw-portals-config-js';
import PageHeaderComponent from '../customer/nlc/common/nlc-components/PageHeaderComponent/PageHeaderComponent';
import { routes } from './App.routes.metadata.json5';
import ResetPasswordPage from '../pages/ResetPasswordPage/ResetPasswordPage';
import EntryPage from '../pages/EntryPage/EntryPage';
import LandingPage from '../customer/nlc/common/capabilities-react/nlc-capabilities-homePage-react/LandingPage/LandingPage';
import messages from './App.messages';

const { capabilitiesConfig } = appConfig;

const componentMap = {
    landingpage: LandingPage,
    policydocumenterror: PolicyDocumentError,
    fnolwcwizard: FNOLWCWizard,
    basefnolwizard: BaseFNOLWizard,
    claimsconfirmationpage: ClaimsConfirmationPage,
    contactagent: ContactAgentPage,
    fnolpawizard: FNOLPAWizard,
    fnolhowizard: FNOLHOWizard,
    additionalenrollmentpage: AdditionalEnrollment,
    claimlist: ClaimList,
    claimdetails: ClaimDetails,
    policydetailspage: PolicyDetails,
    papolicychangewizard: PAPolicyChangeWizard,
    hopolicychangewizard: HOPolicyChangeWizard,
    createnote: CreateNote,
    billingsummarypage: BillingSummaryPage,
    makepaymentpage: MakePaymentPage,
    usersprofile: UsersProfile,
    mfaotpverifycomponent: mfaOtpVerifyComponent,
    policylevelcoverages: PolicyLevelCoverages,
    faqpage: FaqPage,
    fnolcawizard: FNOLCAWizard,
    fnolcpwizard: FNOLCPWizard,
    fnolbopwizard: FNOLBOPWizard,
    fnolgeneralwizard: FNOLGeneralWizard
};

const shouldRouteToSignUp = (authorities) => {
    /* const accountAuthorities = authorities.filter((authority) => authority.authorityType === 'ACCOUNT');
    // if user doesn't have ACCOUNT authorities it is supposed to be enrolled
    const isMFAEnabled = _.get(appConfig, 'env.MFA_Enabled_Ext', false);    
    if (accountAuthorities.length === 0) {
        return <Redirect to="/signup-enrollment" />;
    } else if (isMFAEnabled) {
        const mfaStatus = getCookie('mfaStatus');
        if(!mfaStatus || mfaStatus!=='true'){
            return <Redirect to="/nlc-login" />;
        }        
    } */
    return null;
};

setComponentMapOverrides(
    {
        ...platformComponentMap,
        ...personalizedOfferComponentMap,
        ...VehicleInfoLookupComponentMap,
        ...LoginCapabilityComponentMap,
        ...policyJobComponentMap,
        ...billingComponentMap,
        WizardPageTemplateWithTitle: { component: 'WizardPageTemplateWithTitle' },
        // replace the native IMG component with a proxied version
        img: { component: 'img' },
    },
    {
        ...platformComponents,
        ...personalizedOfferComponents,
        ...VehicleInfoLookupComponents,
        ...LoginCapabilityComponents,
        ...policyJobComponents,
        ...billingAdditionalComponents,
        WizardPageTemplateWithTitle,
        img: ImageComponent,
    }
);

// (Router) Title is required and used for (Browser) title
// in a format of "${title} - ${appName}". In order to show
// the application name only, it needs to be an empty value.
const routesWithTitle = filterCapabilityRoutes(capabilitiesConfig, routes);
const routesWithoutTitle = routesWithTitle.map((route) => {
    return {
        ...route,
        title: ''
    };
});

function App() {
    const translator = useContext(TranslatorContext);
    const [viewModelService, setViewModelService] = useState(undefined);

    useEffect(() => {
        const translatorFn = vmTranslator(translator);
        import(
            /* webpackChunkName: "product-metadata" */
            // eslint-disable-next-line import/no-unresolved
            'product-metadata'
        ).then((productMetadata) => {
            const { default: result } = productMetadata;
            setViewModelService(
                ViewModelServiceFactory.getViewModelService(result, translatorFn)
            );
        });
    }, [translator]);

    const handleError = useCallback(() => {
        return ModalNextProvider.showAlert({
            title: messages.error,
            message: messages.errorMessage,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.ok
        }).catch(_.noop);
    }, []);

    return (
        <AccurateBreakpointPropagation>
            <DependencyProvider
                value={{
                    AccountBillingDetailsService: AccountBillingDetailsService,
                    PolicyService: PolicyService,
                    EndorsementService: EndorsementService,
                    AvailabilityService: AvailabilityService,
                    FNOLService: FNOLService,
                    ClaimService: ClaimService,
                    ClaimDownloadService: ClaimDocumentService,
                    BillingService: BillingService,
                    EnrollmentService: EnrollmentService,
                    ContactService: ProducerInfoService,
                    UserService: UserService,
                }}
            >
                <ErrorBoundary onError={handleError}>
                    <ViewModelServiceContext.Provider value={viewModelService}>
                        <ModalNextProvider />
                        <Switch>
                            <Route exact path="/login-page" component={EntryPage} />
                            <Route exact path="/auth/resetpassword" component={ResetPasswordPage} /> 
                            <Route exact path="/signup-enrollment" component={SignUpEnrollment} /> 
                            <Route exact path="/nlc-login" component={NlcLoginComponent} />                          
                            <Route exact path="/nlc-signup-enrollment" component={NlcEnrollmentComponent} />
                            <Route exact path="/nlc-signup-otp-verification" component={SignUpOtpComponent} />
                            <Route exact path="/nlc-login-otp-verification" component={LoginOtpComponent} />                          
                            <DynamicRoute path="/">
                                <GrantedAuthorities
                                    checkRenderBasedOnAuthorities={shouldRouteToSignUp}
                                >
                                    <PageHeaderComponent routes={routesWithTitle} />
                                    <Main>
                                        <NavRoutes
                                            resolveComponentMap={componentMap}
                                            routes={routesWithoutTitle}
                                        />
                                    </Main>
                                </GrantedAuthorities>
                            </DynamicRoute>
                        </Switch>
                    </ViewModelServiceContext.Provider>
                </ErrorBoundary>
            </DependencyProvider>
        </AccurateBreakpointPropagation>
    );
}

export default withAuthContextProvider()(App);
