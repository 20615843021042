// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { Icon, Link } from '@jutro/components';
import { withIntl, TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PolicyDocuments.metadata.json5';
import styles from './PolicyDocuments.module.scss';
import messages from '../PolicyCommonDocuments.messages';

function PolicyDocuments(props) {
    const {
        data: policyDocuments,
        intl,
        getDownloadLink,
        onDocumentDelete,
        onDocumentUpload
    } = props;
    const { documents } = policyDocuments;
    const { maxFileUploadSize } = appConfig;
    const translator = useContext(TranslatorContext);

    const handleDocumentDelete = useCallback(
        (event, item) => {
            event.preventDefault();
            onDocumentDelete(item);
        },
        [onDocumentDelete]
    );

    documents.sort((a, b) => b.dateModified.localeCompare(a.dateModified))
    
    const handleDocumentUpload = useCallback(
        (item) => {
            onDocumentUpload(item);
        },
        [onDocumentUpload]
    );

    const getFileName = useCallback(
        (document) => {
            const link = getDownloadLink(document);
            return (
                <Link href={link} target="_blank">
                    <Icon icon="mi-insert_drive_file" className={styles.fileTypeIcon} />
                    {document.name}
                </Link>
            );
        },
        [getDownloadLink]
    );

    const getFormattedDate = useCallback(
        (document) => {
            return intl.formatDate(new Date(document.dateModified), { year: 'numeric', month: '2-digit', day: '2-digit' });
        },
        [intl]
    );

    const getDeleteIcon = useCallback(
        (document) => {
            if (document.canDelete) {
                return (
                    <Link href={document.name} onClick={(e) => handleDocumentDelete(e, document)}>
                        <Icon icon="mi-delete" />
                    </Link>
                );
            }
            return null;
        },
        [handleDocumentDelete]
    );

    const overrides = {
        fileUploadComponent: {
            visible: !policyDocuments.isReadOnly,
            maxFileSizeKB: maxFileUploadSize !== undefined
                ? maxFileUploadSize
                : 50000
        },
        uploadedFileTableDetail: {
            placeholder: policyDocuments.isReadOnly && !documents.length ? translator(messages.noDocumentFound) : '',
            data: documents,
        },
        fileDeleteBtnContainer: {
            id: 'deleteBtn',
            renderCell: getDeleteIcon
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onFileUpload: handleDocumentUpload,
            getFileName: getFileName,
            getFormattedDate: getFormattedDate,
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.componentContent, documents, id, path, overrides);
        },
        [documents, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={documents}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
        />
    );
}

PolicyDocuments.propTypes = {
    data: PropTypes.shape({
        canDelete: PropTypes.bool
    }),
    intl: PropTypes.func.isRequired,
    getDownloadLink: PropTypes.func.isRequired,
    onDocumentDelete: PropTypes.func.isRequired,
    onDocumentUpload: PropTypes.func.isRequired
};

PolicyDocuments.defaultProps = {
    data: {
        canDelete: false
    }
};

export default withIntl(PolicyDocuments);
