import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ModalNextProvider } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import {
    WizardPage,
    wizardProps,
    WizardPageTemplate,
    WizardPageTemplateWithTitle
} from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { messages as commonMessages } from 'gw-platform-translations';
import metadata from './TypeOfIncidentPage.metadata.json5';
import FNOLCriminalDamagePage from '../CriminalDamage/CriminalDamagePage';
import FNOLFireDamagePage from '../FireDamage/FireDamagePage';
import FNOLWaterDamagePage from '../WaterDamage/WaterDamagePage';
import messages from '../../FNOLHO.messages';

function getLossCategory(claimVM) {
   const lossCause = _.get(claimVM, 'lossCause.value.code');
    switch (lossCause) {
        case 'water_Ext':
            return 'waterdamage';
        case 'fire':
            return 'fire';
        default:
            return 'miscellaneous';
    }
}

const criminalDamage = {
    id: 'details',
    path: '/details',
    component: FNOLCriminalDamagePage,
    stepProps: {
        template: WizardPageTemplateWithTitle
    },
    title: messages.hoDetails
};

const waterDamage = {
    id: 'details',
    path: '/details',
    component: FNOLWaterDamagePage,
    stepProps: {
        template: WizardPageTemplateWithTitle
    },
    title: messages.hoDetails
};

const fireDamage = {
    id: 'details',
    path: '/details',
    component: FNOLFireDamagePage,
    stepProps: {
        template: WizardPageTemplateWithTitle
    },
    title: messages.hoDetails
};

function FNOLHOTypeOfIncidentPage(props) {
    const {
        wizardData,
        wizardSnapshot,
        updateWizardData,
        history,
        steps,
        changeNextSteps,
        authHeader,
        history: {
            location: { state = {} }
        }
    } = props;
    const { FNOLService } = useDependencies('FNOLService');
    const [isLoading, setIsLoading] = useState(false);
    const [pageData] = useState(state);
    const translator = useContext(TranslatorContext);
    const claimVM = !_.isEmpty(wizardData) ? wizardData : wizardSnapshot;
    const lossCauseTypesArray = claimVM.lossCause.aspects.availableValues;
    const { onValidate, isComponentValid, initialValidation, registerInitialComponentValidation } = useValidation(
        'FNOLHOTypeOfIncidentPage'
    );

    const getLossCauseAvailableValues=()=>{
        const lossCauseValues = [
            {
                code: "CollapseOtherthanSinkhole_Ext",
                name: translator(messages.CollapseOtherthanSinkhole_Ext)
         },
         {
           code: "fire",
           name: translator(messages.fire)
         }, 
         {
            code: "flood_Ext",
            name:translator(messages.flood_Ext)
          }, 
          {
            code: "freezing_Ext",
            name: translator(messages.freezing_Ext)
          },
          {
            code: "hail",
            name: translator(messages.hail)
          },
          {
            code: "lightning_Ext",
            name: translator(messages.lightning_Ext)
          },
          {
            code: "mysteriousdisapoffprem_Ext",
            name: translator(messages.mysteriousdisapoffprem_Ext)
          },
          {
            code: "mysteriousdisaponprem_Ext",
            name: translator(messages.mysteriousdisaponprem_Ext)
          },
         
        {
          code: "PowerSurgeIncludingBrownoutandBlackouts_Ext",
          name: translator(messages.PowerSurgeIncludingBrownoutandBlackouts_Ext)
        },
        {
            code: "smoke_Ext",
            name: translator(messages.smoke_Ext)
          },
          {
            code: "theftBurglaryorRobbery_Ext",
            name:translator(messages.theftBurglaryorRobbery_Ext)
          },
          {
            code: "vandalism_Ext",
            name: translator(messages.vandalism_Ext)
          },
          {
            code: "vehicle_Ext",
            name: translator(messages.vehicle_Ext)
          },
        {
          code: "water_Ext",
          name: translator(messages.water_Ext)
        },
        {
          code: "weightofIceSnowSleet_Ext",
          name: translator(messages.weightofIceSnowSleet_Ext)
        },
        {
          code: "wind",
          name:translator(messages.wind)
        },  
       ];
       return lossCauseValues;
   }

    const incidentPageHandling = useCallback(() => {
        const detailsPageIndex = 2;
        const changeArrayIndex = 0;
        const remainingSteps = steps.slice(detailsPageIndex, steps.length);
        switch (getLossCategory(claimVM)) {
            case 'fire':
                remainingSteps[changeArrayIndex] = fireDamage;
                break;
            case 'waterdamage':
                remainingSteps[changeArrayIndex] = waterDamage;
                break;
            default:
                remainingSteps[changeArrayIndex] = criminalDamage;
        }
        changeNextSteps(remainingSteps);
        return claimVM;
    }, [changeNextSteps, claimVM, steps]);

    useEffect(() => {
        incidentPageHandling();
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getLossCause = useCallback(
        (selectedLossCause) => {
            const LossCause = _.find(lossCauseTypesArray, (cause) => {
                return cause.code === selectedLossCause;
            });
            _.set(claimVM, 'lossCause.value', LossCause);
            updateWizardData(claimVM);
        },
        [claimVM, lossCauseTypesArray, updateWizardData]
    );

    const onNext = useCallback(() => {
        const miscellaneous = claimVM.lossCause.value.code === 'miscellaneous';
        const { redirectPath } = pageData;
        if (miscellaneous) {
            return history.push({
                pathname: '/contact-agent',
                state: {
                    redirectPath: redirectPath
                }
            });
        }
        const policyNumber = _.get(claimVM, 'policy.policyNumber.value');
        const isClaimNumberAvailable = _.get(claimVM, 'claimNumber.value');
        if (isClaimNumberAvailable) {
            return incidentPageHandling();
        }
        setIsLoading(true);
        return FNOLService.getFNOLDetails(policyNumber, claimVM.value, authHeader)
            .then((response) => {
                claimVM.value = response;
                return incidentPageHandling();
            })
            .catch(() => {
                ModalNextProvider.showAlert({
                    title: messages.hoCreateDraftErrorTitle,
                    message: messages.hoCreateDraftErrorMessage,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [FNOLService, authHeader, claimVM, history, incidentPageHandling, pageData]);

    const isClaimStatus = useCallback(() => {
        const { claimStatus } = pageData;
        return !_.isEmpty(claimStatus);
    }, [pageData]);

    const isDetailLossCause =useCallback(()=>{
        const lossCauseCode =  _.get(claimVM, 'lossCause.value.code')
        return (lossCauseCode === 'water_Ext' || lossCauseCode === 'fire' || lossCauseCode === 'smoke_Ext' || lossCauseCode === 'wind' || lossCauseCode === 'theftBurglaryorRobbery_Ext')?true:false;   
   },[]);

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    const overrideProps = {
        lossCauseRadioButton: {
            value: getLossCategory(claimVM)
        },
        lossCauseValue: {
            availableValues: getLossCauseAvailableValues(),
            value: _.get(claimVM,'lossCause.value.code'),
            readOnly: _.get(claimVM, "claimNumber.value")!==undefined
        },
        typeOfIncidentPageLoader: {
            loaded: !isLoading,
            loadingMessage: translator(messages.hoCreateDraftClaim)
        },
        claimsHoContainerPage: {
            visible: !isLoading
        },
        detailLossCause:{
            visible: isDetailLossCause(),
            required: isDetailLossCause(),
            readOnly: _.get(claimVM, "claimNumber.value")!==undefined
        },
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrideProps);
        },
        [claimVM, overrideProps]
    );

    const resolvers = {
        resolveValue: readValue,
        resolveCallbackMap: {
            getLossCause
        }
    };

    return (
        <WizardPage onNext={onNext} disableNext={!isComponentValid} skipWhen={initialValidation} template={WizardPageTemplate}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                resolveValue={resolvers.resolveValue}
            />
        </WizardPage>
    );
}

FNOLHOTypeOfIncidentPage.propTypes = wizardProps;
FNOLHOTypeOfIncidentPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(withAuthenticationContext(FNOLHOTypeOfIncidentPage));
