import { generateURL } from 'gw-portals-url-js';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

function generateDownloadURL(serviceEndpoint, documentID, token) {
    const getUrl = window.location;
    const baseUrl = `${getUrl.origin}/${getUrl.pathname.split('/')[1]}`;
    const redirectRoute = _.get(appConfig, 'documentNotFoundRedirectUrl') || '';
    const params = {
        urlParam: documentID,
        token,
        portalRoute: `${baseUrl}/${redirectRoute}`
    };
    return generateURL(serviceEndpoint, params);
}

function generateDownloadURL_Ext(serviceEndpoint, documentType, policyNumber, token) {
    const getUrl = window.location;
    const baseUrl = `${getUrl.origin}/${getUrl.pathname.split('/')[1]}`;
    const redirectRoute = _.get(appConfig, 'documentNotFoundRedirectUrl') || '';
    const params = {
        urlParam: policyNumber,
        token,
        documentType,
        portalRoute: `${baseUrl}/${redirectRoute}`
    };
    return generateURL(serviceEndpoint, params);
}

function generateBillingDownloadURL_Ext(serviceEndpoint, documentType, policyNumber, docUID, token) {
    const getUrl = window.location;
    const baseUrl = `${getUrl.origin}/${getUrl.pathname.split('/')[1]}`;
    const redirectRoute = _.get(appConfig, 'documentNotFoundRedirectUrl') || '';
    const params = {
        urlParam: policyNumber,
        documentType,
        docUID,
        token,
        portalRoute: `${baseUrl}/${redirectRoute}`
    };
    return generateURL(serviceEndpoint, params);
}

export default class DocumentDownloadService {
    static getDocumentLink(serviceEndpoint, documentID, token) {
        return generateDownloadURL(serviceEndpoint, documentID, token);
    }
    static getDocumentLink_Ext(serviceEndpoint, documentType, policyNumber, token) {
        return generateDownloadURL_Ext(serviceEndpoint, documentType, policyNumber, token);
    }
    static getBillingDocumentLink_Ext(serviceEndpoint, documentType, policyNumber, docUID, token) {
        return generateBillingDownloadURL_Ext(serviceEndpoint, documentType, policyNumber, docUID, token);
    }

    static getDocument(downloadEndpointURL, authHeader, errorCallback = _.noop) {
        const params = {
            method: 'GET',
            url: downloadEndpointURL,
            headers: {
                ...authHeader,
                'Content-Type': 'application/json'
            },
            responseType: 'arraybuffer'
        };
        return fetch(downloadEndpointURL, params).then((response) => {
            if (response.ok) {
                response.blob().then((blob) => {
                    const filename = response.headers.get('Content-Disposition').replace('attachment; filename=', '');
                    if (window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(blob, filename);
                    } else {
                        const downloadLink = document.createElement('a');
                        downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
                        downloadLink.setAttribute('download', filename);
                        downloadLink.style.display = 'none';
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        downloadLink.remove();
                    }
                });
            } else errorCallback();
        }).catch(() => {
            errorCallback();
        });
    }
}
