import React, { useCallback, useEffect, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './ContactItemComponent.metadata.json5';
import paClaimVehicle from '../../pages/Vehicles/VehiclesPage.module.scss';

function FNOLContactItemComponent(props) {
    const {
        data: claimVM, incidentIndex, updateAddPassengers, id, onValidate
    } = props;
    const translator = useContext(TranslatorContext);

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    const generatePassengerTableRow = useCallback(
        (evt, index) => {
            evt.preventDefault();
            const getCurrentMenuItemID = evt.currentTarget.id;
            const getCurrentMenuItemIDNew = getCurrentMenuItemID.split('_');
            const contactsPath = 'contacts.value';
            const contactsItems = _.get(claimVM, contactsPath, []);
            if (getCurrentMenuItemIDNew[0].indexOf('anotherPersonLink') === -1) {
                const selectedPassenger = contactsItems[index];
                if (_.filter(contactsItems, selectedPassenger).length) {
                    claimVM.lobs.personalAuto.vehicleIncidents.value[incidentIndex].addPassenger(
                        selectedPassenger,
                        claimVM.value
                    );
                }
            } else {
                claimVM.lobs.personalAuto.vehicleIncidents.value[incidentIndex].addPassenger(
                    null,
                    claimVM.value
                );
            }
            updateAddPassengers(claimVM);
        },
        [claimVM, incidentIndex, updateAddPassengers]
    );

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const generatePassengerOverrides = useCallback(() => {
        const contactsPath = 'contacts.value';
        const contactsItems = _.get(claimVM, contactsPath, []);
        const addedContacts = claimVM.lobs.personalAuto.vehicleIncidents.value[
            incidentIndex
        ].getAvailablePassengers(claimVM.lobs.personalAuto.value);
        const overrides = contactsItems.map((contactItem, index) => {
            return {
                [`contactItems${index}`]: {
                    message: contactItem.getDisplayName(translator(fnolCommonMessages.fnolUnnamed))
                },
                [`contactItemsDropDown${index}`]: {
                    visible: _.includes(addedContacts, contactItem),
                    onClick: (e) => {
                        generatePassengerTableRow(e, index);
                    }
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [claimVM, generatePassengerTableRow, incidentIndex, translator]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        ...generatePassengerOverrides()
    };

    const resolvers = {
        resolveClassNameMap: paClaimVehicle
    };

    const readValue = useCallback(
        (fieldId, path) => {
            return readViewModelValue(
                metadata.componentContent,
                claimVM,
                fieldId,
                path,
                overrideProps
            );
        },
        [claimVM, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={claimVM}
            overrideProps={overrideProps}
            onModelChange={updateAddPassengers}
            resolveValue={readValue}
            onValidationChange={setComponentValidation}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

FNOLContactItemComponent.propTypes = {
    data: PropTypes.shape({}).isRequired,
    updateAddPassengers: PropTypes.func.isRequired,
    incidentIndex: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired
};

export default FNOLContactItemComponent;
