import { defineMessages } from 'react-intl';

export default defineMessages({
    newPasswordHead: {
        id: 'auth.components.ResetPassword.Reset your password',
        defaultMessage: 'Reset your password'
    },
    chooseNewPassword: {
        id: 'auth.components.ResetPassword.Choose new password',
        defaultMessage: 'Choose new password'
    },
    fieldEmpty: {
        id: 'auth.components.ResetPassword.Enter your new password.',
        defaultMessage: 'Enter your new password.'
    },
    fieldIncorrect: {
        id: 'auth.components.ResetPassword.Fields password and confirmation do not match.',
        defaultMessage: 'Fields password and confirmation do not match.'
    },
    password: {
        id: 'auth.components.ResetPassword.New Password:',
        defaultMessage: 'New Password:'
    },
    confirmPassword: {
        id: 'auth.components.ResetPassword.Retype password',
        defaultMessage: 'Retype password'
    },
    backToSignIn: {
        id: 'auth.components.ResetPassword.Back to Sign In',
        defaultMessage: 'Back to Sign In'
    },
    changePassword: {
        id: 'auth.components.ResetPassword.Reset Password',
        defaultMessage: 'Reset Password'
    },
    passwordSuccessfullyChanged: {
        id: 'auth.components.ResetPassword..Success!',
        defaultMessage: '.Success!'
    },
    returnToSignInAndEnterNewPassword: {
        id: 'auth.components.ResetPassword.Your password has been reset. Remember to use your new password to sign into your account.',
        defaultMessage: 'Your password has been reset. Remember to use your new password to sign into your account.'
    },
    invalidToken: {
        id: 'auth.components.ResetPassword.Invalid token',
        defaultMessage: 'Invalid token'
    },
    resetPasswordLinkDoesntWork: {
        id: 'auth.components.ResetPassword.Sorry, your reset password link is no longer valid.',
        defaultMessage: 'Sorry, your reset password link is no longer valid.'
    },
    unableToProcessYourRequest: {
        id: 'auth.components.ResetPassword.Unable to process your request',
        defaultMessage: 'Unable to process your request'
    },
    resetPasswordLinkNotWorkingOrOldPassword: {
        id: 'auth.components.ResetPassword.Reset password link is no longer valid or your new password should not be same as old password.',
        defaultMessage: 'Reset password link is no longer valid or your new password should not be same as old password.'
    },
    invalidAttempt: {
        id: 'auth.components.ResetPassword.Invalid attempt',
        defaultMessage: 'Invalid attempt'
    },
    unableToProcessYourRequestError: {
        id: 'auth.components.ResetPassword.Unable to process your request.',
        defaultMessage: 'Unable to process your request.'
    },
    invalidPassword: {
        id: 'auth.components.ResetPassword.Password does not meet the criteria.',
        defaultMessage: 'Password does not meet the criteria.'
    },
    passwordMessage: {
        id: 'auth.components.reset.Password must be a minimum of 12 characters and contain at least one capital and one number.',
        defaultMessage: 'Please choose a password you haven\'t used before. Password must be a minimum of 12 characters and contain at least one capital and one number.'
    },
    confirmPassword: {
        id: 'auth.components.ResetPassword.Confirm Password',
        defaultMessage: 'Confirm Password'
    }
});
