import { defineMessages } from 'react-intl';

export default defineMessages({
    forgotYourPassword: {
        id: 'auth.components.ForgotPassword.Forgot Password',
        defaultMessage: 'Forgot Password'
    },
    forgotYourUserName: {
        id: 'auth.components.ForgotPassword.Forgot Username',
        defaultMessage: 'Forgot Username'
    },
    forgotYourPasswordDescription: {
        id: 'auth.components.ForgotPassword.Please enter the Username associated with your account.',
        defaultMessage: 'Please enter the Username associated with your account.'
    },
    forgotYourUserNameDescription: {
        id: 'auth.components.ForgotPassword.Please enter the email address associated with your account.',
        defaultMessage: 'Please enter the email address associated with your account.'
    },
    emailValidation: {
        id: 'auth.components.ForgotPassword.Enter your email address below.',
        defaultMessage: 'Enter your email address below.'
    },
    whiteListEmailValidation: {
        id: 'auth.components.ForgotPassword.whitelistedEmail',
        defaultMessage: 'No matching whitelisted email address entry found'
    },
    userNameValidation: {
        id: 'auth.components.ForgotPassword.Enter your username below.',
        defaultMessage: 'Enter your username below.'
    },
    invalidAttempt: {
        id: 'auth.components.ForgotPassword.Invalid Attempt',
        defaultMessage: 'A reset password link has been sent to your email if there is a matching registered account.'
    },
    email: {
        id: 'auth.components.ForgotPassword.Email:',
        defaultMessage: 'Email:'
    },
    userName: {
        id: 'auth.components.ForgotPassword.Username:',
        defaultMessage: 'Username:'
    },
    backToSignIn: {
        id: 'auth.components.ForgotPassword.Back to Sign In',
        defaultMessage: 'Back to Sign In'
    },
    send: {
        id: 'auth.components.ForgotPassword.Next',
        defaultMessage: 'Next'
    },
    checkYourEmail: {
        id: 'auth.components.ForgotPassword.Check your email.',
        defaultMessage: 'Check your email.'
    },
    linkHasBeenSent: {
        id: 'auth.components.ForgotPassword.A password reset link has been sent. Click the link in the email message to reset your password.',
        defaultMessage: 'A password reset link has been sent. Click the link in the email message to reset your password.'
    },
    invalidEmailAttempt: {
        id: 'auth.components.ForgotPassword.Invalid attempt',
        defaultMessage: 'Invalid attempt'
    },
    noEmailMatch: {
        id: 'auth.components.ForgotPassword.We could not match an account with the email address you entered, please try again.',
        defaultMessage: 'We could not match an account with the email address you entered, please try again.'
    },
    unableToProcessRequest: {
        id: 'auth.components.ForgotPassword.Unable to process your request',
        defaultMessage: 'If an account registered to that email exists, the username has been sent.'
    },
    forgotUsernameSuccess: {
        id: 'auth.components.forgotUserName.If an account registered to that email exists, the username has been sent.',
        defaultMessage: 'If an account registered to that email exists, the username has been sent.'
    },
    forgotPasswordSuccess: {
        id: 'auth.components.forgotUserName.A reset password link has been sent to your email if there is a matching registered account.',
        defaultMessage: 'A reset password link has been sent to your email if there is a matching registered account.'
    },
    forgotSuccessInfo: {
        id: 'auth.components.forgot.Please check your email.',
        defaultMessage: 'Please check your email.'
    },
    forgotSuccessCheck: {
        id: 'auth.components.forgot.Didn\'t receive the email? Please check your junk folder.',
        defaultMessage: 'Didn\'t receive the email? Please check your junk folder.'
    },
    forgotSuccessValid: {
        id: 'auth.components.forgotPassword.Please click the link below within 24 hours to reset your password.',
        defaultMessage: 'Please click the link below within 24 hours to reset your password.'
    },
    continueTosignIn: {
        id: 'auth.components.forgotUserName.Continue to Sign In',
        defaultMessage: 'Continue to Sign In'
    },
    whiteListedEmailMsg: {
        id: 'auth.components.forgotUserName.whitelistedemailmsgMfa',
        defaultMessage: 'No matching whitelisted Email entry found.'
    }
});
