import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './UserUaaDetailsComponent.metadata.json5';
import styles from './UserUaaDetailsComponent.module.scss';

const isSubtype = (contactDetails, subtype) => {
    return contactDetails.subtype.value === subtype;
};

function UserUaaDetailsComponent(props) {
    const [emailErrorTextMsg, SetEmailErrorTextMsg] = useState();

    const showFieldInfo = (event) => {
        const emailInputErrorArea = document.getElementById(event.target.id);
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(event.target.value)) {
            SetEmailErrorTextMsg();
            emailInputErrorArea.style.borderColor = '#7d91ac';
            emailInputErrorArea.style.color = '#28333f';
        } else {
            SetEmailErrorTextMsg('Not a Valid Email');
            emailInputErrorArea.style.borderColor = '#c4221f';
            emailInputErrorArea.style.color = '#c4221f';
        }
    }

    const breakpoint = useContext(BreakpointTrackerContext);
    const {
        id,
        value: account,
        path: elementPath,
        contactPath,
        onValueChange,
        onValidate,
        labelPosition,
        showOptional,
        readOnly,
        nameReadonly,
    } = props;

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const contactVM = useMemo(() => _.get(account, contactPath, {}), [account, contactPath]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    useEffect(()=>{
        SetEmailErrorTextMsg()
    },[readOnly,nameReadonly])

    const onModelChange = useCallback(
        (model) => {
            onValueChange(model, `${elementPath}.${contactPath}`);
        },
        [contactPath, elementPath, onValueChange]
    );

    const overrideProps = {
        '@field': {
            labelPosition,
            showOptional,
            readOnly,
            layout: breakpoint !== 'desktop' ? 'reversed' : ''
        },
        personName2fa: {
            readOnly: readOnly || nameReadonly
        },
        personFirstName2fa: {
            readOnly: readOnly || nameReadonly
        },
        personLastName2fa: {
            readOnly: readOnly || nameReadonly
        },
        emailAddress2fa: {
            onBlur: showFieldInfo
        },
        emailError: {
            content: emailErrorTextMsg,
            visible: !readOnly
        }
    };
    
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={contactVM}
            overrideProps={overrideProps}
            onModelChange={onModelChange}
            onValidationChange={setComponentValidation}
            classNameMap={styles}
        />
    );
}

UserUaaDetailsComponent.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.shape({}).isRequired,
    path: PropTypes.string.isRequired,
    contactPath: PropTypes.string.isRequired,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    labelPosition: PropTypes.string.isRequired,
    showOptional: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool,
    nameReadonly: PropTypes.bool
};

UserUaaDetailsComponent.defaultProps = {
    readOnly: false,
    nameReadonly: false
};

export default UserUaaDetailsComponent;
