import React, { useCallback, useContext,useState,useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './HO4SectionICoverages.metadata.json5';
import styles from './HO4SectionICoverages.module.scss';
import './HO4SectionICoverages';
//import { get } from 'core-js/core/dict';



function HO4SectionICoverages(props) {
    const { sectionICoverages : sectionICoverages_Ext } = props;

    // const getDataTableCell = useCallback((item, index, { path: property }) => {
    //     return item[property];
    // });

    

   

    const getPersonalProperty = useCallback((sectionICoverages_Ext) =>{
        var i 
        for( let p in sectionICoverages_Ext){
           
            if(sectionICoverages_Ext[p].personalProperty == 'Coverage C – Personal Property'){
                i = 'Coverage C – Personal Property'
                
            }
              
        }
        return ` ${i} ` 
        
     });

     const getCovCLimit = useCallback((sectionICoverages_Ext) =>{
        var i 
        for( let p in sectionICoverages_Ext){
            if(sectionICoverages_Ext[p].personalProperty == 'Coverage C – Personal Property'){
                i = sectionICoverages_Ext[p].terms[0].amount
                
            }
        }
        return `Cov C Limit \u00A0\u00A0\u00A0 $${i} `  
    });

    const getLossOfUse = useCallback((sectionICoverages_Ext) =>{
        var i 
        for( let p in sectionICoverages_Ext){
           
            if(sectionICoverages_Ext[p].lossOfUse == 'Coverage D – Loss of Use'){
                i = 'Coverage D – Loss of Use'
                
            }
              
        }
        return ` ${i} ` 
        
     });

     const getCovDLimit = useCallback((sectionICoverages_Ext) =>{
        var i 
        for( let p in sectionICoverages_Ext){
            if(sectionICoverages_Ext[p].lossOfUse == 'Coverage D – Loss of Use'){
                i = sectionICoverages_Ext[p].terms[0].amount
                
            }
        }
        return `Cov D Limit  \u00A0\u00A0\u00A0 $${i} `  
    });

    

    

   
    const overrides = {
        '@element': {
            textAlign: 'left'
        },

        infoTable:{
            data: _.get(sectionICoverages_Ext)
        },

    

        personalProperty:{
            value : getPersonalProperty(sectionICoverages_Ext)
        },

        covCLimit:{
            value : getCovCLimit(sectionICoverages_Ext)
        },

        lossOfUse:{
            value : getLossOfUse(sectionICoverages_Ext)
        },

        covDLimit:{
            value : getCovDLimit(sectionICoverages_Ext)
        }

    };

    const resolvers = {
        resolveCallbackMap:{
          //  getDataTableCell :  getDataTableCell 
            // liabilityLimit:getLimit,
            // rentation:getRentation

        },
        resolveClassNameMap: styles,

    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.componentContent,
                sectionICoverages_Ext,
                id,
                path,
                overrides
            );
        },
        [sectionICoverages_Ext, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={sectionICoverages_Ext}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

HO4SectionICoverages.propTypes = {
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
};

HO4SectionICoverages.defaultProps = {
    value: []
};

export default HO4SectionICoverages;
