import React, {
  useContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import {
  WizardPage,
  wizardProps,
  WizardPageTemplate,
  WizardPageTemplateWithTitle,
} from "gw-portals-wizard-react";
import { withRouter } from "react-router-dom";
import { TranslatorContext } from "@jutro/locale";
import { readViewModelValue } from "gw-jutro-adapters-react";
import { ModalNextProvider } from "@jutro/components";
import { useDependencies } from "gw-portals-dependency-react";
import { Claim } from "gw-capability-fnol-common-react";
import { withAuthenticationContext } from "gw-digital-auth-react";
import { useValidation } from "gw-portals-validation-react";
import { ViewModelForm } from "gw-portals-viewmodel-react";
import { messages as commonMessages } from "gw-platform-translations";
import metadata from "./TypeOfIncidentPage.metadata.json5";
import FNOLPACollisionDetailsPage from "../Details/Collision/CollisionDetailsPage";
import FNOLPAFireDetailsPage from "../Details/Fire/FireDetailsPage";
import FNOLPAWaterDetailsPage from "../Details/Water/WaterDetailsPage";
import FNOLPAGlassDetailsPage from "../Details/Glass/GlassDetailsPage";
import FNOLPATheftDetailsPage from "../Details/Theft/TheftDetailsPage";
import messages from "../../FNOLPA.messages";
import { Loader } from "@jutro/components";

function getLossCategory(claimVM) {
  const lossCause = _.get(claimVM, "lossCause.value.code");
  switch (lossCause) {
    case "theftentire":
    case "theft_Ext":
      return "theft_Ext";
    case "fire":
      return "fire";
    case "water_Ext":
      return "water_Ext";
    case "glassbreakage":
      return "glassbreakage";
    case "otherobjcoll":
      return "otherobjcoll";
    default:
      return "collision_Ext";
  }
}

const collision = {
  id: "details",
  path: "/details",
  component: FNOLPACollisionDetailsPage,
  stepProps: {
    template: WizardPageTemplateWithTitle,
  },
  title: messages.paCollisionDetails,
};

const fire = {
  id: "details",
  path: "/details",
  component: FNOLPAFireDetailsPage,
  stepProps: {
    template: WizardPageTemplateWithTitle,
  },
  title: messages.paFireDetails,
};

const water = {
  id: "details",
  path: "/details",
  component: FNOLPAWaterDetailsPage,
  stepProps: {
    template: WizardPageTemplateWithTitle,
  },
  title: messages.paFireDetails,
};

const theft = {
  id: "details",
  path: "/details",
  component: FNOLPATheftDetailsPage,
  stepProps: {
    template: WizardPageTemplateWithTitle,
  },
  title: messages.paTheftDetails,
};

const glass = {
  id: "details",
  path: "/details",
  component: FNOLPAGlassDetailsPage,
  stepProps: {
    template: WizardPageTemplateWithTitle,
  },
  title: messages.paGlassDetails,
};

function FNOLPATypeOfIncidentPage(props) {
  const {
    wizardData: claimVM,
    updateWizardData,
    steps,
    changeNextSteps,
    history,
    history: {
      location: { state = {} },
    },
    authHeader,
  } = props;
  const translator = useContext(TranslatorContext);
  const [isLoading, setIsLoading] = useState(false);
  const [pageData] = useState(state);
  const { FNOLService } = useDependencies("FNOLService");
  const {
    onValidate,
    isComponentValid,
    initialValidation,
    registerInitialComponentValidation,
  } = useValidation("TypeOfIncidentPage");

  const compareList = [
    {  code:"arson_Ext"},
    {  code:"carelessSmoking_Ext" },
    {  code:"electrical_Ext"},
    {  code:"Mechanical_Ext"},
    {  code:"flammableLiquids_Ext"},
    {  code:"other_Ext"},
    {  code:"vandalism_Ext"}
  ];
  const compareListwater = [
    {  code:"weatherRelatedRainSnow_Ext"},
    {  code:"Flooding_Ext" }
  ];

  const getLossCauseAvailableValues = () => {
    const lossCauseValues = [
      {
        code: "animal",
        name: translator(messages.animal),
      },
      {
        code: "collision_Ext",
        name: translator(messages.collision_Ext),
      },
      {
        code: "FallingObject",
        name: translator(messages.FallingObject),
      },
      {
        code: "fire",
        name: translator(messages.fire),
      },
      {
        code: "glassbreakage",
        name: translator(messages.glassbreakage),
      },
      {
        code: "hail",
        name: translator(messages.hail),
      },
      {
        code: "vandalism",
        name: translator(messages.vandalism),
      },
      {
        code: "mechanicalFailure_Ext",
        name: translator(messages.mechanicalFailure_Ext),
      },
      {
        code: "theft_Ext",
        name: translator(messages.theft_Ext),
      },
      {
        code: "towing_Ext",
        name: translator(messages.towing_Ext),
      },
      {
        code: "water_Ext",
        name: translator(messages.Water),
      },
    ];
    return lossCauseValues;
  };

  const getLossCauseincident = () => {
    const lossCauseCode = _.get(claimVM, 'lossCause.value.code')
    var list = _.get(claimVM, 'detailLossCause_Ext.aspects.availableValues')
    //console.log(_.get(claimVM, 'lossCause.value.code'))
    if (lossCauseCode === 'fire') {
      list = list.filter((value) => {
        return compareList.some((ic) => ic.code === value.code);
      }).map((value) => {
        return { code: value.code, name: translator({ id: value.name }) };
      });
      return list
    } if (lossCauseCode === 'water_Ext') {
      list = list.filter((value) => {
        return compareListwater.some((ic) => ic.code === value.code);
      }).map((value) => {
        return { code: value.code, name: translator({ id: value.name }) };
      });
      return list
    }
    return list.map((value) => {
      return { code: value.code, name: translator({ id: value.name }) };
    });
  }

  const incidentPageHandling = useCallback(() => {
    const [, , ...remainingSteps] = steps;
    const detailPageIndex = _.findIndex(remainingSteps, { path: "/details" });
    const vehiclePageIndex = _.findIndex(remainingSteps, { path: "/vehicles" });
    switch (getLossCategory(claimVM)) {
      case "collision_Ext":
        remainingSteps[detailPageIndex] = collision;
        break;
      case "theft_Ext":
        remainingSteps[detailPageIndex] = theft;
        break;
      case "fire":
        remainingSteps[detailPageIndex] = fire;
        break;
      case "water_Ext":
        remainingSteps[detailPageIndex] = water;
        break;
      case "glassbreakage":
        remainingSteps[detailPageIndex] = glass;
        break;
      default:
        remainingSteps[detailPageIndex] = collision;
    }
    changeNextSteps(remainingSteps);
    return claimVM;
  }, [changeNextSteps, claimVM, steps]);
  useEffect(() => {
    incidentPageHandling();
    // only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectContactAgent = useCallback(() => {
    const { redirectPath } = pageData;
    const claimNumber = _.get(claimVM.value, "claimNumber");
    return history.push({
      pathname: `/contact-agent/${claimNumber}`,
      state: {
        redirectPath: redirectPath,
      },
    });
  }, [claimVM, history, pageData]);
  const onNext = useCallback(() => {
    const miscellaneous = claimVM.lossCause.value.code === "otherobjcoll";
    const policyNumber = _.get(claimVM, "policy.policyNumber.value");
    const isClaimNumberAvailable = _.get(claimVM, "claimNumber.value");
    if (isClaimNumberAvailable) {
      if (miscellaneous) {
        return redirectContactAgent();
      }
      return incidentPageHandling();
    }
    setIsLoading(true);
    return FNOLService.getFNOLDetails(policyNumber, claimVM.value, authHeader)
      .then((response) => {
        claimVM.value = new Claim(response);
        if (miscellaneous) {
          return redirectContactAgent();
        }
        return incidentPageHandling();
      })
      .catch(() => {
        ModalNextProvider.showAlert({
          title: messages.paCreateDraftErrorTitle,
          message: messages.paCreateDraftErrorMessage,
          status: "error",
          icon: "mi-error-outline",
          confirmButtonText: commonMessages.ok,
        }).catch(_.noop);
        return false;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    FNOLService,
    authHeader,
    claimVM,
    incidentPageHandling,
    redirectContactAgent,
  ]);

  const isDetailLossCause = useCallback(() => {
    const lossCauseCode = _.get(claimVM, "lossCause.value.code");
    return lossCauseCode === "collision_Ext" ? true : false;
  }, []);

  const isDetailLossCauseTheft = useCallback(() => {
    const lossCauseCode = _.get(claimVM, "lossCause.value.code");
    return lossCauseCode === "theft_Ext" ? true : false;
  }, []);

  const isDetailLossCauseFire = useCallback(() => {
    const lossCauseCode = _.get(claimVM, "lossCause.value.code");
    return lossCauseCode === "fire" ? true : false;
  }, []);

  const isDetailLossCauseWater = useCallback(() => {
    const lossCauseCode = _.get(claimVM, "lossCause.value.code");
    return lossCauseCode === "water_Ext" ? true : false;
  }, []);

  const overrides = {
    claimsPaTypeOfIncidentPage: {
      visible: !isLoading,
    },
    detailLossCauseCollision: {
      visible: isDetailLossCause(),
      required: isDetailLossCause(),
      readOnly: _.get(claimVM, "claimNumber.value")!==undefined
    },
    detailLossCauseTheft: {
      visible: isDetailLossCauseTheft(),
      required: isDetailLossCauseTheft(),
      readOnly: _.get(claimVM, "claimNumber.value")!==undefined
    },
    detailLossCauseFire: {
      availableValues: getLossCauseincident(),
      visible: isDetailLossCauseFire(),
      required: isDetailLossCauseFire(),
      readOnly: _.get(claimVM, "claimNumber.value")!==undefined
    },
    detailLossCauseWater: {
      availableValues: getLossCauseincident(),
      visible: isDetailLossCauseWater(),
      required: isDetailLossCauseWater(),
      readOnly: _.get(claimVM, "claimNumber.value")!==undefined
    },
    incidentTypeRadioButton: {
      availableValues: getLossCauseAvailableValues(),
      value: _.get(claimVM, "lossCause.value.code"),
      readOnly: _.get(claimVM, "claimNumber.value")!==undefined
    },
    typeOfIncidentPageLoader: {
      loaded: !isLoading,
      loadingMessage: translator(messages.paCreateDraftClaim)
  },
  claimsPaTypeOfIncidentPage: {
      visible: !isLoading
  },
  };

  const readValue = useCallback(
    (id, path) => {
      return readViewModelValue(
        metadata.pageContent,
        claimVM,
        id,
        path,
        overrides
      );
    },
    [claimVM, overrides]
  );

  const resolvers = {
    resolveValue: readValue,
    resolveCallbackMap: {},
  };

  return (
    <WizardPage
      onNext={onNext}
      template={WizardPageTemplate}
      disableNext={!isComponentValid}
      skipWhen={initialValidation}
    >
      <ViewModelForm
        uiProps={metadata.pageContent}
        model={claimVM}
        resolveValue={resolvers.resolveValue}
        callbackMap={resolvers.resolveCallbackMap}
        overrideProps={overrides}
        onValidationChange={onValidate}
        onModelChange={updateWizardData}
      />
    </WizardPage>
  );
}

FNOLPATypeOfIncidentPage.propTypes = wizardProps;
FNOLPATypeOfIncidentPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
export default withRouter(withAuthenticationContext(FNOLPATypeOfIncidentPage));
