import React, { useCallback, useContext,useState,useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PALineLevelCoveragesRI.metadata.json5';
import styles from './PALineLevelCoveragesRI.module.scss';
import { BreakpointTrackerContext } from '@jutro/layout';
import './PALineLevelCoveragesRI';
import { isEmpty } from 'lodash';
//import { get } from 'core-js/core/dict';



function PALineLevelCoveragesRI(props) {
    const { data : lineCoverage, vehicleType : vehicleType, paCoverages } = props;
    const breakpoint = useContext(BreakpointTrackerContext);


    // const getDataTableCell = useCallback((item, index, { path: property }) => {
    //     return item[property];
    // });

   

 

    const getLiabilityBodilyInjury = useCallback((lineCoverage) =>{
        var i 
        for( let p in paCoverages.coverageDTOs){
           
           
               
                    if(paCoverages.coverageDTOs[p].name == 'Bodily Injury' || paCoverages.coverageDTOs[p].name == 'Combined Single Limit'){
                        for(let n in paCoverages.coverageDTOs){
                            if(paCoverages.coverageDTOs[n].name == 'Liability Limit Selection'){
                               
                                    if(paCoverages.coverageDTOs[n].terms[0].amount == 'Split'){
                                        i = 'Liability - Bodily Injury (Each Person/Each Accident)'
                                        return ` ${i} ` 
                                       
                                    }
                                    else {
                                        i = 'Liability - Combined Single Limit (Each Accident)'
                                        return ` ${i} ` 
                                       
                                    }
                                
                            }
                        }
                        
                    }   
        }
        
        
     });


     const getLiabilityPropertyDamage = useCallback((lineCoverage) =>{
        var i 
        for( let p in paCoverages.coverageDTOs){
           
           
               
                    if(paCoverages.coverageDTOs[p].name == 'Property Damage'){
                        for(let n in paCoverages.coverageDTOs){
                         
                                        i = 'Liability - Property damage (Each Accident)'
                                        return ` ${i} `
                             
                            
                        }
                        
                    }   
        }
        
        
     });

     const getUmUimBodilyInjury = useCallback((lineCoverage) =>{
        var i 
        for( let p in paCoverages.coverageDTOs){
           
                    if(paCoverages.coverageDTOs[p].name == 'Uninsured/Underinsured Motorists Bodily Injury' 
                    || paCoverages.coverageDTOs[p].name == 'Uninsured/Underinsured Motorists Combined Single Limit'){
                        for(let n in paCoverages.coverageDTOs){
                           
                                            if(paCoverages.coverageDTOs[n].name == 'Liability Limit Selection'){
                                               
                                                    if(paCoverages.coverageDTOs[n].terms[0].amount == 'Split'){
                                                        i = 'Uninsured/Underinsured Motorist Bodily Injury (Each Person/Each Accident)'
                                                        return ` ${i} ` 
                                                    
                                                       
                                                    }
                                                    else{
                                                        i = 'Uninsured/Underinsured Motorist Combined Single Limit (Each Person/Each Accident)'
                                                        return ` ${i} ` 
                                                    
                                                    }
                                       
                                    }
         
                            
                        }
                        
                    }   
        }
        
        
      }   
);

     const getMedicalPayments = useCallback((paCoverages) =>{
        var i 
        for( let p in paCoverages.coverageDTOs){
           
           
               
                    if(paCoverages.coverageDTOs[p].name == 'Medical Payments'){
                        
                       i = 'Medical Payments (Each Person)'
                        
                    }
                    else return null   
        }
        return ` ${i} ` 
        
     });

     const getReparationsBenefitCoverage = useCallback((paCoverages) =>{
        var i 
        for( let p in paCoverages.coverageDTOs){
           
           
               
                    if(paCoverages.coverageDTOs[p].name == 'Reparations Benefit Coverage'){
                       i= 'Reparations Benefit Coverage – Weekly/Aggregate Limits'
                       return ` ${i} ` 
                        
                    }   
        }
       
        
     });

     const getLBILimit = useCallback((paCoverages) =>{
        var i 
        for( let p in paCoverages.coverageDTOs){
        
                    if(paCoverages.coverageDTOs[p].name == 'Bodily Injury' || paCoverages.coverageDTOs[p].name == 'Combined Single Limit'){
                        if(vehicleType.vehicleType === 'notstored'){
                               
                            i = paCoverages.coverageDTOs[p].terms[0].amount
                            return `$ ${i} ` 
                            

                        }
                        else return `-`
                   
                    }
         
        }
       
     });

     const getLPDLimit = useCallback((paCoverages) =>{
        var i 
        for( let p in paCoverages.coverageDTOs){
        
                    if(paCoverages.coverageDTOs[p].name == 'Property Damage'){
                      
                        if(vehicleType.vehicleType === 'notstored'){
                               
                            i = paCoverages.coverageDTOs[p].terms[0].amount
                            return `$ ${i} ` 
                            

                        }
                        else return `-`
                   
                    }
                    
         
        }
        
     });

     const getUmUimBILimit = useCallback((paCoverages) =>{
        var i 
        for( let p in paCoverages.coverageDTOs){
        
                    if(paCoverages.coverageDTOs[p].name == 'Uninsured/Underinsured Motorists Bodily Injury' ||
                    paCoverages.coverageDTOs[p].name == 'Uninsured/Underinsured Motorists Combined Single Limit'){
                      
                        if(vehicleType.vehicleType === 'notstored'){
                               
                            i = paCoverages.coverageDTOs[p].terms[0].amount
                            return `$ ${i} ` 
                            

                        }
                        else return `-`
                   
                    }
         
        }
       
     });

     const getMPLimit = useCallback((paCoverages) =>{
        var i 
        for( let p in paCoverages.coverageDTOs){
        
                    if(paCoverages.coverageDTOs[p].name == 'Medical Payments'){
                      
                        if(vehicleType.vehicleType === 'notstored'){
                               
                            i = paCoverages.coverageDTOs[p].terms[0].amount
                            return `$ ${i} ` 
                            

                        }
                        else return `-`
                   
                    }
                   
         
        }
       
     });

     const getReparationsBLimit = useCallback((paCoverages) =>{
        var i,j;
        for( let p in paCoverages.coverageDTOs){
        
                    if(paCoverages.coverageDTOs[p].name == 'Reparations Benefit Coverage'){
                      
                                i = paCoverages.coverageDTOs[p].terms[0].amount
                                j = paCoverages.coverageDTOs[p].terms[1].amount                             
                                   return `$ ${j}/${i} ` 
                   
                    }
                   
         
        }
        
     });

    


    

   
    const overrides = {
        '@element': {
            textAlign: 'left'
        },

        infoTable:{
            data: _.get(lineCoverage)
        },

        

        liabilityBodilyInjury : {
            value: getLiabilityBodilyInjury(paCoverages)
           
        },

        lBILimit : {
             value : getLBILimit(paCoverages),
             hideLabel : breakpoint==='phone'? false : true
        },

       umUimBodilyInjury : {
           value : getUmUimBodilyInjury(paCoverages)
       },

       umUimBILimit : {
        value : getUmUimBILimit(paCoverages),
        hideLabel : breakpoint==='phone'? false : true
       },

       umUimBIDeductible : {
        hideLabel : breakpoint==='phone'? false : true  
       },

        medicalPayments : {
            visible : lineCoverage.find(cov=>cov.name === 'Medical Payments') != null
        },

        mPLimit : {
            visible : lineCoverage.find(cov=>cov.name === 'Medical Payments') != null,
            value : getMPLimit(paCoverages),
            hideLabel : breakpoint==='phone'? false : true
        },

        mPDeductible : {
            visible : lineCoverage.find(cov=>cov.name === 'Medical Payments') != null,
            hideLabel : breakpoint==='phone'? false : true
        },

        liabilityPropertyDamage : {
            visible : lineCoverage.find(cov=>cov.name === 'Property Damage') != null,
            value : getLiabilityPropertyDamage(paCoverages)  
        },

        lPDLimit : {
            visible : lineCoverage.find(cov=>cov.name === 'Property Damage') != null,
            value : getLPDLimit(paCoverages),
            hideLabel : breakpoint==='phone'? false : true
        },

        lPDDeductible : {
            visible : lineCoverage.find(cov=>cov.name === 'Property Damage') != null,
            hideLabel : breakpoint==='phone'? false : true
        },

        lBIDeductible : {
            hideLabel : breakpoint==='phone'? false : true
        },
        personalLiabilityLimit : {
            visible : breakpoint==='phone'? false : true
          },
   
          personalLiabilityDeductible : {
           visible : breakpoint==='phone'? false : true 
          },
   
          personalLiabilityCov : {
           visible : breakpoint==='phone'? false : true  
          }


    };

    const resolvers = {
        resolveCallbackMap:{
          //  getDataTableCell :  getDataTableCell 
            // liabilityLimit:getLimit,
            // rentation:getRentation

        },
        resolveClassNameMap: styles,

    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.componentContent,
                lineCoverage,
                id,
                path,
                overrides
            );
        },
        [lineCoverage, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={lineCoverage}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

PALineLevelCoveragesRI.propTypes = {
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
};

PALineLevelCoveragesRI.defaultProps = {
    value: []
};

export default PALineLevelCoveragesRI;
