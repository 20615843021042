import LoginComponent from './LoginComponent/LoginComponent';
import SignUpComponent from './SignUpComponent/SignUpComponent';
import ForgotPasswordComponent from './ForgotPasswordComponent/ForgotPasswordComponent';
import ResetPasswordComponent from './ResetPasswordComponent/ResetPasswordComponent';
import NlcLoginComponent from './NlcLoginComponent/NlcLoginComponent';
import ForgotPasswordOtpComponent from './ForgotPasswordOtpComponent/ForgotPasswordOtpComponent';
const LoginCapabilityComponentMap = {
    LoginComponent: { component: 'LoginComponent' },
    SignUpComponent: { component: 'SignUpComponent' },
    ForgotPasswordComponent: { component: 'ForgotPasswordComponent' },
    ResetPasswordComponent: { component: 'ResetPasswordComponent' },
    NlcLoginComponent: {component: 'NlcLoginComponent'},
    ForgotPasswordOtpComponent: {component: 'ForgotPasswordOtpComponent'}
};

const LoginCapabilityComponents = {
    LoginComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NlcLoginComponent,
    ForgotPasswordOtpComponent
};

export {
    LoginCapabilityComponentMap,
    LoginCapabilityComponents,
    LoginComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NlcLoginComponent,
    ForgotPasswordOtpComponent
};
