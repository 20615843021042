import React, { useContext, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { WizardPage, WizardPageTemplate, wizardProps } from 'gw-portals-wizard-react';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from 'gw-portals-validation-react';
import { FNOLLossLocation, fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './GlassDetailsPage.metadata.json5';
import styles from './GlassDetailsPage.module.scss';
import './GlassDetailsPage.messages';

function FNOLCAGlassDetailsPage(props) {
    const {
        wizardData: claimVM,
        updateWizardData,
        history: {
            location: { state = {} }
        }
    } = props;
    const translator = useContext(TranslatorContext);
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('FNOLCAGlassPage');

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        lossLocationComponent: {
            path: !_.isUndefined(claimVM) ? claimVM : null,
            updatePath: updateWizardData
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            losslocationcomponent: FNOLLossLocation
        },
        resolveCallbackMap: {
            onValidate: onValidate
        }
    };

    const isClaimStatus = useCallback(() => {
        const { claimStatus } = state;
        const lossCauseCheck = _.get(claimVM.value, 'lossCause');
        return !_.isEmpty(claimStatus) && lossCauseCheck === 'glassbreakage';
    }, [claimVM, state]);

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    return (
        <WizardPage
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            skipWhen={initialValidation}
            template={WizardPageTemplate}
            disableNext={!isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

FNOLCAGlassDetailsPage.propTypes = wizardProps;
export default withRouter(FNOLCAGlassDetailsPage);
