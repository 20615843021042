import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter, Redirect  } from 'react-router-dom';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { useAuthentication } from 'gw-digital-auth-react';
import metadata from './mfaOtpVerifyComponent.metadata.json5';
import messages from './mfaOtpVerifyComponent.messages';
import styles from './mfaOtpVerifyComponent.module.scss';
import { useDependencies } from 'gw-portals-dependency-react';
import { EntryPageTemplateWithOutLogo } from 'gw-components-platform-react';
import appConfig from 'app-config';
import { TranslatorContext } from '@jutro/locale';
import { OtpService } from 'nlc-capability-otp';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { Loader } from '@jutro/components';

const applicationDetails = _.get(appConfig, 'applicationDetails', {});
const mfaDetails = _.get(appConfig, 'mfa', {});
const {maxNumberOfEmailOtp, maxNumberOfMobileOtp, maxNumberOfEmailOtpVerification, maxNumberOfMobileOtpVerification} = _.get(mfaDetails, 'login');

function MfaOtpVerifyComponent(props) {
    const { authHeader,refreshToken } = useAuthentication();
    const {history} = props;
    const state = _.get(history, 'location.myData');
    const callFrom = _.get(history, 'location.from');
    let userName = undefined;
    let email = undefined;
    let phoneNumber = undefined;
    let eventIndex = undefined;
    if(state){
        userName = state.currentUserName;
        email = state.currentEmail;
        phoneNumber = state.currentPhoneNumber;
        eventIndex = state.index;
    }
    const translator = useContext(TranslatorContext);
    const [formData, updateFormData] = useState({}); 
    const [codeSent, setCodeSent] = useState(false);
    const { UserService } = useDependencies('UserService');
    const [countOfEmailOtp, setCountOfEmailOtp] = useState(0); 
    const [countOfMobileOtp, setCountOfMobileOtp] = useState(0); 
    const [countOfSignInWithEmail, setCountOfSignInWithEmail] = useState(0); 
    const [countOfSignInWithMobile, setCountOfSignInWithMobile] = useState(0);
    const [disableAll, setDisableAll] = useState(false);
    const [errorMessage, updateErrorMessage] = useState(undefined);
    const { isLoggedIn, isAuthInProgress } = useAuthentication(); 
    const [showOnlyMobileOption, updateShowOnlyMobileOption] = useState(false);
    
    useEffect(() => { 
        if(!authHeader){
            const nextState = { pathname: '/login-page'};
            return <Redirect to={nextState} />;
        }
       
        UserService.getLoggedinUserDetails(authHeader).then((userDetails) => {
            const details = {
                userName: userName,
                email: email,
                phoneNumber: phoneNumber,
                accountHolder:_.get(userDetails,'accountHolder'),
                selectedTarget: undefined,
                otpCode: undefined
            }
            updateFormData(details);
            
        });

        const inputData = new FormData();
        inputData.append('userName', userName);
        inputData.append('type',  'signin');
        const data = {
            method: 'POST',
            body: inputData,
            redirect: 'follow',

        }  
        OtpService.getPasswordResetStatus(data).then((otp)=>{
            if(otp.status === 200) {
                otp.json().then((data) => {
                    if(data && data.resetStatus){
                        data.resetStatus==='true'?updateShowOnlyMobileOption(true):updateShowOnlyMobileOption(false);
                    }                    
                })
            }
        });     
        
        const mobileOtpCount = localStorage.getItem('mobileOtpCount') 
        ? parseInt(localStorage.getItem('mobileOtpCount')) : 0;
        setCountOfMobileOtp(mobileOtpCount);

        const emailOtpCount = localStorage.getItem('emailOtpCount') 
                ? parseInt(localStorage.getItem('emailOtpCount')) : 0;
        setCountOfEmailOtp(emailOtpCount);

        const emailSignInCount = localStorage.getItem('countOfSignInOtpVerificationWithEmail') 
                ? parseInt(localStorage.getItem('countOfSignInOtpVerificationWithEmail')) : 0;
        setCountOfSignInWithEmail(emailSignInCount);

        const mobileSignInCount = localStorage.getItem('countOfSignInOtpVerificationWithMobile') 
                ? parseInt(localStorage.getItem('countOfSignInOtpVerificationWithMobile')) : 0;
        setCountOfSignInWithMobile(mobileSignInCount);

        if(mobileOtpCount === maxNumberOfMobileOtp || emailOtpCount === maxNumberOfEmailOtp
            || emailSignInCount === maxNumberOfEmailOtpVerification || mobileSignInCount === maxNumberOfMobileOtpVerification){
            setDisableAll(true); 
            updateErrorMessage('userLocked');           
        }

    }, [userName, email, phoneNumber, authHeader, maxNumberOfEmailOtp, maxNumberOfMobileOtp, maxNumberOfEmailOtpVerification, maxNumberOfMobileOtpVerification]);

    const sendOtpCode = useCallback(async() => { 
        updateErrorMessage(undefined);
        const target = _.get(formData, 'selectedTarget');
        const inputData = new FormData();
        inputData.append('userName', userName);
        inputData.append('type',  'signin');
        const data = {
            method: 'POST',
            body: inputData,
            redirect: 'follow',

        }        
            
        try{            
            if( target && target === 'email') { 
                if(countOfEmailOtp<maxNumberOfEmailOtp){
                    inputData.append('email', email);
                    OtpService.verifyWhiteListEmailData(data).then((resp) => resp.json())
                    .then((resp) => {
                        if (resp.response === true && resp.statusCode === 400) {
                            updateErrorMessage('whiteListedEmailMsg');
                        } else {
                            const otp = OtpService.sendEmailOtp(data).then((resp) => resp.json()).then((resp) => {
                                if(resp.statusCode === 200) {
                                    setCodeSent(true);
                                    let count = localStorage.getItem('emailOtpCount') 
                                    ? parseInt(localStorage.getItem('emailOtpCount')) : 0;
                                    count =count+1;
                                    localStorage.setItem('emailOtpCount', count);
                                    setCountOfEmailOtp(count);
                                } 
                            })
                        }
                    })
                } else {
                    lockTheUser();                        
                    setDisableAll(true);
                }
            }else if( target && target === 'mobile') {
                if(countOfMobileOtp<maxNumberOfMobileOtp) {            
                    inputData.append('phone',  phoneNumber);
                    inputData.append('mobileNumber',  phoneNumber);
                    OtpService.verifyWhiteListMobileData(data).then((resp) => resp.json())
                    .then((resp) => {
                        if (resp.response === true && resp.statusCode === 400) {
                            updateErrorMessage('whiteListedPhoneMsg');
                        } else {
                             OtpService.sendSms(data).then((resp) => resp.json()).then((resp) => {
                                if(resp.statusCode === 200) {
                                    setCodeSent(true);
                                    let count = localStorage.getItem('mobileOtpCount') 
                                    ? parseInt(localStorage.getItem('mobileOtpCount')) : 0;
                                    count =count+1;
                                    localStorage.setItem('mobileOtpCount', count);
                                    setCountOfMobileOtp(count);
                                } 
                            })
                        }
                    });
                } else {
                    lockTheUser();                        
                    setDisableAll(true);
                }
            }            
        }catch(err){
            console.log(err);
        }
        
    },[formData, userName, email, phoneNumber, countOfMobileOtp, countOfEmailOtp, maxNumberOfEmailOtp, maxNumberOfMobileOtp]);

    const lockTheUser = useCallback(() => {
        const inputData1 = new FormData();
        inputData1.append('userName', userName);  
        const data1 = {
            method: 'POST',
            body: inputData1,
            redirect: 'follow',
        }  
    
        try{                    
            OtpService.lockUser(data1).then((status)=>{
                if(status.status === 200) { 
                    updateErrorMessage('userLocked');
                }
            });            
        }catch(err){
            console.log(err);
        }            
    },[userName]);
    
    const handleNext = useCallback( async()  => {        
        const target = _.get(formData, 'selectedTarget');
        const inputData = new FormData();
        inputData.append('userName', userName);
        inputData.append('otp', _.get(formData, 'otpCode'));
        const data = {
            method: 'POST',
            body: inputData,
            redirect: 'follow'
        }
            
        try{           
            if( target && target === 'email') { 
                if(countOfSignInWithEmail<maxNumberOfEmailOtpVerification){           
                    inputData.append('email', email);
                    const otp = await OtpService.verifyEmailOtp(data);
                    if(otp.status === 200) {
                        moveToHomeAndSetCookie(); 
                    }else{
                        let count = localStorage.getItem('countOfSignInOtpVerificationWithEmail') 
                        ? parseInt(localStorage.getItem('countOfSignInOtpVerificationWithEmail')) : 0;
                        count =count+1;
                        localStorage.setItem('countOfSignInOtpVerificationWithEmail', count);
                        setCountOfSignInWithEmail(count);     
                        updateErrorMessage('invalidOtpMsg');
                    }
                }else{
                    lockTheUser();                        
                    setDisableAll(true); 
                }
            }else if( target && target === 'mobile') {  
                if(countOfSignInWithMobile<maxNumberOfMobileOtpVerification) {       
                    inputData.append('phone', phoneNumber);
                    const otp = await OtpService.verifySmsOtp(data);
                    if(otp.status === 200) {
                        moveToHomeAndSetCookie()  
                    }else {
                        let count = localStorage.getItem('countOfSignInOtpVerificationWithMobile') 
                        ? parseInt(localStorage.getItem('countOfSignInOtpVerificationWithMobile')) : 0;
                        count =count+1;
                        localStorage.setItem('countOfSignInOtpVerificationWithMobile', count);
                        setCountOfSignInWithMobile(count);
                        updateErrorMessage('invalidOtpMsg');
                    }
                } else{
                    lockTheUser();                        
                    setDisableAll(true); 
                }
            }            
        }catch(err){
            console.log(err);
        } 
                
    },[history, userName, email, phoneNumber, formData, countOfSignInWithMobile, maxNumberOfMobileOtpVerification, countOfSignInWithEmail, maxNumberOfEmailOtpVerification]);

    const moveToHomeAndSetCookie = useCallback(() => {
        sessionStorage.setItem('mfaStatus', 'true');
        const nextState = { pathname: '/userprofile', from: callFrom, verifiedFromMfa : {state } };
        history.push(nextState); 
        
    },[userName]);

    const readValue = useCallback((id, path) => _.get(formData, path), [formData]);

    const writeValue = useCallback((value, path) => {
        updateErrorMessage(undefined);
        const nextFormData = _.cloneDeep(formData);
        _.set(nextFormData, path, value);
        updateFormData(nextFormData);
    },[formData]);

    const getMaskedEmail = useCallback(() => {
        let email = _.get(formData, 'email');
        if(email){
            var inter = email.split('@');
            return inter[0].replace(/./g, 'X')+'@'+inter[1];
        }
    },[formData]);

    const getMaskedPhoneNumber = useCallback(() => {
        let phoneNumber = _.get(formData, 'phoneNumber');        
        if(phoneNumber){
            return phoneNumber.replace(/.(?=.{4,}$)/g, 'X');
        }
    },[formData]);

    const shouldDisableSendCodeButton = useCallback(() => {
        const target = _.get(formData, 'selectedTarget');
        if(!target){
            return true;
        }
        return disableAll;
    },[formData, countOfEmailOtp, countOfMobileOtp, disableAll]);

    const backToUserDetails = () => {
        const nextState = { pathname: '/userprofile', from: 'MFAOTPVERIFYCANCEL'};
        history.push(nextState);
    }

    const getTargetAvailableValues = useCallback(() => {
       let availableValues = [  
            {
                code: 'mobile',
                displayName: `Text message to phone number ${getMaskedPhoneNumber()}`
            }                             
        ]
        if(!showOnlyMobileOption){
            availableValues.push( {
                code: 'email',
                displayName: `Email to ${getMaskedEmail()}`                        
            });
        }
        return availableValues;
    },[showOnlyMobileOption, getMaskedPhoneNumber, getMaskedEmail]);

    const overrideProps = {
        '@field': {
            onValueChange: writeValue
        },
        accountHolder: {
            content: `Account Holder : ${_.get(formData, 'accountHolder')}`,
            className: styles.accountHolderName
        },
        targetMfaRadioButton:{
            value: _.get(formData, 'selectedTarget'), 
            hideLabel: showOnlyMobileOption,          
            availableValues:getTargetAvailableValues()
        },
        sendCode: {
            disabled: shouldDisableSendCodeButton(),
            onClick: sendOtpCode
        },
        infoMsgOnSendCode: {
            visible: codeSent && !disableAll,
        },
        signInActionsContainer:{
            className:  styles.sigInActionContainer
        },
        otpCode:{
            disabled: !codeSent || disableAll,
            labelPosition: 'left',
            onValueChange: writeValue,
            value:_.get(formData, 'otpCode')
        },
        backButton: {
            onClick: backToUserDetails 
        },
        nextButton: {
            disabled: _.get(formData, 'otpCode') ===undefined || disableAll,
            onClick: handleNext
        }, 
        otpErrorMessageContainer: {
            visible: errorMessage !== undefined
        },
        otpErrorMessage: {                
            message: translator(messages[errorMessage])
        }
    }

    const resolvers = {
        classNameMap: styles        
    };

    const renderedContentFromMetadata = renderContentFromMetadata(
        metadata.componentContent,
        overrideProps,
        resolvers
    );

    if (isAuthInProgress) {
        return <Loader loaded={!isAuthInProgress} />;
    }

    return (
        <EntryPageTemplateWithOutLogo
            contentToRender={renderedContentFromMetadata}
        />
    );
}

MfaOtpVerifyComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withAuthenticationContext(withRouter(MfaOtpVerifyComponent));
