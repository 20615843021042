import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PolicyCommonDetails.metadata.json5';
import './PolicyCommonDetails.messages';
import styles from './PolicyCommonDetails.module.scss';

function PolicyCommonDetails(props) {
    const { value: policyCommonDetails , homePolicyType , jurisdiction } = props;
    const [producerEmail, setProducerEmail] = useState(_.get(policyCommonDetails, "producerEmail", "-"));
    const [producerWebsite, setProducerWebsite] = useState(_.get(policyCommonDetails, "producerWebsite", "-"));

    const overrides = {   
        emailLink:{
            content: producerEmail,
            target: '_blank',
            href: `mailto:${producerEmail}`   
        },
        websiteLink:{
            content: producerWebsite,
            target: '_blank',
            href: `${producerWebsite}`  
        },
        taxesAndFeesValue : {
            visible : jurisdiction === 'Connecticut' && ( homePolicyType === 'Homeowners' || homePolicyType === 'Renters' 
            || homePolicyType === 'Condominium'|| homePolicyType === 'Dwelling Fire'  )
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyCommonDetails}
            overrideProps={overrides}
            classNameMap={styles}
        />
    );
}

PolicyCommonDetails.propTypes = {
    value: PropTypes.shape({})
};

PolicyCommonDetails.defaultProps = {
    value: {}
};

export default PolicyCommonDetails;
