import { defineMessages } from 'react-intl';

export default defineMessages({
    paDetailsCollision: {
        id: 'fnol.pa.views.pa-water-loss-details.Details.Water',
        defaultMessage: 'Details'
    },
    paWhatHappened: {
        id: 'fnol.pa.views.pa-water-loss-details.What Happened',
        defaultMessage: 'What Happened'
    },
    paDescribeWhatHappened: {
        id: 'fnol.pa.views.pa-water-loss-details.Describe what happened',
        defaultMessage: 'Describe what happened?'
    },
    paAnyPropertyDamaged: {
        id: 'fnol.pa.views.pa-water-loss-details.Was any property damaged, other than the vehicle(s) involved in the water?',
        defaultMessage: 'Was any property damaged, other than the vehicle(s) involved in the water?'
    },
    paLossDetailsYes: {
        id: 'fnol.pa.views.pa-water-loss-details.Yes',
        defaultMessage: 'Yes'
    },
    paLossDetailsNo: {
        id: 'fnol.pa.views.pa-water-loss-details.No',
        defaultMessage: 'No'
    },
    paDescribePropertyDamage: {
        id: 'fnol.pa.views.pa-water-loss-details.Please briefly describe the property damage',
        defaultMessage: 'Please briefly describe the property damage'
    },
    paVehicleLocation: {
    id: 'fnol.pa.views.pa-water-loss-details.Where is the vehicle located',
        defaultMessage: 'Where is the vehicle located'
    }
});
