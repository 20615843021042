import React, { useContext, useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  ModalNextProvider,
  Loader,
  Icon,
  FormattedDate,
} from "@jutro/components";
import { useValidation } from "gw-portals-validation-react";
import { TranslatorContext } from "@jutro/locale";
import {
  WizardPage,
  wizardProps,
  WizardPageTemplate,
} from "gw-portals-wizard-react";
import { withRouter } from "react-router-dom";
import { LobIconUtil, LocalDateUtil } from "gw-portals-util-js";
import { useDependencies } from "gw-portals-dependency-react";
import { useAuthentication } from "gw-digital-auth-react";
// eslint-disable-next-line import/no-unresolved
import appConfig from "app-config";
import moment from "moment";
import { getNormalizedLOBName } from "gw-portals-config-js";
import { ViewModelForm } from "gw-portals-viewmodel-react";

import Claim from "../../models/Claim";
import styles from "./FNOLSelectPolicy.module.scss";
import metadata from "./FNOLSelectPolicy.metadata.json5";
import messages from "../../FNOL.messages";
import { ImageComponent } from "gw-components-platform-react";
import { messages as commonMessages } from 'gw-platform-translations';

function FNOLSelectPolicy(props) {
  const {
    wizardData: claimVM,
    updateWizardData,
    history,
    basePath,
    steps,
    goNext,
    history: {
      location: { state },
    },
  } = props;
  const {
    accountNumber: accountNumberFromPrevPage,
    policyNumber: policyNumberFromPrevPage,
    redirectPath,
    claimNumber,
    claimStatus,
  } = state || {};
  const { FNOLService } = useDependencies("FNOLService");
  const { ClaimService } = useDependencies("ClaimService");
  const { AccountService } = useDependencies("AccountService");
  const translator = useContext(TranslatorContext);
  const [policyData, setPolicyData] = useState({});
  const [policyNumber, setPolicyNumber] = useState(policyNumberFromPrevPage);
  const [accountNumber, setAccountNumber] = useState(accountNumberFromPrevPage);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowInfoMessage, setInfoMessage] = useState(false);
  const { authHeader } = useAuthentication();
  const { isComponentValid, registerComponentValidation } =
    useValidation("FNOLSelectPolicy");

  const getFormattedDate = useCallback((items, index, property) => {
    if (typeof items[property.id] === "undefined") {
      return "";
    }
    return (
      <FormattedDate value={new Date(items[property.id])} format="vshort" />
    );
  }, []);

  const processResponseData = useCallback((accountDetails) => {
    const policyDetails = [];
    if (appConfig.persona === "policyholder") {
      accountDetails.forEach((policyInfo) => {
        if (policyInfo.policyType !== "PersonalUmbrella") {
          const policy = {
            product: policyInfo.policyNumber,
            policyNumber: policyInfo.policyNumber,
            productType: policyInfo.policyType,
            effectiveDate: policyInfo.effectiveDate,
            expirationDate: policyInfo.expirationDate,
          };
          policyDetails.push(policy);
        }
      });
    } else {
      const lobindex = 0;
      const allowedPolicy = appConfig.allowedPolicyTypes;
      accountDetails.policySummaries.forEach((policyInfo) => {
        const { isCancelled } = policyInfo;
        const businessLines = policyInfo.policyLines;
        const { productName } = policyInfo.product;
        const businessLine = businessLines[lobindex].lineOfBusinessCode;
        if (
          productName !== "Commercial Package" &&
          _.includes(allowedPolicy, businessLine) &&
          !isCancelled &&
          !moment().isBefore(moment(policyInfo.effective))
        ) {
          const policy = {
            product: policyInfo.policyNumber,
            policyNumber: policyInfo.policyNumber,
            productType: businessLine,
            effectiveDate: policyInfo.effectiveDate,
            expirationDate: policyInfo.expirationDate,
          };
          policyDetails.push(policy);
        }
      });
    }
    return policyDetails;
  }, []);

  const getPolicyDetails = useCallback(() => {
    if (appConfig.persona === "policyholder") {
      const policySearchCriteria = {
        lossDate: _.get(claimVM.value, "lossDate"),
      };
      FNOLService.searchPolicies(policySearchCriteria, authHeader).then(
        (response) => {
          setPolicyData(processResponseData(response));
          setIsLoading(false);
        }
      );
    }
  }, [FNOLService, authHeader, claimVM.value, processResponseData]);

  const redirectDraftClaimFlow = useCallback(() => {
    setIsLoading(true);
    if (_.isFunction(ClaimService.addRecentlyViewedClaim)) {
      ClaimService.addRecentlyViewedClaim(claimNumber, authHeader);
    }
    return FNOLService.getClaim(claimNumber, authHeader).then(
      (getClaimResponse) => {
        setPolicyNumber(getClaimResponse.policy.policyNumber);
        claimVM.value = getClaimResponse;
        const normalizedLobCode = getNormalizedLOBName(
          claimVM.value.policy.policyType
        );
        return history.push({
          pathname: `/fnol-${normalizedLobCode}`,
          state: {
            claimData: getClaimResponse,
            redirectPath: redirectPath,
            claimStatus: claimStatus,
          },
        });
      }
    );
  }, [
    ClaimService,
    claimNumber,
    authHeader,
    FNOLService,
    claimVM.value,
    history,
    redirectPath,
    claimStatus,
  ]);

  useEffect(() => {
    const previousAccountNumber = _.get(claimVM.value, "policy.accountNumber");

    const policyNumberVar = _.isNil(policyNumber)
      ? _.get(claimVM.value, "policy.policyNumber")
      : policyNumber;
    setPolicyNumber(policyNumberVar);
    setAccountNumber(accountNumberFromPrevPage || previousAccountNumber);

    const flowCheck = redirectPath.split("/");
    if (
      (flowCheck[1] === "accounts" &&
        claimStatus !==
          translator({
            id: "typekey.ClaimState.draft",
            defaultMessage: "draft",
          }) &&
        (!_.isEmpty(accountNumberFromPrevPage) ||
          !_.isEmpty(previousAccountNumber))) ||
      appConfig.persona === "policyholder"
    ) {
      getPolicyDetails(accountNumberFromPrevPage || previousAccountNumber);
    } else {
      setIsLoading(false);
    }
    // This will call only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isEmpty(claimNumber)) {
      redirectDraftClaimFlow();
    }
    const stepsWithoutSelectPolicy = steps.slice(1, steps.length);
    if (
      basePath !== "/fnol-select-policy" &&
      !stepsWithoutSelectPolicy.some((step) => step.visited)
    ) {
      goNext();
    }
    // This will call only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePolicy = useCallback(() => {
    const previousLossDate = _.get(claimVM.value, "lossDate");
    claimVM.value = new Claim();
    _.set(claimVM.value, "lossDate", previousLossDate);
    updateWizardData(claimVM);
  }, [claimVM, updateWizardData]);

  const callSearchPolicyService = useCallback(() => {
    const policySearchCriteria = {
      policyNumber: policyNumber,
      lossDate: _.get(claimVM.value, "lossDate"),
    };
    return FNOLService.searchPolicies(policySearchCriteria, authHeader).then(
      (policyResponse) => {
        if (_.isEmpty(policyResponse) && basePath === "/fnol-select-policy") {
          setInfoMessage(true);
          return claimVM;
        }
        if (_.isEmpty(policyResponse)) {
          setInfoMessage(true);
          return false;
        }
        _.set(claimVM.value, "policy", policyResponse[0]);
        _.set(claimVM.value, "policy.accountNumber", accountNumber);
        const normalizedLobCode = getNormalizedLOBName(
          claimVM.value.policy.policyType
        );
        const fnolLOBPath = `/fnol-${normalizedLobCode}`;
        // if the URL contains the lob code, we have already navigated from the first step
        // and do not need to force the redirect to `/fnol-${normalizedLobCode}`
        if (history.location.pathname.includes(fnolLOBPath)) {
          return claimVM;
        }

        return history.push({
          pathname: fnolLOBPath,
          state: {
            claimData: claimVM.value,
            redirectPath: redirectPath,
          },
        });
      }
    );
  }, [
    policyNumber,
    claimVM,
    FNOLService,
    authHeader,
    basePath,
    accountNumber,
    history,
    redirectPath,
  ]);

  const onNext = useCallback(() => {
    const prevPolicyNo = _.get(claimVM.value, "policy.policyNumber");
    if (
      !_.isEmpty(prevPolicyNo) &&
      !_.isEmpty(policyNumber) &&
      prevPolicyNo !== policyNumber
    ) {
      return ModalNextProvider.showConfirm({
        title: messages.policyChangeModalTitle,
        message: messages.policyChangeModalMessage,
        status: "warning",
        icon: "mi-error-outline",
        confirmButtonText: messages.changePolicyButtonlabel,
        cancelButtonText: messages.fnolMessagePopupCancel,
      }).then((results) => {
        if (results === "cancel") {
          return _.noop();
        }
        changePolicy();
        return callSearchPolicyService();
      }, _.noop);
    }
    return callSearchPolicyService();
  }, [callSearchPolicyService, changePolicy, claimVM.value, policyNumber]);

  const onPolicySelect = useCallback(
    (selectedValue) => {
      if (_.get(claimVM.value, "policy.accountNumber")) {
        setAccountNumber(_.get(claimVM.value, "policy.accountNumber"));
      }
      if (_.get(claimVM, "claimNumber.value")){
          return handleError(
            messages.changePolicyInfo,
            messages.changePolicy)
      }else {
      _.set(claimVM.value, "selectedPolicy", selectedValue);
      updateWizardData(claimVM);
      setPolicyNumber(selectedValue);
      }
    },
    [claimVM, updateWizardData]
  );

  const handleError = useCallback((title, message) => {
    return ModalNextProvider.showAlert({
        title: title,
        message: message,
        status: 'error',
        icon: 'mi-error-outline',
        confirmButtonText: commonMessages.ok
    }).catch(_.noop);
},[]
);

  const getCell = useCallback((item, index, property) => {
    return <span>{item[property.id]}</span>;
  }, []);

  const generateDataForRadioButton = useCallback((row) => {
    return {
      code: row.policyNumber,
      value: "",
    };
  }, []);

  const productImages = {
    PersonalAuto: {
      imgSrc: "/branding/nlc-lob-pa.png",
      value: "Personal Auto",
    },
    Homeowners: {
      imgSrc: "/branding/nlc-lob-ho.png",
      value: "Homeowners",
    },
    PersonalUmbrella: {
      imgSrc: "/branding/nlc-lob-pe.png",
      value: "Personal Umbrella",
    },
    Dwellingfire: {
      imgSrc: "/branding/nlc-lob-df.png",
      value: "Dwelling Fire",
    },
  };

  const getProductImage = useCallback((item) => {
    const productDetails = productImages[item.productType];
    if (productDetails) {
      return (
        <ImageComponent
          src={productDetails.imgSrc}
          title={productDetails.value}
          key={productDetails.value}
        />
      );
    }
  }, []);

  const dateIsInFuture = useCallback((date) => {
    if (!date) {
      return false;
    }
    const currentTime = new Date();
    let selectedDate = new Date(date);
    selectedDate.setMinutes(selectedDate.getMinutes() - 1);
    return selectedDate > currentTime;
  }, []);

  const isPageValid = useCallback(() => {
    if (
      _.isEmpty(policyNumber) &&
      _.isEmpty(_.get(claimVM.value, "policy.policyNumber"))
    ) {
      return false;
    }
    if (
      !_.get(claimVM.value, "lossDate") ||
      dateIsInFuture(_.get(claimVM.value, "lossDate"))
    ) {
      return false;
    }
    return true;
  }, [claimVM.value, dateIsInFuture, policyNumber]);

  useEffect(() => {
    registerComponentValidation(isPageValid);
  }, [isPageValid, registerComponentValidation]);

  const getPolicyNumberHeading = useCallback(() => {
    const previousPolicyNumber = _.get(claimVM.value, "policy.policyNumber");
    const policyNumberToDisplay = policyNumber || previousPolicyNumber || "";
    return translator(messages.lossInformation, {
      policyNumber: policyNumberToDisplay,
    });
  }, [claimVM.value, translator, policyNumber]);

  const overrides = {
    policyTable: {
      data: policyData,
    },
    policyTableHeadingId: {
      visible: policyData.length > 0,
    },
    policyLossDateHeadingId: {
      content: getPolicyNumberHeading(),
    },
    policySelectRadio: {
      option: generateDataForRadioButton,
      value:
        policyNumber ||
        (!_.isEmpty(claimVM.value) &&
          _.get(claimVM.value, "policy.policyNumber")),
      onValueChange: onPolicySelect,
    },
    noPolicyInfoLableId: {
      visible: isShowInfoMessage,
    },
    lossDateId: {
      defaultValue: new Date(),
    },
  };
  const resolvers = {
    resolveCallbackMap: {
      getCell: getCell,
      getFormattedDate: getFormattedDate,
      getProductImage: getProductImage,
    },
    resolveClassNameMap: styles,
  };
  if (isLoading) {
    return <Loader loaded={!isLoading} />;
  }
  return (
    <WizardPage
      onNext={onNext}
      showPrevious={false}
      disableNext={!isComponentValid}
      template={WizardPageTemplate}
    >
      <ViewModelForm
        uiProps={metadata.pageContent}
        model={claimVM}
        overrideProps={overrides}
        onModelChange={updateWizardData}
        callbackMap={resolvers.resolveCallbackMap}
        classNameMap={resolvers.resolveClassNameMap}
      />
    </WizardPage>
  );
}

FNOLSelectPolicy.propTypes = wizardProps;
FNOLSelectPolicy.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(FNOLSelectPolicy);
