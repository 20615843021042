import { defineMessages } from 'react-intl';

export default defineMessages({
    sectionTitle: {
        id: 'policy.common.component.coveragePE.Policy Coverage1',
        defaultMessage: 'Coverages'
    },
    limit: {
        id: 'policy.common.component.coveragePE.Limit of Coverage',
        defaultMessage: 'Limit of Coverage:'
    },
    retention: {
        id: 'policy.common.component.coveragePE.Retention',
        defaultMessage: 'Retention:'
    },

    referinfo:{
        id:'policy.common.component.coveragePE.refer coverages',
        defaultMessage:'PLEASE REFER TO YOUR DECLARATIONS PAGES FOR ADDITIONAL INFORMATION (FORMS,ENDORSEMENTS,DISCOUNTS,COVERAGES AND POLICY PREMIUMS)' 
    },
    
});