import React, {
    useContext, useCallback, useState, useMemo
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    ModalNextProvider,
    Button,
    Loader
} from '@jutro/components';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { IntlContext } from '@jutro/locale';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { valueTypeMap, getFormattedValue, processModalData } from './ScheduleItemsUtil';
import ScheduleItemModalPopover from './ScheduleItemModalPopover';
import messages from './ScheduleItemsComponent.messages';
import styles from './ScheduleItemsComponent.module.scss';
import metadata from './ScheduleItemsComponent.metadata.json5';

function ScheduleItemsComponent(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const intl = useContext(IntlContext);
    const [isLoading, setLoading] = useState(false);
    const {
        value: scheduleItem,
        labelPosition,
        id,
        readOnly,
        onScheduleChange,
        path,
        showTitle
    } = props;

    const renderCell = useCallback((items, index, property) => {
        const { id: pathToValue, valueType } = property.data;

        if (valueType === 'ICON') {
            const { icon, onClick } = property.data;
            return (
                <Button
                    className="actionIcon"
                    icon={icon}
                    type="secondary"
                    onClick={onClick(items, index)}
                />
            );
        }

        const valueObject = _.get(items.itemData, pathToValue);
        const value = _.get(valueObject, valueTypeMap[valueType]);

        if (valueType === 'INTEGER' && _.has(property.data, 'currency')) {
            const { currency } = property.data;
            return intl.formatNumber(
                value,
                {
                    style: 'currency',
                    currency: currency,
                    currencyDisplay: 'code'
                }
            );
        }
        if (valueType === 'DATE') {
            return intl.formatDate(new Date(value), { year: 'numeric', month: 'short', day: 'numeric' });
        }
        return getFormattedValue(value, valueType, property.data);
    }, [intl]);

    const generateColumnOverrides = useCallback(
        (columnData) => {
            const overrideProps = columnData.map((info, index) => ({
                [`scheduleItemColumn${index}`]: {
                    renderCell: renderCell,
                    header: info.label
                }
            }));
            return Object.assign({}, ...overrideProps);
        },
        [renderCell]
    );

    const showModal = useCallback(
        async (scheduleData) => {
            const componentProps = {
                propertyInfo: scheduleItem.propertyInfos,
                scheduleData: processModalData(scheduleData),
                viewModelService,
                scheduleItem,
                labelPosition,
                id,
                isNew: !scheduleData
            };

            const result = await ModalNextProvider.showModal(
                <ScheduleItemModalPopover {...componentProps} />
            );
            return result;
        },
        [id, labelPosition, scheduleItem, viewModelService]
    );

    const removeSchedule = useCallback(
        (scheduleToRemove) => () => {
            ModalNextProvider.showAlert({
                title: messages.scheduleRemoveTitle,
                message: messages.scheduleRemove,
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: messages.delete,
                cancelButtonText: messages.cancel
            }).then(() => {
                const clonedSchedule = _.cloneDeep(scheduleItem);
                clonedSchedule.scheduleItems = clonedSchedule.scheduleItems.filter(
                    (schedule) => !_.isEqual(schedule, scheduleToRemove)
                );

                if (onScheduleChange) {
                    setLoading(true);
                    onScheduleChange(clonedSchedule, path).finally(() => setLoading(false));
                }
            }, _.noop);
        },
        [onScheduleChange, path, scheduleItem]
    );

    const editSchedule = useCallback(
        (schedule, index) => () => {
            const localPathToSchedule = `scheduleItems[${index}]`;

            showModal(schedule)
                .then((updatedSchedule) => {
                    const clonedSchedule = _.cloneDeep(scheduleItem);
                    _.set(clonedSchedule, localPathToSchedule, updatedSchedule);

                    if (onScheduleChange) {
                        setLoading(true);
                        onScheduleChange(clonedSchedule, path).finally(() => setLoading(false));
                    }
                })
                .catch(_.noop);
        },
        [onScheduleChange, path, scheduleItem, showModal]
    );

    const addSchedule = useCallback(() => {
        showModal()
            .then((schedule) => {
                const clonedSchedule = _.cloneDeep(scheduleItem);
                const newScheduleItem = {
                    '@deserialization-class': scheduleItem.deserializationClass,
                    ...schedule
                };

                clonedSchedule.scheduleItems = [...scheduleItem.scheduleItems, newScheduleItem];

                if (onScheduleChange) {
                    setLoading(true);
                    onScheduleChange(clonedSchedule, path).finally(() => setLoading(false));
                }
            })
            .catch(_.noop);
    }, [onScheduleChange, path, scheduleItem, showModal]);

    const columnData = useMemo(() => {
        const orderedPropertyInfo = _.sortBy(scheduleItem.propertyInfos, 'order');
        const icons = [
            {
                valueType: 'ICON',
                icon: 'mi-delete',
                onClick: removeSchedule
            },
            {
                valueType: 'ICON',
                icon: 'mi-edit',
                onClick: editSchedule
            }
        ];
        const iconsShow = !readOnly ? icons : [];
        return [...orderedPropertyInfo, ...iconsShow];
    }, [editSchedule, readOnly, removeSchedule, scheduleItem.propertyInfos]);

    const renderAdd = useCallback(
        () => (
            <Button icon="mi-add" onClick={addSchedule} type="secondary">
                {messages.scheduleAdd}
            </Button>
        ),
        [addSchedule]
    );

    const overrideProps = useMemo(() => {
        return {
            scheduleItemTable: {
                data: columnData,
                title: showTitle ? scheduleItem.displayName : undefined,
                titleAction: !readOnly ? renderAdd : undefined,
                placeholder: readOnly ? messages.noItems : ''
            },
            ...generateColumnOverrides(columnData)
        };
    }, [
        columnData,
        scheduleItem.displayName,
        showTitle,
        readOnly,
        renderAdd,
        generateColumnOverrides
    ]);

    const readValue = useCallback(
        (elementId, schedulePath) => {
            return _.get(scheduleItem, schedulePath);
        },
        [scheduleItem]
    );

    const resolvers = {
        resolveValue: readValue,
        resolveClassNameMap: styles
    };

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    return renderContentFromMetadata(metadata.componentContent, overrideProps, resolvers);
}

ScheduleItemsComponent.propTypes = {
    value: PropTypes.shape({
        scheduleItems: PropTypes.arrayOf(PropTypes.shape({})),
        propertyInfos: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    onScheduleChange: PropTypes.func,
    path: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    labelPosition: PropTypes.string,
    readOnly: PropTypes.bool,
    showTitle: PropTypes.bool
};

ScheduleItemsComponent.defaultProps = {
    onScheduleChange: undefined,
    readOnly: false,
    labelPosition: undefined,
    showTitle: true
};

export default ScheduleItemsComponent;
