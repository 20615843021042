import { defineMessages } from 'react-intl';

export default defineMessages({
    faq: {
        id: 'portal.index.FAQ',
        defaultMessage: 'FAQ'
    },
    home: {
        id: 'app.html.index.Home',
        defaultMessage: 'Home'
    },
    additionalEnrollment: {
        id: 'portal.index.Additional Enrollment',
        defaultMessage: 'Additional Enrollment'
    },
    makeAPayment: {
        id: 'portal.index.Make a Payment',
        defaultMessage: 'Make a Payment'
    },
    billingSummary: {
        id: 'portal.index.Billing',
        defaultMessage: 'Billing'
    },
    claimList: {
        id: 'portal.index.Claims',
        defaultMessage: 'Claims'
    },
    claimDetails: {
        id: 'portal.index.Claim Details',
        defaultMessage: 'Claim Details'
    },
    createNote: {
        id: 'portal.index.Create Note',
        defaultMessage: 'Create Note'
    },
    policyChange: {
        id: 'portal.index.Policy Change',
        defaultMessage: 'Policy Change'
    },
    policyDetails: {
        id: 'portal.index.Policy Details',
        defaultMessage: 'Policy Details'
    },
    accountInformation: {
        id: 'portal.index.Account Information',
        defaultMessage: 'Account Information'
    },
    homePage: {
        id: 'portal.index.Home Page',
        defaultMessage: 'Home Page'
    },
    error: {
        id: 'portal.index.Error',
        defaultMessage: 'Error'
    },
    errorMessage: {
        id: 'portal.index.Sorry, an error occurred. You can try later or contact one of our agents.',
        defaultMessage: 'Sorry, an error occurred. You can try later or contact one of our agents.'
    },
    backButton: {
        id: 'portal.index.Back',
        defaultMessage: 'Back'
    },
});
