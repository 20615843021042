import _ from 'lodash';
import { EntityUtil } from 'gw-portals-util-js';
import VehicleIncident from './VehicleIncident';
import Vehicle from './Vehicle';
import './Contact';

function CAClaimExtension(claim) {
    Object.defineProperty(this, 'claim', {
        enumerable: false,
        value: claim
    });
    if (this.claim.lobs.commercialAuto) {
        this.vehicles = this.claim.lobs.commercialAuto.vehicles || [];
        this.vehicleIncidents = this.claim.lobs.commercialAuto.vehicleIncidents || [];
        this.contacts = this.claim.contacts || [];

        this.vehicles = _.map(this.claim.lobs.commercialAuto.vehicles, (v) => {
            return new Vehicle(v);
        });

        _.each(this.claim.lobs.commercialAuto.vehicleIncidents, _.bind(function (vi) {
            const vehicle = vi;
            vehicle.driver = this.claim.findContact(this.claim, vehicle.driver);
            _.each(vehicle.passengers, _.bind((p, index) => {
                vehicle.passengers[index] = this.claim.findContact(this.claim, p);
            }, this));
            vehicle.vehicle = this.findVehicle(vehicle.vehicle);
        }, this));

        this.vehicleIncidents = _.map(
            this.claim.lobs.commercialAuto.vehicleIncidents, _.bind(function (vi) {
                return new VehicleIncident(this.claim, this, vi);
            }, this)
        );

        this.fixedPropertyIncident = this.claim.lobs.commercialAuto
        && this.claim.lobs.commercialAuto.fixedPropertyIncident
            ? this.claim.lobs.commercialAuto.fixedPropertyIncident
            : { propertyDescription: undefined };
    } else {
        this.fixedPropertyIncident = { propertyDescription: undefined };
        this.vehicleIncidents = [];
        this.vehicles = [];
    }
}

CAClaimExtension.prototype.generateVehicleIncidents = function (vehicles) {
    this.vehicleIncidents = vehicles.map((vehicle) => {
        const vi = new VehicleIncident(this.claim, this);
        vi.tempID = EntityUtil.nextId();
        vi.vehicle = vehicle;
        return vi;
    });
};

CAClaimExtension.prototype.createVehicleIncident = function () {
    const vi = new VehicleIncident(this.claim, this);
    vi.tempID = EntityUtil.nextId();
    this.vehicleIncidents.push(vi);
    return vi;
};

CAClaimExtension.prototype.createVehicle = ({ country }) => {
    return new Vehicle({
        tempID: EntityUtil.nextId(),
        country
    });
};

CAClaimExtension.prototype.removeVehicleIncident = function (incident) {
    const idx = this.vehicleIncidents.indexOf(incident);
    if (idx >= 0) {
        this.vehicleIncidents.splice(idx, 1);
    }
};

CAClaimExtension.prototype.availableVehicles = function (additional) {
    return _.filter(this.vehicles, function (v) { // eslint-disable-line prefer-arrow-callback
        if (additional && _.isEqual(v, additional)) {
            return true;
        }
        const used = _.find(this.vehicleIncidents, (vi) => {
            return _.isEqual(v, vi.vehicle);
        });
        return used === undefined;
    }.bind(this));
};

CAClaimExtension.prototype.availableDrivers = function (additional) {
    return this.availablePassengers(additional);
};

CAClaimExtension.prototype.availablePassengers = function (additional) {
    return _.filter(this.contacts, function (c) { // eslint-disable-line prefer-arrow-callback
        if (additional && _.isEqual(c, additional)) {
            return true;
        }
        const used = _.find(this.vehicleIncidents, (vi) => {
            return _.isEqual(c, vi.driver)
                || _.some(vi.passengers, (p) => {
                    return _.isEqual(c, p);
                });
        });
        return used === undefined;
    }.bind(this));
};

CAClaimExtension.prototype.findVehicle = function (vehicle) {
    return EntityUtil.findMatchingEntity(this.vehicles, vehicle, {
        Ctor: Vehicle
    });
};

CAClaimExtension.prototype.findVehicleIncident = function (vi) {
    return EntityUtil.findMatchingEntity(this.vehicleIncidents, vi, {
        Ctor: VehicleIncident
    });
};

export default CAClaimExtension;
