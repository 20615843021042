import { defineMessages } from 'react-intl';

export default defineMessages({
    HomeDetails: {
        id: 'policy.common.component.policy-dwelling-info-RC.Home Details',
        defaultMessage: 'Dwelling Details'
    },
    locationAddress: {
        id: 'policy.common.component.policy-dwelling-info-RC.Location address',
        defaultMessage: 'Location Address'
    },
    yearBuild: {
        id: 'policy.common.component.policy-dwelling-info-RC.Year Built',
        defaultMessage: 'Year Built'
    }
});
