import React, { useCallback, useContext,useState,useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './HOSectionIICoverages.metadata.json5';
import styles from './HOSectionIICoverages.module.scss';
import './HOSectionIICoverages';
//import { get } from 'core-js/core/dict';



function HOSectionIICoverages(props) {
    const { data : sectionIICoverages_Ext } = props;

    // const getDataTableCell = useCallback((item, index, { path: property }) => {
    //     return item[property];
    // });

     const getLimit= useCallback((sectionIICoverages_Ext) =>{
        var i 
        for( let p in sectionIICoverages_Ext){
           
            if(sectionIICoverages_Ext[p].personalLiability == 'E – Personal Liability (Each Occurrence)'){
                i = 'E – Personal Liability (Each Occurrence)'
               
            }
              
        }
        return ` ${i} ` 
     });

     const getMedicalPayments = useCallback((sectionIICoverages_Ext) =>{
        var i 
        for( let p in sectionIICoverages_Ext){
           
            if(sectionIICoverages_Ext[p].medicalPaymentsToOthers == 'F – Medical Payments To Others (Each Person)'){
                i = 'F – Medical Payments To Others (Each Person)'
                
            }
              
        }
        return ` ${i} ` 

    });

    const getCovELimit = useCallback((sectionIICoverages_Ext) =>{
        var i 
        for( let p in sectionIICoverages_Ext){
            if(sectionIICoverages_Ext[p].personalLiability == 'E – Personal Liability (Each Occurrence)'){
                i = sectionIICoverages_Ext[p].terms[0].amount
                
            }
        }
        return `Cov E Limit \u00A0\u00A0\u00A0 $${i} ` 
       
        
        

    });

    const getCovFLimit = useCallback((sectionIICoverages_Ext) =>{
        var i 
        for( let p in sectionIICoverages_Ext){
            if(sectionIICoverages_Ext[p].medicalPaymentsToOthers == 'F – Medical Payments To Others (Each Person)'){
                i = sectionIICoverages_Ext[p].terms[0].amount
                
            }
        }
        return `Cov F Limit \u00A0\u00A0\u00A0 $${i} `
       

    });

    

   
    const overrides = {
        '@element': {
            textAlign: 'left'
        },

        infoTable:{
            data: _.get(sectionIICoverages_Ext)
        },

         personalLiability:{
             value : getLimit(sectionIICoverages_Ext)
         },

        medicalPaymentsToOthers:{
            value : getMedicalPayments(sectionIICoverages_Ext)
        },

        covELimit:{
            value: getCovELimit(sectionIICoverages_Ext)
        },
        covFLimit:{
            value: getCovFLimit(sectionIICoverages_Ext)
        },
       
    
        // limit:{
        //     value: getLimit(policyCoverages_Ext)
        //    },

        // rentation:{
        //     value: getRentation(policyCoverages_Ext)  
        //    }



    };

    const resolvers = {
        resolveCallbackMap:{
          //  getDataTableCell :  getDataTableCell 
            // liabilityLimit:getLimit,
            // rentation:getRentation

        },
        resolveClassNameMap: styles,

    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.componentContent,
                sectionIICoverages_Ext,
                id,
                path,
                overrides
            );
        },
        [sectionIICoverages_Ext, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={sectionIICoverages_Ext}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

HOSectionIICoverages.propTypes = {
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
};

HOSectionIICoverages.defaultProps = {
    value: []
};

export default HOSectionIICoverages;
