import React, { useCallback, useState, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { useAuthentication } from 'gw-digital-auth-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './EnrollmentComponent.metadata.json5';
import styles from './EnrollmentComponent.module.scss';

import messages from './EnrollmentComponent.messages';

const ENROLLMENT_ERRORS = {
    enrollmentInvalid: 'enrollmentInvalid',
    enrollmentFailed: 'enrollmentFailed',
    fieldEmpty: 'fieldEmpty'
};

function EnrollmentComponent(props) {
    const translator = useContext(TranslatorContext);
    const [formData, updateFormData] = useState({});
    const [enrollmentStatus, setEnrollmentStatus] = useState();
    const [availablePolicies, setAvailablePolicies] = useState([]);
    const { authHeader, refreshToken } = useAuthentication();
    const { EnrollmentService } = useDependencies('EnrollmentService');

    const {
        enrollmentType,
        onSuccessEnrollment,
        informationText,
        informationAdditionalText,
        onReturnLinkClick,
        returnLinkText,
        enrollButtonText
    } = props;

    const enrollPolicy = useCallback(
        (evt) => {
            if (evt) {
                evt.preventDefault();
            }

            const { policyNumber, addressLine1 } = formData;

            if (_.isEmpty(policyNumber) || _.isEmpty(addressLine1)) {
                setEnrollmentStatus(ENROLLMENT_ERRORS.fieldEmpty);
                return Promise.resolve();
            }

            return EnrollmentService.addEnrollmentRecord(
                {
                    details: {
                        policyNumber: policyNumber,
                        addressLine1: addressLine1
                    },
                    type: enrollmentType
                },
                authHeader
            )
                .then((resp) => {
                    refreshToken().then(() => {
                        if (onSuccessEnrollment) {
                            onSuccessEnrollment();
                        } else {
                            updateFormData({});
                            setAvailablePolicies(resp);
                            setEnrollmentStatus('enrollmentRequestSucceeded');
                        }
                    });
                })
                .catch((error) => {
                    setAvailablePolicies([]);
                    if (error && error.baseError && error.baseError.error.message.includes('616')) {
                        setEnrollmentStatus(ENROLLMENT_ERRORS.enrollmentInvalid);
                    } else {
                        setEnrollmentStatus(ENROLLMENT_ERRORS.enrollmentFailed);
                    }
                });
        },
        [EnrollmentService, authHeader, enrollmentType, formData, onSuccessEnrollment, refreshToken]
    );

    const writeValue = useCallback(
        (value, path) => updateFormData({
            ...formData,
            [path]: value
        }),
        [formData]
    );

    const getSuccessNotificationMessage = useCallback(() => {
        return (
            <div>
                <span>
                    {availablePolicies.length > 1
                        ? translator(messages.accessToYourPolicies)
                        : translator(messages.accessToYourPolicy)}
                </span>
                <span>{availablePolicies.join(', ')}</span>
            </div>
        );
    }, [availablePolicies, translator]);

    const overrideProps = {
        verificationInformationText: {
            content: translator(informationText)
        },
        verificationInformationAdditionalText: {
            content: translator(informationAdditionalText)
        },
        enrollmentErrorMessageContainer: {
            visible:
                !_.isUndefined(enrollmentStatus)
                && enrollmentStatus !== 'enrollmentRequestSucceeded'
        },
        enrollmentErrorMessage: {
            message: translator(messages[enrollmentStatus])
        },
        enrollmentSuccessNotificationContainer: {
            visible: enrollmentStatus === 'enrollmentRequestSucceeded'
        },
        enrollmentSuccessNotification: {
            message: availablePolicies.length !== 0 ? getSuccessNotificationMessage() : ''
        },
        returnLink: {
            to: _.isUndefined(onReturnLinkClick) ? '/home' : '#',
            content: translator(returnLinkText),
            onClick: onReturnLinkClick
        },
        enrollButton: {
            content: translator(enrollButtonText),
            trigger: enrollPolicy
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={formData}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

EnrollmentComponent.propTypes = {
    enrollmentType: PropTypes.string.isRequired,
    onSuccessEnrollment: PropTypes.func,
    onReturnLinkClick: PropTypes.func,
    returnLinkText: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }),
    enrollButtonText: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }),
    informationText: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }).isRequired,
    informationAdditionalText: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }).isRequired
};

EnrollmentComponent.defaultProps = {
    onSuccessEnrollment: undefined,
    onReturnLinkClick: undefined,
    returnLinkText: messages.returnToHomePage,
    enrollButtonText: messages.enroll
};

export default EnrollmentComponent;
