import { defineMessages } from 'react-intl';

export default defineMessages({
   selectMedia:{
       id:"login.otp.Select how you want to receive your verification codeMfa:",
       defaultMessage:"Select how you want to receive your verification code:"
   },
   sendCode:{
        id:"login.otp.Send CodeMfa",
        defaultMessage:"Send Code"
    },
    codeSentMessage:{
        id:"login.otp.info.message.A verification code has been sent, please enter the code belowMfa.",
        defaultMessage:"A verification code has been sent, please enter the code below."
    },
    verificationCode:{
        id:"login.otp.Verification CodeMfa:",
        defaultMessage:"Verification Code:"
    },
    rememberMeOnThisDevice:{
        id:"login.remember.Remember me on this DeviceMfa",
        defaultMessage:"Remember me on this Device"
    },
    invalidOtpMsg:{
        id:"login.otp.Incorrect code - please re-enter the code or click on the send code button againMfa.",
        defaultMessage:"Incorrect code - please re-enter the code or click on the send code button again."
    },
    userLocked: {
        id:"login.otp.You have exceeded the maximum number of Send Code requests. You may attempt to have the verification code resent again after 1 hourMfa.",
        defaultMessage:"You have exceeded the maximum number of Send Code requests. You may attempt to have the verification code resent again after 1 hour."
    },
    signIn:{
        id:"login.otp.Sign InMfa",
        defaultMessage:"MFA Re-Verification to Update Portal User Details"
    },
    backButton:{
        id:"login.otp.BackMfa",
        defaultMessage:"Back"
    },
    whiteListedEmailMsg: {
        id: 'login.otp.whitelistedemailmsgMfa',
        defaultMessage: 'No matching whitelisted Email entry found.'
    },
    whiteListedPhoneMsg: {
        id: 'login.otp.whitelistedphonemsgMfa',
        defaultMessage: 'No matching whitelisted Phone Number entry found.'
    }
});
