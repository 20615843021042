import React, {
    useEffect, useState, useCallback, useContext, useMemo
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { Loader, ModalNextProvider, ModalNext } from '@jutro/components';
import { ServiceManager } from '@jutro/services';
import {
    ViewModelServiceContext,
    withViewModelService,
    ViewModelForm
} from 'gw-portals-viewmodel-react';
import { isCapabilityEnabled } from 'gw-portals-config-js';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { UsersProfileDetailsService } from 'gw-capability-profileinfo';
import { messages as commonMessages } from 'gw-platform-translations';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import UserDetailsComponent from '../UserDetailsComponent';
import UserUaaDetailsComponent from '../UserUaaDetailsComponent';
import MfaOtp from '../mfaOtpVerify/mfaOtpVerifyComponent';
import metadata from './PortalUsersProfile.metadata.json5';
import messages from './PortalUsersProfile.messages';
import styles from './PortalUsersProfile.module.scss';
import { OtpService } from 'nlc-capability-otp';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
const NOT_EDIT_MODE_INDEX = -1;

const copyMfaUaaDetails = (mfauaaDetails) => {
    return Object.assign({}, {
        uaaUser: {
            id: mfauaaDetails.uaaUser.id,
            userName: mfauaaDetails.uaaUser.userName,
            name: mfauaaDetails.uaaUser.name,
            groups: mfauaaDetails.uaaUser.groups,
            emails: mfauaaDetails.uaaUser.emails,
            phoneNumbers: mfauaaDetails.uaaUser.phoneNumbers,
            active: mfauaaDetails.uaaUser.active,
            verified: mfauaaDetails.uaaUser.verified,
            origin: mfauaaDetails.uaaUser.origin,
            zoneId: mfauaaDetails.uaaUser.zoneId
        },
        mfaUser: {
            userName: mfauaaDetails.mfaUser.userName,
            email: mfauaaDetails.uaaUser.emails[0].value,
            phoneNumber: mfauaaDetails.uaaUser.phoneNumbers[0].value,
            isEmailVerified: mfauaaDetails.mfaUser.isEmailVerified,
            isPhoneVerified: mfauaaDetails.mfaUser.isPhoneVerified,
            isUserLocked: mfauaaDetails.mfaUser.isUserLocked,
            isTermsAndConditionAccepted: mfauaaDetails.mfaUser.isTermsAndConditionAccepted

        }
    })
}

const mfaDetails = (updatedMFA, backupUpdaetdMFA) => {
    const mfaDetailsSame = _.isEqual(updatedMFA, backupUpdaetdMFA);
    return !mfaDetailsSame;
};

function UsersProfile(props) {
    const myData = 'Sample Data';
    const { history } = props;
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const localeService = ServiceManager.getService('locale-service');
    const { authHeader,refreshToken } = useAuthentication();
    const { onValidate, isComponentValid, disregardFieldValidation ,registerComponentValidation} = useValidation(
        'PortalUsersProfile'
    );
    const [displayLoader, updateDisplayLoader] = useState(true);
    const [displayError, updateDisplayError] = useState(false);
    const [mfaEditModeState, mfaUpdateEditModeState] = useState({
        mfaAccountInEditIndex: NOT_EDIT_MODE_INDEX
    });
    const [accountsDetails, updateAccountsDetails] = useState({});
    const [infoMesssage, updateInfoMessage] = useState(undefined);
    const [showInfoMesssage, updateShowInfoMessage] = useState(false);
    const [authDetails, updateAuthDetails] = useState({});
    const user = useAuthentication();
    let cancelAction = false;

    useEffect(() => {
        const { capabilitiesConfig } = appConfig;
        const mfaUser = UsersProfileDetailsService.getUser(user.user_name, authHeader);
        const accountInfoPromise = UsersProfileDetailsService.getAccountsContactSummaries(
            authHeader
        );
       
        Promise.all([accountInfoPromise, mfaUser])
        .then(([accountResult, mfaUserResult]) => {
                const accountSummaries = _.sortBy(accountResult, 'accountNumber')
                 const accounts = accountSummaries.splice(0,1).map((accountSummary, index) => {
                    return {
                        auth: mfaUserResult,
                        applyToAllAccounts: false
                    };
                });
                updateAccountsDetails({ accounts });  
                    
                if (history.location.from === 'MFAOTPVERIFY' && cancelAction !== true) {
                    editModeAfterMFAOtpSuccess({ accounts })
                }
            })
            .finally(() => updateDisplayLoader(false));
    }, []);

    const updateAccounts = useCallback((model) => {
        const { accounts: updatedDetails } = model;
        updateAccountsDetails({ accounts: [...updatedDetails] });
    }, []);

    const enterMfaEditMode = useCallback(
        (evt) => {
            const verifyMFAData = new FormData();
            verifyMFAData.append('userName', accountsDetails.accounts[0].auth.mfaUser.userName);
            verifyMFAData.append('uuid', localStorage.getItem("nlc-ceamp-dontaskthis-uuid"));
            const data = {
                method: 'POST',
                body: verifyMFAData
            }
            const index = parseInt(evt.index, 10);
            const { accounts } = accountsDetails;
            mfaEditModeState.mfaAccountInEditIndex === NOT_EDIT_MODE_INDEX;
            let currentUserName = accounts[index].auth.mfaUser.userName;
            let currentPhoneNumber = accounts[index].auth.uaaUser.phoneNumbers[0].value.replaceAll("-", "");
            let currentEmail = accounts[index].auth.uaaUser.emails[0].value;
            let currentAuthHeader = authHeader;
            OtpService.verifyMFAData(data).then((resp) => resp.json()).then((resp) => {
                if (resp.statusCode === 400 && resp.statusCode) {
                    mfaUpdateEditModeState({
                        mfaAccountInEditIndex: index,
                        backupauthDetails: _.cloneDeep(accounts[index].auth)
                    });
                } else {
                    const nextState = { pathname: '/verifymfaotp', from: 'MFAOTPVERIFY', myData: { currentUserName, currentPhoneNumber, currentEmail, currentAuthHeader } };
                    history.push(nextState);
                    currentUserName="";
                    currentPhoneNumber="";
                    currentEmail="";
                }
            });
        },
         [accountsDetails]
    );

    /* MFA Re-Verification Code - NLCSS-70 */
    const editModeAfterMFAOtpSuccess = (myObj) => {
        const index = 0;
        const { accounts } = myObj;
        mfaUpdateEditModeState({
            mfaAccountInEditIndex: index,
            accountInEditIndex: index,
            backupauthDetails: _.cloneDeep(accounts[index].auth)
        });
    };
    /* MFA Re-Verification Code - NLCSS-70 */

    const resetEditState = useCallback(() => {
        mfaUpdateEditModeState({
            mfaAccountInEditIndex: NOT_EDIT_MODE_INDEX
        });
        updateDisplayError(false);
    }, []);

    const handleMfaCancel = useCallback(() => {
        updateShowInfoMessage(true);    
        cancelAction = true;
        ModalNextProvider.showConfirm({
            title: messages.cancelEditTitle,
            message: messages.cancelEditMessage,
            status: 'info',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.yesModel,
            cancelButtonText: commonMessages.noModel
        }).then((results) => {
            if (results === 'confirm') {
                const {
                    mfaAccountInEditIndex,
                    backupauthDetails
                } = mfaEditModeState;
                const { accounts } = accountsDetails;
                accounts[mfaAccountInEditIndex].auth = backupauthDetails;
                updateDisplayLoader(true)
                updateAccounts({ accounts });
                resetEditState();
                updateDisplayLoader(false)
                updateInfoMessage(translator(messages.cancelMessage));
                updateShowInfoMessage(false);    
            }
        }, _.noop);
    }, [accountsDetails, authDetails, resetEditState, mfaEditModeState, updateAccounts]);

    const updateMfaContactDetails = useCallback(() => {
       
        const { mfaAccountInEditIndex, backupauthDetails } = mfaEditModeState;
        const { accounts } = accountsDetails;
        const updatedAccountDetails = accounts[mfaAccountInEditIndex];
        const updatedAuthDetails = accounts[mfaAccountInEditIndex].auth
        updateShowInfoMessage(false); 
        updateDisplayLoader(true);
        const updateCalls = [];
        const finalCall = (updateCalls) => {
            Promise.all(updateCalls)
            .then(
                () => {
                    resetEditState();
                    updateInfoMessage(translator(messages.save2faMessage));
                    updateShowInfoMessage(true);
                },
                () => {
                    updateDisplayError(true);
                }
            )
            .finally(() =>{
                updateDisplayLoader(false);
                refreshToken();
            } );   
        } 
        if (mfaDetails(updatedAuthDetails, backupauthDetails)) {
            let re = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
            if (re.test(copyMfaUaaDetails(updatedAuthDetails).mfaUser.phoneNumber)) {
                const mfa = UsersProfileDetailsService.updateAccountUaaMfaDetails(copyMfaUaaDetails(updatedAuthDetails), authHeader);
                updateCalls.push(mfa);
                finalCall(updateCalls);
            }else{
                updateDisplayLoader(false);
                updateDisplayError(true);
            }
        }
    }, [accountsDetails,authHeader, mfaEditModeState, resetEditState, translator, updateAccounts, refreshToken]);

    const isReadOnly = useCallback((index) => mfaEditModeState.mfaAccountInEditIndex !== index, [
        mfaEditModeState.mfaAccountInEditIndex
    ]);
    
    const generatedProps = useMemo(() => {
        const { accounts = [] } = accountsDetails;
        const { capabilitiesConfig } = appConfig;

        const overrides = accounts.map(({ accountSummaryVM }, index) => {
           
            return {
                [`accountItemContainer${index}`]: {
                    className:
                        isReadOnly(index)
                            && mfaEditModeState.mfaAccountInEditIndex !== NOT_EDIT_MODE_INDEX
                            ? styles.accountSectionReadonly
                            : styles.accountSummaryContainer
                },
                [`titleAccount${index}`]: {
                    content: ''
                },
                [`mfaEditButton${index}`]: {
                    visible: mfaEditModeState.mfaAccountInEditIndex === NOT_EDIT_MODE_INDEX
                },
                [`errorMsg${index}`]: {
                    visible: displayError && !isReadOnly(index)
                },
                [`accountContactContainer${index}`]: {
                    readOnly: isReadOnly(index)
                },
                [`portalUserContainer${index}`]: {
                    readOnly: isReadOnly(index)
                },
                [`actionsContainer${index}`]: {
                    visible: !isReadOnly(index)
                },
                [`saveButton${index}`]: {
                    disabled: !isComponentValid
                },
                infoMsg: {
                    content: infoMesssage,
                    visible: showInfoMesssage
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [
        accountsDetails,
        mfaEditModeState.mfaAccountInEditIndex,
        isReadOnly,
        isComponentValid,
        displayError
    ]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false
        },
        globalizationChooserField: {
            languageValue: localeService.getStoredLanguage()
        },
        ...generatedProps
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            enterMfaEditMode,
            editModeAfterMFAOtpSuccess,
            handleMfaCancel,
            updateMfaContactDetails,
            onValidate,
            registerComponentValidation,
            updateLanguage: localeService.saveLanguage
        },
        resolveComponentMap: {
            useruaadetailscomponent : UserUaaDetailsComponent,
            mfaOtp: MfaOtp
        }
    };

    if (displayLoader) {
        return <Loader />;
    }

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={accountsDetails}
            overrideProps={overrideProps}
            onModelChange={updateAccounts}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

UsersProfile.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
};

export default withViewModelService(withRouter(UsersProfile));
