import { events } from 'gw-platform-events-js';

const LOGIN_STATE_CHANGE_EVT = 'loginState::change';
const BACKEND_ACTIVITY_EVENT = 'backend::requestsent';

class InactivityTimerService {
    constructor({ onUserInactive, inactivityIntervalMins = 5 }) {
        this.onUserInactive = onUserInactive;
        this.inactivityIntervalMins = inactivityIntervalMins;
        events.on(BACKEND_ACTIVITY_EVENT, this.resetInactivityTimer);
        events.on(LOGIN_STATE_CHANGE_EVT, this.handleLoginChanges);
    }

    handleLoginChanges = (authData) => {
        const authenticatedFlag = authData.isLoggedIn;
        if (authenticatedFlag) {
            // kick off a timer when user logs in
            this.resetInactivityTimer();
        } else {
            // if user has logged out then deactivate the timers
            this.deactivateTimers();
        }
    }

    initializeInActivityTimer = () => {
        const inactivityInterval = this.inactivityIntervalMins * 1000 * 60;
        this.inactivityTimerId = window.setTimeout(this.onUserInactive, inactivityInterval);
    }

    deactivateTimers = () => {
        if (this.inactivityTimerId) {
            window.clearTimeout(this.inactivityTimerId);
            this.inactivityTimerId = null;
        }
        if (this.logoutConfirmationTimerId) {
            window.clearTimeout(this.logoutConfirmationTimerId);
            this.logoutConfirmationTimerId = null;
        }
    }

    detach = () => {
        events.removeListener(this.resetInactivityTimer);
        events.removeListener(this.handleLoginChanges);
    }

    resetInactivityTimer = () => {
        this.deactivateTimers();
        this.initializeInActivityTimer();
    }
}

export default InactivityTimerService;
