import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { TranslatorContext } from '@jutro/locale';
import { useAuthentication } from 'gw-digital-auth-react';
import { ApplicationHeader, SubApplicationHeader, DropdownMenuLink } from '@jutro/router';
import metadata from './PageHeaderComponent.metadata.json5';
import pageHeaderStyles from './PageHeaderComponent.module.scss';
import messages from './PageHeaderComponent.messages';

function PageHeaderComponent(props) {
    const auth = useAuthentication();
    const { routes } = props;
    const translator = useContext(TranslatorContext);
    const { env } = appConfig;
    const getAvatarLinks = useCallback(() => {
        const links = [
            <DropdownMenuLink to="/userprofile"><b>{translator(messages.account)}</b></DropdownMenuLink>,
            <DropdownMenuLink to="/faq">{translator(messages.faq)}</DropdownMenuLink>
        ];
        // when using jutroAuthContext, jutro application header add the logout link by default
        // avoiding custom logout for gwcp and follow jutro
        if (!auth.useJutroAuthContext) {
            links.push([
                <DropdownMenuLink onClick={auth.logout}>
                    {translator(messages.logout)}
                </DropdownMenuLink>
            ]);
        }
        return links;
    }, [translator, auth]);

    const getLogoSrc = useCallback(() => {
        const url = _.get(env, 'DEPLOYMENT_URL');
        return `${url}/branding/NLC_white.svg`;
    }, [env]);

    const overrides = {
        applicationheader: {
            avatarProps: {
                username: auth.user_name,
                title: auth.name,
                subtitle: auth.user_name
            },
            avatarChildren: getAvatarLinks(),
            logoSrc: getLogoSrc(),
        },
        applicationsubheader: {
            navigationRoutes: routes
        }
    };

    const resolvers = {
        resolveClassNameMap: pageHeaderStyles,
        resolveComponentMap: {
            applicationheader: ApplicationHeader,
            subapplicationheader: SubApplicationHeader
        }
    };

    return renderContentFromMetadata(metadata.componentContent, overrides, resolvers);
}

PageHeaderComponent.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default PageHeaderComponent;
