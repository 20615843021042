import React, { useState, useCallback } from 'react';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import PropTypes from 'prop-types';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { EntryPageTemplate } from 'gw-components-platform-react';
import { Loader } from '@jutro/components';
import { useAuthentication } from 'gw-digital-auth-react';

import metadata from './EntryPage.metadata.json5';
import styles from './EntryPage.module.scss';
import './EntryPage.messages';

const VIEWS = {
    logInView: 'LOG_IN_VIEW',
    signUpView: 'SIGN_UP_VIEW',
    forgotPasswordView: 'FORGOT_PASSWORD_VIEW'
};

const { applicationDetails } = appConfig;

function EntryPage({ location }) {
    const { isAuthInProgress } = useAuthentication();
    const [view, updateView] = useState(VIEWS.logInView);
    const [forgotType, setForgotType] = useState();
    const { state } = location;

    const handleForgotPasswordClick = useCallback(() => {
        setForgotType('password');
        updateView(VIEWS.forgotPasswordView);
    }, []);

    const handleForgotUserNameClick = useCallback((type) => {
        setForgotType('userName');
        updateView(VIEWS.forgotPasswordView);
    }, []);

    const handleSignUpClick = useCallback(() => {
        updateView(VIEWS.signUpView);
    }, []);

    const handleSignInClick = useCallback(() => {
        updateView(VIEWS.logInView);
    }, []);

    if (isAuthInProgress) {
        return <Loader loaded={!isAuthInProgress} />;
    }

    const overrideProps = {
        gwLoginComponent: {
            successPath: _.get(state, 'from.pathname') || '/home'
        },
        logInViewContainer: {
            visible: view === VIEWS.logInView
        },
        signUpViewContainer: {
            visible: view === VIEWS.signUpView
        },
        forgotPasswordViewContainer: {
            visible: view === VIEWS.forgotPasswordView
            
        },
        gwForgotPasswordComponent: {
            forgotType: forgotType
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onForgotPasswordClick: handleForgotPasswordClick,
            onForgotUserNameClick: handleForgotUserNameClick,
            onSignUpClick: handleSignUpClick,
            onSignInClick: handleSignInClick
        }
    };

    const renderedContentFromMetadata = renderContentFromMetadata(
        metadata.pageContent,
        overrideProps,
        resolvers
    );

    return (
        <EntryPageTemplate
            applicationName={applicationDetails.applicationName}
            applicationLogo={applicationDetails.applicationLogo}
            contentToRender={renderedContentFromMetadata}
        />
    );
}

EntryPage.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({})
    })
};

EntryPage.defaultProps = {
    location: {}
};

export default EntryPage;
