import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { InputField, Icon } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import RepairFacilitiesSearchResults from './RepairFacilitiesSearchResults';
import messages from './RepairFacilitiesSearch.messages';
import styles from './RepairFacilitiesSearch.module.scss';

class RepairFacilitiesSearch extends Component {
    static propTypes = {
        currentMap: PropTypes.shape({
            getBounds: PropTypes.func,
            panTo: PropTypes.func
        }).isRequired,
        myLocation: PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number
        }),
        mapCenter: PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number
        }).isRequired
    };

    static contextType = TranslatorContext;

    constructor(props) {
        super(props);
        if (!window.google || !window.google.maps) {
            throw new Error('google map api not found');
        }
        this.mapsApi = window.google.maps;
        this.autocomplete = new window.google.maps.places.AutocompleteService();
        this.geocoder = new window.google.maps.Geocoder();
    }

    state = {
        showResults: false,
        places: [],
        inputValue: ''
    };

    writeValue = (value) => {
        const { currentMap } = this.props;
        setTimeout(() => {
            this.autocomplete.getPlacePredictions(
                {
                    bounds: currentMap.getBounds(),
                    input: value
                },
                (predictions) => {
                    this.setState({
                        showResults: true,
                        places: predictions || []
                    });
                }
            );
        }, 500);

        this.setState({ inputValue: value });

        if (!value) {
            this.setState({ places: [] });
        }
    };

    showList = () => {
        this.setState({
            showResults: true
        });
    };

    hideList = (event) => {
        const isOptionSelected = /place/g.test(_.get(event, 'relatedTarget.className', ''));
        this.setState({
            showResults: isOptionSelected
        });
    };

    geoCodePlace = (placeId) => {
        return new Promise((resolve, reject) => {
            this.geocoder.geocode(
                { placeId: placeId },
                (results, status) => {
                    const location = _.get(results, '0.geometry.location');
                    if (status === this.mapsApi.GeocoderStatus.OK && results.length > 0) {
                        resolve({
                            latitude: location.lat(),
                            longitude: location.lng()
                        });
                    } else {
                        reject(Error(`Google Geocode API error : ${status}`));
                    }
                }
            );
        });
    };

    selectAddress = (e) => {
        const selectedPlace = e.target.textContent;
        const { currentMap } = this.props;
        const { places } = this.state;
        const getPlaceId = places.find((place) => place.description === selectedPlace).place_id;
        this.geoCodePlace(getPlaceId).then((coordinates) => {
            currentMap.panTo(new this.mapsApi.LatLng(coordinates.latitude, coordinates.longitude));
        });
        this.setState({
            showResults: false,
            inputValue: selectedPlace,
            places: []
        });
    };

    policyIncidentAddress = (e) => {
        e.preventDefault();
        const {
            currentMap,
            mapCenter: { latitude, longitude }
        } = this.props;
        if (latitude && longitude) {
            currentMap.panTo(new this.mapsApi.LatLng(latitude, longitude));
        }
        this.setState({
            showResults: false,
            inputValue: '',
            places: []
        });
    };

    myLocationClickHandler = (e) => {
        e.preventDefault();
        const {
            currentMap,
            myLocation: { latitude, longitude }
        } = this.props;
        if (latitude && longitude) {
            currentMap.panTo(new this.mapsApi.LatLng(latitude, longitude));
        }
        this.setState({
            showResults: false,
            inputValue: '',
            places: []
        });
    };

    render() {
        const { showResults, places, inputValue } = this.state;
        const { myLocation } = this.props;
        const translator = this.context;
        return (
            <div className={styles.mapSearch}>
                <div className={styles.content}>
                    <div className={styles.toggle}>
                        <InputField
                            id="searchBox"
                            onValueChange={this.writeValue}
                            onFocus={this.showList}
                            className={styles.searchBoxContainer}
                            onBlur={this.hideList}
                            controlClassName={styles.searchBox}
                            placeholder={translator(messages.searchByAddressOrZIPCode)}
                            value={inputValue}
                            autoComplete={false}
                        />
                        <Icon className={styles.icon} icon="mi-search" />
                    </div>
                    {showResults && (
                        <RepairFacilitiesSearchResults
                            selectAddress={this.selectAddress}
                            policyIncidentAddress={this.policyIncidentAddress}
                            myLocation={myLocation}
                            myLocationHandler={this.myLocationClickHandler}
                            places={places}
                        />
                    )}
                </div>
            </div>
        );
    }
}

RepairFacilitiesSearch.defaultProps = {
    myLocation: {
        latitude: null,
        longitude: null
    }
};
export default RepairFacilitiesSearch;
