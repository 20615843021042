import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import PolicyVehiclesDriversInfo from '../PA/PolicyVehiclesDriversInfo/PolicyVehiclesDriversInfo';
import PolicyDwellingInfoHO3 from '../HO/PolicyDwellingInfoHO3/PolicyDwellingInfoHO3';
import PolicyDwellingInfoHO4 from '../HO/PolicyDwellingInfoHO4/PolicyDwellingInfoHO4';
import PolicyDwellingInfoHO6 from '../HO/PolicyDwellingInfoHO6/PolicyDwellingInfoHO6';
import PolicyDwellingInfoNR from '../HO/PolicyDwellingInfoNR/PolicyDwellingInfoNR';
import PolicyDwellingInfoRC from '../HO/PolicyDwellingInfoRC/PolicyDwellingInfoRC';
import PolicyCommonCoverables from '../PolicyCommonCoverables/PolicyCommonCoverables';
import metadata from './PolicyCoverages.metadata.json5';

function PolicyCoverages(props) {
    const { value: policy , homePolicyType ,dwellingUsage, jurisdiction , coverables} = props;

    if (_.isEmpty(policy)) {
        return null;
    }

    const overrides = {
        policyVehiclesDriversInfoComponent: {
            visible: !_.isEmpty(policy.lobs.personalAuto),
            coverables : coverables,
            jurisdiction : jurisdiction
        },
        policyHomeOwnersInfoHO3Component: {
            visible: !_.isEmpty(policy.lobs.homeOwners) && homePolicyType === 'Homeowners' 
        },
         policyHomeOwnersInfoHO4Component: {
             visible: !_.isEmpty(policy.lobs.homeOwners) && homePolicyType === 'Renters'
         },
         policyHomeOwnersInfoHO6Component: {
             visible: !_.isEmpty(policy.lobs.homeOwners) && homePolicyType === 'Condominium'
         },
         policyHomeOwnersInfoNRComponent: {
            visible: !_.isEmpty(policy.lobs.homeOwners) && homePolicyType === 'Dwelling Fire' && dwellingUsage !== 'Rented Condo'
         },
         policyHomeOwnersInfoRCComponent: {
            visible: !_.isEmpty(policy.lobs.homeOwners) && homePolicyType === 'Dwelling Fire' && dwellingUsage === 'Rented Condo' 
         },
         
        genericCoveragesComponent: {
            visible:
                _.isEmpty(policy.lobs.homeOwners)
                && _.isEmpty(policy.lobs.personalAuto)
                && !_.isEmpty(policy.coverables)
        }
    };

    const resolvers = {
        resolveComponentMap: {
            policyvehiclesdriversinfocomponent: PolicyVehiclesDriversInfo,
            policydwellingho3component: PolicyDwellingInfoHO3,
            policydwellingho4component : PolicyDwellingInfoHO4,
            policydwellingho6component : PolicyDwellingInfoHO6,
            policydwellingNRcomponent: PolicyDwellingInfoNR,
            policydwellingRCcomponent: PolicyDwellingInfoRC,
            genericcoveragecomponent: PolicyCommonCoverables
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policy}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PolicyCoverages.propTypes = {
    value: PropTypes.shape({})
};

PolicyCoverages.defaultProps = {
    value: {
        lobs: {
            homeOwners: {},
            personalAuto: {},
            coverables: []
        }
    }
};

export default PolicyCoverages;
