import React, {
    useState, useContext, useCallback, useEffect, useMemo
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './ContactComponent.metadata.json5';
import styles from './ContactComponent.module.scss';
import Claim from '../../models/Claim';
import messages from './ContactComponent.messages';
import {
    Button,
    InputField,
    Icon,
    DropdownSelectField,
    Link
} from '@jutro/components';
import { PhoneNumber } from 'gw-components-platform-react';
import classNames from 'classnames';
import { useValidation } from 'gw-portals-validation-react';

function ContactComponent(props) {
    const {claimVM, updateWizardData} = props;
    const [claimObj, updateClaimObj] = useState({});
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const { onValidate, isComponentValid, registerComponentValidation } = useValidation(
        'ContactComponent'
    );
    const ROLES_TO_FILTER = ['witness', 'other'];

    const showInjuredColumn = useMemo(
        () => !['InlandMarine', 'GeneralLiability', 'BusinessOwners'].includes(_.get(claimVM, 'policy.policyType.value')),        
        []
    );

    useEffect(() => {
        updateClaimObj(new Claim(claimVM.value));
    }, []);

    const getPhoneFieldBasedOnPrimaryPhoneType = (relatedContact) => {
        let primaryPhoneType;
        switch (relatedContact.contact.primaryPhoneType) {
            case 'home':
                primaryPhoneType = 'homeNumber';
                break;
            case 'work':
                primaryPhoneType = 'workNumber';
                break;
            case 'mobile':
                primaryPhoneType = 'cellNumber';
                break;
            default:
                primaryPhoneType = 'homeNumber';
        }
        return primaryPhoneType;
    };

    const filteredRoleTypeList = useMemo(() => {
        const typeList = viewModelService.create(
            {},
            'cc',
            'edge.capabilities.claim.fnol.dto.FnolRelatedContactDTO'
        );
        const filteredList = typeList.role.aspects.availableValues
            .filter((typeCode) => {
                return ROLES_TO_FILTER.includes(typeCode.code);
            })
            .map((typeCode) => {
                return {
                    code: typeCode.code,
                    name: translator({
                        id: typeCode.name,
                        defaultMessage: typeCode.name
                    })
                };
            });
        return filteredList;
    }, [translator, viewModelService]);

    const getContactsRelatedPersons = useCallback((contactsItem) => {
        if (contactsItem.subtype === 'Person' && contactsItem.lastName) {
            return `${contactsItem.firstName} ${contactsItem.lastName}`;
        }
        return '';
    }, []);

    const generateNewWitnessRow = useCallback(
        (event) => {
            event.preventDefault();
            const { contacts } = claimVM;
            const getCurrentMenuItemID = event.currentTarget.id;
            if (getCurrentMenuItemID.indexOf('anotherPersonLink') === -1) {
                const getIndex = parseInt(getCurrentMenuItemID.match(/[0-9]/gi), 10);
                const selectedContact = contacts.value[getIndex];
                claimObj.addRelatedContact_ext(selectedContact);
                _.set(claimVM.value, 'relatedContacts', claimObj.relatedContacts);
            } else {
                claimObj.addRelatedContact_ext(null);
                _.set(claimVM.value, 'relatedContacts', claimObj.relatedContacts);
            }
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData, claimObj]
    );

    const getFirstLastNameData = useCallback(
        (item, rowId, property) => {
            const { id, path } = property;
            const claimVMPath = `relatedContacts.value[${rowId}].${path}`;
            const setDisable = item.contact.publicID
                ? 'disabled'
                : '';
            return (
                <InputField
                    id={`${id}_${rowId}`}
                    className={styles.tableColumn}
                    path={claimVMPath}
                    onValueChange={writeValue}
                    value={_.get(claimVM, claimVMPath)}
                    disabled={setDisable}
                />
            );
        },
        [claimVM, writeValue]
    );

    const getInvolvementInjuredData = useCallback(
        (item, rowId, property) => {
            const { id, path } = property;
            let availableValues;
            const claimVMPath = `relatedContacts.value[${rowId}].${path}`;
            if (id === 'role') {
                availableValues = filteredRoleTypeList;
            } else {
                availableValues = [
                    {
                        code: 'true',
                        name: 'Yes'
                    },
                    {
                        code: 'false',
                        name: 'No'
                    }
                ];
            }
            return (
                <DropdownSelectField
                    id={`${id}_${rowId}`}
                    alwaysShowPlaceholder={false}
                    availableValues={availableValues}
                    path={claimVMPath}
                    onValueChange={writeValue}
                    value={_.get(claimVM, claimVMPath)}
                    className={styles.involvmentInjuredDropDown}
                />
            );
        },
        [claimVM, filteredRoleTypeList, translator, writeValue]
    );

    const getPhoneNumberData = useCallback(
        (item, rowId) => {
            const phoneField = getPhoneFieldBasedOnPrimaryPhoneType(item);
            const claimVMPath = `relatedContacts.value[${rowId}].contact.${phoneField}`;
            const setDisable = item.contact.publicID
                ? 'disabled'
                : '';
            return (
                
                <PhoneNumber
                    id={`${item.primaryPhoneID}_${rowId}`}
                    alwaysShowMask
                    onValueChange={writeValue}
                    path={claimVMPath}
                    disabled={setDisable}
                    value={_.get(claimVM, claimVMPath)}
                />
            );
        },
        [claimVM, writeValue, claimObj]
    );

    const removeContactItem = useCallback(
        (e, rowId) => {
            e.preventDefault();
            const { relatedContacts } = claimVM;
            const relatedContactToDelete = relatedContacts.value.filter((relatedContact, index) => {
                return index === rowId;
            });
            claimObj.removeRelatedContact(relatedContactToDelete[0].contact);
            _.set(claimVM.value, 'relatedContacts', claimObj.relatedContacts);
            updateWizardData(claimVM);
        },
        [claimVM, claimObj, updateWizardData]
    );

    const removeContactRow = useCallback(
        (item, index) => {
            return (
                <Link
                    to="/"
                    onClick={(e) => removeContactItem(e, index, item)}
                    className={classNames(styles.deleteSection, styles.deleteRow)}
                >
                    <Icon icon="mi-delete" className={styles.deleteIcon} />
                </Link>
            );
        },
        [removeContactItem]
    );

    const writeValue = useCallback(
        (value, path) => {
            const newClaimVM = _.clone(claimVM);
            _.set(newClaimVM, path, value);
            updateWizardData(newClaimVM);
            _.set(claimObj, 'relatedContacts', newClaimVM.relatedContacts.value);
        },
        [claimObj, claimVM, updateWizardData]
    );

    const generateAddPersonMenuDropDown = useCallback(
        (dropDownProps, toggleMenu) => {
            const { isOpen } = dropDownProps;
            const onAddPersonClick = () => {
                toggleMenu(!isOpen);
            };
            return (
                <Button onClick={onAddPersonClick} icon="mi-expand_more" iconPosition="right">
                    Add Person
                </Button>
            );
        },
        [translator]
    );

    const validateTableForm = useCallback(() => {
        if (
            !claimVM.relatedContacts.aspects.valid
            || !claimVM.relatedContacts.aspects.subtreeValid
        ) {
            return false;
        }
        return true;
    }, [claimVM]);

    useEffect(() => {
        registerComponentValidation(validateTableForm);
    }, [registerComponentValidation, validateTableForm]);

    const generateMoreInfoAddPersonOverrides = useCallback(() => {
        const contactsPath = 'value.contacts';
        const contactsItems = _.get(claimVM, contactsPath, []);
        let availableRelatedContacts = [];
        if (!_.isEmpty(claimObj)) {
            availableRelatedContacts = claimObj.availableRelatedContacts().filter((contact) => {
                return contact.subtype === 'Person' && contact.firstName;
            });
        }

        if (!contactsItems.length) {
            return {};
        }
        const overrides = contactsItems.map((contactItem, index) => {
            return {
                [`contactItems${index}`]: {
                    message: getContactsRelatedPersons(contactItem)
                },
                [`contactItemsDropDown${index}`]: {
                    visible: _.includes(availableRelatedContacts, contactItem)
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [claimVM, claimObj, getContactsRelatedPersons]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },       
        witnessPartyTable: {
            data: claimVM.relatedContacts.value,
            visible: !_.isEmpty(claimVM.relatedContacts.value)
        },
        injuredHeader: {
            visible: showInjuredColumn
        },
        ...generateMoreInfoAddPersonOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            generateWitnessPartyTableRow: generateNewWitnessRow,
            getFirstNameData: getFirstLastNameData,
            getLastNameData: getFirstLastNameData,
            getInvolvementData: getInvolvementInjuredData,
            getPhoneNumberData: getPhoneNumberData,
            getInjuredData: getInvolvementInjuredData,
            removeContactRow: removeContactRow,
            renderTrigger: generateAddPersonMenuDropDown
        }
    };

    

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={claimVM}
            onModelChange={updateWizardData}
            onValueChange={writeValue}
            overrideProps={overrideProps}
            onValidationChange={onValidate}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

ContactComponent.propTypes = {
    
};

export default ContactComponent;
