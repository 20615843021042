import { defineMessages } from 'react-intl';

export default defineMessages({
    cancelEditTitle: {
        id: 'usersprofile.views.amp-users-profile.Cancel contact information update',
        defaultMessage: 'Cancel contact information update'
    },
    cancelEditMessage: {
        id: 'usersprofile.views.amp-users-profile.Are you sure you want to cancel your account contact profile changes?',
        defaultMessage: 'Are you sure you want to cancel your account contact profile changes?'
    },
    saveMessage:{
        id: 'usersprofile.views.amp-users-profile.save changes',
        defaultMessage: 'Your changes have been updated successfully in this website as well as in policy details.'
    },
    save2faMessage:{
        id: 'usersprofile.views.amp-users-profile.save2fa',
        defaultMessage: 'Your 2FA verification method has been updated successfully.'
    },
    cancelMessage:{
        id: 'usersprofile.views.amp-users-profile.cancel changes',
        defaultMessage: 'All your changes have been discarded.'
    },
    mfaOtpDialogTitle:{
        id: 'usersprofile.views.amp-users-profile.mfaOtpDialogTitleText',
        defaultMessage: 'Please Verify OTP'
    },
    mfaOtpDialogPhoneInfo:{
        id: 'usersprofile.views.amp-users-profile.mfaOtpDialogPhoneInfo',
        defaultMessage: 'Please enter OTP below Textbox, which you got to your Mobile Number.'
    },
    mfaOtpReVerificationSuccess: {
        id: 'usersprofile.views.amp-users-profile.mfaOtpReVerificationSuccessMsg',
        defaultMessage: 'MFA OTP Re-Verification is Done'
    },
    whiteListedEmailMsg: {
        id: 'usersprofile.views.amp-users-profile.whitelistedemailmsg',
        defaultMessage: 'No matching whitelisted Email entry found.'
    },
    whiteListedPhoneMsg: {
        id: 'usersprofile.views.amp-users-profile.whitelistedphonemsg',
        defaultMessage: 'No matching whitelisted Phone Number entry found.'
    }
});
