import React, {
    useEffect, useState, useContext, useCallback
} from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import PropTypes from 'prop-types';
import { Loader } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { isCapabilityEnabled, getNormalizedLOBName } from 'gw-portals-config-js';
import { useAuthentication } from 'gw-digital-auth-react';
import { PolicyService } from 'gw-capability-policy';
import { DocumentService } from 'gw-capability-policydocument';
import {
    PolicyCommonDetails,
    PolicyCommonDocuments,
    PolicyCommonContacts,
    PolicyCoverages,
    PolicyScheduleInfo,
    PolicyCoverage,
    HOSectionIICoverages,
    DFSectionIICoverages,
    HOSectionIDeductible,
    DFDeductibleCoverages,
    HO6SectionICoverages,
    DFRCSectionICoverages,
    DFNRSectionICoverages,
    DP12SectionICoverages,
    HO4SectionICoverages,
    HO3SectionICoverages
} from 'nlc-capability-policy-common-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PolicyDetails.metadata.json5';
import messages from './PolicyDetails.messages';
import styles from './PolicyDetails.module.scss';
//import HOSectionIICoverages from 'nlc-capability-policy-common-react/components/HO/HOSectionIICoverages/HOSectionIICoverages';

const handlePrint = () => {
    window.print();
};

function PolicyDetails(props) {
    const { history, match } = props;
    const { authHeader } = useAuthentication();
    const [policyCommonDetails, updatePolicyCommonDetails] = useState({
        currentPeriodDetails: {},
        renewedPeriodDetails: {},
        hasRenewedPeriod: false
    });
    const [writableDocuments, updateWritableDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const translator = useContext(TranslatorContext);
    const { capabilitiesConfig } = appConfig;
    const policyNumber = _.get(match, 'params.policyNumber');

    const getPolicyDetails = useCallback(async () => {
        const policyDetails = await PolicyService.getAccountPolicyDetails(policyNumber, authHeader);
        updatePolicyCommonDetails({
            currentPeriodDetails: policyDetails.currentPeriod,
            renewedPeriodDetails: policyDetails.renewedPeriod ? policyDetails.renewedPeriod : {},
            hasRenewedPeriod: !_.isEmpty(policyDetails.renewedPeriod)
        });
        setLoading(false);
    }, [policyNumber, authHeader]);

    const redirectToEndorsement = useCallback(
        (policyDetails, requestType) => {
            const productCode = _.findKey(policyDetails.lobs, (lob) => !_.isEmpty(lob));
            history.push(`/endorsement-${getNormalizedLOBName(productCode)}`,
                { policyNumber, policyDetails, requestType });
        },
        [history, policyNumber]
    );

    const canChangePolicy = useCallback(
        (policyPeriod) => {
            if (!_.isEmpty(policyPeriod)) {
                const productCode = _.findKey(policyPeriod.lobs, (lob) => !_.isEmpty(lob));
                if (_.isEmpty(productCode)) {
                    return false;
                }
                return (
                    isCapabilityEnabled({
                        capabilitiesConfig,
                        capabilityName: 'policychange',
                        lob: productCode
                    }) && policyPeriod.canBeChanged
                );
            }
            return false;
        },
        [capabilitiesConfig]
    );

    const getPolicyDownloadLink = useCallback(() => {
        return DocumentService.downloadPolicyDocument(
            _.get(policyCommonDetails.currentPeriodDetails, 'idCardPublicID'),
            _.get(policyCommonDetails.currentPeriodDetails, 'idCardSessionID')
        );
    }, [policyCommonDetails]);

    useEffect(
        () => {
            try {
                getPolicyDetails();
            } catch (e) {
                throw new Error(e);
            }
        },
        // Only need to run once when initial page render and when documents are added/removed
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [writableDocuments.length]
    );

    const overrideProps = {
        pageTitle: {
            content: translator(messages.pageTitle, {
                policyNumber: policyCommonDetails.currentPeriodDetails.policyNumber
            })
        },
        currentPeriod: {
            heading: translator(messages.currentTab)
        },
        renewalPeriod: {
            heading: policyCommonDetails.hasRenewedPeriod ? translator(messages.renewalTab) : '',
            disabled: !policyCommonDetails.hasRenewedPeriod
        },
        policyChangeButtonContainer: {
            visible: canChangePolicy(
                policyCommonDetails.currentPeriodDetails,
                policyCommonDetails.currentPeriodDetails.productName
            )
        },
        renewalPolicyChangeButtonContainer: {
            visible: canChangePolicy(
                policyCommonDetails.renewedPeriodDetails,
                policyCommonDetails.currentPeriodDetails.productName
            )
        },
        idCardContainer: {
            visible: !_.isEmpty(_.get(policyCommonDetails.currentPeriodDetails, 'idCardPublicID'))
        },
        idCard: {
            href: getPolicyDownloadLink(),
            target: '_blank'
        },
        policyDocumentsSection: {
            updateWritableDocuments: updateWritableDocuments,
            writableDocuments: writableDocuments
        },
        policyCoveragesSection : {
            visible: ( policyCommonDetails.currentPeriodDetails.productName !=='Personal Umbrella'),
            homePolicyType : policyCommonDetails.currentPeriodDetails.productName,
            dwellingUsage : policyCommonDetails.currentPeriodDetails.dwellingUsage_Ext,
            jurisdiction : policyCommonDetails.currentPeriodDetails.state_Ext,
            coverables : policyCommonDetails.currentPeriodDetails.coverables,
            
        },
        policyCoveragesRenewelSection : {
            visible: ( policyCommonDetails.renewedPeriodDetails.productName !=='Personal Umbrella'),
            homePolicyType : policyCommonDetails.renewedPeriodDetails.productName,
            dwellingUsage : policyCommonDetails.renewedPeriodDetails.dwellingUsage_Ext,
            jurisdiction : policyCommonDetails.renewedPeriodDetails.state_Ext,
            coverables : policyCommonDetails.renewedPeriodDetails.coverables,
            
        },
        policyCommonDetailsForCurrentPeriod : {
            homePolicyType : policyCommonDetails.currentPeriodDetails.productName,
            jurisdiction : policyCommonDetails.currentPeriodDetails.state_Ext
        },
        renewalPolicyCommonDetailsForRenewalPeriod : {
            homePolicyType : policyCommonDetails.renewedPeriodDetails.productName,
            jurisdiction : policyCommonDetails.renewedPeriodDetails.state_Ext
        },
        policyScheduleSection:{  
            visible: policyCommonDetails.currentPeriodDetails.productName ==='Personal Umbrella',
            data:_.get(policyCommonDetails, 'currentPeriodDetails.policySchedule_Ext')
        },
        policyCoveragePersonalExcessSection:{
            visible: policyCommonDetails.currentPeriodDetails.productName ==='Personal Umbrella',
            data:_.get(policyCommonDetails, 'currentPeriodDetails.policyCoverages_Ext')
        },
        policyScheduleRenewelSection:{  
            visible: policyCommonDetails.renewedPeriodDetails.productName ==='Personal Umbrella',
            data:_.get(policyCommonDetails, 'renewedPeriodDetails.policySchedule_Ext')
        },
        policyCoveragePersonalExcessRenewelSection:{
            visible: policyCommonDetails.renewedPeriodDetails.productName ==='Personal Umbrella',
            data:_.get(policyCommonDetails, 'renewedPeriodDetails.policyCoverages_Ext')
        },
          policyCoverageHomeownersSection : {
            visible : policyCommonDetails.currentPeriodDetails.productName ==='Condominium' || policyCommonDetails.currentPeriodDetails.productName ==='Renters' ||
             policyCommonDetails.currentPeriodDetails.productName ==='Homeowners',
              sectionICoverages :_.get(policyCommonDetails, 'currentPeriodDetails.sectionICoverages_Ext'),
              sectionIDeductible :_.get(policyCommonDetails, 'currentPeriodDetails.sectionIDeductible_Ext'),
              data :_.get(policyCommonDetails, 'currentPeriodDetails.sectionIICoverages_Ext')
          },
          policyCoverageHomeownersRenewelSection : {
            visible : policyCommonDetails.renewedPeriodDetails.productName ==='Condominium' || policyCommonDetails.renewedPeriodDetails.productName ==='Renters' ||
             policyCommonDetails.renewedPeriodDetails.productName ==='Homeowners',
              sectionICoverages :_.get(policyCommonDetails, 'renewedPeriodDetails.sectionICoverages_Ext'),
              sectionIDeductible :_.get(policyCommonDetails, 'renewedPeriodDetails.sectionIDeductible_Ext'),
              data :_.get(policyCommonDetails, 'renewedPeriodDetails.sectionIICoverages_Ext')
          },
          policyCoverageDwellingSection : {
            visible : policyCommonDetails.currentPeriodDetails.policyType ==='DP1' || policyCommonDetails.currentPeriodDetails.policyType ==='DP2' ||
             policyCommonDetails.currentPeriodDetails.policyType ==='DP3',
              sectionICoverages :_.get(policyCommonDetails, 'currentPeriodDetails.sectionICoverages_Ext'),
              deductibleCoverages :_.get(policyCommonDetails, 'currentPeriodDetails.dwellingDeductible_Ext'),
              data :_.get(policyCommonDetails, 'currentPeriodDetails.sectionIILiabilityCoverages_Ext')
          },
          policyCoverageDwellingRenewelSection : {
            visible : policyCommonDetails.renewedPeriodDetails.policyType ==='DP1' || policyCommonDetails.renewedPeriodDetails.policyType ==='DP2' ||
             policyCommonDetails.renewedPeriodDetails.policyType ==='DP3',
              sectionICoverages :_.get(policyCommonDetails, 'renewedPeriodDetails.sectionICoverages_Ext'),
              deductibleCoverages :_.get(policyCommonDetails, 'renewedPeriodDetails.dwellingDeductible_Ext'),
              data :_.get(policyCommonDetails, 'renewedPeriodDetails.sectionIILiabilityCoverages_Ext')
          },
          policyDeductibleHomeownersSection : {
            visible : policyCommonDetails.currentPeriodDetails.productName ==='Condominium' || policyCommonDetails.currentPeriodDetails.productName ==='Renters' ||
             policyCommonDetails.currentPeriodDetails.productName ==='Homeowners',
            sectionIDeductible :_.get(policyCommonDetails, 'currentPeriodDetails.sectionIDeductible_Ext')
          },
          policyDeductibleHomeownersRenewelSection : {
            visible : policyCommonDetails.renewedPeriodDetails.productName ==='Condominium' || policyCommonDetails.renewedPeriodDetails.productName ==='Renters' ||
             policyCommonDetails.renewedPeriodDetails.productName ==='Homeowners',
            sectionIDeductible :_.get(policyCommonDetails, 'renewedPeriodDetails.sectionIDeductible_Ext')
          },
          policyDeductibleDwellingSection : {
            visible : policyCommonDetails.currentPeriodDetails.policyType ==='DP1' || policyCommonDetails.currentPeriodDetails.policyType ==='DP2' ||
             policyCommonDetails.currentPeriodDetails.policyType ==='DP3',
            deductibleCoverages :_.get(policyCommonDetails, 'currentPeriodDetails.dwellingDeductible_Ext')
          },
          policyDeductibleDwellingRenewelSection : {
            visible : policyCommonDetails.renewedPeriodDetails.policyType ==='DP1' || policyCommonDetails.renewedPeriodDetails.policyType ==='DP2' ||
             policyCommonDetails.renewedPeriodDetails.policyType ==='DP3',
            deductibleCoverages :_.get(policyCommonDetails, 'renewedPeriodDetails.dwellingDeductible_Ext')
          },

          policyCoveragesSectionIHomeowners6 : {
            visible : policyCommonDetails.currentPeriodDetails.productName ==='Condominium',
            sectionICoverages :_.get(policyCommonDetails, 'currentPeriodDetails.sectionICoverages_Ext')
          },
          policyCoveragesSectionIRenewelHomeowners6 : {
            visible : policyCommonDetails.renewedPeriodDetails.productName ==='Condominium',
            sectionICoverages :_.get(policyCommonDetails, 'renewedPeriodDetails.sectionICoverages_Ext')
          },
          policyCoveragesSectionIDFRC : {
            visible : (policyCommonDetails.currentPeriodDetails.policyType ==='DP1' || policyCommonDetails.currentPeriodDetails.policyType ==='DP2' ||
             policyCommonDetails.currentPeriodDetails.policyType ==='DP3') && policyCommonDetails.currentPeriodDetails.dwellingUsage_Ext ==='Rented Condo',
            sectionIDFCoverages :_.get(policyCommonDetails, 'currentPeriodDetails.sectionIPropertyCoverages_Ext')
          },
          policyCoveragesSectionIRenewelDFRC : {
            visible : (policyCommonDetails.renewedPeriodDetails.policyType ==='DP1' || policyCommonDetails.renewedPeriodDetails.policyType ==='DP2' ||
             policyCommonDetails.renewedPeriodDetails.policyType ==='DP3') && policyCommonDetails.renewedPeriodDetails.dwellingUsage_Ext ==='Rented Condo',
            sectionIDFCoverages :_.get(policyCommonDetails, 'renewedPeriodDetails.sectionIPropertyCoverages_Ext')
          },
          policyCoveragesSectionIDFNR : {
            visible : (policyCommonDetails.currentPeriodDetails.policyType ==='DP1') && (policyCommonDetails.currentPeriodDetails.dwellingUsage_Ext !=='Rented Condo'),
            sectionIDFCoverages :_.get(policyCommonDetails, 'currentPeriodDetails.sectionIPropertyCoverages_Ext')
          },
          policyCoveragesSectionIRenewelDFNR : {
            visible : (policyCommonDetails.renewedPeriodDetails.policyType ==='DP1') && (policyCommonDetails.renewedPeriodDetails.dwellingUsage_Ext !=='Rented Condo'),
            sectionIDFCoverages :_.get(policyCommonDetails, 'renewedPeriodDetails.sectionIPropertyCoverages_Ext')
          },
          policyCoveragesSectionIDP12 : {
            visible : (policyCommonDetails.currentPeriodDetails.policyType ==='DP2' || policyCommonDetails.currentPeriodDetails.policyType ==='DP3') && (policyCommonDetails.currentPeriodDetails.dwellingUsage_Ext !=='Rented Condo'),
            sectionIDFCoverages :_.get(policyCommonDetails, 'currentPeriodDetails.sectionIPropertyCoverages_Ext')
          },
          policyCoveragesSectionIRenewelDP12 : {
            visible : (policyCommonDetails.renewedPeriodDetails.policyType ==='DP2' || policyCommonDetails.renewedPeriodDetails.policyType ==='DP3') && (policyCommonDetails.currentPeriodDetails.dwellingUsage_Ext !=='Rented Condo'),
            sectionIDFCoverages :_.get(policyCommonDetails, 'renewedPeriodDetails.sectionIPropertyCoverages_Ext')
          },
          policyCoveragesSectionIHomeowners4 : {
            visible : policyCommonDetails.currentPeriodDetails.productName ==='Renters',
            sectionICoverages :_.get(policyCommonDetails, 'currentPeriodDetails.sectionICoverages_Ext')
          },
          policyCoveragesSectionIRenewelHomeowners4 : {
            visible : policyCommonDetails.renewedPeriodDetails.productName ==='Renters',
            sectionICoverages :_.get(policyCommonDetails, 'renewedPeriodDetails.sectionICoverages_Ext')
          },
          policyCoveragesSectionIHomeowners3 : {
            visible : policyCommonDetails.currentPeriodDetails.productName ==='Homeowners',
            sectionICoverages :_.get(policyCommonDetails, 'currentPeriodDetails.sectionICoverages_Ext')
          },
          policyCoveragesSectionIRenewelHomeowners3 : {
            visible : policyCommonDetails.renewedPeriodDetails.productName ==='Homeowners',
            sectionICoverages :_.get(policyCommonDetails, 'renewedPeriodDetails.sectionICoverages_Ext')
          },

    };

    const resolvers = {
        resolveComponentMap: {
            policycommondetailscomponent: PolicyCommonDetails,
            policycoveragescomponent: PolicyCoverages,
            policycommondocumentscomponent: PolicyCommonDocuments,
            policycommoncontactscomponent: PolicyCommonContacts,
            policycheduleinfocomponent: PolicyScheduleInfo,
            policycoveragepecomponent: PolicyCoverage,
            policycoveragehocomponent : HOSectionIICoverages,
            policycoveragedfcomponent : DFSectionIICoverages, 
            policydeductiblehocomponent : HOSectionIDeductible,
            policydeductibledfcomponent : DFDeductibleCoverages,
            policycoveragessectionIho6component : HO6SectionICoverages,
            policycoveragessectionIdfrccomponent : DFRCSectionICoverages,
            policycoveragessectionIdfnrcomponent : DFNRSectionICoverages,
            policycoveragessectionIdfnr12component : DP12SectionICoverages,
            policycoveragessectionIho4component : HO4SectionICoverages,
            policycoveragessectionIho3component : HO3SectionICoverages

        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onPrint: handlePrint,
            onChangePolicy: () => redirectToEndorsement(policyCommonDetails.currentPeriodDetails, 'policyChange'),
            onChangePolicyRenewal: () => redirectToEndorsement(
                policyCommonDetails.renewedPeriodDetails, 'policyRenew'
            )
        }
    };

    return (
        <Loader loaded={!loading}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyCommonDetails}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </Loader>
    );
}
PolicyDetails.propTypes = {
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        productCode: PropTypes.string
    }).isRequired
};

export default withRouter(PolicyDetails);
