/* eslint-disable */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ViewModelUtil } from 'gw-portals-viewmodel-js';
import { QuestionSetsViewModelUtil } from 'gw-portals-questionsets-js';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { renderContentFromMetadata, findInvalidFieldsFromMetadata } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import { getFlattenedUiPropsContent } from './flattenUiPropsHelper';

const DISPLAY_KEYS_TO_IGNORE = [
    // This field is Required
    'displaykey.Edge.Web.Api.Model.NotNull',
    // Value entered must be a valid phone number
    'displaykey.Edge.Web.Api.Model.Phone'
];

/**
 * @private
 */
function ViewModelForm(props) {
    const {
        uiProps,
        overrideProps,
        model,
        onValueChange,
        onModelChange,
        resolveValue,
        showErrors,
        onValidationChange,
        classNameMap,
        componentMap,
        callbackMap,
        className,
        showOptional
    } = props;

    const translator = useContext(TranslatorContext);

    const flatUiProps = useMemo(() => getFlattenedUiPropsContent(uiProps), [uiProps]);

    const [fieldValidationMessages, setFieldValidationMessages] = useState({});

    const [hasDoneInitialValidation, setHasDoneInitialValidation] = useState(false);

    const translateMessages = useCallback(
        (messages = []) => {
            return messages
                .filter((message) => !DISPLAY_KEYS_TO_IGNORE.includes(message))
                .map((message) => translator(message));
        },
        [translator]
    );

    const readValue = useCallback(
        (id, path) => {
            const uiPropsContent = flatUiProps;
            const componentUiProps = uiPropsContent.find((item) => item.id === id) || {};
            const { componentProps = {} } = componentUiProps;
            const { passViewModel } = componentProps;

            const node = _.get(model, path);

            const overrideValue = _.get(overrideProps, `${id}.value`);
            if (!_.isUndefined(overrideValue)) {
                return overrideValue;
            }

            if (passViewModel) {
                return node;
            }

            if (ViewModelUtil.isViewModelNode(node) || QuestionSetsViewModelUtil.isViewModelNode(node)) {
                return node.value;
            }

            return node;
        },
        [flatUiProps, model, overrideProps]
    );

    /**
     * Formats the availableValue to have a displayName with i18n message shape and id
     *
     * @param {object} availableValue the availableValue to format
     * @returns {object} the availableValue in the correct format
     */
    const formatAvailableValue = useCallback((availableValue) => {
        if (availableValue && availableValue.hasOwnProperty('displayName') && availableValue.hasOwnProperty('id')) {
            return availableValue;
        }
        return {
            ...availableValue,
            displayName: {
                defaultMessage: availableValue.name || availableValue.code,
                id: availableValue.name
            },
            id: availableValue.code
        };
    }, []);

    const resolveDataProps = useCallback(
        (id, path) => {
            const vmNode = _.get(model, path, {}) || {};
            const { aspects = {} } = vmNode;
            const {
                required,
                availableValues,
                validationMessages,
                visible,
                inputCtrlType
            } = aspects;

            return {
                datatype: inputCtrlType,
                componentProps: {
                    availableValues: availableValues ? availableValues.map(formatAvailableValue) : [],
                    validationMessages: translateMessages(validationMessages),
                    visible,
                    schemaRequired: required
                }
            };
        },
        [model, translateMessages]
    );

    const onFieldValueChange = useCallback(
        (value, path) => {
            _.set(model, path, value);
            onValueChange && onValueChange(value, path);
            onModelChange && onModelChange(model);
        },
        [model, onModelChange, onValueChange]
    );

    const onFieldValidationChanged = useCallback(
        (isValid, path, message) => {
            setFieldValidationMessages((previousMessages) => {
                if (isValid) {
                    return _.omit(previousMessages, path);
                }
                return {
                    ...previousMessages,
                    [path]: message
                };
            });

            if (!hasDoneInitialValidation) {
                setHasDoneInitialValidation(true);
            }
        },
        [fieldValidationMessages, hasDoneInitialValidation]
    );

    const isNodeValid = useCallback(
        (node) => {
            if (ViewModelUtil.isViewModelNode(node)) {
                const { valid, subtreeValid } = node.aspects;
                return valid && subtreeValid;
            }
            return true;
        },
        []
    );

    const fieldOverrides = overrideProps ? overrideProps['@field'] : {};
    const extendedProps = {
        ...overrideProps,
        '@field': {
            onValueChange: onFieldValueChange,
            onValidationChange: onFieldValidationChanged,
            showErrors,
            showOptional,
            ...fieldOverrides
        }
    };

    const resolvers = {
        resolveValue: resolveValue || readValue, // resolve value from data using path
        resolveDataProps,
        resolveCallbackMap: callbackMap, // resolve callback string to callback function,
        resolveComponentMap: componentMap,
        resolveClassNameMap: classNameMap // resolve class names to css module names
    };

    useEffect(() => {
        Object.keys(fieldValidationMessages).forEach((fieldPath) => {
            const invalidField = flatUiProps.find((uiProp) => _.get(uiProp, 'componentProps.path') === fieldPath) || {};
            const dataProps = resolveDataProps(invalidField.id, fieldPath) || {};
            const { visible, schemaRequired, validationMessages } = dataProps.componentProps;
            const node = _.get(model, `${fieldPath}`, {});

            if ((!visible || !schemaRequired || isNodeValid(node)) && _.isEmpty(validationMessages)) {
                setFieldValidationMessages((previousMessages) => {
                    return _.omit(previousMessages, fieldPath);
                })
            }
        });
    });

    const invalidFields = findInvalidFieldsFromMetadata(uiProps, extendedProps, resolvers, uiProps);
    const isFormValid = _.isEmpty(invalidFields) && _.isEmpty(fieldValidationMessages);

    useEffect(() => {
        onValidationChange && onValidationChange(isFormValid);
    }, [isFormValid, onValidationChange]);

    const pageContent = renderContentFromMetadata(uiProps, extendedProps, resolvers);

    return <div className={className}>{pageContent}</div>;
}

ViewModelForm.propTypes = {
    /**
     * Content metadata or array of metadata
     */
    uiProps: PropTypes.object.isRequired,
    /**
     * Override props at different levels e.g field
     */
    overrideProps: PropTypes.object,
    /**
     * ViewModel object
     */
    model: PropTypes.object.isRequired,
    /**
     * Callback for when the view model is updated
     */
    onModelChange: PropTypes.func,
    /**
     * Callback for when individual fields are updated
     */
    onValueChange: PropTypes.func,
    /**
     * Override to force showing input errors
     */
    showErrors: PropTypes.bool,
    /**
     * Callback for when the form validation state changes
     */
    onValidationChange: PropTypes.func,
    /**
     * Resolve metadata class names
     */
    classNameMap: PropTypes.object,
    /**
     * Resolve component string to component
     */
    componentMap: PropTypes.object,
    /**
     * Resolve callback fn string to fn
     */
    callbackMap: PropTypes.object,
    /**
     * Custom class to apply to content wrapper
     */
    className: PropTypes.string,

    /**
     * If true, displays the `Optional` span
     **/
    showOptional: PropTypes.bool
};

export default ViewModelForm;
