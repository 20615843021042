import { defineMessages } from 'react-intl';

export default defineMessages({
    header: {
        id: "forgotPassword.otp.Forgot Username or password",
        defaultMessage: "Forgot Username or password"
    },
    otpValidationMessage: {
        id: "forgotPassword.otp.Incorrect code - please re-enter the code or click on the send code button again.",
        defaultMessage: "Incorrect code - please re-enter the code or click on the send code button again."
    },
    otpSentMessage: {
        id: "forgotPassword.otp.A verifcation code has been sent. Please enter the code below.",
        defaultMessage: "A verifcation code has been sent. Please enter the code below."
    },
    email:{
        id:"forgotPassword.otp.Email:",
        defaultMessage:"Email:"
    },
    sendCode:{
        id:"forgotPassword.otp.Send Code",
        defaultMessage:"Send Code"
    },
    enterCode:{
        id:"forgotPassword.otp.Enter Code:",
        defaultMessage:"Enter Code:"
    },
    verify:{
        id:"forgotPassword.otp.Verify",
        defaultMessage:"Verify"
    },
    phoneNumber:{
        id:"forgotPassword.otp.Phone Number:",
        defaultMessage:"Phone Number:"
    },
    next:{
        id:"forgotPassword.otp.Next",
        defaultMessage:"Next"
    },
    invalidOtpInfo:{
        id:"forgotPassword.otp.Incorrect code - please re-enter the code or click on the send code button again.",
        defaultMessage:"Incorrect code - please re-enter the code or click on the send code button again."
    },
    emailSent:{
        id:"forgotPassword.otp.Success! Your user ID sent to registered email",
        defaultMessage:"Success! Your user ID sent to registered email"
    },
    errorOnEmailSent:{
        id:"forgotPassword.otp.Failed! error occured while sending user ID",
        defaultMessage:"Failed! error occured while sending user ID"
    },
    failed:{
        id:"forgotPassword.otp.Failed!",
        defaultMessage:"Failed!"
    },
    success:{
        id:"forgotPassword.otp.Success!",
        defaultMessage:"Success!"
    },
    userIdMessage:{
        id:"forgotPassword.otp.Success! Your user ID",
        defaultMessage:"Success! Your user ID is: {userName}"
    }
    
   
});
