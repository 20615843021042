import { defineMessages } from 'react-intl';

export default defineMessages({
    successText: {
        id: "signup.otp.accountsuccessfullycreated",
        defaultMessage: "Your Portal account has been successfully created!"
    },
    informationText: {
        id: "signup.otp.NLC requires Two Factor Authentication (2FA) as an extra layer of protection. With 2FA you will be prompted to enter an authentication code each time you log in.",
        defaultMessage: "NLC requires Two Factor Authentication (2FA) as an extra layer of protection. With 2FA you will be prompted to enter an authentication code each time you log in."
    },
    infoText: {
        id: "signup.otp.pleaseenrollemailphone",
        defaultMessage: "Please enroll your email and mobile phone to complete the sign up process."
    },
    informationAdditionalText: {
        id: "signup.otp.Please confirm or update following identification details and click on Send Code to complete the 2FA enrollment for each notification method.",
        defaultMessage: "Please confirm or update following identification details and click on Send Code to complete the 2FA enrollment for each notification method."
    },
    otpValidationMessage: {
        id: "signup.otp.Incorrect code - please re-enter the code or click on the send code button again.",
        defaultMessage: "Incorrect code - please re-enter the code or click on the send code button again."
    },
    otpSentMessage: {
        id: "singup.otp.A verification code has been sent. Please enter the code below.",
        defaultMessage: "A verification code has been sent. Please enter the code below."
    },
    email:{
        id:"signUp.otp.Email:",
        defaultMessage:"Email:"
    },
    sendCode:{
        id:"signUp.otp.Send Code",
        defaultMessage:"Send Code"
    },
    enterCode:{
        id:"signUp.otp.Enter Code:",
        defaultMessage:"Enter Code:"
    },
    verify:{
        id:"signUp.otp.Verify",
        defaultMessage:"Verify"
    },
    phoneNumber:{
        id:"signUp.otp.Phone Number:",
        defaultMessage:"Phone Number:"
    },
    next:{
        id:"signUp.otp.Next",
        defaultMessage:"Next"
    },
    invalidOtpInfo:{
        id:"signUp.otp.Incorrect code - please re-enter the code or click on the send code button again.",
        defaultMessage:"Incorrect code - please re-enter the code or click on the send code button again."
    },
    userLocked: {
        id:"signUp.otp.You have exceeded the maximum number of Send Code requests. You may attempt to have the verification code resent again after 1 hour.",
        defaultMessage:"You have exceeded the maximum number of Send Code requests. You may attempt to have the verification code resent again after 1 hour."
    },
    termsCheckBox: {
        id:"signUp.otp.I agree to the",
        defaultMessage:"I agree to the"
    },
    termsAndConditionsLink: {
        id:"signUp.otp.Terms and conditions",
        defaultMessage:"Terms and conditions"
    },
    okButton: {
        id:"signUp.otp.Ok",
        defaultMessage:"Ok"
    },
    cancelButton: {
        id:"signUp.otp.Cancel",
        defaultMessage:"Cancel"
    }
   
});
