import React, {
    useContext, useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ModalNextProvider, Loader } from '@jutro/components';
import { TranslatorContext, IntlContext } from '@jutro/locale';
import { MetadataForm } from '@jutro/uiconfig';

import { withAuthenticationContext } from 'gw-digital-auth-react';
import { getNormalizedLOBName } from 'gw-portals-config-js';
import { useDependencies } from 'gw-portals-dependency-react';
import { PaymentComponent } from 'gw-components-platform-react';
import { messages as commonMessages } from 'gw-platform-translations';

import metadata from './BillingSummary.metadata.json5';
import styles from './BillingSummary.module.scss';
import messages from './BillingSummary.messages';
import { getConfigValue } from '@jutro/config';

const policyLevelHeaderStrings = {
    PersonalAuto: {
        autoPaymentEnabled: messages.personalAutoPolicyAutomaticPaymentsEnabled,
        default: messages.personalAutoPolicy
    },
    Homeowners: {
        autoPaymentEnabled: messages.homeownersPolicyAutomaticPaymentsEnabled,
        default: messages.homeownersPolicy
    },
    bop: {
        autoPaymentEnabled: messages.businessOwnersPolicyAutomaticPaymentsEnabled,
        default: messages.businessOwnersPolicy
    },
    ca: {
        autoPaymentEnabled: messages.commercialAutoPolicyAutomaticPaymentsEnabled,
        default: messages.commercialAutoPolicy
    },
    cp: {
        autoPaymentEnabled: messages.commercialPropertyPolicyAutomaticPaymentsEnabled,
        default: messages.commercialPropertyPolicy
    },
    cpkg: {
        autoPaymentEnabled: messages.commercialPackagePolicyAutomaticPaymentsEnabled,
        default: messages.commercialPackagePolicy
    },
    gl: {
        autoPaymentEnabled: messages.generalLiabilityPolicyAutomaticPaymentsEnabled,
        default: messages.generalLiabilityPolicy
    },
    im: {
        autoPaymentEnabled: messages.inlandMarinePolicyAutomaticPaymentsEnabled,
        default: messages.inlandMarinePolicy
    },
    wc: {
        autoPaymentEnabled: messages.workersCompPolicyAutomaticPaymentsEnabled,
        default: messages.workersCompPolicy
    },
    wc7: {
        autoPaymentEnabled: messages.workersComp7PolicyAutomaticPaymentsEnabled,
        default: messages.workersComp7Policy
    },
    Dwellingfire: {
        autoPaymentEnabled: messages.dwellingFirePolicyAutomaticPaymentsEnabled,
        default: messages.dwellingFire
    },
    PersonalUmbrella: {
        autoPaymentEnabled: messages.personalExcessPolicyAutomaticPaymentsEnabled,
        default: messages.personalExcess
    },
    default: {
        autoPaymentEnabled: messages.policyAutomaticPaymentsEnabled,
        default: messages.policy
    }
};

const getAnyPaidInvoices = (invoiceSummary) => {
    return _.filter(invoiceSummary, (item) => {
        const { amount } = item.totalPaid;
        return item.paidStatus === 'fullypaid' || item.invoiceStatus ==='billed' || amount !== 0;
    });
};

const getUnpaidInvoices = (invoiceSummary) => {
    return _.filter(invoiceSummary, (item) => (item.paidStatus !== 'fullypaid' ));
};

const hasUnpaidInvoices = (invoiceSummary) => {
    const unpaidInvoices = getUnpaidInvoices(invoiceSummary);
    return unpaidInvoices.length > 0;
};

const createPaymentInstrumentObject = (paymentDetailsVm, paymentMethod) => {
    const paymentDetailsValue = paymentDetailsVm.value;

    const bankAccountData = {
        ...paymentDetailsValue
    };

    const creditCardData = {
        ...paymentDetailsValue
    };

    let detailsValue;
    if (paymentMethod === 'wire') {
        detailsValue = { bankAccountData };
    } else {
        detailsValue = { creditCardData };
    }

    const paymentInstrumentValue = {
        paymentMethod,
        ...detailsValue
    };

    return paymentInstrumentValue;
};

const getPolicyLevelInvoiceStreamHeader = (lobCode, isAutoPaymentEnabled) => {
    const normalizedLOBName = lobCode//getNormalizedLOBName(lobCode);
    const lobHeader = _.has(policyLevelHeaderStrings, normalizedLOBName)
        ? policyLevelHeaderStrings[normalizedLOBName]
        : policyLevelHeaderStrings.default;

    if (isAutoPaymentEnabled) {
        return lobHeader.autoPaymentEnabled;
    }

    return lobHeader.default;
};

const getInvoiceStreamHeader = (isAccLevelStream, paymentGroupDetails, lobCode) => {
    let header = '';
    const paymentInstrumentSummary = paymentGroupDetails
        ? paymentGroupDetails.paymentInstrumentSummary
        : null;
    const isAutomaticPaymentEnabled = paymentInstrumentSummary != null;
    if (isAccLevelStream) {
        header = isAutomaticPaymentEnabled
            ? messages.invoicesAutomaticPaymentsEnabled
            : messages.invoices;
    } else {
        header = getPolicyLevelInvoiceStreamHeader(lobCode, isAutomaticPaymentEnabled);
    }
    return header;
};

function BillingSummary(props) {
    const {
        invoiceStreamId,
        accountLevelBilling,
        lobCode,
        paymentGroupDetails,
        currency,
        policySummaries,
        documentSummaries,
        paymentInformationSummaries,
        disbursementSummaries,
        authHeader,
        reloadInvoiceDetails,
        invoiceStreams,
        policyNumber
    } = props;

    const intl = useContext(IntlContext);
    const translator = useContext(TranslatorContext);

    const { AccountBillingDetailsService: BillingService } = useDependencies(
        'AccountBillingDetailsService'
    );

    // screen states
    const [isLoadingData, updateIsLoadingData] = useState(false);
    const [selectedInvoicesForPayment, updateSelectedInvoicesForPayment] = useState([]);
    const [selectedInvoiceFilter, updateSelectedInvoiceFilter] = useState('planned');
    const [selectedScreen, updateSelectedScreen] = useState('default');
    const [manualAmountToPay, updateManualAmount] = useState(null);
    const [selectedInvoiceAmount, updateSelectedInvoiceAmount] = useState(0);
    const selectedStream = invoiceStreams.find((is)=> is.internalId === invoiceStreamId );
    const { PolicyService } = useDependencies('PolicyService');

    const payingAmountValue = manualAmountToPay ? manualAmountToPay.amount : 0;
    const {
        invoiceSummary = [],
        relatedPolicies = [],
        paymentInstrument,
        paymentInstrumentSummary,
        periodicity
    } = paymentGroupDetails;

    const firstInvoiceDueDate = useMemo(() => {
        const unPaidInvoices = _.filter(invoiceSummary, (invoice) => {
            return invoice.paidStatus !== 'paid';
        });

        const firstUnpaidInvoice = _.minBy(unPaidInvoices, (invoice) => {
            return new Date(invoice.dueDate).getTime();
        });

        return firstUnpaidInvoice ? firstUnpaidInvoice.dueDate : null;
    }, [invoiceSummary]);

    const resetInternalStates = useCallback(() => {
        updateIsLoadingData(true);
        reloadInvoiceDetails(invoiceStreamId, true).finally(() => {
            updateSelectedInvoicesForPayment([]);
            updateSelectedInvoiceFilter('planned');
            updateManualAmount(null);
            updateIsLoadingData(false);
        });
    }, [reloadInvoiceDetails, invoiceStreamId]);

    const onClickConfirmMakePayment = useCallback(
        (paymentDetailsVm, paymentMethod) => {
            const paymentInstrumentValue = createPaymentInstrumentObject(
                paymentDetailsVm,
                paymentMethod
            );

            BillingService.makeDirectBillPayment(
                selectedInvoicesForPayment,
                manualAmountToPay.amount,
                paymentInstrumentValue,
                authHeader
            )
                .then(() => {
                    updateSelectedScreen('paymentConfirmation');
                })
                .catch(() => {
                    ModalNextProvider.showAlert({
                        title: messages.paymentRequestFailed,
                        message: messages.sorryYourPaymentCouldNotBeProcessedAtThisTime,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).then(() => {
                        resetInternalStates();
                    }, _.noop);
                });
        },
        [BillingService, selectedInvoicesForPayment, manualAmountToPay,
            authHeader, resetInternalStates]
    );

    const onClickCancelMakePayment = useCallback(() => {
        updateSelectedScreen();
    }, []);

    const onClickConfirmSetupAutomaticPayment = useCallback(
        (paymentDetailsVm, paymentMethod) => {
            const paymentDetailsValue = paymentDetailsVm.value;

            const bankAccountData = {
                ...paymentDetailsValue
            };

            const creditCardData = {
                ...paymentDetailsValue
            };

            let detailsValue;
            if (paymentMethod === 'wire') {
                detailsValue = { bankAccountData };
            } else {
                detailsValue = { creditCardData };
            }

            const paymentInstrumentValue = {
                paymentMethod,
                ...detailsValue
            };

            BillingService.setPaymentGroupPaymentInformation(
                invoiceStreamId,
                paymentInstrumentValue,
                authHeader
            )
                .then(() => {
                    updateSelectedScreen('automaticConfirmation');
                })
                .catch(() => {
                    ModalNextProvider.showAlert({
                        title: messages.accountUpdateFailed,
                        message: messages.sorryWeWereNotAbleToSetupAutomaticPaymentsOnYourAccount,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).then(() => {
                        resetInternalStates();
                    }, _.noop);
                });
        },
        [BillingService, invoiceStreamId, authHeader, resetInternalStates]
    );

    const onClickCancelSetupAutomaticPayment = useCallback(() => {
        updateSelectedScreen();
    }, []);

    const getDataTableCell = useCallback((item, index, { path: property }) => {
        return item[property];
    });
    const getFormattedDate = useCallback((item, index, property) => {
        return intl.formatDate(new Date(item[property.id]),{ year: 'numeric', month: '2-digit',day: '2-digit' });
        
    }, [intl]);

    const onClickConfirmChangePaymentSource = useCallback(
        (paymentDetailsVm, paymentMethod) => {
            const paymentInstrumentValue = createPaymentInstrumentObject(
                paymentDetailsVm,
                paymentMethod
            );

            BillingService.setPaymentGroupPaymentInformation(
                invoiceStreamId,
                paymentInstrumentValue,
                authHeader
            )
                .then(() => {
                    updateSelectedScreen('paymentSourceConfirmation');
                })
                .catch(() => {
                    ModalNextProvider.showAlert({
                        title: messages.changingPaymentMethod,
                        message: messages.thereWasAProblemChangingThePaymentMethod,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).then(() => {
                        resetInternalStates();
                    }, _.noop);
                });
        },
        [BillingService, invoiceStreamId, authHeader, resetInternalStates]
    );

    const onClickCancelChangePaymentSource = useCallback(() => {
        updateSelectedScreen();
    }, []);

    const onUpdateSelectedInvoices = useCallback(
        (selectedInvoiceSummariesId) => {
            const selectedInvoiceObjects = _.filter(invoiceSummary, (anInvoiceSummary) => {
                return selectedInvoiceSummariesId.includes(anInvoiceSummary.invoiceId);
            });

            const selectedTotalAmount = _.sumBy(selectedInvoiceObjects, (invoice) => {
                return Number(_.get(invoice, 'amountDue.amount'));
            }).toFixed(2);

            updateSelectedInvoiceAmount(selectedTotalAmount);
            const amountObject = {
                amount: selectedTotalAmount,
                currency: currency
            };

            updateManualAmount(amountObject);
            updateSelectedInvoicesForPayment(selectedInvoiceSummariesId);
        },
        [invoiceSummary, currency]
    );

    const openbillTrustPage = useCallback(() => {
        PolicyService.getBillTrustUrl_Ext(policyNumber, authHeader).then((bData)=>{
            if(bData && bData.loginPage &&  bData.id ){
                const url =  `${bData.loginPage}?spentityid=${bData.id}`; 
                window.open(url,'_blank')
            }
        })
    },[policyNumber, authHeader]);

    const onClickMenuLink = useCallback(
        (evt) => {
            const { id: menuLinkId } = evt.currentTarget;
            let screen;
            switch (menuLinkId) {
                case 'makeAPaymentLinkId':
                    screen = 'payment';
                    break;
                case 'setupAutomaticPaymentsLinkId':
                    screen = 'automatic';
                    break;
                case 'changePaymentMethodLinkId':
                    screen = 'payment-source';
                    break;
                case 'viewPoliciesLinkId':
                    screen = 'policy-list';
                    break;
                case 'viewInvoicesLinkId':
                    screen = 'policy-list';
                    break;
                case 'viewInvoicesLinkId1':
                case 'default':
                    screen = 'default';
                    break;
                default:
                    screen = 'default';
                    resetInternalStates();
                    break;
            }
            updateSelectedScreen(screen);
        },
        [resetInternalStates]
    ); 

    const onClickFilterChange = useCallback((value) => {
        updateSelectedInvoiceFilter(value);
    }, []);

    const isAmountToPayValid = useCallback((amount) => {
        return selectedInvoiceAmount < amount || amount === 0 || _.isNull(amount);
    }, [selectedInvoiceAmount]);

    const getClaimLink= useCallback(() => {
        return (
            <div>
             <br></br>
             <p style={{color:"red"}}>This policy period is in delinquency</p>
            </div> 
        );
        },[]);


    useEffect(() => {
        const defaultFilter = hasUnpaidInvoices(invoiceSummary) ? 'planned' : 'paid';
        updateSelectedInvoiceFilter(defaultFilter);
    }, [invoiceSummary]);

    if (isLoadingData) {
        return <Loader loaded={!isLoadingData} />;
    }

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        totalBilled:{
            content:_.get(selectedStream,'totalBilled_Ext')!="0.00"?`$${_.get(selectedStream,'totalBilled_Ext')}`:`-`,
            visible:false
        },
        cancellationAmount:{
            content: _.get(selectedStream,'cancellationAmountDue_Ext')!="0.00"?`$${_.get(selectedStream,'cancellationAmountDue_Ext')}`:`-`
        },
         current:{
            content: _.get(selectedStream,'currentDue_Ext')>"0.00"?`$${_.get(selectedStream,'currentDue_Ext')}`:`-`
         },
         pastDue:{
            content: _.get(selectedStream,'pastDue_Ext')!="0"?`$${ _.get(selectedStream,'pastDue_Ext')}`:`-`
         },
        balanceAmount:{
           content: _.get(selectedStream,'totalPaid_Ext')!="0.00"?`$${_.get(selectedStream,'totalPaid_Ext')}`:`-`
        },
         billingMethod:{
            content: _.get(selectedStream,'billingMethod_Ext'),
            visible:false
         },
         billingMethodLabel:{
             visible:false
         },
        sendInvoiceBy:{
            content: _.get(selectedStream,'invoiceDeliveryType_Ext')
         },
         paymentPlan:{
            content: _.get(selectedStream,'paymentPlan_Ext')
         },
         payerName:{
            content: _.get(selectedStream,'payerName_Ext')
         },
         unBilled:{
            content: _.get(selectedStream,'unBilled_Ext')!="0.00"?`$${_.get(selectedStream,'unBilled_Ext')}`:`-`,
            visible:false
         },
         unBilledLabel:{
        visible:false
         },
         unPaidBalance:{
            content: _.get(selectedStream,'unpaidBalance_Ext')!="0.00"?`$${_.get(selectedStream,'unpaidBalance_Ext')}`:`-`
         },
         balanceDate:{
            content: _.get(selectedStream,'totalCharges_Ext')!="0.00"?`$${_.get(selectedStream,'totalCharges_Ext')}`:`-`
         },
         accessID:{
              content: getClaimLink(),
              visible: _.get(selectedStream,'delinquentIndicator_Ext')
         },
        menuLinkContainerId: {
            visible: (invoiceStreamId || accountLevelBilling) && !(selectedScreen === 'automaticConfirmation')
        },
        makeAPaymentLinkId: {
            visible: !paymentInstrumentSummary && hasUnpaidInvoices(invoiceSummary)
        },
        viewPoliciesLinkId: {
            visible: accountLevelBilling
        },
        changePaymentMethodLinkId: {
            visible: !!paymentInstrumentSummary
        },
        setupAutomaticPaymentsLinkId: {
            visible: false
        },
        billingInvoicesContainerId: {
            visible: selectedScreen === 'default' && !!invoiceStreamId
        },
        billingInvoicesHeaderId: {
            content: getInvoiceStreamHeader(accountLevelBilling, paymentGroupDetails, lobCode),
            visible: invoiceStreamId || accountLevelBilling
        },
        invoicesFilterToggleId: {
            onValueChange: onClickFilterChange
        },
        billingInvoicesComponentId: {
            invoiceSummary: getUnpaidInvoices(invoiceSummary),       
            showPaidDate: selectedInvoiceFilter === 'paid',
            showPolicyNumber: selectedInvoiceFilter === 'paid',
            showInvoiceNumber: selectedInvoiceFilter !== 'paid',
            showDueDate: selectedInvoiceFilter !== 'paid',
            showPaidAmount: selectedInvoiceFilter ==='paid',
            showInvoiceValue: selectedInvoiceFilter !=='paid',
        },
        infoTable:{
             data:disbursementSummaries
        },
        payinfoTable:{
            data:paymentInformationSummaries
       },
        policyscheduleInfoContainer:{
            visible: false
        },
        makePaymentContainerId: {
            visible: selectedScreen === 'payment'
        },
        makePaymentInvoicesTableComponentId: {
            invoiceSummary: getUnpaidInvoices(invoiceSummary),
            selectedInvoices: selectedInvoicesForPayment,
            onUpdateSelectedInvoices: onUpdateSelectedInvoices
        },
        makePaymentSourceComponentId: {
            title: messages.setPaymentSource,
            xCenter: 'bc',
            model: paymentInstrument,
            isDisabled: selectedInvoicesForPayment.length === 0,
            isSelectedInvoiceAmount: manualAmountToPay
                && isAmountToPayValid(manualAmountToPay.amount)
        },
        makePaymentAmountToPayId: {
            defaultCurrency: currency,
            disabled: selectedInvoicesForPayment.length === 0,
            onValueChange: updateManualAmount
        },
        makePaymentConfirmationContainerId: {
            visible: selectedScreen === 'paymentConfirmation'
        },
        makePaymentConfirmationMessageId: {
            content: translator(messages.paymentOfHasBeenAppliedToYourAccount, {
                paidAmount: intl.formatNumber(
                    payingAmountValue,
                    {
                        style: 'currency',
                        currency: currency,
                        currencyDisplay: 'code'
                    }
                )
            })
        },
        setUpAutomaticPaymentContainerId: {
            visible: selectedScreen === 'automatic'
        },
        setupAutomaticPaymentComponentId: {
            xCenter: 'bc',
            model: paymentInstrument
        },
        setUpAutomaticPaymentConfirmationContainerId: {
            visible: selectedScreen === 'automaticConfirmation'
        },
        setUpAutomaticPaymentConfirmationInfoId: {
            content: translator(messages.yourAutomaticPaymentsWillBeginOn, {
                startingDate: intl.formatDate(new Date(firstInvoiceDueDate), { year: 'numeric', month: 'short', day: 'numeric' })
            })
        },
        changePaymentSourceContainerId: {
            visible: selectedScreen === 'payment-source'
        },
        changePaymentSourceFormComponentId: {
            title: messages.changePaymentMethod,
            xCenter: 'bc',
            model: paymentInstrument
        },
        changePaymentSourceConfirmationContainerId: {
            visible: selectedScreen === 'paymentSourceConfirmation'
        },
        policyListContainerId: {
            visible: selectedScreen === 'policy-list1'
        },
        billingListDocumentsContainerId:{
            visible: selectedScreen === 'policy-list'
        },
        viewPoliciesComponentId: {
            policySummaries: policySummaries,
            documentSummaries:documentSummaries,
            policyNumbers: relatedPolicies
        },
        totalBilledLabel:{
            visible:false
        }
    };

    const dataForComponent = {
        invoiceStreamId,
        selectedInvoiceFilter,
        periodicity,
        startingDate: firstInvoiceDueDate,
        manualAmountToPay
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            paymentpagecomponent: PaymentComponent
        },
        resolveCallbackMap: {
            onClickMenuLink,
            onClickConfirmMakePayment,
            onClickCancelMakePayment,
            onClickConfirmSetupAutomaticPayment,
            onClickCancelSetupAutomaticPayment,
            onClickConfirmChangePaymentSource,
            onClickCancelChangePaymentSource,
            openbillTrustPage,
            getDataTableCell :  getDataTableCell,
            getFormattedDate : getFormattedDate
        }
    };

    return (
        <MetadataForm
            data={dataForComponent}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

BillingSummary.propTypes = {
    invoiceStreamId: PropTypes.string,
    accountLevelBilling: PropTypes.bool,
    lobCode: PropTypes.string,
    paymentGroupDetails: PropTypes.shape({
        invoiceSummary: PropTypes.array,
        relatedPolicies: PropTypes.array,
        paymentInstrument: PropTypes.object,
        paymentInstrumentSummary: PropTypes.object,
        periodicity: PropTypes.string
    }),
    currency: PropTypes.string.isRequired,
    policySummaries: PropTypes.arrayOf(PropTypes.object),
    documentSummaries: PropTypes.arrayOf(PropTypes.object),
    disbursementSummaries: PropTypes.arrayOf(PropTypes.object), 
    paymentInformationSummaries: PropTypes.arrayOf(PropTypes.object),
    authHeader: PropTypes.shape({}).isRequired,
    reloadInvoiceDetails: PropTypes.func.isRequired,
    invoiceStreams: PropTypes.arrayOf(PropTypes.object)

};

BillingSummary.defaultProps = {
    invoiceStreamId: null,
    accountLevelBilling: null,
    lobCode: null,
    paymentGroupDetails: {
        invoiceSummary: [],
        relatedPolicies: [],
        paymentInstrument: null,
        paymentInstrumentSummary: null,
        periodicity: null
    },
    policySummaries: [],
    documentSummaries:[],
    disbursementSummaries:[],
    paymentInformationSummaries:[],
    invoiceStreams:[]
};

export default withAuthenticationContext(BillingSummary);
