import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps, WizardPageTemplate } from 'gw-portals-wizard-react';
import { fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { useValidation } from 'gw-portals-validation-react';
import { RepairFacilitiesMap } from 'gw-capability-vendor-react';
import { loadGoogleMapsAPI } from 'gw-portals-google-maps-js';
import './RepairFacilityMapPage.messages';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './RepairFacilityMapPage.metadata.json5';

function FNOLRepairFacilityMapPage(props) {
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const [isMapLoaded, updateMapLoaded] = useState(!!window.google);
    const { wizardData: claimVM, updateWizardData, goNext } = props;
    const {
        isComponentValid,
        initialValidation,
        registerComponentValidation,
        registerInitialComponentValidation
    } = useValidation('RepairFacilityMapPage');

    const updateSelectedRepairFacility = useCallback(
        (item) => {
            _.set(claimVM, 'lobs.personalAuto.repairOption.repairFacility.value', item);
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData]
    );

    const isLocationSelected = useCallback(() => {
        return _.get(claimVM, 'lobs.personalAuto.repairOption.repairFacility.value');
    }, [claimVM]);

    useEffect(() => {
        registerComponentValidation(isLocationSelected);
        registerInitialComponentValidation(isLocationSelected);
    }, [isLocationSelected, registerComponentValidation, registerInitialComponentValidation]);

    useEffect(() => {
        // Manullay loading google map api for RepairFacilityMapPage.jsx
        if (!window.google) {
            loadGoogleMapsAPI().then(() => {
                window.googleMapsAPILoadedManually = true;
                updateMapLoaded(true);
            });
        }
    }, []);

    const overrides = {
        repairFacilities: {
            selectRepairFacilityCallback: updateSelectedRepairFacility,
            claimVM: claimVM.value,
            lossCause: _.get(claimVM.value, 'lossCause'),
            contactName: _.get(claimVM.value, 'lobs.personalAuto.repairOption.repairFacility.contactName', ''),
            goNext,
            visible: isMapLoaded
        },
        loadingIndicator: {
            visible: !isMapLoaded
        }
    };

    const resolvers = {
        resolveComponentMap: {
            repairfacilitiesmap: RepairFacilitiesMap
        }
    };
    return (
        <WizardPage
            template={WizardPageTemplate}
            skipWhen={initialValidation}
            showNext={breakpoint !== 'phone'}
            disableNext={!isComponentValid}
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrides}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

FNOLRepairFacilityMapPage.propTypes = wizardProps;
export default FNOLRepairFacilityMapPage;
