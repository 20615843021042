import { defineMessages } from 'react-intl';

export default defineMessages({
    signIn: {
        id: 'auth.components.LockLogin.Sign In',
        defaultMessage: 'Sign In'
    },
    login: {
        id: 'auth.components.LockLogin.Login',
        defaultMessage: 'Login'
    },
    logout: {
        id: 'auth.components.LockLogin.Logout',
        defaultMessage: 'Logout'
    },
    username: {
        id: 'auth.components.LockLogin.Username:',
        defaultMessage: 'Username:'
    },
    password: {
        id: 'auth.components.LockLogin.Password:',
        defaultMessage: 'Password:'
    },
    fieldEmpty: {
        id: 'auth.components.LockLogin.Enter username and password to sign in.',
        defaultMessage: 'Enter username and password to sign in.'
    },
    fieldsIncorrect: {
        id: 'auth.components.LockLogin.Username or password is incorrect.',
        defaultMessage: 'Username or password is incorrect.'
    },
    invalidAttempt: {
        id: 'auth.components.LockLogin.Invalid login attempt.',
        defaultMessage: 'Invalid login attempt.'
    },
    forgotYourPassword: {
        id: 'auth.components.LockLogin.Forgot Password',
        defaultMessage: 'Forgot Password'
    },
    forgotUserName: {
        id: 'auth.components.LockLogin.Forgot Username',
        defaultMessage: 'Forgot Username'
    },
    accountLocked: {
        id: 'auth.components.LockLogin.For security reasons your account is temporarily locked.',
        defaultMessage: 'For security reasons your account is temporarily locked.'
    },
    accountLocked: {
        id: 'auth.components.LockLogin.Your account is already locked, please try after some time',
        defaultMessage: 'Your account is locked.  Please try again after 5 minutes or use the Forgot Password link below.'
    },
});
