import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import { CheckboxField } from '@jutro/components';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { IntlContext } from '@jutro/locale';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import metadata from './InvoicesTable.metadata.json5';
import styles from './InvoicesTable.module.scss';
import messages from './InvoicesTable.messages';
import _ from 'lodash';

const getBillStatus = (item, index, property) => {
    if (item.paidStatus === 'fullypaid') {
        return messages.paid;
    }
    return {
        id: `typekey.InvoiceStatus.${item[property.id]}`,
        defaultMessage: item[property.id]
    };
};

const getPolicyNumber = (item, index, property) => {
    return item[property.id]
};

const renderFormattedCurrencyField = (item, index) => {
    return (
        <div className={styles.currencyContainer}>
            <CurrencyField
                id={`currency_${index}`}
                value={item}
                readOnly
                hideLabel
            />
        </div>
    );
};

const getFormattedCurrency = (item, index, property) => {
    return renderFormattedCurrencyField(item[property.id]);
};

const getCell = (item, index, property) => {
    return item[property.id];
};

function InvoicesTable(props) {
    const {
        invoiceSummary,
        selectedInvoices,
        onUpdateSelectedInvoices,
        showPaidDate,
        showPolicyNumber,
        showSelect,
        showInvoiceNumber,
        showDueDate,
        showPaidAmount,
        showInvoiceValue,
    } = props;


    
    const intl = useContext(IntlContext);

    const getFormattedDate = useCallback((item, index, property) => {
        if(item[property.id] === null || item[property.id] === undefined){
            return "-"
        }else{
        return intl.formatDate(new Date(item[property.id]));
        }
    }, [intl]);

    const sortedInvoices = useCallback((invoiceSummary)=>{
      const sortedInvoices = _.orderBy(invoiceSummary,['dueDate'],['asc'])
      return sortedInvoices
      
    },[invoiceSummary]);

    const getCheckboxDataCell = useCallback(
        (item, index, property) => {
            const value = item[property.id];
            const selectedIds = [...selectedInvoices];
            const isChecked = selectedIds.indexOf(value) > -1;
            return (
                <CheckboxField
                    id={`${property.id}_${index}`}
                    name={`${property.id}_${index}`}
                    value={isChecked}
                    onValueChange={(selected) => {
                        if (selected) {
                            selectedIds.push(value);
                            onUpdateSelectedInvoices(selectedIds);
                        } else {
                            const updated = selectedIds.filter((iter) => iter !== value);
                            onUpdateSelectedInvoices(updated);
                        }
                    }}
                />
            );
        },
        [selectedInvoices, onUpdateSelectedInvoices]
    );

    const overrideProps = {
        tablePaymentScheduleDetail: {
            data: sortedInvoices(invoiceSummary)
        },
        tablePaymentScheduleDetailLastPaymentDate: {
            visible: showPaidDate
        },
        tablePaymentScheduleDetailPay: {
            visible: showSelect
        },
         tablePaymentScheduleDetailPolicyNumber:{
             visible:showPolicyNumber
         },
         tablePaymentScheduleDetailInvoiceNumber:{
              visible:showInvoiceNumber
         },
         tablePaymentScheduleDetailDueDate:{
            visible:showDueDate
         },
         tablePaymentScheduleDetailBillStatus:{
             visible:false
         },
         tablePaymentScheduleDetailTotalPaid:{
             visible:showPaidAmount
         },
         tablePaymentScheduleDetailOutstandingAmount:{
             visible:false
         },
         tablePaymentScheduleDetailTotalValue:{
             visible:showInvoiceValue
         }

        
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getFormattedDate,
            getBillStatus,
            getFormattedCurrency,
            getCell,
            getCheckboxDataCell,
            getPolicyNumber,
        }
    };

    return renderContentFromMetadata(metadata.componentContent, overrideProps, resolvers);
}

InvoicesTable.propTypes = {
    invoiceSummary: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    selectedInvoices: PropTypes.arrayOf(PropTypes.string),
    onUpdateSelectedInvoices: PropTypes.func,
    showSelect: PropTypes.bool,
    showPaidDate: PropTypes.bool,
    showPolicyNumber: PropTypes.bool,
    showInvoiceNumber: PropTypes.bool,
    showDueDate:PropTypes.bool,
    showPaidAmount:PropTypes.bool, 
    showInvoiceValue:PropTypes.bool,
};

InvoicesTable.defaultProps = {
    selectedInvoices: [],
    onUpdateSelectedInvoices: undefined,
    showSelect: false,
    showPaidDate: false,
    showPolicyNumber:false,
    showPaidAmount:false,
    showInvoiceNumber:false,
    showInvoiceValue:false,
    showDueDate:false,
};

export default InvoicesTable;
