import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class EnrollmentService {
    /**
    * Enrolls a new policy or account for the current user
    *
    * @param {Object} data enrollment data
    * @param {Object} additionalHeaders additional headers to pass to the backend (e.g. auth)
    * @returns {Promise} the promise from the backend call
    */
    static addEnrollmentRecord(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policyEnrollment'), 'addEnrollmentRecord', [data], additionalHeaders);
    }

    static addEnrollmentRecord_Ext(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policyEnrollment'), 'addEnrollmentRecord_Ext', [data], additionalHeaders);
    }
   
}
