import React, { useCallback, useContext, useState, useMemo } from "react";
import { HashLink as Link } from "react-router-hash-link";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import classNames from "classnames";
import { renderContentFromMetadata } from "@jutro/uiconfig";
import PropTypes from "prop-types";
import { TranslatorContext } from "@jutro/locale";
import { FaqSectionComponent } from "gw-components-platform-react";
// eslint-disable-next-line import/no-unresolved
import faqConfig from "faq-config";
import metadata from "./FaqPage.metadata.json5";
import styles from "./FaqPage.module.scss";
import messages from "./FaqPage.messages";

import {
  Icon,
  ModalNextProvider,
  Loader,
  Link as DownloadLink
} from '@jutro/components';
function FaqSideBar(props) {
  const translator = useContext(TranslatorContext);
  const { value, enabled } = props;
  const { id, title } = value;
  const sidebarLinkStyle = classNames(styles.wizardLi, {
    [styles.wizardLiDisabled]: !enabled,
  });
  return (
    <li key={id} className={sidebarLinkStyle}>
      <Link to={`/faq#${id}`}>{translator(title)}</Link>
    </li>
  );
}
function checkSafeMatching(phrase = "", query = "") {
  const htmlStrippedPhrase = _.unescape(phrase.replace(/<[^<>]+>/gi, ""));
  return htmlStrippedPhrase.toLocaleLowerCase().includes(query.toLowerCase());
}
const searchTopic = (topic, query, translator) => {
  const questionMatching = checkSafeMatching(
    translator(_.find(messages, { id: topic.question })),
    query
  );
  const answerMatching = checkSafeMatching(
    translator(_.find(messages, { id: topic.long })),
    query
  );
  const linkMatching = checkSafeMatching(
    translator(_.find(messages, { id: _.get(topic, "link.name", "") })),
    query
  );
  return questionMatching || answerMatching || linkMatching;
};
const filterTopics = (topics, query, translator) => {
  return topics.filter((topic) => searchTopic(topic, query, translator));
};
const getFilteredSections = (sections, query, translator) => {
  return sections
    .map((section) => {
      return {
        ...section,
        topics: filterTopics(section.topics, query, translator),
      };
    })
    .filter((section) => {
      return !_.isEmpty(section.topics);
    });
};
function FaqPage() {
  const translator = useContext(TranslatorContext);
  const [formData, setFormData] = useState({ searchTopic: "" });
  const [returnToFnol, setReturnToFnol] = useState(false);
  const filteredSections = useMemo(() => {
    return getFilteredSections(
      faqConfig.sections,
      formData.searchTopic,
      translator
    );
  }, [formData.searchTopic, translator]);
  const readValue = useCallback(
    (id, path = "") => {
      if (id === "searchTopicField") {
        return _.get(formData, path);
      }
      if (path && path.includes("filteredSections")) {
        const sectionPath = path.replace("filteredSections", "sections");
        const newFaqObject = { sections: filteredSections };
        return _.get(newFaqObject, sectionPath);
      }
      return _.get(faqConfig, path);
    },
    [filteredSections, formData]
  );
  const writeValue = useCallback(
    (value, path) => {
      const formDataClone = _.cloneDeep(formData);
      _.set(formDataClone, path, value);
      setFormData(formDataClone);
    },
    [formData, setFormData]
  );
  const generatedOverrides = useCallback(() => {
    const searchTopicText = _.get(formData, "searchTopic");
    const sectionOverrides = faqConfig.sections.map((section, index) => {
      const linkEnabled = filteredSections.some(
        (item) => item.id === section.id
      );
      return {
        [`faqSectionContainer${index}`]: {
          searchText: searchTopicText,
          messages: messages,
        },
        [`faqSidebarSectionPageTitle${index}`]: { enabled: linkEnabled },
      };
    });
    return Object.assign({}, ...sectionOverrides);
  }, [filteredSections, formData]);
  const getClaim = useCallback(() => {
    return (
      <div>
        <p>Contact your agent for help with the following:</p>
        <ul>
          <li className={styles.label}>
            Updating your mailing address or policy address.{" "}
          </li>
          <br></br>
          <li className={styles.label}>
            Updating limits or deductibles on any of your policies.
          </li>
          <br></br>
          <li className={styles.label}>
            Adding/deleting a vehicle or drivers on your auto policy.
          </li>
          <br></br>
          <li className={styles.label}>Updating your billing payment plan.</li>
          <br></br>
          <li className={styles.label}>
            Updating your billing invoice due dates.
          </li>
          <br></br>
          <li className={styles.label}>
            Enroll in NLC’s Vuebill payment website and set up automatic
            payments.
          </li>
          <br></br>
          <li className={styles.label}>
            Understanding why your policy premium or bill has changed.
          </li>
          <br></br>
          <li className={styles.cancellabel}>Canceling your policy.</li>
        </ul>
      </div>
    );
  }, []);
  const getClaimLink = useCallback(() => {
    var state = {
      redirectPath: "/home",
      claimData: { lossDate: new Date() },
    };
    return (
      <div>
        <p>Need help reporting a claim? Select from the list below:</p>
        <ul>
          <li className={styles.label}>
            Submit a claim online{" "}
            <Link onClick={onFileAClaimQuickLinkClick()}>Click Here</Link> to
            begin!
          </li>
          <br></br>
          <li className={styles.label}>
            Call our claims department at: 800-962-0800
          </li>
          <br></br>
          <li className={styles.label}>Fax: 860-886-8270</li>
          <br></br>
          <li className={styles.cancellabel}>
            <b>AFTER HOURS EMERGENCY CLAIMS </b> CALL 800-962-0800
          </li>
        </ul>
        <p>
          Need help finding information on an existing claim? Claim information
          can be found on the <Link to={{ pathname: "/claims" }}>Claims</Link>{" "}
          tab of the Consumer Portal. Contact your agent or NLC Customer Service
          at 800-962-0800 for further assistance.
        </p>
        <br></br>
      </div>
    );
  }, []);
  const onFileAClaimQuickLinkClick = useCallback(
    () => () => {
      setReturnToFnol(true);
    },
    []
  );
  const getOneTimePaymentLink = useCallback(() => {
    return (
      <div className={styles.labelPayment}>
        <br></br>
        <p>
          <Link to={{ pathname: "/billing-summary" }}>
            Make a one-time payment{" "}
          </Link>
          using a major credit card, a checking account, or savings account.
        </p>
      </div>
    );
  }, []);
  const getAccessIDLink = useCallback(() => {
    return (
      <div className={styles.labelNew}>
        <br></br>
        <p>
          Access your ID cards by clicking on the Print ID link next to your
          auto policy on the <Link to={{ pathname: "/home" }}>Home</Link> tab.
        </p>
      </div>
    );
  }, []);
  const getPolicyDocLink = useCallback(() => {
    return (
      <div className={styles.labelNew}>
        <p>
          View policy documents by selecting your policy from the{" "}
          <Link to={{ pathname: "/home" }}>Home</Link> tab. Under Policy
          Documents you will find declaration pages for up to three policy
          terms.
        </p>
      </div>
    );
  }, []);
  const getBillingLink = useCallback(() => {
   // NLCSS-66 Customer Support - Billing Statements text
    return (
      <div className={styles.labelNew}>
        <p>
          View billing statements by selecting your policy from{" "}
          <Link to={{ pathname: "/billing-summary" }}>Billing</Link> tab and
          click on View Documents. <b>This will display statements for two policy terms.</b>
        </p>
      </div>
    );
   // Ends
  }, []);

  const getVueBill = useCallback(() => {
    return (
      <div className={styles.labelstatic}>
        <p>
          Register your policy on NLC’s <u> <DownloadLink href="https://www.vuebill.com/nlc/Login.aspx?ReturnUrl=%2fnlc%2f" target="_blank">Vuebill</DownloadLink> </u> payment website to set up automatic payments using a major credit card, a checking account, or savings account. Have your current billing statement available to help with the enrollment process. 
        </p>
      </div>
    );
  }, []);

    const overrideProps = {
    "@field": {
      onValueChange: writeValue,
    },
    staticCliamLink: {
      content: getClaimLink(),
    },
    staticFieldID: {
      content: getClaim(),
    },
    oneTimePayment: {
      content: getOneTimePaymentLink(),
    },
    accessID: {
      content: getAccessIDLink(),
    },
    policyDocuments: {
      content: getPolicyDocLink(),
    },
    billingTab: {
      content: getBillingLink(),
    },
    staticFieldij:{
      content:getVueBill()
    },
    ...generatedOverrides(),
  };
  const resolvers = {
    resolveValue: readValue,
    resolveComponentMap: {
      faqsidebar: FaqSideBar,
      faqsectioncomponent: FaqSectionComponent,
    },
    resolveClassNameMap: styles,
  };
  if (returnToFnol) {
    const data = {
      pathname: "/fnol-select-policy",
      state: {
        redirectPath: "/home",
        claimData: { lossDate: new Date() },
      },
    };
    return <Redirect to={data} />;
  }
  return (
    <div>
      {renderContentFromMetadata(
        metadata.pageContent,
        overrideProps,
        resolvers
      )}
    </div>
  );
}
FaqSideBar.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  enabled: PropTypes.bool.isRequired,
};
export default FaqPage;
