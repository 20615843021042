import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import styles from '../RepairFacilitiesMap.module.scss';
import messages from '../RepairFacilitiesSearch/RepairFacilitiesSearch.messages';

function RepairFacilitiesSearchOption(props) {
    const {
        mapsApi, currentMap, policyLocation, lossLocation
    } = props;

    const goToPolicy = useCallback(() => {
        const { geocode } = policyLocation;
        if (geocode) {
            currentMap.panTo(
                new mapsApi.LatLng(geocode.latitude, geocode.longitude)
            );
        }
    }, [currentMap, mapsApi.LatLng, policyLocation]);

    const goToIncident = useCallback(() => {
        const { geocode } = lossLocation;
        if (geocode) {
            currentMap.panTo(
                new mapsApi.LatLng(geocode.latitude, geocode.longitude)
            );
        }
    }, [currentMap, lossLocation, mapsApi.LatLng]);

    const staticLocations = useMemo(() => {
        return [{
            type: 'policy',
            handler: goToPolicy,
            icon: 'mi-home',
            label: messages.policyAddress
        }, {
            type: 'incident',
            handler: goToIncident,
            icon: 'mi-directions_car',
            label: messages.incident
        }];
    }, [goToPolicy, goToIncident]);

    return (
        <TranslatorContext.Consumer>
            {(translator) => {
                return (
                    <div className={styles.searchOptionsContainer}>
                        <span>{translator(messages.searchNear)}</span>
                        {staticLocations.map((location) => {
                            return (
                                <div
                                    className={styles.mapOptions}
                                    onClick={location.handler}
                                    role="presentation"
                                >
                                    <div className={styles[location.type]}>
                                        <Icon icon={location.icon} />
                                    </div>
                                    <span className={styles.label}>
                                        {translator(location.label)}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                );
            }}
        </TranslatorContext.Consumer>
    );
}

RepairFacilitiesSearchOption.propTypes = {
    currentMap: PropTypes.shape({
        panTo: PropTypes.func.isRequired,
        getCenter: PropTypes.func
    }).isRequired,
    mapsApi: PropTypes.shape({
        LatLng: PropTypes.func.isRequired
    }).isRequired,
    policyLocation: PropTypes.shape({
        geocode: PropTypes.shape({
            latitude: PropTypes.number.isRequired,
            longitude: PropTypes.number.isRequired
        })
    }).isRequired,
    lossLocation: PropTypes.shape({
        geocode: PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number
        })
    })
};

RepairFacilitiesSearchOption.defaultProps = {
    lossLocation: {
        latitude: null,
        longitude: null
    }
};

export default RepairFacilitiesSearchOption;
