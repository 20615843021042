import {
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import CustomMarkerFactory from './CustomMarker/CustomMarkerFactory';
import stylesList from './RepairFacilitiesList/RepairFacilitiesList.module.scss';
import MarkerStyle from './MarkerStyle';

const DEFAULT_MAP_ZOOM = 13;
const REPAIR_MAP_DELAY = 200;
let markersArray = [];
let activeInfoWindow = false;

function useRepairFacilityMap() {
    if (!window.google || !window.google.maps) {
        throw new Error('google map api not found');
    }
    const mapsApi = window.google.maps;
    const [map, setMap] = useState({});
    const [zoomLevel, setZoomLevel] = useState(DEFAULT_MAP_ZOOM);
    const [mapCenter, setMapCenter] = useState({
        latitude: appConfig.defaultLocaleDetails.mapCenter.lat,
        longitude: appConfig.defaultLocaleDetails.mapCenter.lon
    });
    const [myLocation, setMyLocation] = useState({});
    const [mapElement, setMapElement] = useState('');

    // map event listners
    const initListners = useCallback((mapInstance) => {
        mapInstance.addListener('zoom_changed', () => {
            mapsApi.event.addListenerOnce(mapInstance, 'idle', () => {
                setZoomLevel(() => mapInstance.getZoom());
            });
        });

        const reloadDebounceOptions = {
            leading: false,
            trailing: true
        };
        const onDragendCallback = () => {
            const mapCenterPoint = mapInstance.getCenter();
            setMapCenter({
                latitude: mapCenterPoint.lat(),
                longitude: mapCenterPoint.lng()
            });
        };

        const onDragend = _.debounce(
            onDragendCallback,
            REPAIR_MAP_DELAY * 2,
            reloadDebounceOptions
        );

        mapsApi.event.addListener(mapInstance, 'dragend', () => {
            mapsApi.event.addListenerOnce(mapInstance, 'idle', () => {
                onDragend();
            });
        });
    }, [mapsApi]);

    const initMap = useCallback(() => {
        const { latitude, longitude } = mapCenter;
        if (!mapElement) {
            return;
        }
        const DOMElement = document.getElementById(mapElement);
        const mapInstance = new mapsApi.Map(DOMElement, {
            center: new mapsApi.LatLng(latitude, longitude),
            zoom: DEFAULT_MAP_ZOOM,
            clickableIcons: false,
            streetViewControl: false,
            gestureHandling: 'greedy',
            zoomControl: true,
            zoomControlOptions: {
                position: mapsApi.ControlPosition.RIGHT_BOTTOM
            },
            mapTypeControlOptions: {
                style: mapsApi.MapTypeControlStyle.HORIZONTAL_BAR,
                position: mapsApi.ControlPosition.LEFT_BOTTOM,
                mapTypeIds: ['roadmap', 'satellite']
            }
        });
        setMap(() => mapInstance);
        initListners(mapInstance);
    }, [mapCenter, mapsApi, mapElement, initListners]);

    const getCurrentLocation = useCallback(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                // user current location
                setMyLocation(() => {
                    return {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    };
                });
            });
        } else {
            // usefull warning
            // eslint-disable-next-line no-console
            console.warn('Geolocation is not supported for this browser/OS.');
        }
    }, []);

    const createInfoWindow = useCallback((content, geoCode) => {
        return new mapsApi.InfoWindow({
            content: content,
            position: new mapsApi.LatLng(geoCode.latitude, geoCode.longitude)
        });
    }, [mapsApi]);

    const moveMapToCenter = useCallback((selectedGeocode, content = '') => {
        if (activeInfoWindow) {
            activeInfoWindow.close();
        }
        if (map.panTo) {
            map.panTo(new mapsApi.LatLng(selectedGeocode.latitude, selectedGeocode.longitude));
            // eslint-disable-next-line no-new
            activeInfoWindow = createInfoWindow(content, selectedGeocode);
            activeInfoWindow.open(map);
        }
    }, [map, mapsApi.LatLng, createInfoWindow]);

    const mapInfoWindow = useCallback((marker, content, infoWindowCallback) => {
        marker.addListener('click', () => {
            if (activeInfoWindow) {
                activeInfoWindow.close();
            }
            const geoCode = {
                latitude: marker.latlng.lat(),
                longitude: marker.latlng.lng()
            };

            if (infoWindowCallback) {
                infoWindowCallback();
            }

            activeInfoWindow = createInfoWindow(content, geoCode);
            activeInfoWindow.open(map, marker);
        });
    }, [createInfoWindow, map]);

    const clearMarkers = useCallback(() => {
        markersArray.forEach((marker) => {
            marker.setMap(null);
        });
        markersArray.length = 0;
        markersArray = [];
    }, []);

    const addMarker = useCallback((geoCode = {}, type, content = '', infoWindowCallback) => {
        if (_.isEmpty(geoCode)) {
            return;
        }
        const Marker = CustomMarkerFactory(mapsApi, stylesList);
        const customMarker = new Marker(
            new mapsApi.LatLng(geoCode.latitude, geoCode.longitude),
            map,
            MarkerStyle[type]
        );
        mapInfoWindow(customMarker, content, infoWindowCallback);
        markersArray.push(customMarker);
    }, [map, mapsApi, mapInfoWindow]);

    useEffect(() => {
        initMap();
        getCurrentLocation();
    }, [initMap]);

    return {
        addMarker,
        clearMarkers,
        map,
        mapsApi,
        mapCenter,
        moveMapToCenter,
        myLocation,
        setMapElement,
        zoomLevel
    };
}


export default useRepairFacilityMap;
