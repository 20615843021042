import { defineMessages } from 'react-intl';

export default defineMessages({
    verificationInformationTextMsg: {
        id: 'auth.components.SignUp.Please provide verification information for the account you wish to access.',
        defaultMessage: 'Please provide verification information to proceed with NLC Online Account creation.'
    },
    completeYourPortalAccountMsg: {
        id: 'auth.components.SignUp.CompleteYourProtalAccount',
        defaultMessage: 'Complete the following to create your Portal account.'
    },
    verificationInformationAdditionalTextMsg: {
        id: 'auth.components.SignUp.You will be able to add access to other policies once you log in.',
        defaultMessage: 'You will be able to add access to other policies once you log in.'
    },
    signUp: {
        id: 'auth.components.SignUp.Sign Up',
        defaultMessage: 'Sign Up'
    },
    enterYourDetails: {
        id: 'auth.components.Enter Your Details',
        defaultMessage: 'Enter Your Details'
    },
    firstName: {
        id: 'auth.components.SignUp.First Name:',
        defaultMessage: 'First Name:'
    },
    lastName: {
        id: 'auth.components.SignUp.Last Name:',
        defaultMessage: 'Last Name:'
    },
    userName: {
        id: 'auth.components.SignUp.User Name:',
        defaultMessage: 'User Name:'
    },
    password: {
        id: 'auth.components.SignUp.Password:',
        defaultMessage: 'Password:'
    },
    confirmPassword: {
        id: 'auth.components.SignUp.Confirm Password:',
        defaultMessage: 'Confirm Password:'
    },
    next: {
        id: 'auth.components.SignUp.Next',
        defaultMessage: 'Next'
    },
    fieldEmpty: {
        id: 'auth.components.SignUp.All fields are required to sign up.',
        defaultMessage: 'All fields are required to sign up.'
    },
    invalidEmail: {
        id: 'auth.components.SignUp.Please enter a valid email address.',
        defaultMessage: 'Please enter a valid email address.'
    },
    invalidPassword: {
        id: 'auth.components.SignUp.Password does not meet the criteria.',
        defaultMessage: 'Password does not meet the criteria.'
    },
    passwordMessage: {
        id: 'auth.components.SignUp.Password must be a minimum of 12 characters and contain at least one capital and one number.',
        defaultMessage: 'Password must be a minimum of 12 characters and contain at least one capital and one number.'
    },
    passwordsNotMatching: {
        id: 'auth.components.SignUp.Fields password and confirmation do not match.',
        defaultMessage: 'Fields password and confirmation do not match.'
    },
    emailAlreadyExists: {
        id: 'auth.components.SignUp.Email already exists',
        defaultMessage: 'Email already exists'
    },
    emailAlreadyExistsDescription: {
        id: 'auth.components.SignUp.An account with the email address you entered already exists.',
        defaultMessage: 'An account with the email address you entered already exists.'
    },
    invalidAttempt: {
        id: 'auth.components.SignUp.Invalid Attempt',
        defaultMessage: 'Invalid Attempt'
    },
    unableToCreateUserAccount: {
        id: 'auth.components.SignUp.Unable to create user account',
        defaultMessage: 'Unable to create user account'
    },
    unableToLoginAutomatically: {
        id: 'auth.components.SignUp.Unable to login automatically',
        defaultMessage: 'Unable to login automatically'
    },
    unableToLoginAutomaticallyDescription: {
        id: 'auth.components.SignUp.Account successfully created, however, not able to login automatically. Please return to Sign In and enter your email and password.',
        defaultMessage: 'Account successfully created, however, not able to login automatically. Please return to Sign In and enter your email and password.'
    },
    doYouHaveAccount: {
        id: 'auth.components.SignUp.Already have an account?',
        defaultMessage: 'Already have an account?'
    },
    next: {
        id: 'auth.components.SignUp.next',
        defaultMessage: 'Next'
    },
    signIn: {
        id: 'auth.components.SignUp.Sign In',
        defaultMessage: 'Sign In'
    },
    validate: {
        id: 'auth.components.SignUp.Validate',
        defaultMessage: 'Validate'
    },
    invalidPolicyDetails: {
        id: 'auth.components.SignUp.Please verify your information and try again. Contact your local independent agent if you have any policy detail questions.',
        defaultMessage: 'Please verify your information and try again. Contact Customer Service at 800-962-0800 for assistance. Our regular business hours are Monday - Friday from 8:15am to 4:15pm.'
    },
    userAlreadyExists: {
        id: 'auth.components.User already exists with provided user name.',
        defaultMessage: 'User already exists with provided user name.'
    },
    emailAlreadyExists: {
        id: 'auth.components.Email already exists.',
        defaultMessage: 'Email already exists.'
    },
    contactYourAgentTextMsg: {
        id: 'auth.components.SignUp.contactyouragent',
        defaultMessage: 'Having trouble enrolling? Please contact your independent agent to review your policy details.'
    },
    contactYourAgentPhoneTextMsg: {
        id: 'auth.components.SignUp.contactyouragentphone',
        defaultMessage: 'Having trouble creating your Portal credentials? Contact us at 800-962-0800 for assistance.'
    },
    createYourPortalAccountTextMsg: {
        id: 'auth.components.SignUp.createyourportalaccount',
        defaultMessage: 'Complete the following to create your Portal account.'
    },
    whiteListedTxtMsg: {
        id: 'auth.components.SignUp.whitelistedtxtmsg',
        defaultMessage: 'No matching whitelisted entry found.'
    }
});
