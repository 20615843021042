import { defineMessages } from 'react-intl';

export default defineMessages({
    repairHere: {
        id: 'fnol.pa.components.repair-facility-details.Repair Here',
        defaultMessage: 'Repair Here'
    },
    repairFacilitiesSearch: {
        id: 'fnol.pa.components.repair-facilities-search.Repair Facilities Search',
        defaultMessage: 'Repair Facilities Search'
    }
});
