import React, {
    useCallback, useMemo, useState, useEffect, useContext
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { useAuthentication } from 'gw-digital-auth-react';
import metadata from './SignUpOtpComponent.metadata.json5';
import messages from './SignUpOtpComponent.messages';
import styles from './SignUpOtpComponent.module.scss';
import { EntryPageTemplate } from 'gw-components-platform-react';
import appConfig from 'app-config';
import { TranslatorContext } from '@jutro/locale';
import { OtpService } from 'nlc-capability-otp';
import { Loader } from '@jutro/components';

const applicationDetails = _.get(appConfig, 'applicationDetails', {});
const mfaDetails = _.get(appConfig, 'mfa', {});
const {maxNumberOfEmailOtp, maxNumberOfMobileOtp, maxNumberOfEmailOtpVerification, maxNumberOfMobileOtpVerification} = _.get(mfaDetails, 'signup');

function SignupOtpComponent(props) {
    const { history } = props;
    const [formData, updateFormData] = useState({});
    const [mobileOtpSent, setMobileOtpSent] = useState(false);
    const [errorMessage, updateErrorMessage] = useState(undefined);
    const [infoMessage, setInfoMessage] = useState(undefined);
    const [emailOtpSent, setEmailOtpSent] = useState(false);
    const [mobileOtpVerified, setMobileOtpVerified] = useState(false);
    const [emailOtpVerified, setEmailOtpVerified] = useState(false);
    const [countOfEmailOtp, setCountOfEmailOtp] = useState(0); 
    const [countOfMobileOtp, setCountOfMobileOtp] = useState(0);
    const [countOfEmailOtpVerify, setCountOfEmailOtpVerify] = useState(0); 
    const [countOfMobileOtpVerify, setCountOfMobileOtpVerify] = useState(0);
    const [acceptanceStatus, setAcceptanceStatus] = useState(false);
    const [disableAll, setDisableAll] = useState(false);
    const translator = useContext(TranslatorContext);
    const { isLoggedIn, isAuthInProgress, logout } = useAuthentication();
    const state = _.get(history, 'location.state');
    let userName = undefined;
    let email = undefined;
    let phoneNumber = undefined;
    if(state){
        userName = state.userName;
        email = state.email;
        phoneNumber = state.phoneNumber;
    }
    
    useEffect(() => { 
        const formData = {userName:userName, email:email, phoneNumber:phoneNumber, emailOtpCode:undefined, mobileOtpCode:undefined};
        updateFormData(formData); 
        
        const mobileOtpCount = localStorage.getItem('mobileOtpCount') 
        ? parseInt(localStorage.getItem('mobileOtpCount')) : 0;
        setCountOfMobileOtp(mobileOtpCount);

        const emailOtpCount = localStorage.getItem('emailOtpCount') 
                ? parseInt(localStorage.getItem('emailOtpCount')) : 0;
        setCountOfEmailOtp(emailOtpCount);

        const  emailOtpVerifyCount= localStorage.getItem('countOfSignUpOtpVerificationWithEmail') 
        ? parseInt(localStorage.getItem('countOfSignUpOtpVerificationWithEmail')) : 0;
        setCountOfEmailOtpVerify(mobileOtpCount);

        const mobileOtpVerifyCount = localStorage.getItem('countOfSignUpOtpVerificationWithMobile') 
                ? parseInt(localStorage.getItem('countOfSignUpOtpVerificationWithMobile')) : 0;
        setCountOfMobileOtpVerify(emailOtpCount);

        if(mobileOtpCount === maxNumberOfMobileOtp || emailOtpCount === maxNumberOfEmailOtp 
            || emailOtpVerifyCount === maxNumberOfEmailOtpVerification || mobileOtpVerifyCount === maxNumberOfMobileOtpVerification){
            setDisableAll(true); 
            setInfoMessage(undefined); 
            updateErrorMessage('userLocked');           
        }
       
    }, [userName, email, phoneNumber, localStorage, maxNumberOfMobileOtp, maxNumberOfEmailOtp, 
        maxNumberOfEmailOtpVerification, maxNumberOfMobileOtpVerification]);
    
    
    const sendOtpToMobile = useCallback(async() => {  
        updateErrorMessage(undefined);  
        if(countOfMobileOtp<maxNumberOfMobileOtp) {
            const inputData = new FormData();
            inputData.append('userName',  userName);
            inputData.append('phone',  _.get(formData, 'phoneNumber'));
            const data = {
                method: 'POST',
                body: inputData,
                redirect: 'follow',

            }                    
            try{
                const otp = await OtpService.sendSms(data);
                if(otp.status === 200) {
                    setMobileOtpSent(true);
                    setInfoMessage('otpSentMessage');                    
                    updateMobileOtpCount();
                   
                } else {
                    setMobileOtpSent(false);
                }           
            }catch(err){
                setMobileOtpSent(false);
            } 
        } else {
            //updateMobileOtpCount();
            lockTheUser();                        
            setDisableAll(true);
        }          
        //setMobileOtpSent(true);
    },[formData, countOfMobileOtp, maxNumberOfMobileOtp]);    

    const sendOtpToEmail = useCallback(async() => { 
        updateErrorMessage(undefined);
        if(countOfEmailOtp<maxNumberOfEmailOtp){
            const inputData = new FormData();
            inputData.append('userName',  userName);
            inputData.append('email',  email);
            inputData.append('type',  'signup');
            const data = {
                method: 'POST',
                body: inputData,
                redirect: 'follow',

            }                    
            try{
                const otp = await OtpService.sendEmailOtp(data);
                if(otp.status === 200) {
                    setEmailOtpSent(true);
                    setInfoMessage('otpSentMessage');
                    updateEmailOtpCount();
                   
                } else {
                    setEmailOtpSent(false);
                }           
            }catch(err){
                setMobileOtpSent(false);
            }   
        } else {
            //updateEmailOtpCount();
            lockTheUser();            
            setDisableAll(true);
        }        
        //setEmailOtpSent(true);
    },[userName, email, countOfEmailOtp, maxNumberOfEmailOtp]);

    
    const verifyMobileOtp = useCallback(async() => {
        updateErrorMessage(undefined); 
        if(countOfMobileOtpVerify<maxNumberOfMobileOtpVerification) { 
            const inputData = new FormData();
            inputData.append('userName', userName);
            inputData.append('phone', _.get(formData, 'phoneNumber'));
            inputData.append('otp', _.get(formData, 'mobileOtpCode'));
            const data = {
                method: 'POST',
                body: inputData,
                redirect: 'follow',

            }             
            try{
                const otp = await OtpService.verifySmsOtp(data);
                if(otp.status === 200) {
                    setMobileOtpVerified(true);
                }else{
                    updateErrorMessage('invalidOtpInfo');
                    setMobileOtpVerified(false);
                    updateMobileOtpVerifyCount();
                } 
                setInfoMessage(undefined);          
            }catch(err){
                setMobileOtpVerified(false);
                console.log(err);
            }
        } else {
            lockTheUser();                        
            setDisableAll(true);
        }              
        //setMobileOtpVerified(true);
    },[formData, setMobileOtpVerified, countOfMobileOtpVerify, maxNumberOfMobileOtpVerification]);

    const verifyEmailOtp = useCallback(async() => { 
        updateErrorMessage(undefined);  
        if(countOfEmailOtpVerify<maxNumberOfEmailOtpVerification) {
            const inputData = new FormData();
            inputData.append('userName', userName);
            inputData.append('email', email);        
            inputData.append('otp', _.get(formData, 'emailOtpCode'));
            const data = {
                method: 'POST',
                body: inputData,
                redirect: 'follow',

            }             
            try{
                const otp = await OtpService.verifyEmailOtp(data);
                if(otp.status === 200) {                
                    setEmailOtpVerified(true);
                }else{
                    updateErrorMessage('invalidOtpInfo');
                    setEmailOtpVerified(false);
                    updateEmailOtpVerifyCount();
                } 
                setInfoMessage(undefined);          
            }catch(err){
                setEmailOtpVerified(false);
                console.log(err);
            } 
        } else {
            lockTheUser();                        
            setDisableAll(true);           
        }  
        //setEmailOtpVerified(true);
    },[formData, userName, email, countOfEmailOtpVerify, maxNumberOfEmailOtpVerification]);

    const updateEmailOtpCount = useCallback(() => {
        let count = localStorage.getItem('emailOtpCount') 
        ? parseInt(localStorage.getItem('emailOtpCount')) : 0;
        count =count+1;
        setCountOfEmailOtp(count);
        localStorage.setItem('emailOtpCount', count);
    },[]);

    const updateMobileOtpCount = useCallback(() => {
        let count = localStorage.getItem('mobileOtpCount') 
        ? parseInt(localStorage.getItem('mobileOtpCount')) : 0;
        count =count+1;
        setCountOfMobileOtp(count);
        localStorage.setItem('mobileOtpCount', count);
    },[]);

    const updateEmailOtpVerifyCount = useCallback(() => {
        let count = localStorage.getItem('countOfSignUpOtpVerificationWithEmail') 
        ? parseInt(localStorage.getItem('countOfSignUpOtpVerificationWithEmail')) : 0;
        count =count+1;
        setCountOfEmailOtpVerify(count);
        localStorage.setItem('countOfSignUpOtpVerificationWithEmail', count);
    },[]);

    const updateMobileOtpVerifyCount = useCallback(() => {
        let count = localStorage.getItem('countOfSignUpOtpVerificationWithMobile') 
        ? parseInt(localStorage.getItem('countOfSignUpOtpVerificationWithMobile')) : 0;
        count =count+1;
        setCountOfMobileOtpVerify(count);
        localStorage.setItem('countOfSignUpOtpVerificationWithMobile', count);
    },[]);


    const handleNext = useCallback(() => { 
        const inputData = new FormData();
        const data = {
            method: 'POST',
            body: inputData,
            redirect: 'follow',
        }  
        inputData.append('userName', userName);   
        inputData.append('isEmailVerified', emailOtpVerified); 
        inputData.append('isPhoneVerified', mobileOtpVerified);
        inputData.append('isTncAccepted',  acceptanceStatus);                      
        try{                    
            OtpService.updateSignUpVerificationStatus(data).then((otp)=>{
                if(otp.status === 200) { 
                    sessionStorage.setItem('mfaStatus', 'true');                  
                    const nextState = { pathname: '/nlc-signup-tnc'};
                    history.push(nextState); 
                }
            });            
        }catch(err){
            console.log(err);
        }               
    },[history, emailOtpVerified, mobileOtpVerified, acceptanceStatus]);

    const readValue = useCallback((id, path) => _.get(formData, path), [formData]);

    const writeValue = useCallback((value, path) => {
        updateErrorMessage(undefined);
        const nextFormData = _.cloneDeep(formData);
        _.set(nextFormData, path, value);
        updateFormData(nextFormData);
    },[formData]);

   
    const getMessage = useCallback(() => {
       if(!disableAll && (emailOtpSent || mobileOtpSent)) {
            return translator(messages['otpSentMessage']);
       }
       return '';
    },[emailOtpSent, mobileOtpSent, disableAll]);

    const lockTheUser = useCallback(() => {
        setInfoMessage(undefined);
        const inputData1 = new FormData();
        inputData1.append('userName', userName);  
        const data1 = {
            method: 'POST',
            body: inputData1,
            redirect: 'follow',
        }  
    
        try{                    
            OtpService.lockUser(data1).then((status)=>{
                if(status.status === 200) { 
                    updateErrorMessage('userLocked');
                }
            });            
        }catch(err){
            console.log(err);
        }            
    },[userName]);

    const disableSendCodeToEmail = useCallback(() => {
        const email = _.get(formData, 'email');
        if(!email){             
            return true;
        }else if(emailOtpVerified){
            return true;
        }else if(disableAll){            
            return true;
        }
        return false;
     },[formData, emailOtpVerified, disableAll]);

     const disableSendCodeToMobile = useCallback(() => {
        const mobile = _.get(formData, 'phoneNumber');
        if(!mobile){
            return true;
        }else if(!emailOtpSent || !emailOtpVerified || mobileOtpVerified){
            return true;
        }else if(disableAll){             
            return true;
        }
        return false;
     },[formData, emailOtpVerified, mobileOtpVerified, disableAll]);
     
    const updateAcceptanceStatus = useCallback((value) => {
        setAcceptanceStatus(value);
    }, []);


    const openTermsAndConditions = useCallback((value) => {
        <Link target="_blank" href={idCardlink} className={styles.link}>{messages.printID}</Link>
    }, []);
    const overrideProps = { 
        heading:{
            className: styles.heading
        }, 
        otpSuccessText: {
            className: styles.otpSuccessText,
            content: translator(messages['successText'])
        },
        otpInformationText: {
            content: translator(messages['informationText'])
        }, 
        otpInfoText: {
            className: styles.otpInfoText,
            content: translator(messages['infoText'])
        },
        otpInfoMessageContainer: {
            visible: (emailOtpSent || mobileOtpSent) && !disableAll
        },
        otpInfoMessage: {
            type: 'info',
            visible: infoMessage!==undefined,
            message: messages[infoMessage]
        },   
        email: {
            readOnly: true,
            value:_.get(formData, 'email')
        },
        sendCodeToEmail: {
            disabled: disableSendCodeToEmail(),
            onClick: sendOtpToEmail
        },
        enterEmailCode: {
            disabled: !emailOtpSent || disableAll,
            onValueChange: writeValue,
            value: _.get(formData, 'emailOtpCode')
        },
        verifyEmailOtp: {
            disabled: !emailOtpSent || _.get(formData, 'emailOtpCode')===undefined || emailOtpVerified || disableAll,
            onClick: verifyEmailOtp
        },
        phoneNumber: {
            readOnly: true,
            value:_.get(formData, 'phoneNumber')
        },
        sendCodeToPhone: {
            disabled: disableSendCodeToMobile(),
            onClick: sendOtpToMobile
        },
        enterMobileCode: {
            disabled: !mobileOtpSent || disableAll,
            onValueChange: writeValue,
            value: _.get(formData, 'mobileOtpCode')
        },
        verifyPhoneOtp: {
            disabled: !mobileOtpSent || _.get(formData, 'mobileOtpCode')===undefined || mobileOtpVerified || disableAll,
            onClick: verifyMobileOtp
        },
        checkboxField: {
            disabled: disableAll || !mobileOtpVerified || !emailOtpVerified,
            value: acceptanceStatus,
            onValueChange: updateAcceptanceStatus
        },
        tncLink:{           
            target:'_blank',
            href:'https://www.nlcinsurance.com/terms%20of%20use.php'
        },
        actionsContainer:{
            className: styles.actionContainer
        },
        signUpactionsContainer: {
            className: styles.signupActionContainer
        },
        termsAndConditionsContainer:{
            className: styles.tncContainer
        },
        backButton: {
            onClick: logout,
        },
        nextButton: {
            disabled: !emailOtpVerified || !mobileOtpVerified || !acceptanceStatus,
            onClick: handleNext,
        },
        otpErrorMessageContainer: {
            visible: errorMessage !== undefined,
        },
        otpErrorMessage: {                
            message: translator(messages[errorMessage])
        },      
    }

    const resolvers = {
        classNameMap:styles
    };

    const renderedContentFromMetadata = renderContentFromMetadata(
        metadata.componentContent,
        overrideProps,
        resolvers
    );    

    if (isAuthInProgress) {
        return <Loader loaded={!isAuthInProgress} />;
    }

    if (!isLoggedIn) {
        const nextState = { pathname: '/login-page'};
        return <Redirect to={nextState} />;
    } 

    if (!state) {
        const nextState = { pathname: '/login-page'};
        return <Redirect to={nextState} />;
    }

  
    return (
        <EntryPageTemplate
            applicationName={applicationDetails.applicationName}
            applicationLogo={applicationDetails.applicationLogo}
            contentToRender={renderedContentFromMetadata}
        />
    );
}

SignupOtpComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(SignupOtpComponent);
