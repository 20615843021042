import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext, FormattedMessage } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import HomeCoverageTable from '../HomeCoverageTable/HomeCoverageTable';
import metadata from './PolicyDwellingInfoHO3.metadata.json5';
import messages from './PolicyDwellingInfoHO3.messages';
import styles from './PolicyDwellingInfoHO3.module.scss';

const getFormattedInfo = (message, key, value) => {
    return (
        <FormattedMessage
            {...message}
            values={{
                [key]: value
            }}
        />
    );
};

const translateYesNo = (keyObject, translator) => {
    const object = _.cloneDeep(keyObject);
    const appendingYear = (obj, key) => {
        let result = '';
        const mappingYear = {
            heatingUpgraded: 'heatingUpgradeYear',
            plumbingUpgraded: 'plumbingUpgradeYear',
            wiringUpgraded: 'wiringUpgradeYear',
            roofUpgraded: 'roofUpgradeYear'
        };
        const getDisplayYearKey = mappingYear[key];
        if (getDisplayYearKey && obj[key]) {
            result = `(${obj[getDisplayYearKey]})`;
        }
        return result;
    };
    if (!_.isEmpty(object)) {
        _.map(object, (value, key) => {
            if (typeof value === 'boolean') {
                object[key] = `${translator(
                    value ? messages.infoYes : messages.infoNo
                )} ${appendingYear(object, key)}`;
            }
        });
    }
    return object;
};

function PolicyDwellingInfoHO3(props) {
    const translator = useContext(TranslatorContext);
    const updateCoverages = (homeOwnersData) => {
        const houseOwners = _.cloneDeep(homeOwnersData);
        const coverages = houseOwners.coverageDTOs.concat(houseOwners.dwellingDTO.coverageDTOs);
        const allCoverages = {
            sectionICoverages: [],
            sectionIICoverages: [],
            additionalLiabilityCoverages: [],
            additionalPropertyCoverages: [],
            scheduledItems: []
        };
        coverages.forEach((coverage) => {
            const cov = coverage;
            switch (cov.categoryCodeIdentifier) {
                case 'HOPSectionICovCat':
                case 'HODW_SectionI_HOE':
                    allCoverages.sectionICoverages.push(cov);
                    break;
                case 'HOPSectionIICovCat':
                case 'HODW_SectionII_HOE':
                    allCoverages.sectionIICoverages.push(cov);
                    break;
                case 'HOPAdditionalCovCat':
                case 'HOLI_Additional_HOE':
                    allCoverages.additionalLiabilityCoverages.push(cov);
                    break;
                case 'HOPPCAdditionalCovCat':
                case 'HODW_Additional_HOE':
                    allCoverages.additionalPropertyCoverages.push(cov);
                    break;
                case 'HOPScheduledItemCovCat':
                case 'HODW_SchedulesCov_HOE':
                    if (cov.specialLimits) {
                        cov.specialLimits.forEach((coverageItem) => {
                            allCoverages.scheduledItems.push(coverageItem);
                        });
                    } else if (cov.scheduledProperty) {
                        cov.scheduledProperty.forEach((coverageItem) => {
                            allCoverages.scheduledItems.push(coverageItem);
                        });
                    }
                    break;
                default:
                    break;
            }
        });
        return allCoverages;
    };

    const getHomeOwnersData = useCallback(() => {
        const homeOwnersData = _.cloneDeep(props.value);
        if (!_.isEmpty(homeOwnersData.dwellingDTO.homeDetails)) {
            homeOwnersData.dwellingDTO.homeDetails = translateYesNo(
                homeOwnersData.dwellingDTO.homeDetails,
                translator
            );
            _.set(
                homeOwnersData,
                'dwellingDTO.homeDetails.distanceToFireHydrant',
                getFormattedInfo(
                    messages.FeetToFireHydrant,
                    'distance',
                    homeOwnersData.dwellingDTO.homeDetails.distanceToFireHydrant
                )
            );
            _.set(
                homeOwnersData,
                'dwellingDTO.homeDetails.distanceToFireStation',
                getFormattedInfo(
                    messages.MilesToFireStation,
                    'miles',
                    homeOwnersData.dwellingDTO.homeDetails.distanceToFireStation
                )
            );
        }
        if (!_.isEmpty(homeOwnersData.dwellingDTO.constructionDetails)) {
            homeOwnersData.dwellingDTO.constructionDetails = translateYesNo(
                homeOwnersData.dwellingDTO.constructionDetails,
                translator
            );
        }
        if (!_.isEmpty(homeOwnersData.dwellingDTO.miscDetails)) {
            homeOwnersData.dwellingDTO.miscDetails = translateYesNo(
                homeOwnersData.dwellingDTO.miscDetails,
                translator
            );
        }
        if (_.isEmpty(homeOwnersData.dwellingDTO.mortgageeDetailsDTO)) {
            homeOwnersData.mortgageeDetailsDTO = [];
        }
        homeOwnersData.allCoverages = updateCoverages(homeOwnersData);
        return homeOwnersData;
    }, [props, translator]);

    const homeOwnersData = getHomeOwnersData();

    if (_.isEmpty(homeOwnersData)) {
        return null;
    }

    const overrides = {
        homeDetailsSeparator: {
            visible: !_.isEmpty(homeOwnersData.mortgageeDetailsDTO)
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
     //       homecoveragetablecomponent: HomeCoverageTable
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={homeOwnersData}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PolicyDwellingInfoHO3.propTypes = {
    value: PropTypes.shape({}).isRequired
};

export default PolicyDwellingInfoHO3;
