import React, { useCallback, useContext,useState,useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './DFDeductibleCoverages.metadata.json5';
import styles from './DFDeductibleCoverages.module.scss';
import './DFDeductibleCoverages';
//import { get } from 'core-js/core/dict';



function DFDeductibleCoverages(props) {
    const { deductibleCoverages : dwellingDeductible_Ext } = props;

    // const getDataTableCell = useCallback((item, index, { path: property }) => {
    //     return item[property];
    // });

    

    const getDeductibleName = useCallback((dwellingDeductible_Ext) =>{
        const limit = dwellingDeductible_Ext[0].deductibleCoverages
        return `  ${limit} `

    });

    const getDeductibleLimit = useCallback((dwellingDeductible_Ext) =>{
        const limit = dwellingDeductible_Ext[0].terms[0].amount
        return ` $${limit} `

    });

    

   
    const overrides = {
        '@element': {
            textAlign: 'left'
        },

        infoTable:{
            data: _.get(dwellingDeductible_Ext)
        },

       
        sectionIDeductible:{
            value: getDeductibleName(dwellingDeductible_Ext)
        },

        deductibleValue:{
            value : getDeductibleLimit(dwellingDeductible_Ext)
        }
       
    
        // limit:{
        //     value: getLimit(policyCoverages_Ext)
        //    },

        // rentation:{
        //     value: getRentation(policyCoverages_Ext)  
        //    }



    };

    const resolvers = {
        resolveCallbackMap:{
          //  getDataTableCell :  getDataTableCell 
            // liabilityLimit:getLimit,
            // rentation:getRentation

        },
        resolveClassNameMap: styles,

    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.componentContent,
                dwellingDeductible_Ext,
                id,
                path,
                overrides
            );
        },
        [dwellingDeductible_Ext, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={dwellingDeductible_Ext}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

DFDeductibleCoverages.propTypes = {
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
};

DFDeductibleCoverages.defaultProps = {
    value: []
};

export default DFDeductibleCoverages;
