import React, { useCallback, useContext,useState,useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PolicyScheduleInfo.metadata.json5';
import styles from './PolicyScheduleInfo.module.scss';
import './PolicyScheduleInfo.messages';


function PolicyScheduleInfo(props) {
    const { data: policySchedule_Ext } = props;

    
    const getDataTableCell = useCallback((item, index, { path: property }) => {
        return item[property];
    });

   

    const overrides = {
        '@element': {
            textAlign: 'left'
        },

        infoTable:{
            data: policySchedule_Ext 
        },
        
       
        //...getTableOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            getDataTableCell :  getDataTableCell 
        },
        resolveClassNameMap: styles
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.componentContent,
                policySchedule_Ext,
                id,
                path,
                overrides
            );
        },
        [policySchedule_Ext, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policySchedule_Ext}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

PolicyScheduleInfo.propTypes = {
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
};

PolicyScheduleInfo.defaultProps = {
    value: []
};

export default PolicyScheduleInfo;
