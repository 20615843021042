import React, {
    useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect, Prompt } from 'react-router-dom';
import _ from 'lodash';

import { PromptService } from '@jutro/router';
import { ModalNextProvider } from '@jutro/components';
import { ViewModelUtil } from 'gw-portals-viewmodel-js';
import { messages as commonMessages } from 'gw-platform-translations';
import { wizardStepProps } from './prop-types/wizardPropTypes';
import useWizardSteps from './hooks/useWizardSteps';
import useWizardData from './hooks/useWizardData';
import useWizardErrors from './hooks/useWizardErrors';
import { WizardContext } from './WizardContext';
import WizardRoutes from './WizardRoutes';

const checkIsDataValid = (data) => {
    return (
        !ViewModelUtil.isViewModelNode(data) || (data.aspects.subtreeValid && data.aspects.valid)
    );
};

const checkDataValidityDuringTransition = (wizardData, wizardSnapshot, modalMessageProps,
    cloneData, updateWizardData) => {
    return checkIsDataValid(wizardData)
        ? wizardData
        : ModalNextProvider.showConfirm(modalMessageProps).then(
            (results) => {
                if (results === 'cancel') {
                    return _.stubFalse();
                }
                updateWizardData(cloneData(wizardSnapshot));
                return wizardSnapshot;
            }, _.stubFalse
        );
};

const checkContinue = (result) => {
    if (result === false) {
        return Promise.reject(new Error(false));
    }
    return Promise.resolve(result);
};

const handleOnPrevious = (wizardProps) => {
    const {
        wizardData, wizardSnapshot, onPreviousModalProps,
        cloneData, updateWizardData
    } = wizardProps;
    return checkDataValidityDuringTransition(wizardData, wizardSnapshot, onPreviousModalProps,
        cloneData, updateWizardData);
};

function Wizard(props) {
    const {
        wizardTitle,
        initialSteps,
        initialData,
        skipCompletedSteps,
        onFinish,
        onCancel,
        onPrevious,
        onPreviousModalProps,
        onKnockOut,
        wizardStepToFieldMapping,
        location,
        match,
        basePath = match.url
    } = props;

    const {
        steps,
        currentStepIndex,
        currentStep,
        goNext,
        goPrevious,
        jumpTo,
        markStepSubmitted,
        changeNextSteps,
        isSkipping,
        stopSkipping,
        clearVisitedStepsAfterCurrent
    } = useWizardSteps(initialSteps, skipCompletedSteps);

    const {
        data, updateData, snapshot, updateSnapshot, cloneData
    } = useWizardData(initialData);
    const submittedStepIds = _.filter(steps, 'submitted').map((step) => step.id);
    const {
        acknowledgeError,
        reportErrors,
        stepsWithErrors,
        hasNewErrors,
        underwritingIssues,
        knockOutErrors
    } = useWizardErrors(wizardStepToFieldMapping, submittedStepIds);
    const [toggle, flip] = useState(false);

    const [isUwIssuesInitialized, updateIsUwIssuesInitialized] = useState(false);

    useEffect(() => {
        if (!isUwIssuesInitialized) {
            // errorsAndWarnings is not set because updateWizardData
            //  is NOT called right after retrieving data from the API
            if (underwritingIssues.length === 0) {
                const errorsPath = ViewModelUtil.isViewModelNode(data)
                    ? 'errorsAndWarnings.value'
                    : 'errorsAndWarnings';
                const errorsAndWarnings = _.get(data, errorsPath);
                reportErrors(errorsAndWarnings);
            }
            updateIsUwIssuesInitialized(true);
        }
    }, [data, isUwIssuesInitialized, reportErrors, underwritingIssues.length]);

    const errorsForStep = stepsWithErrors[currentStep.id] || [];

    const updateWizardData = useCallback(
        (newData, clearErrors = true) => {
            clearVisitedStepsAfterCurrent();
            const errorsPath = ViewModelUtil.isViewModelNode(newData)
                ? 'errorsAndWarnings.value'
                : 'errorsAndWarnings';
            const errorsAndWarnings = _.get(newData, errorsPath);
            flip(!toggle);
            updateData(newData);
            if (errorsAndWarnings) {
                if (clearErrors) {
                    const errorsIds = _.map(errorsForStep, 'id');
                    return reportErrors(errorsAndWarnings, errorsIds);
                }
                return reportErrors(errorsAndWarnings, []);
            }
            return false;
        },
        [toggle, updateData, clearVisitedStepsAfterCurrent, reportErrors, errorsForStep]
    );

    const updateWizardSnapshot = useCallback(
        (newData, clearErrors = true) => {
            updateSnapshot(cloneData(newData));
            return updateWizardData(newData, clearErrors);
        },
        [cloneData, updateSnapshot, updateWizardData]
    );

    const finishCallback = useCallback(
        (params) => onFinish({
            steps,
            currentStepIndex,
            wizardData: data,
            params
        }),
        [steps, currentStepIndex, data, onFinish]
    );

    const previousCallback = useCallback(
        (param) => {
            return Promise.resolve(
                onPrevious({
                    wizardSnapshot: snapshot,
                    wizardData: data,
                    onPreviousModalProps,
                    param,
                    cloneData,
                    updateWizardData
                })
            )
                .then(checkContinue)
                .then(goPrevious)
                .catch((error) => {
                    if (_.get(error, 'message') === 'false') {
                        // do nothing as we don't want to proceed
                        return;
                    }
                    throw error;
                });
        },
        [onPrevious, snapshot, data, onPreviousModalProps, goPrevious, cloneData, updateWizardData]
    );

    const onPageJump = useCallback(
        ({
            wizardData, wizardSnapshot, modalMessages, index
        }) => {
            const pageTransitionPromise = Promise.resolve(
                checkDataValidityDuringTransition(wizardData, wizardSnapshot, modalMessages,
                    cloneData, updateWizardData)
            );

            pageTransitionPromise
                .then((result) => {
                    if (result !== false) {
                        jumpTo(index);
                    }
                })
                .catch((error) => {
                    if (_.get(error, 'message') === 'false') {
                        // do nothing as we don't want to proceed
                        return;
                    }
                    throw error;
                });
        },
        [cloneData, jumpTo, updateWizardData]
    );

    const cancelCallback = useCallback(
        (param) => onCancel({
            steps,
            currentStepIndex,
            wizardSnapshot: snapshot,
            wizardData: data,
            param
        }),
        [onCancel, steps, currentStepIndex, snapshot, data]
    );

    const handlePromptMessage = useCallback(
        ({ pathname }) => {
            const isWizardPath = pathname.startsWith(basePath);

            // if triggered by the wizard, we ignore it
            if (isWizardPath) {
                return null;
            }

            const actionName = 'onCancel';

            // register the onCancel action with Jutro
            PromptService.pushPrompt(actionName, { trigger: cancelCallback.bind(null, pathname) });

            return actionName;
        },
        [basePath, cancelCallback]
    );

    if (hasNewErrors) {
        const errorStepIds = Object.keys(stepsWithErrors);
        const stepIndex = _.findIndex(steps, (step) => errorStepIds.includes(step.id));
        if (stepIndex !== -1 && stepIndex < currentStepIndex) {
            jumpTo(stepIndex);
        }
    }

    if (!_.isEmpty(knockOutErrors)) {
        onKnockOut({
            steps,
            knockOutErrors,
            wizardData: data
        });
    }

    /*
     * current step has authority over the URL.
     *
     * If current step is not matching the URL,
     * we adjust by sending a redirect to the step URL
     */
    const expectedPath = `${basePath}${currentStep.path}`;
    if (!location.pathname.startsWith(expectedPath)) {
        const nextLocation = {
            pathname: expectedPath,
            state: location.state
        };
        return <Redirect to={nextLocation} />;
    }

    const wizardProps = {
        // wizard details
        wizardTitle,
        // steps props
        steps,
        changeNextSteps,
        currentStepIndex,
        currentStep,
        // transitions props
        isSkipping,
        stopSkipping,
        goNext,
        goPrevious: previousCallback,
        markStepSubmitted,
        jumpTo,
        finish: finishCallback,
        cancel: cancelCallback,
        onPageJump,
        // wizard data props
        wizardData: data,
        updateWizardData,
        updateWizardSnapshot,
        wizardSnapshot: snapshot,
        // errors props
        hasNewErrors,
        errorsForStep,
        stepsWithErrors,
        underwritingIssues,
        acknowledgeError,
        reportErrors
    };

    // we pass the props to both the component
    // and the context to allow nested components to reuse them
    // without prop-drilling
    return (
        <WizardContext.Provider value={wizardProps}>
            <Prompt message={handlePromptMessage} />
            <WizardRoutes basePath={basePath} {...wizardProps} />
        </WizardContext.Provider>
    );
}

Wizard.propTypes = {
    /** the title for this wizard */
    wizardTitle: PropTypes.oneOfType([
        PropTypes.shape({
            id: PropTypes.string
        }),
        PropTypes.string
    ]),
    /**
     * the steps that will compose this wizard
     * (once initialized, they cannot be changed from the props)
     */
    initialSteps: PropTypes.arrayOf(PropTypes.shape(wizardStepProps)).isRequired,
    /**
     * the data to pass to the wizard
     * (once initialized, it cannot be changed from props)
     */
    initialData: PropTypes.shape({}),
    /**
     * the React Router location
     */
    location: PropTypes.shape({
        pathname: PropTypes.string,
        state: PropTypes.shape({})
    }).isRequired,
    /**
     * the React Router history
     */
    history: PropTypes.shape({}).isRequired,
    /**
     * The React Router match
     */
    match: PropTypes.shape({ url: PropTypes.string }).isRequired,
    /**
     * Steps to match errors to the specific wizard step (e.g. backend validation)
     */
    wizardStepToFieldMapping: PropTypes.shape({}),
    /**
     * whether the wizard should begin while skipping the completed steps
     * (once initialized, this cannot be changed from the props)
     */
    skipCompletedSteps: PropTypes.bool,
    /**
     * Callback that will be called when the wizard is finished.
     * This will be called with one parameter containing all the wizard data
     */
    onFinish: PropTypes.func,
    /**
     * Callback that will be called when the wizard is canceled.
     * This will be called with one parameter containing all the wizard data
     */
    onCancel: PropTypes.func,
    onPrevious: PropTypes.func,
    onPreviousModalProps: PropTypes.shape({
        title: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        }),
        message: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        }),
        status: PropTypes.string,
        icon: PropTypes.string,
        confirmButtonText: PropTypes.string,
        cancelButtonText: PropTypes.string
    }),
    /**
     * Callback that will be called when the wizard is interrupted because of
     * a knock-out error.
     * This will be called with one parameter containing all the wizard data
     */
    onKnockOut: PropTypes.func,
    /**
     * Where this wizard is based
     * If not passed the current path of the page will be used.
     * This is particularly useful when nesting one wizard inside another
     */
    basePath: PropTypes.string
};

Wizard.defaultProps = {
    wizardTitle: null,
    initialData: null,
    skipCompletedSteps: false,
    onCancel: _.noop,
    onPrevious: handleOnPrevious,
    onPreviousModalProps: {
        title: commonMessages.wantToJump,
        message: commonMessages.wantToJumpMessage,
        status: 'warning',
        icon: 'mi-error-outline',
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.close
    },
    onFinish: _.noop,
    wizardStepToFieldMapping: {},
    basePath: undefined,
    onKnockOut: _.noop
};

export default withRouter(Wizard);
