import { defineMessages } from 'react-intl';

export default defineMessages({
    sectionTitle: {
        id: 'policy.common.component.contacts.Policy Info',
        defaultMessage: 'Schedule of Basic Policies'
    },
    policyType: {
        id: 'policy.common.component.scheduleInfo.Policy Type',
        defaultMessage: 'Policy Type'
    },
    carrier: {
        id: 'policy.common.component.scheduleInfo.Carrier',
        defaultMessage: 'Carrier'
    },
    policyNumber: {
        id: 'policy.common.component.scheduleInfo.Policy Number',
        defaultMessage: 'Policy Number'
    },policyEffective: {
        id: 'policy.common.component.scheduleInfo.Policy Effective',
        defaultMessage: 'Policy Effective'
    },
    policyExpiration: {
        id: 'policy.common.component.scheduleInfo.Policy Expiration',
        defaultMessage: 'Policy Expiration'
    }
});
