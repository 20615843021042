import { defineMessages } from 'react-intl';

export default defineMessages({
    policyAddress: {
        id: 'fnol.pa.components.repair-facilities-search-option.Policy address',
        defaultMessage: 'Policy address'
    },
    incident: {
        id: 'fnol.pa.components.repair-facilities-search-option.Incident',
        defaultMessage: 'Incident'
    },
    myLocation: {
        id: 'fnol.pa.components.repair-facilities-search-option.My current location',
        defaultMessage: 'My current location'
    },
    mySelectedLocation: {
        id: 'fnol.pa.components.repair-facilities-search-option.My selected location',
        defaultMessage: 'My selected location'
    },
    searchByAddressOrZIPCode: {
        id: 'fnol.pa.components.map-search.Search by address or ZIP code',
        defaultMessage: 'Search by address or ZIP code'
    },
    searchNear: {
        id: 'fnol.pa.components.repair-facilities-search-option.Search near:',
        defaultMessage: 'Search near:'
    },
    incidentAddress: {
        id: 'fnol.pa.components.repair-facilities-search-option.Incident address',
        defaultMessage: 'Incident address'
    }
});
