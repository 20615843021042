import React, {
    useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import { ModalNextProvider, Icon, Loader } from '@jutro/components';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { ServiceManager } from '@jutro/services';

import { withAuthenticationContext } from 'gw-digital-auth-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { messages as commonMessages } from 'gw-platform-translations';
import lobIconUtil from 'gw-portals-util-js/lobIconUtil';

import metadata from './BillingSummaryPage.metadata.json5';
import styles from './BillingSummaryPage.module.scss';
import messages from './BillingSummaryPage.messages';

const getTabHeader = (productCode, label = '') => {
    const icon = lobIconUtil.getMaterialIcon(productCode);
    return (
        <div id={`divFor${productCode}${label}`} className={styles.tabContent}>
            {productCode ? (
                <Icon id={`iconFor${productCode}${label}`} icon={icon} title={label} />
            ) : null}
            <span id={`labelFor${productCode}${label}`}>{label.toUpperCase()}</span>
        </div>
    );
};

function BillingSummaryPage(props) {
    const { match, authHeader, history } = props;
    const { params } = match;
    const { streamId } = params;

    const localeService = ServiceManager.getService('locale-service');
    const defaultCurrencyCode = localeService.getDefaultCurrencyCode().toUpperCase();

    const { AccountBillingDetailsService: BillingService } = useDependencies(
        'AccountBillingDetailsService'
    );

    const { PolicyService } = useDependencies('PolicyService');

    // screen states
    const [isLoadingData, updateIsLoadingData] = useState(true);
    const [selectedInvoiceStreamId, updateSelectedInvoiceStreamId] = useState(streamId);
    const [selectedCurrency, updateSelectedCurrency] = useState(defaultCurrencyCode);
    const [activeTab, updateActiveTab] = useState(selectedInvoiceStreamId);

    // fetched data
    const [accountBillingSummaryFromApi, setAccountBillingSummaryFromApi] = useState({});
    const [invoiceStreamDetailsMapFromApi, setInvoiceStreamDetailsMapFromApi] = useState(new Map());
    const [policySummariesFromApi, setPolicySummariesFromApi] = useState([]);
    const [documentSummariesFromApi, setDocumentSummariesFromApi] = useState([]);
    const [disbursementSummariesFromApi, setdisbursementSummariesFromApi] = useState([]);
    const [paymentInfoSummariesFromApi, setPaymentInfoSummariesFromApi] = useState([]);

    const { accountLevelBilling = false, invoiceStreams = [] } = accountBillingSummaryFromApi;

    const updateMap = useCallback(
        (invoiceStreamId) => (fetchData) => {
            setInvoiceStreamDetailsMapFromApi(
                invoiceStreamDetailsMapFromApi.set(invoiceStreamId, fetchData)
            );

            const { totalValue } = fetchData;
            const { currency } = totalValue;
            updateSelectedCurrency(currency.toUpperCase());
        },
        [invoiceStreamDetailsMapFromApi]
    );

    const fetchAccountLevelInvoiceStreams = useCallback(() => {
        let accountInvoicePromise;
        let policySummaryPromise;

        if (_.isEmpty(accountBillingSummaryFromApi)) {
            accountInvoicePromise = BillingService.getAccountInvoiceStreamInformation(
                authHeader
            ).then((accountInvoiceStreams) => {
                const {
                    accountLevelBilling: accountLevelBillingFromApi = false,
                    invoiceStreams: invoiceStreamsFromApi = []
                } = accountInvoiceStreams;

                let defaultInvoiceStreamId;
                if (invoiceStreamsFromApi.length > 0) {
                    defaultInvoiceStreamId = invoiceStreamsFromApi[0].internalId;
                    updateSelectedInvoiceStreamId(invoiceStreamsFromApi[0].internalId);
                }
                if (accountLevelBillingFromApi && invoiceStreamsFromApi.length === 1) {
                    defaultInvoiceStreamId = invoiceStreamsFromApi[0].internalId;
                    updateSelectedInvoiceStreamId(invoiceStreamsFromApi[0].internalId);
                    updateActiveTab(invoiceStreamsFromApi[0].internalId);
                }

                setAccountBillingSummaryFromApi(accountInvoiceStreams);
                return defaultInvoiceStreamId;
            });
        } else {
            accountInvoicePromise = Promise.resolve(accountBillingSummaryFromApi);
        }

        if (policySummariesFromApi.length === 0) {
            policySummaryPromise = PolicyService.getAccountPolicySummaries(authHeader).then(
                setPolicySummariesFromApi
            );
        } else {
            policySummaryPromise = Promise.resolve(policySummariesFromApi);
        }

        return Promise.all([accountInvoicePromise, policySummaryPromise]).catch(() => {
            ModalNextProvider.showAlert({
                title: messages.failedToLoadPolices,
                message: messages.sorryWeCanNotLoadYourPolicyInformationAtThisTime,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).then(() => {
                history.push('/home');
            }, _.noop);
        });
    }, [
        BillingService,
        PolicyService,
        history,
        authHeader,
        accountBillingSummaryFromApi,
        policySummariesFromApi
    ]);

    const getPaymentDetailsGroupFromMap = useCallback(
        (invoiceStreamId) => {
            return invoiceStreamDetailsMapFromApi.get(invoiceStreamId);
        },
        [invoiceStreamDetailsMapFromApi]
    );
    const fetchInvoiceDetailsByStreamId = useCallback(
        (invoiceStreamId, forceRefresh = false) => {
            const invoiceDetails = getPaymentDetailsGroupFromMap(invoiceStreamId);
            let invoiceDetailsPromise;

            if ((invoiceStreamId && _.isEmpty(invoiceDetails)) || forceRefresh) {
                invoiceDetailsPromise = BillingService.getInvoiceStreamDetails(
                    invoiceStreamId,
                    authHeader
                )
                    .then(updateMap(invoiceStreamId))
                    .catch(() => {
                        ModalNextProvider.showAlert({
                            title: messages.failedToLoadPolices,
                            message: messages.sorryWeCanNotLoadYourPolicyInformationAtThisTime,
                            status: 'error',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.ok
                        }).then(() => {
                            history.push('/home');
                        }, _.noop);
                    });
            }
         else {
                invoiceDetailsPromise = Promise.resolve(invoiceDetails);
            }

            return invoiceDetailsPromise;
        },
        [getPaymentDetailsGroupFromMap, BillingService, authHeader, updateMap, history]
    );

    const selectedLobCode = useMemo(() => {
        const foundInvoiceStreams = invoiceStreams.filter(
            (item) => item.internalId === selectedInvoiceStreamId
        );
        return foundInvoiceStreams.length > 0 ? foundInvoiceStreams[0].lobcode : undefined;
    }, [invoiceStreams, selectedInvoiceStreamId]);

    const selectedPolicynumber = useMemo(() => {
        const foundInvoiceStreams = invoiceStreams.filter(
            (item) => item.internalId === selectedInvoiceStreamId
        );
        return foundInvoiceStreams.length > 0 ? foundInvoiceStreams[0].label : undefined;
    }, [invoiceStreams, selectedInvoiceStreamId]);
    
    const getDocumentsForSelectedPolicy = useMemo(() => {
        const foundInvoiceStreams = invoiceStreams.filter(
            (item) => item.internalId === selectedInvoiceStreamId
        );
        if(foundInvoiceStreams && foundInvoiceStreams.length>0){
            return documentSummariesFromApi.filter((elt)=>elt.policyNumber === foundInvoiceStreams[0].label);
        }
        return  undefined;
    }, [invoiceStreams, selectedInvoiceStreamId, documentSummariesFromApi]);

    const getDisbursementForSelectedPolicy = useMemo(() => {
        const foundInvoiceStreams = invoiceStreams.filter(
            (item) => item.internalId === selectedInvoiceStreamId
        );
        if(foundInvoiceStreams && foundInvoiceStreams.length>0){
            return disbursementSummariesFromApi.filter((elt)=>elt.policyNumber === foundInvoiceStreams[0].label);
        }
        return  undefined;
    }, [invoiceStreams, selectedInvoiceStreamId, disbursementSummariesFromApi]);

    const getPaymentForSelectedPolicy = useMemo(() => {
        const foundInvoiceStreams = invoiceStreams.filter(
            (item) => item.internalId === selectedInvoiceStreamId
        );
        if(foundInvoiceStreams && foundInvoiceStreams.length>0){
            return paymentInfoSummariesFromApi.filter((elt)=>elt.policyNumber === foundInvoiceStreams[0].label);
        }
        return  undefined;
    }, [invoiceStreams, selectedInvoiceStreamId, paymentInfoSummariesFromApi]);

    const onTabClick = useCallback(
        (invoiceStreamIdFromTabId) => {
            fetchInvoiceDetailsByStreamId(invoiceStreamIdFromTabId).finally(() => {
                updateActiveTab(invoiceStreamIdFromTabId);
                updateSelectedInvoiceStreamId(invoiceStreamIdFromTabId);
            });
            const invoiceDetails = getPaymentDetailsGroupFromMap(invoiceStreamIdFromTabId);
            const policyNumber = _.get(invoiceDetails, "displayname")
        },
        [fetchInvoiceDetailsByStreamId]
    );

    const reloadInvoiceDetails = useCallback(
        (invoiceStreamId) => {
            updateIsLoadingData(true);
            return fetchInvoiceDetailsByStreamId(invoiceStreamId, true).finally(() => {
                updateIsLoadingData(false);
            });
        },
        [fetchInvoiceDetailsByStreamId]
    );

    useEffect(() => {
        BillingService.getDisbursementDetails(authHeader).then(
        setdisbursementSummariesFromApi);
        }
   ,[]);

   useEffect(() => {
    BillingService.getPaymentInformation(authHeader).then(
    setPaymentInfoSummariesFromApi);
    }
,[]);
   
    useEffect(() => {
        updateIsLoadingData(true);
        fetchAccountLevelInvoiceStreams().then((retValues) => {
            if (retValues) {
                const initStreamId = selectedInvoiceStreamId || retValues[0];
                fetchInvoiceDetailsByStreamId(initStreamId).finally(() => {
                    onTabClick(initStreamId);
                    updateIsLoadingData(false);
                });
            }
        });
        // execure once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
         BillingService.getDocumentsDetails(authHeader).then(
         setDocumentSummariesFromApi);
         }
    ,[]);


    

    const noData = useCallback(
        () => {
            return invoiceStreams.length === 0;
        },
        [invoiceStreams]
    );

    if (isLoadingData) {
        return <Loader loaded={!isLoadingData} />;
    }

    const overrideProps = {
        noDataMessageContainer: {
            visible: noData(),
            message: messages.noBillingData
        },
        billingSummaryContainer: {
            visible: !noData(),
        },
        billingTabsId: {
            //data: invoiceStreams,
            content: _.map(invoiceStreams, (item) => {
                return {
                    id: item.internalId,
                    type: 'element',
                    component: 'tab',
                    componentProps: {
                        heading: getTabHeader(item.lobcode, item.label)
                    },
                    content: [
                        {
                            id: `billingContentFor${item.internalId}`,
                            type: 'container',
                            component: 'BillingSummary',
                            componentProps: {
                                accountLevelBilling,
                                invoiceStreamId: selectedInvoiceStreamId,
                                lobCode: selectedLobCode,
                                policyNumber: selectedPolicynumber,
                                paymentGroupDetails: getPaymentDetailsGroupFromMap(
                                    selectedInvoiceStreamId
                                ),
                                currency: selectedCurrency,
                                policySummaries: policySummariesFromApi,
                                reloadInvoiceDetails: reloadInvoiceDetails,
                                documentSummaries:getDocumentsForSelectedPolicy,
                                disbursementSummaries:getDisbursementForSelectedPolicy,
                                paymentInformationSummaries:getPaymentForSelectedPolicy,
                                invoiceStreams: invoiceStreams
                            }
                        }
                    ]
                };
            }),
            activeTab: activeTab,
            onTabChange: onTabClick
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(metadata.pageContent, overrideProps, resolvers);
}

BillingSummaryPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            streamId: PropTypes.string
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({}).isRequired
};

export default withAuthenticationContext(withRouter(BillingSummaryPage));