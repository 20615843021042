import React, { useContext, useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import PropTypes from 'prop-types';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { withRouter } from 'react-router-dom';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { VehicleDamagePicker, fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { WizardPage, wizardProps, WizardPageTemplate } from 'gw-portals-wizard-react';
import { PhoneNumber } from 'gw-components-platform-react';
import {
    Button,
    InputField,
    Icon,
    Link,
    Chevron,
    IconButton
} from '@jutro/components';

// eslint-disable-next-line import/no-unresolved
import config from 'app-config';

import FNOLContactItemComponent from '../../components/ContactItemComponent/ContactItemComponent';

import messages from '../../FNOLPA.messages';
import metadata from './VehiclesPage.metadata.json5';
import paClaimVehicle from './VehiclesPage.module.scss';
import {ContactComponent} from 'nlc-capability-fnol-common-react';

function FNOLPAVehiclesPage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useContext(TranslatorContext);
    const { wizardData: claimVM, updateWizardData } = props;
    const { oldestCarYear } = config.personalAutoConfig;
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        isComponentValid,
        initialValidation,
        onValidate,
        registerComponentValidation
    } = useValidation('VehiclesPage');
    const newVehicle = {
        placeholder: true
    };
    const newContact = {
        placeholder: true
    };
    const unknownVehicle ={
        placeholder: true,
        isUnknown: true
    }

    const validateTableForm = useCallback(() => {
        if (
            !claimVM.lobs.personalAuto.vehicleIncidents.aspects.valid
            || !claimVM.lobs.personalAuto.vehicleIncidents.aspects.subtreeValid
        ) {
            return false;
        }
        return true;
    }, [claimVM]);

    useEffect(() => {
        const vehicleIncidents = claimVM.lobs.personalAuto.vehicleIncidents.value;
        if (_.isEmpty(vehicleIncidents)) {
            claimVM.lobs.personalAuto.value.createVehicleIncident();
            updateWizardData(claimVM);
        }
        registerComponentValidation(validateTableForm);
    }, [
        registerComponentValidation,
        validateTableForm,
        oldestCarYear,
        claimVM,
        updateWizardData,
        viewModelService
    ]);

    const writeValue = useCallback(
        (value, path) => {
            const newClaimVM = _.clone(claimVM);
            _.set(newClaimVM, path, value);
            updateWizardData(newClaimVM);
        },
        [claimVM, updateWizardData]
    );

    const getAvailableVehicles = useCallback(
        (selectedVehicle, index) => {
            const vehiclesItems = claimVM.lobs.personalAuto.vehicleIncidents.value[
                index
            ].availableVehicles(claimVM.lobs.personalAuto.value, selectedVehicle);
            vehiclesItems.push(newVehicle);
            vehiclesItems.push(unknownVehicle);

            // if there is a new vehicle added pass it in to list of available vehicles
            if (
                !_.isEmpty(
                    claimVM.lobs.personalAuto.vehicleIncidents.children[index].vehicle.value
                )
                && !vehiclesItems.includes(
                    claimVM.lobs.personalAuto.vehicleIncidents.children[index].vehicle.value
                )
            ) {
                vehiclesItems.push(
                    claimVM.lobs.personalAuto.vehicleIncidents.children[index].vehicle.value
                );
            }

            const vehicleValues = vehiclesItems.map((vehicleItem) => {
                const val = vehicleItem.placeholder
                    ? vehicleItem.isUnknown? translator(messages.paVehicleUnknownVehicle) : translator(messages.paVehicleOtherVehicle)
                    : vehicleItem.getDisplayName(translator(messages.paVehicleNewVehicle));
                return {
                    code: val,
                    name: val
                };
            });
            return vehicleValues;
        },
        [claimVM, newVehicle, translator]
    );

    const getVehicleDamagedValue = useCallback(
        (index) => {
            const vehiclePath = `lobs.personalAuto.vehicleIncidents.value[${index}].vehicle`;
            const selectedVehicle = _.get(claimVM, vehiclePath);
           
            return !_.isEmpty(selectedVehicle)
                ? (selectedVehicle.isUnknownVehicle? translator(messages.paVehicleUnknownVehicle) : selectedVehicle.getDisplayName(translator(messages.paVehicleNewVehicle)))
                : '';
        },
        [claimVM, translator]
    );

    const getDriverValue = useCallback(
        (index) => {
            const driverPath = `lobs.personalAuto.vehicleIncidents.value[${index}].driver`;
            const selectedDriver = _.get(claimVM, driverPath);
            return !_.isEmpty(selectedDriver)
                ? selectedDriver.getDisplayName(translator(messages.paVehicleNewDriver))
                : '';
        },
        [claimVM, translator]
    );

    const getAvailableYears = useCallback(() => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = currentYear; i >= oldestCarYear; i -= 1) {
            years.push({
                code: i,
                name: i
            });
        }
        return years;
    }, [oldestCarYear]);

    const handleVehicleSelect = useCallback(
        (value, selectedIndex) => {
            let selectedVehicle = {};
            if (value === translator(messages.paVehicleOtherVehicle)) {
                const vehicleIncidentPath = `lobs.personalAuto.vehicleIncidents.value[${selectedIndex}]`;
                const vehicleIncident = _.get(claimVM, vehicleIncidentPath);
                vehicleIncident.setNewVehicle(claimVM.value);               
                updateWizardData(claimVM);
            } else if(value === translator(messages.paVehicleUnknownVehicle)) {
                const vehicleIncidentPath = `lobs.personalAuto.vehicleIncidents.value[${selectedIndex}]`;
                const vehicleIncident = _.get(claimVM, vehicleIncidentPath);
                vehicleIncident.setUnknownVehicle_Ext(claimVM.value);               
                updateWizardData(claimVM);
            } else {
                const vehiclesItems = _.get(claimVM, 'lobs.personalAuto.vehicles.value', []);
                selectedVehicle = vehiclesItems.find((vehicle) => {
                    const displayName = vehicle.getDisplayName();
                    return displayName === value;
                });
                if (!_.isEmpty(selectedVehicle)) {
                    _.set(
                        claimVM,
                        `lobs.personalAuto.vehicleIncidents.value[${selectedIndex}].vehicle`,
                        selectedVehicle
                    );
                }
                updateWizardData(claimVM);
            }
        },
        [claimVM, translator, updateWizardData]
    );

    const getAvailableDrivers = useCallback(
        (selectedContact, index) => {
            const driversItems = claimVM.lobs.personalAuto.vehicleIncidents.value[
                index
            ].availableDrivers(claimVM.lobs.personalAuto.value, selectedContact);
            driversItems.push(newContact);
            const driverValues = driversItems.map((driverValue) => {
                const val = driverValue.placeholder
                    ? translator(messages.paVehicleOtherDriver)
                    : driverValue.getDisplayName(translator(messages.paVehicleNewDriver));
                return {
                    code: val,
                    name: val
                };
            });
            return driverValues;
        },
        [claimVM, newContact, translator]
    );

    const handleDriverSelect = useCallback(
        (value, selectedIndex) => {
            let selectedDriver = {};
            if (value === translator(messages.paVehicleOtherDriver)) {
                const vehicleIncidentPath = `lobs.personalAuto.vehicleIncidents.value[${selectedIndex}]`;
                const vehicleIncident = _.get(claimVM, vehicleIncidentPath);
                vehicleIncident.setNewDriver(claimVM.value);
                updateWizardData(claimVM);
            } else {
                const driversItems = _.get(claimVM, 'contacts.value', []);
                selectedDriver = driversItems.find((driver) => {
                    const driverName = driver.getDisplayName(
                        translator(messages.paVehicleNewDriver)
                    );
                    return driverName === value;
                });
                if (!_.isEmpty(selectedDriver)) {
                    _.set(
                        claimVM,
                        `lobs.personalAuto.vehicleIncidents.value[${selectedIndex}].driver`,
                        selectedDriver
                    );
                }
                updateWizardData(claimVM);
            }
        },
        [claimVM, translator, updateWizardData]
    );

    const createTypeList = useCallback(() => {
        let typeList = viewModelService.create(
            {},
            'cc',
            'edge.capabilities.claim.lob.impl.commonauto.dto.VehicleIncidentDTO'
        );
        typeList = typeList.collisionPoint.aspects.availableValues[0].typelist;
        return typeList;
    }, [viewModelService]);

    const renderTriggerAddPerson = useCallback(
        (dropDownProps, toggleMenu) => {
            const { isOpen } = dropDownProps;
            const onAddPersonClick = () => {
                toggleMenu(!isOpen);
            };
            return (
                <Button onClick={onAddPersonClick} icon="mi-expand_more" iconPosition="right">
                    {translator(messages.paClaimAddAPassenger)}
                </Button>
            );
        },
        [translator]
    );

    const handleVehicleIncidentDelete = useCallback(
        (event, vehicleIncident) => {
            event.stopPropagation();
            claimVM.lobs.personalAuto.value.removeVehicleIncident(vehicleIncident);
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData]
    );

    const renderAccordionHeader = useCallback(
        (isOpen, vehicleIncident) => {
            const vehicleIncidentsPath = 'lobs.personalAuto.vehicleIncidents.value';
            const vehicleIncidents = _.get(claimVM, vehicleIncidentsPath);
            const vi = vehicleIncident;
            let vehicleAccordionTitle;
            if (!_.isEmpty(vehicleIncident.vehicle)) {
                if(vehicleIncident.vehicle.isUnknownVehicle) {
                    vehicleAccordionTitle = translator(messages.paVehicleUnknownVehicle);
                } else{
                    let { make, model, year } = vi.vehicle;
                    make = _.isObject(make) ? make.value : make;
                    model = _.isObject(model) ? model.value : model;
                    year = _.isObject(year) ? year.value : year;
                    vehicleAccordionTitle = ` ${year || ''} ${make || ''} ${model || ''}`;
                }
            } else {
                vehicleAccordionTitle = '';
            }
            vehicleAccordionTitle = _.trim(vehicleAccordionTitle) === ''
                ? ` ${translator(messages.paVehicleNewVehicle)}`
                : vehicleAccordionTitle;
            return (
                <React.Fragment>
                    <Chevron
                        isOpen={isOpen}
                        align="right"
                        className={paClaimVehicle.chevronStyle}
                    />
                    <h4 className="wizardTitle">
                        <Icon icon="mi-directions_car" />
                        {vehicleAccordionTitle}
                        {vehicleIncidents.length > 1 ? (
                            <IconButton
                                icon="mi-delete"
                                value={vehicleIncident}
                                className={paClaimVehicle.paVehicleIncidentDeleteIcon}
                                onClick={(event) => {
                                    handleVehicleIncidentDelete(event, vehicleIncident);
                                }}
                            />
                        ) : (
                            <></>
                        )}
                    </h4>
                </React.Fragment>
            );
        },
        [claimVM, handleVehicleIncidentDelete, translator]
    );

    const generatePassengerTableRow = useCallback(
        (evt, incidentIndex, index) => {
            evt.preventDefault();
            const getCurrentMenuItemID = evt.currentTarget.id;
            const getCurrentMenuItemIDNew = getCurrentMenuItemID.split('_');
            const contactsPath = 'contacts.value';
            const contactsItems = _.get(claimVM, contactsPath, []);
            if (getCurrentMenuItemIDNew[0].indexOf('anotherPersonLink') === -1) {
                const selectedPassenger = contactsItems[index];
                if (_.filter(contactsItems, selectedPassenger).length) {
                    claimVM.lobs.personalAuto.vehicleIncidents.value[incidentIndex].addPassenger(
                        selectedPassenger,
                        claimVM.value
                    );
                }
            } else {
                claimVM.lobs.personalAuto.vehicleIncidents.value[incidentIndex].addPassenger(
                    null,
                    claimVM.value
                );
            }
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData]
    );

    const generateVehicleOverrides = useCallback(() => {
        const vehicleIncidentsPath = 'lobs.personalAuto.vehicleIncidents.value';
        const vehicleIncidents = _.get(claimVM, vehicleIncidentsPath, []);
        const overrides = vehicleIncidents.map((vehicleIncident, index) => {
            return {
                [`paAddPassengerTable${index}`]: {
                    data: vehicleIncident.passengers,
                    visible: !_.isEmpty(vehicleIncident.passengers)
                },
                [`paVehicleAccordionDetails${index}`]: {
                    renderHeader: (isOpen) => {
                        return renderAccordionHeader(isOpen, vehicleIncident);
                    }
                },
                [`paPassengersAddPassenger${index}`]: {
                    renderTrigger: renderTriggerAddPerson
                },
                [`paVehiclesWhichDamaged${index}`]: {
                    availableValues: getAvailableVehicles(vehicleIncident.vehicle, index),
                    onValueChange: (value) => {
                        handleVehicleSelect(value, index);
                    },
                    value: getVehicleDamagedValue(index),
                    showOptional: false,
                    required: true
                },
                [`paVehicleOtherVehicleYear${index}`]: {
                    availableValues: getAvailableYears(),
                    value: _.get(
                        claimVM,
                        `lobs.personalAuto.vehicleIncidents.children[${index}].vehicle.year.value`
                    )
                },
                [`paVehicleOtherVehicleSection${index}`]: {
                    visible:
                        !_.isEmpty(
                            _.get(
                                claimVM,
                                `lobs.personalAuto.vehicleIncidents.children[${index}].vehicle.value`
                            )
                        )
                        && !_.get(
                            claimVM,
                            `lobs.personalAuto.vehicleIncidents.children[${index}].vehicle.policyVehicle.value`
                        )
                },
                [`paVehicleOtherDriverSection${index}`]: {
                    visible:
                        !_.isEmpty(
                            _.get(
                                claimVM,
                                `lobs.personalAuto.vehicleIncidents.children[${index}].driver.value`
                            )
                        )
                        && !_.get(
                            claimVM,
                            `lobs.personalAuto.vehicleIncidents.children[${index}].driver.policyRole.value`
                        )
                },
                [`paVehiclesWhoDriving${index}`]: {
                    availableValues: getAvailableDrivers(vehicleIncident.driver, index),
                    value: getDriverValue(index),
                    onValueChange: (value) => {
                        handleDriverSelect(value, index);
                    },
                    showOptional: true
                },
                [`paPointOfImpactSelect${index}`]: {
                    collisionPoints: createTypeList(),
                    onValueChange: writeValue
                },
                [`addPersonDropDownItems${index}`]: {
                    data: claimVM,
                    incidentIndex: index,
                    updateAddPassengers: updateWizardData
                },
                [`anotherPersonLink${index}`]: {
                    onClick: (e) => {
                        generatePassengerTableRow(e, index);
                    }
                },
                 [`pavehicleLocationAddress${index}`]: {
                    visible: _.get(claimVM, `lobs.personalAuto.vehicleIncidents.children[${index}].vehicleTowed.value`),
                    onValueChange: writeValue
                },
                [`vehicleDetailContainer${index}`]: {
                    visible: !_.get(claimVM, `lobs.personalAuto.vehicleIncidents.children[${index}].vehicle.isUnknownVehicle.value`),
                },
            };
        });
        return Object.assign({}, ...overrides);
    }, [
        claimVM,
        createTypeList,
        generatePassengerTableRow,
        getAvailableDrivers,
        getAvailableVehicles,
        getAvailableYears,
        getDriverValue,
        getVehicleDamagedValue,
        handleDriverSelect,
        handleVehicleSelect,
        renderAccordionHeader,
        renderTriggerAddPerson,
        updateWizardData,
        writeValue
    ]);

    const getAddPassengerName = useCallback(
        (item, index, property) => {
            let [vehicleIncidentIndex] = (property.path || '').match(/\d+/g) || [];
            vehicleIncidentIndex = Number(vehicleIncidentIndex);
            const elementPath = `lobs.personalAuto.vehicleIncidents.value[${vehicleIncidentIndex}].passengers[${index}].${property.id}`;
            return (
                <InputField
                    id={`${property.id}_${vehicleIncidentIndex}_${index}`}
                    path={elementPath}
                    onValueChange={writeValue}
                    value={item[property.id]}
                    disabled={!_.isEmpty(item.publicID)}
                    hideLabel
                />
            );
        },
        [writeValue]
    );

    const getAddPassengerPhone = useCallback(
        (item, index, property) => {
            let [vehicleIncidentIndex] = (property.path || '').match(/\d+/g) || [];
            vehicleIncidentIndex = Number(vehicleIncidentIndex);
            const elementPath = `lobs.personalAuto.vehicleIncidents.value[${vehicleIncidentIndex}].passengers[${index}].${property.id}`;
            return (
                <PhoneNumber
                    id={`${property.id}_${vehicleIncidentIndex}_${index}`}
                    path={elementPath}
                    onValueChange={writeValue}
                    value={item[property.id]}
                    disabled={!_.isEmpty(item.publicID)}
                    hideLabel
                    alwaysShowMask
                />
            );
        },
        [writeValue]
    );

    const removePassengerItem = useCallback(
        (e, index, incidentIndex) => {
            e.preventDefault();
            const newClaimVM = _.clone(claimVM);
            newClaimVM.lobs.personalAuto.vehicleIncidents.value[incidentIndex].passengers.splice(
                index,
                1
            );
            updateWizardData(newClaimVM);
        },
        [claimVM, updateWizardData]
    );

    const getAddPassengerRemove = useCallback(
        (item, index, property) => {
            let [vehicleIncidentIndex] = (property.path || '').match(/\d+/g) || [];
            vehicleIncidentIndex = Number(vehicleIncidentIndex);
            return (
                <Link
                    to="/"
                    onClick={(e) => removePassengerItem(e, index, vehicleIncidentIndex)}
                    title={translator(messages.paClaimPassengerEdit)}
                    className={classNames(
                        paClaimVehicle.addPersonDeleteSection,
                        paClaimVehicle.deleteRow
                    )}
                >
                    <Icon icon="mi-delete" className={paClaimVehicle.addPersonRemoveIcon} />
                </Link>
            );
        },
        [removePassengerItem, translator]
    );

    const addVehicleIncident = useCallback(() => {
        if (validateTableForm()) {
            claimVM.lobs.personalAuto.value.createVehicleIncident();
            updateWizardData(claimVM);
        }
    }, [claimVM, updateWizardData, validateTableForm]);

    const onNext = useCallback(() => {
        const vehiclesList = _.get(claimVM, 'lobs.personalAuto.vehicles.value');
        const vehicleIncidentsList = _.get(claimVM, 'lobs.personalAuto.vehicleIncidents.value');
        vehicleIncidentsList.map((vehicleIncident) => {
            if (
                !_.isEmpty(vehicleIncident.vehicle)
                && !vehiclesList.includes(vehicleIncident.vehicle)
            ) {
                vehiclesList.push(vehicleIncident.vehicle);
            }
            return vehicleIncident;
        });
        _.set(claimVM, 'lobs.personalAuto.vehicles.value', vehiclesList);
        return claimVM;
    }, [claimVM]);

    const overrides = {
        '@field': {
            showOptional: true
        },
        paAddVehicleButton: {
            disabled: !isComponentValid
        },
        paClaimAccordion: {
            size: breakpoint === 'desktop' ? 'medium' : 'small'
        },
        witnesOrOtherParties: {
            claimVM,
            updateWizardData
        },
        ...generateVehicleOverrides()
    };
    const resolvers = {
        resolveClassNameMap: paClaimVehicle,
        resolveComponentMap: {
            vehiclecollision: VehicleDamagePicker,
            contactitemcomponent: FNOLContactItemComponent,
            contactComponent:ContactComponent
        },
        resolveCallbackMap: {
            getAddPassengerName: getAddPassengerName,
            getAddPassengerPhone: getAddPassengerPhone,
            getAddPassengerRemove: getAddPassengerRemove,
            addVehicleIncident: addVehicleIncident,
        
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
        },
        [claimVM, overrides]
    );

    return (
        <WizardPage
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            template={WizardPageTemplate}
            onNext={onNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrides}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

FNOLPAVehiclesPage.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired
};

FNOLPAVehiclesPage.propTypes = wizardProps;
export default withRouter(FNOLPAVehiclesPage);
