import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import appConfig from 'app-config';
import { useAuthentication } from 'gw-digital-auth-react';
import { Loader } from '@jutro/components';
import { OtpService } from 'nlc-capability-otp';

function NlcLoginComponent(props) {
    const isMFAEnabled = _.get(appConfig, 'env.MFA_Enabled_Ext', 'false');
    const { history } = props;
    const { authHeader } = useAuthentication();
    const [isLoading, setLoading] = useState(false);
    const auth = useAuthentication();
    const { user_name: userName } = auth;
    const nlcCeampDontAskThisUUID = localStorage.getItem('nlc-ceamp-dontaskthis-uuid');
    const [browserResponseData, setBrowserResponseData] = useState()

    useEffect(() => {
        setLoading(true);
        if (isMFAEnabled && isMFAEnabled === 'true') {
            const mfaSessionStatus = sessionStorage.getItem('mfaStatus');
            if (!mfaSessionStatus || mfaSessionStatus === 'false') {

                try {
                    const browserData = new FormData();
                    browserData.append('userName', userName);
                    if (nlcCeampDontAskThisUUID !== null && nlcCeampDontAskThisUUID !== undefined)
                        browserData.append('uuid', nlcCeampDontAskThisUUID);
                    else
                        browserData.append('uuid', "");
                    const dontAskData = {
                        method: 'POST',
                        body: browserData
                    }
                    OtpService.browserData(dontAskData).then(res => {
                        if (res.status === 200) {
                            sessionStorage.setItem('mfaStatus', 'true');
                            const nextState = { pathname: '/home' };
                            history.push(nextState);
                        } else {
                            const inputData = new FormData();
                            inputData.append('userName', userName);

                            const data = {
                                method: 'POST',
                                body: inputData

                            }
                            OtpService.getSignUpVerificationStatus(data).then((otp) => {
                                if (otp.status === 200) {
                                    otp.json().then((st) => {
                                        const email = st.email;
                                        const phoneNumber = st.phoneNumber;
                                        if (st.isVerified && st.isVerified === "false") {
                                            const nextState = { pathname: '/nlc-signup-otp-verification', state: { userName, email, phoneNumber, authHeader } };
                                            history.push(nextState);
                                        } else {
                                            const nextState = { pathname: '/nlc-login-otp-verification', state: { userName, email, phoneNumber, authHeader } };
                                            history.push(nextState);
                                        }
                                    });
                                }
                            });
                        }
                    });


                } catch (err) {
                    console.log(err);
                }
            } else {
                const nextState = { pathname: '/home' };
                history.push(nextState);
            }
        } else {
            const nextState = { pathname: '/home' };
            history.push(nextState);

        }
    }, [history, userName, browserResponseData]);

    const verifyBrowserIdAndRedirect = async () => {
        const browserData = new FormData();
        browserData.append('userName', userName);
        browserData.append('uuid', nlcCeampDontAskThisUUID);
        const data = {
            method: 'POST',
            body: browserData
        }
        OtpService.browserData(data).then(res => {
            console.log("Response Status=" + res.status);
            if (res.status === 200) {
                sessionStorage.setItem('mfaStatus', 'true');
                const nextState = { pathname: '/home' };
                history.push(nextState);
            }
        });

    }

    return (<Loader loaded={!isLoading} />);
}

NlcLoginComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
};

export default NlcLoginComponent;
