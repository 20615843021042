import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { withRouter, Redirect } from 'react-router-dom';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { EntryPageTemplate } from 'gw-components-platform-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { isPolicyEnrollment } from '../../EnrollmentUtil';
import EnrollmentComponent from '../../components/EnrollmentComponent/EnrollmentComponent';

import metadata from './SignUpEnrollment.metadata.json5';
import messages from './SignUpEnrollment.messages';

const applicationDetails = _.get(appConfig, 'applicationDetails', {});
const isMultiAccountUser = _.get(appConfig, 'self-enrollment.multiAccountEnrollment', false);
const enrollmentType = _.get(appConfig, 'self-enrollment.type', '');

function SignUpEnrollment(props) {
    const { isLoggedIn, logout } = useAuthentication();
    const { history, location } = props;

    const onSuccessEnrollment = useCallback(() => {
        history.push('/home');
    }, [history]);

    const overrides = {
        enrollmentComponent: {
            enrollmentType: enrollmentType.toUpperCase(),
            onSuccessEnrollment,
            informationText: isPolicyEnrollment(enrollmentType)
                ? messages.provideVerificationInformationPolicy
                : messages.provideVerificationInformationAccount,
            informationAdditionalText:
                isMultiAccountUser === true
                    ? messages.ableToAddMorePolicies
                    : messages.ableToAddOnePolicy,
            onReturnLinkClick: logout,
            returnLinkText: messages.logout,
            enrollButtonText: isPolicyEnrollment(enrollmentType)
                ? messages.addPolicy
                : messages.addAccount
        }
    };

    const resolvers = {
        resolveComponentMap: {
            enrollmentcomponent: EnrollmentComponent
        }
    };

    const renderedContentFromMetadata = renderContentFromMetadata(
        metadata.pageContent,
        overrides,
        resolvers
    );

    if (!isLoggedIn) {
        const nextState = { pathname: '/login-page', state: { from: location } };
        return <Redirect to={nextState} />;
    }

    return (
        <EntryPageTemplate
            applicationName={applicationDetails.applicationName}
            applicationLogo={applicationDetails.applicationLogo}
            contentToRender={renderedContentFromMetadata}
        />
    );
}

SignUpEnrollment.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({}).isRequired
};

export default withRouter(SignUpEnrollment);
