import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class FNOLService {
    static submitClaim(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnol'),
            'submitClaim', [data], additionalHeaders);
    }

    static getFNOLDetails(policyNumber, data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnol'),
            'createClaim', [policyNumber, data], additionalHeaders);
    }

    static getClaim(claimNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnol'),
            'getClaim', [claimNumber], additionalHeaders);
    }

    static saveFNOLDetails(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnol'),
            'saveClaim', [data], additionalHeaders);
    }

    static searchPolicies(policySearchCriteria, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnolPolicy'), 'searchPolicies', [policySearchCriteria], additionalHeaders);
    }

    static getPolicySummaryRiskUnits(policyNumber, lossDate, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('fnolPolicy'), 'getPolicySummaryRiskUnits', [policyNumber, lossDate], additionalHeaders);
    }

    static getAccountsContactSummaries(authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('userProfileInfo'), 'getAccountsContactSummaries', [], authHeader);
    }
}
