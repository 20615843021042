import React, { useState,useMemo,useContext, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from 'gw-portals-validation-react';
import { WizardPage, wizardProps, WizardPageTemplate } from 'gw-portals-wizard-react';
import { fnolCommonMessages } from 'gw-capability-fnol-common-react';
import metadata from './FireDamagePage.metadata.json5';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { PhoneNumber } from 'gw-components-platform-react';
import {
    Button,
    InputField,
    Icon,
    DropdownSelectField,
    Link,
    ModalNextProvider
} from '@jutro/components';
import  messages  from '../../../nlc-capability-fnol-common-react/FNOL.messages';
import styles from '../../../nlc-capability-fnol-common-react/pages/AdditionalInformation/AdditionalInformationPage.module.scss';
import Claim from '../../../nlc-capability-fnol-common-react/models/Claim';
import classNames from 'classnames';

const ROLES_TO_FILTER = ['witness', 'other'];

const getPhoneFieldBasedOnPrimaryPhoneType = (relatedContact) => {
    let primaryPhoneType;
    switch (relatedContact.contact.primaryPhoneType) {
        case 'home':
            primaryPhoneType = 'homeNumber';
            break;
        case 'work':
            primaryPhoneType = 'workNumber';
            break;
        case 'mobile':
            primaryPhoneType = 'cellNumber';
            break;
        default:
            primaryPhoneType = 'homeNumber';
    }
    return primaryPhoneType;
};
function FNOLFireDamagePage(props) {
    const {
        wizardData: claimVM,
        updateWizardData,
        history: {
            location: { state = {} }
        }
    } = props;
    const { claimStatus } = state;
    const translator = useContext(TranslatorContext);
    const [claimObj, updateClaimObj] = useState({});
    const viewModelService = useContext(ViewModelServiceContext);


    const writeValue = useCallback(
        (value, path) => {
            _.set(claimVM, path, value);
            updateWizardData(claimVM);
            _.set(claimObj, 'relatedContacts', claimVM.relatedContacts.value);
        },
        [claimObj, claimVM, updateWizardData]
    );

    const {
        onValidate,
        initialValidation,
        isComponentValid,
        registerInitialComponentValidation
    } = useValidation('FNOLHOFireDamagePage');

    const initialiseVM = useCallback(() => {
        // claimVM.lobs.homeowners.propertyFireDamage.value = !_.isEmpty(
        //     claimVM.lobs.homeowners.propertyFireDamage.value
        // )
        //     ? claimVM.lobs.homeowners.propertyFireDamage.value
        //     : {
        //         isHomeHabitable: true,
        //         isHomeSecure: true
        //     };
        
        if (_.isNil(_.get(claimVM.value, 'lobs.homeowners.propertyFireDamage.isHomeSecure'))) {
            _.set(claimVM.value, 'lobs.homeowners.propertyFireDamage.isHomeSecure', '');
        }
        if (_.isNil(_.get(claimVM.value, 'lobs.homeowners.propertyFireDamage.isHomeHabitable'))) {
            _.set(claimVM.value, 'lobs.homeowners.propertyFireDamage.isHomeHabitable', '');
        }
        return claimVM;
    }, [claimVM]);

    const isClaimStatus = useCallback(() => {
        return _.get(claimVM.value, 'lossCause') === 'fire' && !_.isEmpty(claimStatus);
    }, [claimStatus, claimVM]);

    const getContactsRelatedPersons = useCallback((contactsItem) => {
        if (contactsItem.subtype === 'Person' && contactsItem.lastName) {
            return `${contactsItem.firstName} ${contactsItem.lastName}`;
        }
        return '';
    }, []);

    const generateMoreInfoAddPersonOverrides = useCallback(() => {
        const contactsPath = 'value.contacts';
        const contactsItems = _.get(claimVM, contactsPath, []);
        let availableRelatedContacts = [];
        if (!_.isEmpty(claimObj)) {
            availableRelatedContacts = claimObj.availableRelatedContacts().filter((contact) => {
                return contact.subtype === 'Person' && contact.firstName;
            });
        }

        if (!contactsItems.length) {
            return {};
        }
        const overrides = contactsItems.map((contactItem, index) => {
            return {
                [`contactItems${index}`]: {
                    message: getContactsRelatedPersons(contactItem)
                },
                [`contactItemsDropDown${index}`]: {
                    visible: _.includes(availableRelatedContacts, contactItem)
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [claimVM, claimObj, getContactsRelatedPersons]);

    const getFirstLastNameData = useCallback(
        (item, rowId, property) => {
            const { id, path } = property;
            const claimVMPath = `relatedContacts.value[${rowId}].${path}`;
            const setDisable = item.contact.publicID
                ? 'disabled'
                : '';
            return (
                <InputField
                    id={`${id}_${rowId}`}
                    className={styles.tableColumn}
                    path={claimVMPath}
                    onValueChange={writeValue}
                    value={_.get(claimVM, claimVMPath)}
                    disabled={setDisable}
                />
            );
        },
        [claimVM, writeValue]
    );

    const getPhoneNumberData = useCallback(
        (item, rowId) => {
            const phoneField = getPhoneFieldBasedOnPrimaryPhoneType(item);
            const claimVMPath = `relatedContacts.value[${rowId}].contact.${phoneField}`;
            const setDisable = item.contact.publicID
                ? 'disabled'
                : '';
            return (
                <PhoneNumber
                    id={`${item.primaryPhoneID}_${rowId}`}
                    alwaysShowMask
                    onValueChange={writeValue}
                    path={claimVMPath}
                    disabled={setDisable}
                    value={_.get(claimVM, claimVMPath)}
                />
            );
        },
        [claimVM, writeValue]
    );

    const filteredRoleTypeList = useMemo(() => {
        const typeList = viewModelService.create(
            {},
            'cc',
            'edge.capabilities.claim.fnol.dto.FnolRelatedContactDTO'
        );
        const filteredList = typeList.role.aspects.availableValues
            .filter((typeCode) => {
                return ROLES_TO_FILTER.includes(typeCode.code);
            })
            .map((typeCode) => {
                return {
                    code: typeCode.code,
                    name: translator({
                        id: typeCode.name,
                        defaultMessage: typeCode.name
                    })
                };
            });
        return filteredList;
    }, [translator, viewModelService]);
    const getInvolvementInjuredData = useCallback(
        (item, rowId, property) => {
            const { id, path } = property;
            let availableValues;
            const claimVMPath = `relatedContacts.value[${rowId}].${path}`;
            if (id === 'role') {
                availableValues = filteredRoleTypeList;
            } else {
                availableValues = [
                    {
                        code: 'true',
                        name: translator(messages.fnolYes)
                    },
                    {
                        code: 'false',
                        name: translator(messages.fnolNo)
                    }
                ];
            }
            return (
                <DropdownSelectField
                    id={`${id}_${rowId}`}
                    alwaysShowPlaceholder={false}
                    availableValues={availableValues}
                    path={claimVMPath}
                    onValueChange={writeValue}
                    value={_.get(claimVM, claimVMPath)}
                    className={styles.involvementField}
                />
            );
        },
        [claimVM, filteredRoleTypeList, translator, writeValue]
    );
    const removeContactItem = useCallback(
        (e, rowId) => {
            e.preventDefault();
            const { relatedContacts } = claimVM;
            const relatedContactToDelete = relatedContacts.value.filter((relatedContact, index) => {
                return index === rowId;
            });
            claimObj.removeRelatedContact(relatedContactToDelete[0].contact);
            _.set(claimVM.value, 'relatedContacts', claimObj.relatedContacts);
            updateWizardData(claimVM);
        },
        [claimVM, claimObj, updateWizardData]
    );

    const removeContactRow = useCallback(
        (item, index) => {
            return (
                <Link
                    to="/"
                    onClick={(e) => removeContactItem(e, index, item)}
                    className={classNames(styles.deleteSection, styles.deleteRow)}
                >
                    <Icon icon="mi-delete" className={styles.deleteIcon} />
                </Link>
            );
        },
        [removeContactItem]
    );

    const generateNewWitnessRow = useCallback(
        (event) => {
            event.preventDefault();
            const { contacts } = claimVM;
            const getCurrentMenuItemID = event.currentTarget.id;
            if (getCurrentMenuItemID.indexOf('anotherPersonLink') === -1) {
                const getIndex = parseInt(getCurrentMenuItemID.match(/[0-9]/gi), 10);
                const selectedContact = contacts.value[getIndex];
                claimObj.addRelatedContact_ext(selectedContact);
                _.set(claimVM.value, 'relatedContacts', claimObj.relatedContacts);
            } else {
                claimObj.addRelatedContact_ext(null);
                _.set(claimVM.value, 'relatedContacts', claimObj.relatedContacts);
            }
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData, claimObj]
    );
    const generateAddPersonMenuDropDown = useCallback(
        (dropDownProps, toggleMenu) => {
            const { isOpen } = dropDownProps;
            const onAddPersonClick = () => {
                toggleMenu(!isOpen);
            };
            return (
                <Button onClick={onAddPersonClick} icon="mi-expand_more" iconPosition="right">
                    {translator(messages.fnolAddPerson)}
                </Button>
            );
        },
        [translator]
    );


    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    useEffect(
        () => {
            const nextclaimVM = initialiseVM();
            updateWizardData(nextclaimVM);
            updateClaimObj(new Claim(claimVM.value));
        },
        // Call once when page gets rendered
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const overrides = {
        '@field': {
            showOptional: true
        },
        witnessPartyTable: {
            data: claimVM.relatedContacts.value,
            visible: !_.isEmpty(claimVM.relatedContacts.value)
        },
        ...generateMoreInfoAddPersonOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            generateWitnessPartyTableRow: generateNewWitnessRow,
            getFirstNameData: getFirstLastNameData,
            getLastNameData: getFirstLastNameData,
            getInvolvementData: getInvolvementInjuredData,
            getPhoneNumberData: getPhoneNumberData,
            getInjuredData: getInvolvementInjuredData,
            removeContactRow: removeContactRow,
            renderTrigger: generateAddPersonMenuDropDown
        }
    };

    return (
        <WizardPage
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            skipWhen={initialValidation}
            template={WizardPageTemplate}
            disableNext={!isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrides}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

FNOLFireDamagePage.propTypes = wizardProps;
export default withRouter(FNOLFireDamagePage);
