import { defineMessages } from 'react-intl';

export default defineMessages({
    provideVerificationInformationPolicy: {
        id: 'enrollment.components.signupenrollment.Please provide verification information for the policy you wish to access.',
        defaultMessage: 'Please provide verification information for the policy you wish to access.'
    },
    provideVerificationInformationAccount: {
        id: 'enrollment.components.signupenrollment.Please provide verification information for the account you wish to access.',
        defaultMessage: 'Please provide verification information to proceed with NLC Online Account creation.'
    },
    ableToAddMorePolicies: {
        id: 'enrollment.components.signupenrollment.You will be able to add access to other policies once you log in.',
        defaultMessage: 'You will be able to add access to other policies once you log in.'
    },
    ableToAddOnePolicy: {
        id: 'enrollment.components.signupenrollment.Simply complete all fields for online access to your policy information.',
        defaultMessage: 'Simply complete all fields for online access to your policy information.'
    },
    addPolicy: {
        id: 'enrollment.components.signupenrollment.Add Policy',
        defaultMessage: 'Add Policy'
    },
    addAccount: {
        id: 'enrollment.components.signupenrollment.Add Account',
        defaultMessage: 'Add Account'
    },
    logout: {
        id: 'enrollment.components.signupenrollment.Log out',
        defaultMessage: 'Log out'
    }
});
