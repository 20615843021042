import React, { useContext, useState, useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TranslatorContext, IntlContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import VehicleCoverageTable from '../VehicleCoverageTable/VehicleCoverageTable';
import metadata from './PolicyVehiclesDriversInfo.metadata.json5';
import messages from './PolicyVehiclesDriversInfo.messages';
import styles from './PolicyVehiclesDriversInfo.module.scss';
import { BreakpointTrackerContext } from '@jutro/layout';
import PALineLevelCoverages from '../PALineLevelCoverages/PALineLevelCoverages';
import PAVehicleLevelCoverages from '../PAVehilceLevelCoverages/PAVehicleLevelCoverages';
import PALineLevelCoveragesRI from '../PALineLevelCoveragesRI/PALineLevelCoveragesRI';
import PAVehicleLevelCoveragesRI from '../PAVehilceLevelCoveragesRI/PAVehicleLevelCoveragesRI';

function PolicyVehiclesDriversInfo(props) {
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const [openItems, setOpenItems] = useState([]);
    const { value: paCoverages , coverables , jurisdiction } = props;

    const intl = useContext(IntlContext);

    const getFormattedDate = useCallback((item) => {
        return intl.formatDate(new Date(item), { year: 'numeric', month: 'short', day: 'numeric' });
    }, [intl]);

    const getLimitDeductibleValue = (terms, type) => {
        if (!_.isEmpty(terms) ) {
            return terms[0].amount;
        }
        return '-';
    };

    const getLimitDeductibleValueNew = (terms, type) => {
        if (!_.isEmpty(terms) && terms[0].modelType == type && (terms[0].name != 'Limit per Day/Maximum Limit' ||
        terms[0].name != 'Uninsured Motorists Property Damage (Each Accident)') )  {
            return terms[0].amount;
        }
        else if( !_.isEmpty(terms) && (terms[0].name == 'Limit per Day/Maximum Limit' || 
        terms[0].name == 'Uninsured Motorists Property Damage (Each Accident)')){
          return terms[0].amount
        }
        else return '-';
    };

    const getCoverage = useCallback(
        (coverages, isLineCoverage) => {
            return coverages.map((coverage) => {
                return {
                    name: coverage.name,
                    coverageType: isLineCoverage ? translator(messages.vehicleInfoPolicy) : null,
                    limit: getLimitDeductibleValue(coverage.terms, 'Limit'),
                 //   deductible: getLimitDeductibleValue(coverage.terms, 'Deductible'),
                    premium: coverage.premium
                };
            });
        },
        [translator]
    );

    const getCoverageNew = useCallback(
        (coverages, isLineCoverage,index) => {
            return coverages.map((coverage) => {
                return {
                    name: coverage.name,
                    coverageType: isLineCoverage ? translator(messages.vehicleInfoPolicy) : null,
                    limit: getLimitDeductibleValueNew(coverage.terms, 'Limit'),
                    deductible: getLimitDeductibleValueNew(coverage.terms, 'Deductible'),
                    premium: coverage.premium,
               //     vehicleType : paCoverages.vehicleDTOs[index].vehicleType_Ext
                };
            });
        },
        [translator]
    );

     const getVehicleType = useCallback(
         (coverages, index) => {
            
                 return {
                    vehicleType : paCoverages.vehicleDTOs[index].vehicleType_Ext
                 };
             
         },
         [translator]
     );

   

    

    const lineCoverage = getCoverage(paCoverages.coverageDTOs, true);

    const toggleShowHide = useCallback(
        (item) => {
            const openedItem = _.clone(openItems);
            const index = _.indexOf(openedItem, item);
            if (index === -1) {
                openedItem.push(item);
                setOpenItems(openedItem);
            } else {

                setOpenItems(_.slice(openedItem, index, 0));
            }
        },
        [openItems]
    );

    const generateVehicleOverrides = useCallback(() => {
        const overrides = paCoverages.vehicleDTOs.map((vehicleDTO, index) => {
            const vehicleCoverage = getCoverageNew(vehicleDTO.coverages,index);
            const vehicleType = getVehicleType(vehicleDTO.vehicleType_Ext,index);
         //   const vehicleType = getVehicleType(vehicleDTO);
          //  const vehicleUse = getVehicleUse(vehicleDTO)
            return {
                // [`vehicleCoverageInfo${index}`]: {
                //    // data: _.concat(lineCoverage, vehicleCoverage),
                //    data:vehicleCoverage,
                //     show: openItems
                // },
                // [`lineLevelCoverageInfo${index}`]: {
                //     // data: _.concat(lineCoverage, vehicleCoverage),
                //     data:lineCoverage,
                    
                //      show: openItems
                //  },
                 [`lineLevelCoverageInfonew${index}`]: {
                    visible : jurisdiction == 'Connecticut',
                     data: _.concat(lineCoverage, vehicleCoverage),
                 
                    
                     vehicleType : vehicleType,
                    paCoverages : paCoverages,
                     show: openItems
                 },
                 [`vehicleLevelCoverageInfonew${index}`]: {
                    // data: _.concat(lineCoverage, vehicleCoverage),
                    visible : jurisdiction == 'Connecticut',
                    data:vehicleCoverage,
                    paCoverages : paCoverages,
                    
                     show: openItems
                    
                     
                 },
                 [`lineLevelCoverageInfonewRI${index}`]: {
                    // data: _.concat(lineCoverage, vehicleCoverage),
                    visible : jurisdiction == 'Rhode Island',
                    data:lineCoverage,
                    vehicleType : vehicleType,
                    paCoverages : paCoverages,
                     show: openItems
                 },
                 [`vehicleLevelCoverageInfonewRI${index}`]: {
                    // data: _.concat(lineCoverage, vehicleCoverage),
                    visible : jurisdiction == 'Rhode Island',
                    data:vehicleCoverage,
                    paCoverages : paCoverages,
                    
                     show: openItems
                 },
                [`showContentToggleLink${index}`]: {
                    onClick: () => toggleShowHide(index)
                },
                [`vehicleYear${index}`]: {
                    hideLabel: index >0 && breakpoint==='desktop'? true : false
                },
                [`vehicleMake${index}`]: {
                    hideLabel: index >0 && breakpoint==='desktop'? true : false
                },
                [`vehicleModel${index}`]: {
                    hideLabel: index >0 && breakpoint==='desktop'? true : false
                },
                [`vehicleLicence${index}`]: {
                    hideLabel: index >0 && breakpoint==='desktop'? true : false
                },
                [`primaryUse${index}`]: {
                    hideLabel: index >0 && breakpoint==='desktop'? true : false
                },                          
                [`contentDetailToggle${index}`]: {
                    icon: _.includes(openItems, index) ? 'caret-up' : 'caret-down',
                    message: _.includes(openItems, index)
                        ? translator(messages.hideVehicleCoverages)
                        : translator(messages.showVehicleCoverages)
                },
                [`vehicleCoverageContainer${index}`]: {
                    visible: _.includes(openItems, index)
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [paCoverages, toggleShowHide, breakpoint]);

    const generateDriverOverrides = useCallback(() => {
        const overrides = paCoverages.driverDTOs.map((driverDTO, index) => {
            return {
                [`driverName${index}`]: {
                    hideLabel: index >0 && breakpoint==='desktop'? true : false 
                },
                [`driverDOB${index}`]: {
                    hideLabel: index >0 && breakpoint==='desktop'? true : false  
                },
                [`driverLicense${index}`]: {
                    hideLabel: index >0 && breakpoint==='desktop'? true : false  
                },
                [`driverLicenseNum${index}`]: {
                    hideLabel: index >0 && breakpoint==='desktop'? true : false 
                }, 
            };
        });

        return Object.assign({}, ...overrides);
    }, [paCoverages, breakpoint]);

    const overrides = {

        
        ...generateDriverOverrides(),
        ...generateVehicleOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
         //   vehiclecoverage: VehicleCoverageTable,
         //   lineLevelcoverage: LineLevelCoverageTable,
            lineLevelcoveragenew : PALineLevelCoverages,
            vehicleLevelcoveragenew : PAVehicleLevelCoverages,
            lineLevelcoveragenewRI : PALineLevelCoveragesRI,
            vehicleLevelcoveragenewRI : PAVehicleLevelCoveragesRI
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={paCoverages}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PolicyVehiclesDriversInfo.propTypes = {
    value: PropTypes.shape({
        coverageDTOs: PropTypes.arrayOf(PropTypes.shape({})),
        vehicleDTOs: PropTypes.arrayOf(PropTypes.shape({})),
        driverDTOs: PropTypes.arrayOf(PropTypes.shape({})),
        additionalLiabilityCoverages: PropTypes.arrayOf(PropTypes.shape({})),
        additionalPropertyCoverages: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired
};

export default PolicyVehiclesDriversInfo;
