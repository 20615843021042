import { renderContentFromMetadata } from '@jutro/uiconfig';
import { ServiceManager } from '@jutro/services';
import metadata from './Preferences.metadata.json5';
import './Preferences.messages';

function Preferences() {
    const localeService = ServiceManager.getService('locale-service');

    const overrideProps = {
        globalizationChooserField: {
            onLanguageValueChange: localeService.saveLanguage,
            languageValue: localeService.getStoredLanguage()
        }
    };
    const resolvers = {};

    return renderContentFromMetadata(metadata.pageContent, overrideProps, resolvers);
}

export default Preferences;
