import { getProxiedUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class UserService {
    static getLoggedinUserDetails(authHeader = {}) {
        return JsonRPCService.send(getProxiedUrl('user'), 'getLoggedinUserDetails', [], authHeader);
    }

    static updateVerificationOfEmailAndMobile(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedUrl('user'), 'updateVerificationOfEmailAndMobile', data, additionalHeaders);
    }

    static getVerificationOfEmailAndMobile(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedUrl('user'), 'getVerificationOfEmailAndMobile', [], additionalHeaders);
    }

}
