import { defineMessages } from 'react-intl';

export default defineMessages({
   selectMedia:{
       id:"login.otp.Select how you want to receive your verification code:",
       defaultMessage:"Select how you want to receive your verification code:"
   },
   sendCode:{
        id:"login.otp.Send Code",
        defaultMessage:"Send Code"
    },
    codeSentMessage:{
        id:"login.otp.info.message.A verification code has been sent, please enter the code below.",
        defaultMessage:"A verification code has been sent, please enter the code below."
    },
    verificationCode:{
        id:"login.otp.Verification Code:",
        defaultMessage:"Verification Code:"
    },
    rememberMeOnThisDevice:{
        id:"login.remember.Remember me on this Device",
        defaultMessage:"Remember me on this Device"
    },
    invalidOtpMsg:{
        id:"login.otp.Incorrect code - please re-enter the code or click on the send code button again.",
        defaultMessage:"Incorrect code - please re-enter the code or click on the send code button again."
    },
    userLocked: {
        id:"login.otp.You have exceeded the maximum number of Send Code requests. You may attempt to have the verification code resent again after 1 hour.",
        defaultMessage:"You have exceeded the maximum number of Send Code requests. You may attempt to have the verification code resent again after 1 hour."
    },
    signIn:{
        id:"login.otp.Sign In",
        defaultMessage:"Sign In"
    },
    backButton:{
        id:"login.otp.Back",
        defaultMessage:"Back"
    },
    whiteListedEmailMsg: {
        id: 'login.otp.whitelistedemailmsg',
        defaultMessage: 'No matching whitelisted Email entry found.'
    },
    whiteListedPhoneMsg: {
        id: 'login.otp.whitelistedphonemsg',
        defaultMessage: 'No matching whitelisted Phone Number entry found.'
    }
});
