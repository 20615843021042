import React, { useContext, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { WizardPage, WizardPageTemplate, wizardProps } from 'gw-portals-wizard-react';
import { useValidation } from 'gw-portals-validation-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { TranslatorContext } from '@jutro/locale';
import { FNOLLossLocation, fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './TheftDetailsPage.metadata.json5';
import styles from './TheftDetailsPage.module.scss';
import './TheftDetailsPage.messages';

function FNOLCATheftDetailsPage(props) {
    const {
        wizardData: claimVM,
        updateWizardData,
        history: {
            location: { state = {} }
        }
    } = props;
    const translator = useContext(TranslatorContext);
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('FNOLCATheftPage');

    useEffect(() => {
        const theftCodes = {
            theftentire: _.find(claimVM.lossCause.aspects.availableValues, {
                code: 'theftentire'
            }),
            theftparts: _.find(claimVM.lossCause.aspects.availableValues, {
                code: 'theftparts'
            })
        };
        _.set(
            claimVM.value,
            'lobs.commercialAuto.vehicleIncidents.theft',
            _.values(theftCodes).includes(claimVM.lossCause.value)
        );
        _.set(claimVM.value, 'lobs.commercialAuto.vehicleIncidents.safeToDrive', false);
        updateWizardData(claimVM);
        // It should call once when component gets mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getLossCause = useCallback(
        (selectedLossCause) => {
            _.set(claimVM, 'lossCause.value', selectedLossCause);
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData]
    );

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        lossLocationComponent: {
            path: !_.isUndefined(claimVM) ? claimVM : null,
            updatePath: updateWizardData
        },
        whatStolen: {
            value: _.get(claimVM.value, 'lossCause')
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            losslocationcomponent: FNOLLossLocation
        },
        resolveCallbackMap: {
            onValidate: onValidate,
            getLossCause: getLossCause
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrideProps);
        },
        [claimVM, overrideProps]
    );

    const isClaimStatus = useCallback(() => {
        const { claimStatus } = state;
        const lossCauseCheck = _.get(claimVM.value, 'lossCause');
        return !_.isEmpty(claimStatus) && lossCauseCheck === 'theftentire';
    }, [claimVM.value, state]);

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    return (
        <WizardPage
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            skipWhen={initialValidation}
            template={WizardPageTemplate}
            disableNext={!isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                callbackMap={resolvers.resolveCallbackMap}
                resolveValue={readValue}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

FNOLCATheftDetailsPage.propTypes = wizardProps;
export default withRouter(FNOLCATheftDetailsPage);
