import React, {
    useEffect, useState, useMemo, useCallback, useContext
} from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import {
    Icon, Loader, InfoLabel
} from '@jutro/components';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { ProducerInfoService, UsersProfileDetailsService } from 'gw-capability-profileinfo';
import { PolicyService } from 'gw-capability-policy';
import { AMPSubmissionDraftService } from 'gw-capability-quoteandbind';
import { DocumentService } from 'gw-capability-policydocument';
import { DateRangeComponent, Link, Currency as CurrencyField } from 'gw-components-platform-react';
import { getNormalizedLOBName, isCapabilityEnabled } from 'gw-portals-config-js';
import { useAuthentication } from 'gw-digital-auth-react';
import { LobIconUtil, LocalDateUtil } from 'gw-portals-util-js';
import { generateURL } from 'gw-portals-url-js';
import { TranslatorContext } from '@jutro/locale';
import { ImageComponent } from 'gw-components-platform-react';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import styles from './LandingPage.module.scss';
import metadata from './LandingPage.metadata.json5';
import messages from './LandingPage.messages';

const productImages = {
    'PersonalAuto':{        
        'imgSrc':'/branding/nlc-lob-pa.png',
        'showPrintIDCard':true
    },
    'Homeowners':{        
        'imgSrc':'/branding/nlc-lob-ho.png'
    },
    'PersonalUmbrella':{        
        'imgSrc':'/branding/nlc-lob-pe.png'
    },
    'Dwellingfire' :{
        'imgSrc':'/branding/nlc-lob-df.png'
    }
    
}

const getCellType = (rowData) => {
    const productDetails = productImages[rowData.productCode_Ext];
    if(productDetails){
        return(<ImageComponent
            src={productDetails.imgSrc}
            title={rowData.productName_Ext}
            alt={rowData.productName_Ext}
        />);
    }
};

const isPolicyActive = (periods) => {
    const currentRenewed = periods.length > 1;
    const activePeriod = _.first(periods);
    return new Date(activePeriod.effective).getTime() < new Date().getTime() && !currentRenewed;
};

const shouldRenewalNotificationShow = (accountPolicyData) => {
    return accountPolicyData.some((policy) => policy.periods.length > 1);
};

const getCellStatus = (rowData) => {
    if (isPolicyActive(rowData.periods)) {
        return <InfoLabel type="success" size="medium" message={messages.statusActive} />;
    }

    return <InfoLabel type="info" size="medium" message={messages.statusRenewed} />;
};

const getCellPolicy = (rowData) => {
    const activePeriod = _.first(rowData.periods);
    const periodWithIDCard = rowData.periods.find((policy) => policy.idCardPublicID);
    return (
        <Link
            className={styles.link}
            to={{
                pathname: `/account-policy-details/${activePeriod.policyId}`,
                productCode: activePeriod.lines[0],
                idCard: periodWithIDCard ? {
                    idCardSessionID: _.get(periodWithIDCard, 'idCardSessionID'),
                    idCardPublicID: _.get(periodWithIDCard, 'idCardPublicID')
                } : null
            }}
        >
            {activePeriod.policyId}
        </Link>
    );
};

const getCellRiskUnits =(rowData)=>{
    const activePeriod = _.first(rowData.periods);  
    if(activePeriod.vehicles && activePeriod.vehicles.length > 0)
    {
        var vs=[];
        for(let e in activePeriod.vehicles)
        {
            vs[e] = activePeriod.vehicles+'\n';
            return vs[e];
            
        }    
    }else if(activePeriod.dwelling){
        return activePeriod.dwelling;
    }
    return '';
}

const getCellAgent = (rowData) => {
    const activePeriod = _.first(rowData.periods);
    const to = `/account-policy-details/${activePeriod.policyId}`;
    return (
        <Link className={styles.link} to={to}>
            {activePeriod.producerName}
        </Link> 
    );
};

const getCellEffective = (rowData, rowIndex) => {
    const activePeriod = _.first(rowData.periods);
    return(
        <span>{activePeriod.effective} - {activePeriod.expiration}</span>
    );
};

const getCellClaim = (rowData) => {
    const activePeriod = _.first(rowData.periods);

    const redirectPath = {
        pathname: '/fnol-select-policy',
        state: {
            policyNumber: activePeriod.policyId,
            redirectPath: '/home',
            claimData: { lossDate: new Date() }
        }
    };
    return (
        <Link to={redirectPath} className={styles.link}>
            {messages.fileClaim}
        </Link>
    );
};

const getCellPremium = (rowData, rowIndex) => {
    const activePeriod = _.first(rowData.periods);
    return (
        <CurrencyField
            id={`premium_${rowIndex}`}
            value={activePeriod.premium}
            dataType="object"
            readOnly
            hideLabel
        />
    );
};

const getPrintAllIDCards = (rowData) => {
    const productDetails = productImages[rowData.productCode_Ext];
    const activePeriod = _.first(rowData.periods);
    const policyNumber = _.get(activePeriod,'policyId');
    const docSessionId = _.get(rowData,'documentSessionId_Ext');
    const idCardlink = DocumentService.downloadPolicyDocument_Ext('PersonalAutoIDCard',policyNumber, docSessionId);
    if(productDetails && productDetails.showPrintIDCard){
        return(
            <Link target="_blank" href={idCardlink} className={styles.link}>{messages.printID}</Link>
        );
    }    
}

function LandingPage(props) {
    const translator = useContext(TranslatorContext);
    const [accountBillingData, setAccountBillingData] = useState([]);
    const [accountPolicyData, setAccountPolicyData] = useState([]);
    const { authHeader } = useAuthentication();
    const auth = useAuthentication();
    const [accountData, setAccountData] = useState([]);
    const [accountHolderName, setAccountHolderName] = useState(null);
    const [isLoading, setLoadingState] = useState(false);
    const { capabilitiesConfig } = appConfig;

    useEffect(() => {
        setLoadingState(true);
        let billingPromise = Promise.resolve([]);
        if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' })) {
            billingPromise = AccountBillingDetailsService.getAccountBillingSummary(authHeader);
        }
        Promise.all([billingPromise.then(setAccountBillingData),
            PolicyService.getAccountPolicySummaries(authHeader).then((data)=>{
                setAccountPolicyData(data)
                if(data.length>0 ){
                    setAccountHolderName(data[0].accountHolderName_Ext);
                }
            }),
            UsersProfileDetailsService.getAccountsContactSummaries(authHeader).then((data)=>{
                setAccountData(data)
                if(data.length>0 ){
                    setAccountHolderName(data[0].accountContact.firstName?data[0].accountContact.firstName:data[0].accountContact.displayName);
                }
            }),
        ]).finally(() => {
            setLoadingState(false);
        });
        // disable re render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const aggregateBilling = useCallback((invoices) => {
        if (invoices.length === 0) {
            return null;
        }

        let total = 0;
        let due = new Date(invoices[0].dueDate);

        _.each(invoices, (invoice) => {
            total += invoice.amountDue.amount;
            const newDue = new Date(invoice.dueDate);
            if (newDue.getTime() < due.getTime()) {
                due = newDue;
            }
        });

        return {
            amountDue: {
                amount: total,
                currency: invoices[0].amountDue.currency
            },
            earliestDue: due
        };
    }, []);

    const getMyBalanceOverDueAndCurrentData = useCallback(
        (invoiceStatus) => {
            if (accountBillingData.activeInvoices) {
                const invoices = accountBillingData.activeInvoices;
                return aggregateBilling(
                    invoices.filter((invoice) => invoice.invoiceStatus === invoiceStatus)
                );
            }
            return null;
        },
        [accountBillingData.activeInvoices, aggregateBilling]
    );

    const getBalanceData = useCallback(() => {
        const overdue = getMyBalanceOverDueAndCurrentData('due');
        const current = getMyBalanceOverDueAndCurrentData('billed');
        if (overdue && current) {
            return {
                amount: current.amountDue.amount + overdue.amountDue.amount,
                currency: current.amountDue.currency
            };
        }
        if (overdue || current) {
            return (overdue || current).amountDue;
        }
        return null;
    }, [getMyBalanceOverDueAndCurrentData]);

    const readValue = useCallback(
        (id, path) => {
            if (path === 'balanceAmount') {
                return getBalanceData();
            }
            if (path === 'overDueAmount') {
                return _.get(getMyBalanceOverDueAndCurrentData('due'), 'amountDue');
            }
            if (path === 'currentAmount') {
                return _.get(getMyBalanceOverDueAndCurrentData('billed'), 'amountDue');
            }
            if (path === 'dueDate') {
                return _.get(getMyBalanceOverDueAndCurrentData('due'), 'earliestDue');
            }
            return _.get(accountBillingData, path);
        },
        [accountBillingData, getBalanceData, getMyBalanceOverDueAndCurrentData]
    );

    const policyWithIDCard = useMemo(() => {
        let periodWithIDCard;
        accountPolicyData.some((policyData) => {
            return policyData.periods.some((period) => {
                if (period.idCardPublicID) {
                    periodWithIDCard = period;
                    return true;
                }
                return false;
            });
        });
        return periodWithIDCard;
    }, [accountPolicyData]);

    const getIDCardQuickLink = useMemo(() => {
        if (!policyWithIDCard) {
            return null;
        }
        return DocumentService.downloadPolicyDocument(
            policyWithIDCard.idCardPublicID,
            policyWithIDCard.idCardSessionID
        );
    }, [policyWithIDCard]);

    const automatedPaymentURL = useMemo(() => {
        const billingID = _.get(accountBillingData, 'manualPayPaymentGroups[0]');

        if (!billingID) {
            return undefined;
        }
        return `billing-summary/${billingID}`;
    }, [accountBillingData]);

    const firstAccountSummaryDefined = useMemo(() => {
        return accountData.length <= 1 && !_.isEmpty(accountData);
    }, [accountData]);

    const mailToTrigger = useCallback((event, email) => {
        event.preventDefault();
        window.location = `mailto:${email}`;
    }, []);

    const claimCapabilityCheck = useMemo(() => {
        return isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'fnol' });
    }, []);

    const onQuoteQuickLinkClick = useCallback(
        (lob) => () => {
            const accountSummary = accountData[0];
            const submission = {
                baseData: {
                    productCode: lob,
                    accountHolder: accountSummary,
                    accountNumber: accountSummary.accountNumber,
                    policyAddress: accountSummary.accountContact.primaryAddress,
                    periodStartDate: LocalDateUtil.today()
                }
            };
            AMPSubmissionDraftService.createForAccount(submission, authHeader).then(
                (savedSubmission) => {
                    const { url } = appConfig.quoteAndBuy;
                    const location = `${url}/quote-${getNormalizedLOBName(lob)}`;
                    const params = {
                        quoteID: savedSubmission.quoteID,
                        postalCode: savedSubmission.baseData.policyAddress.postalCode
                    };

                    window.location = generateURL(location, params);
                }
            );
        },
        [accountData, authHeader]
    );    

    const getTableOverrides = useCallback(() => {
        return {
            typeColumn: { renderCell: getCellType },
            statusColumn: { renderCell: getCellStatus },
            policyColumn: { renderCell: getCellPolicy },
            effectiveColumn: { renderCell: getCellEffective },
            premiumColumn: { renderCell: getCellPremium },
             printAllIDCardsColumn: {
                renderCell: getPrintAllIDCards
                 },
            fileAClaimColumn: {
                visible: claimCapabilityCheck,
                renderCell: getCellClaim
            },
            agentColumn: {
                renderCell: getCellAgent
            },
            riskUnitDetails:{
                renderCell: getCellRiskUnits
            },
           
            
        };
    }, [accountData, getCellAgent]);

    const onFileAClaimQuickLinkClick = useCallback(
        () => () => {
            const { history } = props;
            return history.push({
                pathname: '/fnol-select-policy',
                state: {
                    redirectPath: '/home',
                    claimData: { lossDate: new Date() }
                }
            });
        },
        [props]
    );

    const handleLinkClick = useCallback(
        (event) => {
            const { history } = props;
            return history.push(event.path);
        },
        [props]
    );

    const showBillingSection = useMemo(() => {
        return isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' });
    }, []);

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }
    const override = {
        '@field': {
            phone: {
                labelPosition: 'left'
            }
        },
        greetingMessage: {
            content: `Hello, ${auth.given_name}!`
        }, 
        myBalanceOverdueAmount: {
            visible: !_.isEmpty(getMyBalanceOverDueAndCurrentData('due'))
        },
        myBalanceCurrentAmount: {
            visible: !_.isEmpty(getMyBalanceOverDueAndCurrentData('billed'))
        },
        dueDateContainer: {
            visible: !_.isEmpty(getMyBalanceOverDueAndCurrentData('due'))
        },
        dueDateLabel: {
            visible: !_.isEmpty(getMyBalanceOverDueAndCurrentData('billed'))
        },
        payNowButton: {
            visible: false
        },
        mediaSidebar: {
            showContact: accountData.length > 1
        },
        policiesTable: {
            data: accountPolicyData
        },
        printAnIDCardLink: {
            visible: !_.isNil(policyWithIDCard),
            href: getIDCardQuickLink,
            target: '_blank'
        },
        setupAutomatedPaymentsLink: {
            to: automatedPaymentURL,
            visible: !!automatedPaymentURL && showBillingSection
        },
        getAPAQuote: {
            onClick: onQuoteQuickLinkClick('PersonalAuto'),
            visible: appConfig.quoteAndBuy.ampQuote && firstAccountSummaryDefined
        },
        getAHOQuote: {
            onClick: onQuoteQuickLinkClick('Homeowners'),
            visible: appConfig.quoteAndBuy.ampQuote && firstAccountSummaryDefined
        },
        renewalNotification: {
            visible: shouldRenewalNotificationShow(accountPolicyData)
        },
        fileAClaimLink: {
            onClick: onFileAClaimQuickLinkClick(),
            visible: claimCapabilityCheck
        },
        tileGroupContainer: {
            visible: showBillingSection
        },
        makeAPaymentLink: {
            visible: showBillingSection
        },
        balanceContainer:{
            visible:false
        },
        nextBillContainer:{
            visible:false
        },
        lastPaymentContainer:{
            visible:false
        },
        quickLinkHeader:{
            visible:false
        },
        
        ...getTableOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveValue: readValue,
        resolveCallbackMap: {
            handleLinkClick
        }
    };

    return renderContentFromMetadata(metadata.pageContent, override, resolvers);
}

export default withRouter(LandingPage);
