import React, { useCallback, useContext,useState,useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PAVehicleLevelCoveragesRI.metadata.json5';
import styles from './PAVehicleLevelCoveragesRI.module.scss';
import { BreakpointTrackerContext } from '@jutro/layout';
import './PAVehicleLevelCoveragesRI';
//import { get } from 'core-js/core/dict';



function PAVehicleLevelCoveragesRI(props) {
    const { data : vehicleCoverage , paCoverages : paCoverages } = props;
    const breakpoint = useContext(BreakpointTrackerContext);

    // const getDataTableCell = useCallback((item, index, { path: property }) => {
    //     return item[property];
    // });

   

 

    const getLiabilityBodilyInjury = useCallback((lineCoverage) =>{
        var i 
        for( let p in lineCoverage){
           
           
               
                    if(lineCoverage[p].name == 'Bodily Injury'){
                        i = 'Liability - Bodily Injury (Each Person/Each Accident)'
                    }
                
               
            
              
        }
        return ` ${i} ` 
     });

      const getComprehensiveDeductible = useCallback((vehicleCoverage) =>{
         var i ;
      //   var length = paCoverages.vehicleDTOs.length;
     //    var vehicleNumber = 0;
         
         for( let p in vehicleCoverage){
                
                     if( vehicleCoverage[p].name == 'Comprehensive'){
                        
                            if(vehicleCoverage[p].deductible == 'Actual Cash Value'){
                                
                                return `$0`
                            }
                            else {
                                i = vehicleCoverage[p].deductible
                                return `$ ${i} ` 

                            }
                                
                  
                     }
                     
          
         }
         
         
      });

     const getCollisionDeductible = useCallback((vehicleCoverage) =>{
        var i 
        
        for( let p in vehicleCoverage){
       
            if( vehicleCoverage[p].name == 'Collision'){
                        
                            
                i = vehicleCoverage[p].deductible
                return `$ ${i} ` 
 
    }
        }
         
     });

     const getFSGCoverageDeductible = useCallback((vehicleCoverage) =>{
        var i 
        for( let p in vehicleCoverage){
       
            if( vehicleCoverage[p].name == 'Full Safety Glass Coverage'){
                        
                            
                i = `INCL`
                return ` ${i} ` 
 
    }
          
        }
       
     });

     const getTAndLaborLimit = useCallback((vehicleCoverage) =>{
        var i 
        for( let p in vehicleCoverage){
       
                    if(vehicleCoverage[p].name == 'Towing & Labor Costs Coverage'){
                        
                            
                        i = vehicleCoverage[p].limit
                        return `$ ${i} ` 
                  
                    }
          
        }
        
     });

     const getOTExpenseLimit = useCallback((vehicleCoverage) =>{
        var i 
        for( let p in vehicleCoverage){
       
                    if(vehicleCoverage[p].name == 'Optional Limits Transportation Expense Coverage'){
                        
                            
                        i = vehicleCoverage[p].limit
                        return `$ ${i} ` 
                  
                    }
          
        }
        
     });

     const getUMRIPropertyDamageLimit = useCallback((vehicleCoverage) =>{
        var i 
        for( let p in vehicleCoverage){
       
                    if(vehicleCoverage[p].name == 'Property Damage-Uninsured Motorists  Coverage - Rhode Island'){
                        
                            
                        i = vehicleCoverage[p].limit
                        return `$ ${i} ` 
                  
                    }
          
        }
        
     });





    


    

   
    const overrides = {
        '@element': {
            textAlign: 'left'
        },

        // infoTable:{
        //     data: _.get(lineCoverage)
        // },


         comprehensive : {
             visible : vehicleCoverage.find(cov=>cov.name === 'Comprehensive') != null
         },

         comprehensiveLimit : {
            visible : vehicleCoverage.find(cov=>cov.name === 'Comprehensive') != null,
            hideLabel : breakpoint==='phone'? false : true
        },

        comprehensiveDeductible : {
            visible : vehicleCoverage.find(cov=>cov.name === 'Comprehensive') != null,
            value : getComprehensiveDeductible(vehicleCoverage),
            hideLabel : breakpoint==='phone'? false : true
        },

        collision : {
            visible : vehicleCoverage.find(cov=>cov.name === 'Collision') != null
        },

        collisionLimit : {
            visible : vehicleCoverage.find(cov=>cov.name === 'Collision') != null,
            hideLabel : breakpoint==='phone'? false : true
        },

        collisionDeductible : {
            visible : vehicleCoverage.find(cov=>cov.name === 'Collision') != null,
            value : getCollisionDeductible(vehicleCoverage),
            hideLabel : breakpoint==='phone'? false : true
        },

        tAndLabor : {
            visible : vehicleCoverage.find(cov=>cov.name === 'Towing & Labor Costs Coverage') != null
        },

        tAndLaborLimit : {
            visible : vehicleCoverage.find(cov=>cov.name === 'Towing & Labor Costs Coverage') != null,
            value : getTAndLaborLimit(vehicleCoverage),
            hideLabel : breakpoint==='phone'? false : true
        },

        tAndLaborDeductible : {
            visible : vehicleCoverage.find(cov=>cov.name === 'Towing & Labor Costs Coverage') != null,
            hideLabel : breakpoint==='phone'? false : true
        },

        oTExpense : {
            visible : vehicleCoverage.find(cov=>cov.name === 'Optional Limits Transportation Expense Coverage') != null
        },

        oTExpenseLimit : {
            visible : vehicleCoverage.find(cov=>cov.name === 'Optional Limits Transportation Expense Coverage') != null,
            value : getOTExpenseLimit(vehicleCoverage),
            hideLabel : breakpoint==='phone'? false : true
        },

        oTExpenseDeductible : {
            visible : vehicleCoverage.find(cov=>cov.name === 'Optional Limits Transportation Expense Coverage') != null,
            hideLabel : breakpoint==='phone'? false : true
        },

        uMRIPropertyDamage : {
            visible : vehicleCoverage.find(cov=>cov.name === 'Property Damage-Uninsured Motorists  Coverage - Rhode Island') != null
        },

        uMRIPropertyDamageLimit : {
            visible : vehicleCoverage.find(cov=>cov.name === 'Property Damage-Uninsured Motorists  Coverage - Rhode Island') != null,
            value : getUMRIPropertyDamageLimit(vehicleCoverage),
            hideLabel : breakpoint==='phone'? false : true
        },

        uMRIPropertyDamageDeductible : {
            visible : vehicleCoverage.find(cov=>cov.name === 'Property Damage-Uninsured Motorists  Coverage - Rhode Island') != null,
            hideLabel : breakpoint==='phone'? false : true
        },

        personalLiabilityLimit : {
            visible : breakpoint==='phone'? false : true
        },
    
          personalLiabilityDeductible : {
           visible : breakpoint==='phone'? false : true 
        }

    };

    const resolvers = {
        resolveCallbackMap:{
          //  getDataTableCell :  getDataTableCell 
            // liabilityLimit:getLimit,
            // rentation:getRentation

        },
        resolveClassNameMap: styles,

    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.componentContent,
                vehicleCoverage,
                
                id,
                path,
                overrides
            );
        },
        [vehicleCoverage, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleCoverage,paCoverages}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

PAVehicleLevelCoveragesRI.propTypes = {
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
};

PAVehicleLevelCoveragesRI.defaultProps = {
    value: []
};

export default PAVehicleLevelCoveragesRI;
