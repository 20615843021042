import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { DocumentDownloadService } from 'gw-portals-document-js';

export default class DocumentService {
    /**
     * Retrieves a document link.
     *
     * @param {String} documentID the documents id to retrieve
     * @param {String} token the security token
     */

    static downloadPolicyDocument(documentID, token) {
        return DocumentDownloadService.getDocumentLink(getProxiedServiceUrl('policyDocument'), documentID, token);
    }

    static downloadPolicyDocument_Ext(documentType, policyNumber, token) {
        return DocumentDownloadService.getDocumentLink_Ext(getProxiedServiceUrl('policyDocument_Ext'), documentType, policyNumber, token);
    }

    static downloadBillingDocument_Ext(documentType, policyNumber, docUid, token) {
        return DocumentDownloadService.getBillingDocumentLink_Ext(getProxiedServiceUrl('billingDocument_Ext'), documentType, policyNumber, docUid, token);
    }
}
