import React, {
    useCallback, useMemo, useState, useEffect, useContext
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import metadata from './ForgotPasswordOtpComponent.metadata.json5';
import messages from './ForgotPasswordOtpComponent.messages';
import styles from './ForgotPasswordOtpComponent.module.scss';
import { EntryPageTemplate } from 'gw-components-platform-react';
import AuthMessageComponent from '../AuthMessageComponent/AuthMessageComponent';
import { useAuthentication } from 'gw-digital-auth-react';
import appConfig from 'app-config';
import { TranslatorContext } from '@jutro/locale';
import { OtpService } from 'nlc-capability-otp';

const applicationDetails = _.get(appConfig, 'applicationDetails', {});

function ForgotPasswordOtpComponent(props) {
    const { history, userDetails, onOtpSuccessVerification } = props;
    const {userName, email, phoneNumber}= userDetails;
    const [formData, updateFormData] = useState({});
    const [phoneOtpSent, setPhoneOtpSent] = useState(false);
    const [emailOtpSent, setEmailOtpSent] = useState(false);
    const [phoneOtpVerified, setPhoneOtpVerified] = useState(false);
    const [emailOtpVerified, setEmailOtpVerified] = useState(false);
    const [invalidOtp, setInvalidOtp] = useState(false);
    const [verificationFailedStatus, setVerificationFailedStatus] = useState(false); 
    const translator = useContext(TranslatorContext);    
    const [moveToResetPwd, setMoveToResetPwd]= useState(false);
    const [message, setMessage] = useState();
    const [showUserName, setShowUserName] = useState(false);
    const isMFAEnabled = _.get(appConfig, 'env.MFA_Enabled_Ext', 'false');
    
    const { forgotPassword_Ext, authHeader } = useAuthentication();

    useEffect(() => { 
        const formData = {userName:userName, email:email, phoneNumber:phoneNumber, emailOtpCode:undefined, mobileOtpCode:undefined};
        updateFormData(formData);     
    }, [forgotPassword_Ext]);
    
    const sendOtpToPhone= useCallback(async() => {  
        const inputData = new FormData();
        inputData.append('userName',  userName);
        inputData.append('phone',  _.get(formData, 'phoneNumber'));
        const data = {
            method: 'POST',
            body: inputData,
            redirect: 'follow',

        }                    
        try{
            const otp = await OtpService.sendSms(data);
            if(otp.status === 200) {
                setPhoneOtpSent(true);
            } else {
                setPhoneOtpSent(false);
            }           
        }catch(err){
            setPhoneOtpSent(false);
        }         
        //setPhoneOtpSent(true);
    },[formData]);

    const sendOtpToEmail = useCallback(async() => { 
        const inputData = new FormData();
        inputData.append('userName',  userName);
        inputData.append('email',  email);
        inputData.append('type',  'forgotPassword');
        const data = {
            method: 'POST',
            body: inputData,
            redirect: 'follow',

        }                    
        try{
            const otp = await OtpService.sendEmailOtp(data);
            if(otp.status === 200) {
                setEmailOtpSent(true);
            } else {
                setEmailOtpSent(false);
            }           
        }catch(err){
            setEmailOtpSent(false);
        }           
        //setEmailOtpSent(true);
    },[userName, email]);

    const verifyMobileOtp = useCallback(async() => {  
        const inputData = new FormData();
        inputData.append('userName', userName);
        inputData.append('phone', _.get(formData, 'phoneNumber'));
        inputData.append('otp', _.get(formData, 'mobileOtpCode'));
        const data = {
            method: 'POST',
            body: inputData,
            redirect: 'follow',

        }             
        try{
            const otp = await OtpService.verifySmsOtp(data);
            if(otp.status === 200) {
                setInvalidOtp(false);
                setPhoneOtpVerified(true);
            }else{
                setInvalidOtp(true);
                setPhoneOtpVerified(false);
            }           
        }catch(err){
            setPhoneOtpVerified(false);
            console.log(err);
        }              
        //setPhoneOtpVerified(true);
    },[formData, setPhoneOtpVerified]);

    const verifyEmailOtp = useCallback(async() => {   
        const inputData = new FormData();
        inputData.append('userName', userName);
        inputData.append('email', email);        
        inputData.append('otp', _.get(formData, 'emailOtpCode'));
        const data = {
            method: 'POST',
            body: inputData,
            redirect: 'follow',

        }             
        try{
            const otp = await OtpService.verifyEmailOtp(data);
            if(otp.status === 200) {
                setInvalidOtp(false);
                setEmailOtpVerified(true);
            }else{
                setInvalidOtp(true);
                setEmailOtpVerified(false);
            }           
        }catch(err){
            setEmailOtpVerified(false);
            console.log(err);
        }   
        //setEmailOtpVerified(true);
    },[formData, userName, email]);

    const handleNext = useCallback(() => {       
        onOtpSuccessVerification();               
    },[history, emailOtpVerified, phoneOtpVerified, formData]);

    const readValue = useCallback((id, path) => _.get(formData, path), [formData]);

    const writeValue = useCallback((value, path) => {
        const nextFormData = _.cloneDeep(formData);
        _.set(nextFormData, path, value);
        updateFormData(nextFormData);
    },[formData]);

    const getMessage = useCallback(() => {
       if(emailOtpSent || phoneOtpSent) {
            return translator(messages['otpSentMessage']);
       }else if(verificationFailedStatus){
           return translator(messages['otpValidationMessage']);
       }
       return '';
    },[emailOtpSent, phoneOtpSent, verificationFailedStatus]);

    const overrideProps = { 
        successfulContainer: {
            visible: showUserName,
            description:translator(messages.userIdMessage, {userName})
        }, 
        errorContainer: {
            visible: false
        },  
        otpContainer: {
            visible: !showUserName
        } ,  
        mfaInputContainer: {
            visible: isMFAEnabled === 'true'
        }, 
        otpInfoMessageContainer: {
            visible: emailOtpSent || phoneOtpSent || verificationFailedStatus
        },        
        otpInfoMessage: {
            type: (emailOtpSent || phoneOtpSent) ? 'info' : 'error',
            message: getMessage()
        },          
        email: {
            readOnly: true,
            value:_.get(formData, 'email')
        },
        sendCodeToEmail: {
            disabled: !_.get(formData, 'email'),
            onClick: sendOtpToEmail,
            className: styles.otpButton
        },
        enterEmailCode: {
            disabled: !emailOtpSent,
            onValueChange: writeValue,
            value: _.get(formData, 'emailOtpCode')
        },
        verifyEmailOtp: {
            disabled: !emailOtpSent && _.get(formData, 'emailOtpCode')===undefined,
            onClick: verifyEmailOtp,
            className: styles.otpButton
        },
        phoneNumber: {
            readOnly: true,
            value:_.get(formData, 'phoneNumber')
        },
        sendCodeToPhone: {
            disabled:(!_.get(formData, 'phoneNumber') || !emailOtpSent || !emailOtpVerified),
            onClick: sendOtpToPhone,
            className: styles.otpButton
        },
        enterMobileCode: {
            disabled: !phoneOtpSent,
            onValueChange: writeValue,
            value: _.get(formData, 'mobileOtpCode')
        },
        verifyPhoneOtp: {
            disabled: !phoneOtpSent && _.get(formData, 'mobileOtpCode')===undefined,
            onClick: verifyMobileOtp,
            className: styles.otpButton
        },
        nextButton: {
            disabled: !emailOtpVerified || !phoneOtpVerified || verificationFailedStatus,
            onClick: handleNext
        },
        otpErrorMessageContainer: {
            visible: invalidOtp && invalidOtp === true,
        },
        otpErrorMessage: {                
            message: translator(messages['invalidOtpInfo'])
        }, 
        actionsContainer: {
            className: styles.actionContainer
        }     
    }

    const resolvers = {
        classNameMap:styles,
        resolveComponentMap: {
            authmessagecomponent: AuthMessageComponent
        }
    };

    const renderedContentFromMetadata = renderContentFromMetadata(
        metadata.componentContent,
        overrideProps,
        resolvers
    );
    if(moveToResetPwd){
        const nextState = { pathname: '/auth/resetpassword', state:{userName}};                    
        return <Redirect to={nextState} />;
    }

    return renderContentFromMetadata(metadata.componentContent, overrideProps, resolvers);
}

ForgotPasswordOtpComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(ForgotPasswordOtpComponent);
