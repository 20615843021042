import React, { useCallback, useContext,useState,useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './DFRCSectionICoverages.metadata.json5';
import styles from './DFRCSectionICoverages.module.scss';
import './DFRCSectionICoverages';
//import { get } from 'core-js/core/dict';



function DFRCSectionICoverages(props) {
    const { sectionIDFCoverages : sectionIPropertyCoverages_Ext } = props;

    // const getDataTableCell = useCallback((item, index, { path: property }) => {
    //     return item[property];
    // });

     const getDwellingLimit = useCallback((sectionIPropertyCoverages_Ext) =>{
        var i 
        for( let p in sectionIPropertyCoverages_Ext){
           
            if(sectionIPropertyCoverages_Ext[p].dwelling == 'Coverage A – Dwelling'){
                i = 'Condominium Fixtures, Additions & Alterations'
                
            }
              
        }
        return ` ${i} ` 
        
     });

     const getCovALimit = useCallback((sectionIPropertyCoverages_Ext) =>{
        var i 
        for( let p in sectionIPropertyCoverages_Ext){
            if(sectionIPropertyCoverages_Ext[p].dwelling == 'Coverage A – Dwelling'){
                i = sectionIPropertyCoverages_Ext[p].terms[0].amount
                
            }
        }
        return ` \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 Limit \u00A0\u00A0\u00A0 $${i} `  
    });

    const getOtherStructures = useCallback((sectionIPropertyCoverages_Ext) =>{
        var i 
        for( let p in sectionIPropertyCoverages_Ext){
           
            if(sectionIPropertyCoverages_Ext[p].otherStructures == 'Coverage B – Other Structures'){
                i = 'Coverage B – Other Structures'
                
            }
              
        }
       
        
     });

     const getCovBLimit = useCallback((sectionIPropertyCoverages_Ext) =>{
        var i 
        for( let p in sectionIPropertyCoverages_Ext){
            if(sectionIPropertyCoverages_Ext[p].otherStructures == 'Coverage B – Other Structures'){
                i = sectionIPropertyCoverages_Ext[p].terms[0].amount
                return `Cov B Limit \u00A0\u00A0\u00A0 $${i} `
            }
        }
        return `Cov B Limit \u00A0\u00A0\u00A0 - `
    });

    const getPersonalProperty = useCallback((sectionIPropertyCoverages_Ext) =>{
        var i 
        for( let p in sectionIPropertyCoverages_Ext){
           
            if(sectionIPropertyCoverages_Ext[p].personalProperty == 'Coverage C – Personal Property'){
                i = 'Coverage C – Personal Property'
                
            }
              
        }
        return ` ${i} ` 
        
     });

     const getCovCLimit = useCallback((sectionIPropertyCoverages_Ext) =>{
        var i 
        for( let p in sectionIPropertyCoverages_Ext){
            if(sectionIPropertyCoverages_Ext[p].personalProperty == 'Coverage C – Personal Property'){
                i = sectionIPropertyCoverages_Ext[p].terms[0].amount
                return `Cov C Limit \u00A0\u00A0\u00A0 $${i} `
            }
        }
        return `Cov C Limit \u00A0\u00A0\u00A0 - `  
    });

    const getFairRentalValue = useCallback((sectionIPropertyCoverages_Ext) =>{
        var i 
        for( let p in sectionIPropertyCoverages_Ext){
           
            if(sectionIPropertyCoverages_Ext[p].fairRentalValue == 'Coverage D – Fair Rental Value'){
                i = 'Coverage D – Fair Rental Value'
                
            }
              
        }
        return ` ${i} ` 
        
     });

     const getCovDLimit = useCallback((sectionIPropertyCoverages_Ext) =>{
        var i 
        for( let p in sectionIPropertyCoverages_Ext){
            if(sectionIPropertyCoverages_Ext[p].fairRentalValue == 'Coverage D – Fair Rental Value'){
                i = sectionIPropertyCoverages_Ext[p].terms[0].amount
                return `Cov D Limit \u00A0\u00A0\u00A0 $${i} `
            }
        }
        return `Cov D Limit \u00A0\u00A0\u00A0 - `  
    });

    const getAdlExpense = useCallback((sectionIPropertyCoverages_Ext) =>{
        var i 
        for( let p in sectionIPropertyCoverages_Ext){
           
            if(sectionIPropertyCoverages_Ext[p].additionalLivingExpenses == 'Coverage E – Additional Living Expenses'){
                i = 'Coverage E – Additional Living Expenses'
                
            }
              
        }
        return ` ${i} ` 
        
     });

     const getCovELimit = useCallback((sectionIPropertyCoverages_Ext) =>{
        var i 
        for( let p in sectionIPropertyCoverages_Ext){
            if(sectionIPropertyCoverages_Ext[p].additionalLivingExpenses == 'Coverage E – Additional Living Expenses'){
                i = sectionIPropertyCoverages_Ext[p].terms[0].amount
                return `Cov E Limit \u00A0\u00A0\u00A0 $${i} `  
                
            }
        }
        return `Cov E Limit \u00A0\u00A0\u00A0  - `
       
    });

    

    

   
    const overrides = {
        '@element': {
            textAlign: 'left'
        },

        infoTable:{
            data: _.get(sectionIPropertyCoverages_Ext)
        },

        dwelling:{
            value : getDwellingLimit(sectionIPropertyCoverages_Ext)
        },

        covALimit:{
            value : getCovALimit(sectionIPropertyCoverages_Ext)
        },
        covBLimit:{
            value : getCovBLimit(sectionIPropertyCoverages_Ext)
        },

        covCLimit:{
            value : getCovCLimit(sectionIPropertyCoverages_Ext)
        },

        covDLimit:{
            value : getCovDLimit(sectionIPropertyCoverages_Ext)
        },

        covELimit:{
            value : getCovELimit(sectionIPropertyCoverages_Ext)
        },

    };

    const resolvers = {
        resolveCallbackMap:{
          //  getDataTableCell :  getDataTableCell 
            // liabilityLimit:getLimit,
            // rentation:getRentation

        },
        resolveClassNameMap: styles,

    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.componentContent,
                sectionIPropertyCoverages_Ext,
                id,
                path,
                overrides
            );
        },
        [sectionIPropertyCoverages_Ext, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={sectionIPropertyCoverages_Ext}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

DFRCSectionICoverages.propTypes = {
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
};

DFRCSectionICoverages.defaultProps = {
    value: []
};

export default DFRCSectionICoverages;
