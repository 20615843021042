import { setComponentMapOverrides } from '@jutro/uiconfig';

import FNOLBOPWizard from './FNOLBOPWizard';

setComponentMapOverrides(
    {
        FNOLBOPWizard: { component: 'FNOLBOPWizard' }
    },
    {
        FNOLBOPWizard
    }
);

export {
    FNOLBOPWizard as default
};
