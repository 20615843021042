import React, { useCallback, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IntlContext } from '@jutro/locale';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PolicyCommonContacts.metadata.json5';
import styles from './PolicyCommonContacts.module.scss';
import './PolicyCommonContacts.messages';

const getPhoneNumber = (contact) => {
    if(contact.primaryPhoneType === 'work'){
        return contact.workNumber
    }
    else if(contact.primaryPhoneType === 'home'){
        return contact.homeNumber 
    }
    else{
        return contact.cellNumber  
    }
   
};

const getCell = (items, index, property) => {
    return !_.isObject(items[property.id]) ? items[property.id] : null;
};

const formatContactRole = (roles) => {
    return roles.length ? roles.toString() : '-';
};

function PolicyCommonContacts(props) {
    const { value: policyContacts } = props;

    const intl = useContext(IntlContext);

    const getContactDoB = useCallback((dateOfBirth) => {
        if (_.isEmpty(dateOfBirth)) {
            return '';
        }
        return intl.formatDate(
            new Date(dateOfBirth.year, dateOfBirth.month, dateOfBirth.day),
            { year: 'numeric', month: '2-digit', day: '2-digit' }
        );
    }, [intl]);

    const getContactsData = useCallback(() => {
        const contactData = policyContacts.reduce((prev, curr) => {
            const existContact = prev.find(
                (contact) => contact.contact.publicID === curr.contact.publicID
            );
            if (existContact) {
                existContact.contactRole.push(curr.contactRole);
            } else {
                prev.push({
                    contact: curr.contact,
                    contactRole: [curr.contactRole]
                });
            }
            return prev;
        }, []);

        return contactData
            .map((contact) => {
                return {
                    contactName: contact.contact.displayName,
                    contactRole: formatContactRole(contact.contactRole),
                    contactDOB: getContactDoB(contact.contact.dateOfBirth),
                    contactEmail: contact.contact.emailAddress1
                        ? contact.contact.emailAddress1
                        : '-',
                    contactPhone: getPhoneNumber(contact.contact),
                    contactAddress: contact.contact.primaryAddress.displayName
                };
            })
            .sort((a, b) => {
                return a.name - b.name;
            });
    }, [policyContacts]);

    const overrides = {
        '@element': {
            textAlign: 'left'
        },
        contactsTable: {
            data: getContactsData()
        },
        contactDOBColumn:{
            visible:false
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            getContactData: getCell
        },
        resolveClassNameMap: styles
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.componentContent,
                policyContacts,
                id,
                path,
                overrides
            );
        },
        [policyContacts, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyContacts}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

PolicyCommonContacts.propTypes = {
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
};

PolicyCommonContacts.defaultProps = {
    value: []
};

export default PolicyCommonContacts;
