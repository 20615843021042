import { defineMessages } from 'react-intl';

export default defineMessages({
    language: {
        id: 'gateway.views.preferences.languages',
        defaultMessage: 'Language',
    },
    Preferences: {
        id: 'portal.index.Preferences',
        defaultMessage: 'Preferences',
    }
});
